import React, { useMemo } from 'react';
import {
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Card,
} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { FaChartLine } from 'react-icons/fa';
import { CheckForLight } from '../../util/common/CommanFunction';

// HighchartsExporting(Highcharts);
// HighchartsExportData(Highcharts);

const Barchart = ({ EventStatistcs, size }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(EventStatistcs, 'barevent');
  const chartData = useMemo(() => {
    if (!EventStatistcs || EventStatistcs.length === 0) {
      return [];
    }
    const redColor = '#FF1913';
    return EventStatistcs.map((item) => ({
      name: item.siteName,
      data: [Number(item.alarmSiteCount)],
      color: redColor,
    }));
  }, [EventStatistcs]);
  function validateDataArray(objects) {
    return objects.every((obj) => {
      const data = obj.data;

      // Check if data is an empty array
      if (data.length === 0) return false;

      // Check if all values are 0, null, or undefined
      const allValuesInvalid = data.every(
        (value) => value === 0 || value === null || value === undefined
      );
      return !allValuesInvalid;
    });
  }

  const dataIsAvilable = validateDataArray(chartData);

  const columnChartOptions = {
    chart: {
      type: 'column',
      height: size.height,
      marginRight: 20,
      backgroundColor: CheckForLight() ? 'white' : '#121212',
    },
    xAxis: {
      categories: chartData.map((item) => 'Sites'),
      labels: {
        rotation: 0,
        style: {
          color: CheckForLight() ? 'black' : 'white',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Alarm Count',
        style: {
          color: CheckForLight() ? 'black' : 'white',
        },
      },
      labels: {
        style: {
          color: CheckForLight() ? 'black' : 'white',
        },
      },
      // tickAmount: 4,
    },
    title: {
      text: null,
      style: {
        fontSize: '18px',
        fontFamily: "'Inter','Ubuntu','Roboto Slab','Fira Sans'",
        fontWeight: 'normal',
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointWidth: 20,
        groupPadding: 0.1,
      },
    },
    tooltip: {
      formatter: function () {
        const mappedName = this.series.name;
        return `<b>${mappedName}</b>: ${this.y}`;
      },
    },
    series: chartData.map((series) => ({
      ...series,
      name: series.name,
    })),
  };

  return (
    <Card
      elevation={0}
      className="total-metrics"
      style={{
        // width: '35vw',
        paddingTop: '20px',
        borderRadius: '20px',
      }}
    >
      <Grid container padding={0}>
        <Grid item lg={10} md={10}>
          <Typography
            style={{
              fontSize: '18px',
              marginLeft: '30px',
              marginBottom: '10px',
              textTransform: 'capitalize',
              lineHeight: '14.52px !important',
              color: ' rgb(113, 118, 111)',
            }}
          >
            Top 5 Sites With Alarms
          </Typography>
        </Grid>

        {dataIsAvilable ? (
          <Grid item xs={12} mt={1}>
            <HighchartsReact
              highcharts={Highcharts}
              options={columnChartOptions}
              containerProps={{
                style: {
                  width: isSmallScreen ? 'auto' : 'auto',
                },
              }}
            />
          </Grid>
        ) : (
          <div
            style={{
              display: 'flex',
              height: '43vh',
              justifyContent: 'center',
              alignItems: 'center',
              // marginTop: '15%',
              marginLeft: 'auto',
              marginRight: 'auto',
              // Adjust this value if needed
            }}
          >
            <Typography
              style={{
                fontSize: '20px',
              }}
            >
              There are no Alarms to Show
            </Typography>
          </div>
        )}
      </Grid>
    </Card>
  );
};
export default Barchart;
