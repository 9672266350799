import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Stack,
  Autocomplete,
  Card,
  Tooltip,
  IconButton,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FadeLoader from "react-spinners/FadeLoader";
import * as XLSX from "xlsx";
// ========Modal================
import { styled } from "@mui/material/styles";
/*------------api call-----------------*/
import {
  fetchEquipmentTable,
  //   Getdropdownvalue,
} from "../../Api/EquipmenttypeApi";
import {
  Equipmentsitedropdowns,
  //   getDownTimeMinutes,
  postDgrValueSave,
} from "../../Api/DgrApi";
import { Getdropdownvalue } from "../../Api/MapsiteApi";
import CustomBreadcrumbs from "../util/components/CustomBread";
import { EquipmentType } from "../util/TextField";
import { Statusdropdown, fetchInstalltionCapacity } from "../../Api/CommonApi";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export default function DailyGeneration({ dailyGeneration }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  console.log(SiteEquipment, "SiteEquipment");
  const [equipmentvalue, setEquipmentValue] = useState([]);
  const [DownTime, setDownTime] = useState("");
  console.log(DownTime);
  console.log(DownTime[0]?.downTime);
  console.log(equipmentvalue);
  const [down, setdown] = useState("");
  const [downn, setdownn] = useState("");
  console.log(downn, "downn");
  const [selectedDate, setSelectedDate] = useState(null);
  const [equipment, setEquipment] = useState("");
  const [toBackend, setToBackend] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const [sitestatus, setSitestatus] = useState("");
  const [remarks, setRemarks] = useState("");
  console.log(remarks, "remarks");
  const [remarkvalue, setRemarkValue] = useState("");
  console.log(remarkvalue, ">>>>>>>");
  const remarkRefs = useRef([]);
  const [editcall, seteditcall] = useState();
  const [unitsIC, setUnitsIC] = useState([]);
  const [text, setTextField] = useState([]);
  const fileInputRef = useRef(null);
  const initalState = {};
  const [stdField, setStdField] = useState(initalState);
  console.log(stdField);
  console.log(stdField[0]?.downtime);
  const [statusDrop, setStatusDrop] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  /*----------------------------Fields-------------------------------*/
  const initialFieldSet = {
    equipmentName: "",
    equipmentId: "",
    timestamp: "",
    todayEnergy: "",
    exportTotalEnergy: "",
    importTotalEnergy: "",
    irradiation: "",
    ambientTemperature: "",
  };
  const [fieldSets, setFieldSets] = useState([initialFieldSet]);
  console.log(fieldSets);
  console.log("Field Sets after update:", fieldSets);
  const handleSite = async (event, value) => {
    setSitestatus(value);
    const site = siteIdName.find((item) => item.siteName === value);
    if (site) {
      try {
        const [equipmentData, downtimeData] = await Promise.all([
          Equipmentsitedropdowns(site.siteId),
        ]);
        setEquipmentValue(equipmentData);
        const newFieldSets = equipmentData.map((equipment) => {
          return {
            equipmentName: equipment.displayName || "",
            equipmentId: equipment.equipmentId || "",
          };
        });
        setFieldSets(newFieldSets);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const toolStyle = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  };
  /*--------------------------Download Excel ----------------------*/

  const handleDownload = () => {
    // Prepare your data here. For this example, I'll use dummy data
    const data = [
      { equipmentName: "Inverter 1", todayEnergy: 283 },
      { equipmentName: "Energy Meter", exportEnergy: 500, importEnergy: 100 },
      { equipmentName: "Sensor", irradiation: 7.83, ambient: 48.2 },
    ];

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Daily Generation Data");

    // Generate Excel file and download
    XLSX.writeFile(wb, "DailyGenerationData.xlsx");
  };
  /*--------------------------Upload Excel ----------------------*/
  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input reference is not available");
      // Optionally, show an error message to the user
      setSnack({
        open: true,
        severity: "error",
        message: "Upload functionality is not available at the moment",
      });
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            
            // Validate the data
            const { validData, errorRows } = validateData(jsonData);

            if (errorRows.length > 0) {
                // Handle errors, e.g., show an error message or highlight rows
                console.error('Errors in rows:', errorRows);
            } else {
                // If data is valid, proceed with upload
                handleUpload(validData);
            }
        };
        reader.readAsArrayBuffer(file);
    }
};
const validateData = (data) => {
    const validData = [];
    const errorRows = [];

    data.forEach((row, index) => {
        const errors = [];

        // Validate each field according to your requirements
        if (!row['Field1'] || row['Field1'].length > 50) errors.push('Field1 error');
        if (!row['Description'] || row['Description'].length > 100) errors.push('Description error');
        // Add more validations as required...

        if (errors.length > 0) {
            errorRows.push({ index, errors });
        } else {
            validData.push(row);
        }
    });

    return { validData, errorRows };
};
  const processUploadedData = (data) => {
    try {
      const processedData = data.map((row) => ({
        equipmentName: row.equipmentName || "",
        todayEnergy: row.todayEnergy || "",
        exportEnergy: row.exportEnergy || "",
        importEnergy: row.importEnergy || "",
        irradiation: row.irradiation || "",
        ambient: row.ambient || "",
      }));

      setFieldSets(processedData);

      setSnack({
        open: true,
        severity: "success",
        message: "Data uploaded successfully",
      });
    } catch (error) {
      console.error("Error processing uploaded data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error processing the uploaded data",
      });
    }
  };
  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate());
    setSelectedDate(selectedDate);
    console.log(selectedDate);
  };

  const sortedFieldSets = [...fieldSets].sort((a, b) => {
    const order = ["inverter", "energy meter", "sensor"];
    const aType = order.findIndex((type) =>
      a.equipmentName.toLowerCase().includes(type)
    );
    const bType = order.findIndex((type) =>
      b.equipmentName.toLowerCase().includes(type)
    );
    return aType - bType;
  });

  const getEquipment = async (id) => {
    // const data = await Equipmentsitedropdowns(id);
    // setEquipmentValue(data);
    // return(data)
  };

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchUnits();
    getsitelist();
  }, []);
  useEffect(() => {
    let data = EquipmentType();
    setTextField(data);
  }, []);
  useEffect(() => {
    console.log("Field Sets updated:", fieldSets);
  }, [fieldSets]);
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, "dataOutside");
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };
  const getsitelist = async () => {
    try {
      let data = await Getdropdownvalue();
      if (Array.isArray(data)) {
        setSiteId(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteId([data]);
      }
      setLoading(false);
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };
  console.log(siteIdName, "siteIdName");
  /*------------------------- handleSave ---------------------------*/

  /*----------------------CHAT GPT------------------------*/
  //   const handleSave = async (apiData) => {
  //     // Convert apiData to an array if it's not already one
  //     const dataArray = Array.isArray(apiData) ? apiData : [apiData];

  //     let data = dataArray.map((item, index) => ({
  //       siteId: item.siteId,
  //       equipmentId: item.equipmentId,
  //       todayEnergy: item.todayEnergy,
  //       exportTotalEnergy: item.exportTotalEnergy,
  //       importTotalEnergy: item.importTotalEnergy,
  //       irradiation: item.irradiation,
  //       ambientTemperature: item.ambientTemperature,
  //       timestamp: item.timestamp,
  //       remarks: fieldSets[index]?.remarks?.join(", ") || "",
  //       createdBy: Number(sessionStorage.getItem("id")),
  //     }));

  //     console.log('Mapped Data:', data);

  //     try {
  //       const response = await postDgrValueSave(data);
  //       console.log("Data saved successfully:", response);

  //       // Reset the fields after save
  //       setFieldSets([initialFieldSet]); // Clear fieldSets after save
  //       setSelectedDate(null); // Clear selectedDate after save
  //       setSitestatus(""); // Clear sitestatus after save
  //       setSiteId([]); // Clear siteIdName
  //       setRemarks(""); // Clear remarks
  //       setSnack({
  //         ...snack,
  //         open: true,
  //         severity: "success",
  //         message: response.statusDescription,
  //       });
  //     } catch (error) {
  //       console.error("Error saving data:", error.message);
  //     }
  //   };

  /*----------------------CLAUDE------------------------*/

  const handleSave = async () => {
    const selectedSite = siteIdName.find(
      (site) => site.siteName === sitestatus
    );

    if (!selectedSite || !selectedDate) {
      setSnack({
        open: true,
        severity: "error",
        message: "Please select both a site and a date.",
      });
      return;
    }

    const formattedDate = selectedDate.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    let data = fieldSets
      .map((fieldSet) => {
        let payload = {
          siteId: selectedSite.siteId,
          equipmentId: fieldSet.equipmentId,
          timestamp: formattedDate,
        };

        if (fieldSet.equipmentName.toLowerCase().includes("inverter")) {
          payload.todayEnergy = parseFloat(fieldSet.todayEnergy) || 0;
        } else if (fieldSet.equipmentName.toLowerCase() === "energy meter") {
          payload.exportTotalEnergy = parseFloat(fieldSet.exportEnergy) || 0;
          payload.importTotalEnergy = parseFloat(fieldSet.importEnergy) || 0;
        } else if (fieldSet.equipmentName.toLowerCase() === "sensor") {
          payload.irradiation = parseFloat(fieldSet.irradiation) || 0;
          payload.ambientTemperature = parseFloat(fieldSet.ambient) || 0;
        }

        return payload;
      })
      .filter((item) => Object.keys(item).length > 3); // Remove entries with only siteId, equipmentId, and timestamp

    console.log(data);

    try {
      const response = await postDgrValueSave(data);
      console.log("Data saved successfully:", response);

      // Reset fields and show success message
      setFieldSets([initialFieldSet]);
      setSelectedDate(null);
      setSitestatus("");
      setSiteId([]);
      setRemarks("");
      setSnack({
        open: true,
        severity: "success",
        message: response.statusDescription || "Data saved successfully",
      });
    } catch (error) {
      console.error("Error saving data:", error.message);
      setSnack({
        open: true,
        severity: "error",
        message: "Error saving data. Please try again.",
      });
    }
  };

  useEffect(() => {
    fetchData();
    getsitelist();
    fetchUnits();
    getEquipment();
    fetchStatus();
  }, []);
  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchEquipmentTable();
      if (Array.isArray(data)) {
        setSiteEquipment(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteEquipment([data]);
      }
      setLoading(false);
      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*---------------------------------- New Equipment Call -----------------------*/
  const handleClose = () => {
    setOpen(false);
    seteditcall([]);

    setEdit(false);
    setEquipment("");
  };
  const Item = styled(Paper)(({ theme }) => ({}));
  const Total = SiteEquipment.length;
  const Active = SiteEquipment.filter((site) => site.status == 1).length;
  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Daily Generaion Value", path: "dgr" },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={() => setSnack(initialSnack)}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  // mt: 2,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "101%",
                  height: "6.5vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </div>
              </Card>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "100%",
                  height: "13vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0 0 30px",
                  }}
                >
                  <div>
                    <Typography className="modal-typo">Site Name *</Typography>
                    <div style={{ marginTop: "5%" }}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={siteIdName.map((option) => option.siteName)}
                        defaultValue={edit ? edit.siteName : null}
                        onChange={(data, event) => handleSite(data, event)}
                        // value={site}
                        sx={{ width: "14vw" }}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} sx={{}} placeholder="IFP" />
                        )}
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: "5%" }}>
                    <Typography className="modal-typo" sx={{ width: "14vw" }}>
                      Date
                    </Typography>
                    <div style={{ marginTop: "5%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="small"
                          // disablePast
                          sx={{ width: "14vw" }}
                          onChange={handleDateChange}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div>
                  <Stack spacing={2} direction="row">
                    {/* <Box sx={{ width: "100%" }}> */}

                    <div style={{ margin: "0 30px 0 0" }}>
                      <Tooltip
                        title="Upload Data"
                        userSelect="none"
                        followCursor
                        PopperProps={toolStyle}
                      >
                        <IconButton
                          variant="text"
                          onClick={handleUpload}
                          style={{
                            textTransform: "capitalize",
                            color: "#004AFF",
                            padding: "0",
                            borderRadius: "20px 20px 20px 20px",
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <FileUploadOutlinedIcon
                            style={{
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Download Data"
                        userSelect="none"
                        followCursor
                        PopperProps={toolStyle}
                      >
                        <IconButton
                          //   disabled={rowsValue.length === 0}
                          variant="text"
                          onClick={handleDownload}
                          style={{
                            textTransform: "capitalize",
                            // color: "white",
                            color: "#004AFF",
                            // fontSize: '70px',
                            padding: "0",
                            borderRadius: "20px 20px 20px 20px",
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <SaveAltIcon
                            style={{
                              //   color: rowsValue.length === 0 ? "grey" : "#004AFF",
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>

                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                        margin: "0 20px 0 0",
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      disabled={toBackend}
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                        margin: "0 20px 0 0",
                      }}
                    >
                      Cancel
                    </Button>
                    {/* </Box> */}
                  </Stack>
                </div>
              </Card>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": { m: 1, width: "100%", height: "57vh" },
              }}
            >
              <Paper elevation={0}>
                {sitestatus ? (
                  <div
                    style={{
                      padding: "25px",
                    }}
                  >
                    {sortedFieldSets.map((fieldSet, index, stdField) => (
                      <Grid
                        container
                        spacing={2}
                        style={{ margin: "10px 0 0 0" }}
                        key={index}
                      >
                        <Grid item xs={1.7}>
                          <Typography className="modal-typo">
                            Equipment Name *
                          </Typography>
                          <Typography
                            sx={{
                              width: "14vw",
                              padding: "8.5px 14px",
                              fontSize: "1rem",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              minHeight: "1.4375em",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ marginTop: "5%" }}>
                              {fieldSet.equipmentName}
                            </div>
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={1.7}>
                            <Typography className="modal-typo" style={{marginBottom: "10px"}}>
                              Today Energy (kWh) *
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="todayEnergy"
                              placeholder="283"
                            //   defaultValue={
                            //     editValue ? editValue.errorMessage : null
                            //   }
                            //   onChange={(event) => handleChange(event)}
                              sx={{ width: "11vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                 
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </Grid>
                          <Grid item xs={1.7}>
                            <Typography className="modal-typo" style={{marginBottom: "10px"}}>
                              Energy Meter *
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="energyMeter"
                              placeholder="Energy Meter"
                            //   defaultValue={
                            //     editValue ? editValue.errorMessage : null
                            //   }
                            //   onChange={(event) => handleChange(event)}
                              sx={{ width: "11vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </Grid>
                          <Grid item xs={1.7}>
                            <Typography className="modal-typo" style={{marginBottom: "10px"}}>
                              Sensor *
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="sensor"
                              placeholder="Sensor"
                            //   defaultValue={
                            //     editValue ? editValue.errorMessage : null
                            //   }
                            //   onChange={(event) => handleChange(event)}
                              sx={{ width: "11vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </Grid>
                          <Grid item xs={1.7}>
                            <Typography className="modal-typo" style={{marginBottom: "10px"}}>
                             Irradiation *
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="irradiation"
                              placeholder="7.83"
                            //   defaultValue={
                            //     editValue ? editValue.errorMessage : null
                            //   }
                            //   onChange={(event) => handleChange(event)}
                              sx={{ width: "11vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </Grid>
                          <Grid item xs={1.7}>
                            <Typography className="modal-typo" style={{marginBottom: "10px"}}>
                              Export Energy *
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="exportEnergy"
                              placeholder="Export Energy"
                            //   defaultValue={
                            //     editValue ? editValue.errorMessage : null
                            //   }
                            //   onChange={(event) => handleChange(event)}
                              sx={{ width: "11vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </Grid>
                          <Grid item xs={1.7}>
                            <Typography className="modal-typo" style={{marginBottom: "10px"}}>
                              Import Energy *
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="importEnergy"
                              placeholder="Import Energy"
                            //   defaultValue={
                            //     editValue ? editValue.errorMessage : null
                            //   }
                            //   onChange={(event) => handleChange(event)}
                              sx={{ width: "11vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </Grid> */}
                        {(fieldSet.equipmentName
                          .toLowerCase()
                          .startsWith("inv") ||
                          fieldSet.equipmentName
                            .toLowerCase()
                            .includes("inverter")) && (
                          <Grid item xs={1.7}>
                            <Typography
                              className="modal-typo"
                              style={{ marginBottom: "10px" }}
                            >
                              Today Energy (kWh) *
                            </Typography>
                            <TextField
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              name="todayEnergy"
                              placeholder="283"
                              //   defaultValue={
                              //     editValue ? editValue.errorMessage : null
                              //   }
                              //   onChange={(event) => handleChange(event)}
                              sx={{ width: "11vw" }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </Grid>
                        )}

                        {fieldSet.equipmentName.toLowerCase() ===
                          "energy meter" && (
                          <>
                            <Grid item xs={1.7}>
                              <Typography
                                className="modal-typo"
                                style={{ marginBottom: "10px" }}
                              >
                                Export Energy *
                              </Typography>
                              <TextField
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                name="exportEnergy"
                                placeholder="Export Energy"
                                sx={{ width: "11vw" }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </Grid>
                            <Grid item xs={1.7}>
                              <Typography
                                className="modal-typo"
                                style={{ marginBottom: "10px" }}
                              >
                                Import Energy *
                              </Typography>
                              <TextField
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                name="importEnergy"
                                placeholder="Import Energy"
                                sx={{ width: "11vw" }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </Grid>
                          </>
                        )}

                        {fieldSet.equipmentName.toLowerCase() === "sensor" && (
                          <>
                            <Grid item xs={1.7}>
                              <Typography
                                className="modal-typo"
                                style={{ marginBottom: "10px" }}
                              >
                                Irradiation *
                              </Typography>
                              <TextField
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                name="irradiation"
                                placeholder="7.83"
                                sx={{ width: "11vw" }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </Grid>
                            <Grid item xs={1.7}>
                              <Typography
                                className="modal-typo"
                                style={{ marginBottom: "10px" }}
                              >
                                Ambient *
                              </Typography>
                              <TextField
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                name="ambient"
                                placeholder="48.2"
                                sx={{ width: "11vw" }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                classes={{ option: "autocomplete" }}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      // margin: "340px 0 0 530px",
                      position: "fixed",
                      textAlign: "center",
                      // width: "100%",
                      left: "40%",
                      top: "61%",
                    }}
                  >
                    <Typography>No site has been selected</Typography>
                  </div>
                )}
              </Paper>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}
