import React, { useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CheckForLight } from '../util/common/CommanFunction';
import { IconButton } from '@mui/material';

HighchartsBoost(Highcharts);
HighchartsHeatmap(Highcharts);

export const HeatmapChart = (props) => {
  const { tableData, chartName, fallBack, WidgetName, from } = props;
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState('');
  console.log(props, 'props');
  const isLight = CheckForLight();
  useEffect(() => {
    console.log('Table Data:', tableData);
    console.log('Chart Name:', chartName);
  }, [tableData, chartName]);

  const minValue = useMemo(
    () =>
      tableData.reduce((min, obj) => {
        return obj.specificYield < min ? obj.specificYield : min;
      }, tableData[0].specificYield),
    [tableData]
  );
  const maxValue = useMemo(
    () =>
      tableData.reduce((max, obj) => {
        return obj.specificYield > max ? obj.specificYield : max;
      }, tableData[0].specificYield),
    [tableData]
  );

  console.log(minValue, Math.ceil(maxValue));

  if (!tableData || tableData.length === 0) {
    console.error('No data available for the heatmap');
    return fallBack;
  }

  function convertApiDataToHeatmap(apiData) {
    if (!apiData || !Array.isArray(apiData)) {
      console.error('Invalid data format:', apiData);
      return [];
    }

    // Remove sensor value
    const filteredData = apiData.filter(
      (entry) => entry.equipmentName !== 'Radiation sensor'
    );

    // Sort the data by timeStamp
    const sortedData = [...filteredData].sort(
      (a, b) => new Date(a.timeStamp) - new Date(b.timeStamp)
    );

    // Create a mapping of equipment names to numerical indices
    const equipmentNameMap = {};
    sortedData.forEach((entry) => {
      const name = entry.equipmentName || 'Unknown';
      if (!(name in equipmentNameMap)) {
        equipmentNameMap[name] = Object.keys(equipmentNameMap).length;
      }
    });

    // Create heatmap data
    const heatmapData = sortedData.map((entry) => ({
      x: new Date(entry.timeStamp).getTime(),
      y: equipmentNameMap[entry.equipmentName || 'Unknown'],
      value: entry.specificYield,
      custom: {
        equipmentId: entry.equipmentId === null ? 0 : entry.equipmentId,
        specificYield: entry.specificYield,
        timeStamp: entry.timeStamp,
        equipmentName: entry.equipmentName || 'Unknown',
      },
    }));
    console.log('Heatmap Data:', heatmapData);
    return heatmapData;
  }

  const heatmapData = convertApiDataToHeatmap(tableData);
  if (heatmapData.length === 0) {
    console.error('Heatmap data is empty after conversion');
    return <div>Unable to generate heatmap from the provided data.</div>;
  }

  const equipmentNames = [
    ...new Set(heatmapData.map((item) => item.custom.equipmentName)),
  ];

  const chartOptions = {
    chart: {
      type: 'heatmap',
      backgroundColor: isLight ? 'white' : '#121212',
    },
    boost: {
      useGPUTranslations: true,
    },
    title: {
      text: chartName,
      align: 'left',
      x: 40,
      style: {
        color: isLight ? 'black' : 'white',
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%b %d, %Y}',
        style: {
          color: isLight ? 'black' : 'white',
        },
      },
      title: {
        text: 'Date',
        style: {
          color: isLight ? 'black' : 'white',
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'separator',
            'downloadPNG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadCSV',
            'downloadXLS',
            from === 'Vanalytics'
              ? {
                  text: 'View Table',
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );
                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : '',
          ],
        },
      },
      enabled: true,
      // showTable: true,
      columnHeaderFormatter: function (item, key) {
        if (item.isXAxis) {
          return item.options.title.text || item.name;
        }
        if (key === 'y') {
          return item.yAxis.options.title.text || item.name;
        }
      },
    },
    plotOption: {
      events: {
        legendItemClick: function () {
          const series = this.chart.series;
          const currentSeries = this;

          if (series.length === 2) {
            const [series1, series2] = series;

            if (currentSeries === series1) {
              // If series1 is clicked
              if (series1.visible && !series2.visible) {
                // Both series should be visible if series1 is visible and series2 is not
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              } else if (!series1.visible && series2.visible) {
                // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                series1.update({ visible: true }, false);
                series2.update({ visible: false }, false);
              } else if (series1.visible && series2.visible) {
                // If both are visible, make series2 invisible, and series1 remains visible
                series1.update({ visible: true }, false);
                series2.update({ visible: false }, false);
              } else if (!series1.visible && !series2.visible) {
                // If both are not visible, make both visible
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              }
            } else if (currentSeries === series2) {
              // If series2 is clicked
              if (series2.visible && !series1.visible) {
                // Both series should be visible if series2 is visible and series1 is not
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              } else if (!series2.visible && series1.visible) {
                // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                series1.update({ visible: false }, false);
                series2.update({ visible: true }, false);
              } else if (series1.visible && series2.visible) {
                // If both are visible, make series1 invisible, and series2 remains visible
                series1.update({ visible: false }, false);
                series2.update({ visible: true }, false);
              } else if (!series2.visible && !series1.visible) {
                // If both are not visible, make both visible
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              }
            }

            // Finally, redraw the chart
            this.chart.redraw();
          } else {
            // Handle charts with more than two series or other cases
            this.chart.update(
              {
                series: series.map((s) => {
                  if (s === currentSeries) {
                    return {
                      visible: currentSeries.visible ? false : true,
                    };
                  } else {
                    const visbleSeries = series.filter(
                      (series) => series.visible
                    );
                    console.log(
                      visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1,
                      visbleSeries
                    );

                    if (
                      visbleSeries.length > 1 &&
                      visbleSeries.length < series.length - 1
                    ) {
                      visbleSeries.forEach((series) => {
                        series.update({ visible: false }, false);
                      });
                    }
                    return {
                      visible: s.visible
                        ? visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1
                          ? true
                          : false
                        : true,
                    };
                  }
                }),
              },
              true
            );
          }

          return false; // Prevent the default action of hiding the clicked series
        },
      },
    },
    yAxis: {
      categories: equipmentNames,
      title: {
        text: 'Equipment Name',
        style: {
          color: isLight ? 'black' : 'white',
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: isLight ? 'black' : 'white',
        },
      },
      reversed: false,
    },
    colorAxis: {
      stops: [
        [0, '#c4463a'], // Red
        [0.25, '#ff7f50'], // Coral
        [0.5, '#fffbbc'], // Yellow
        [0.75, '#87cefa'], // Light Sky Blue
        [1, '#3060cf'], // Blue
      ],

      min: minValue,
      max: maxValue,
      startOnTick: false,
      endOnTick: false,
      labels: {
        format: '{value}',
      },
    },
    series: [
      {
        boostThreshold: 5000,
        borderWidth: 0,
        nullColor: '#3060cf',
        colsize: 3600 * 1000 * 24,
        data: heatmapData,
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          headerFormat: '',
          pointFormat:
            'Date: {point.custom.timeStamp}<br/>' +
            '{point.custom.equipmentName}<br/>' +
            'Specific Yield: {point.custom.specificYield}',
        },
        turboThreshold: Number.MAX_VALUE,
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: isLight ? 'black' : 'white',
      },
      itemHoverStyle: {
        color: isLight ? 'gray' : 'lightgray',
      },
    },
  };

  return (
    <div
      style={{ width: '100%', height: from === 'Vanalytics' ? '500px' : null }}
    >
      {tableData.length > 0 ? (
        showTable ? (
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  height: '40px',
                  marginRight: '10px',
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{WidgetName.split('-')[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: '100%',
                height: from === 'Vanalytics' ? '500px' : null,
              },
            }}
            options={chartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
