import axios from 'axios';
export const fetchsectorTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_TABLE}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchsectorTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SECTOR_BYID
      }/${sessionStorage.getItem('customerId')}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
// SECTOR - EDIT
// export const fetchsectoredit = async () => {

//   try {
//       const response = await axios.get(`${process.env.REACT_APP_SECTOR_Edit}`);
//       console.log(response);
//       return response.data;
//   } catch (error) {
//       console.error('Error posting data:', error);
//       throw error;
//   }
// };
///post data
export const postsectordata = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_Submit_Data}`,
      obj
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

//edit icon
export const Getediticon = async (data) => {
  // console.log(data,'data');
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_Edit}/${data}`
    );
    // const response = await axios.get(`${process.env.REACT_APP_SECTOR_Edit}`);
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/// for getting data from autocomplete
export const Getdropdowndata = async () => {
  // console.log(datas,'data');
  try {
    //   const response = await axios.get(`${process.env.REACT_APP_CUSTOMER_EDIT}/${data}`);
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_dropdown_companylist}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const Getdropdownsectorlist = async () => {
  // console.log(datas,'data');
  try {
    //   const response = await axios.get(`${process.env.REACT_APP_CUSTOMER_EDIT}/${data}`);
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_dropdown_sectorlist}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// AFTER EDIT THE VALUE UPDATE TO THE DB
export const putupdatesectorvalue = async (data, id) => {
  console.log(data, 'editvalue');

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_Update_Data}/${id}`,
      data
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
