import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import TollIcon from '@mui/icons-material/Toll';
import CloudIcon from '@mui/icons-material/Cloud';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router-dom';

const TypographyStyled = ({ children, variant, ...props }) => (
  <Typography
    style={{
      textTransform: 'capitalize',
      lineHeight: '14.52px !important',
      color: 'rgb(113, 118, 111)',
      fontSize: '14px !important',
    }}
    variant={variant}
    {...props}
  >
    {children}
  </Typography>
);

const CardItem = ({ item, navigate }) => (
  <Grid item xs={12} sm={12} md={12}>
    <Card elevation={0}>
      <CardContent elevation={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: 'column', md: 'row' }}
          elevation={0}
        >
          <TypographyStyled variant="h6">{item.maintitle}</TypographyStyled>
          <item.Icon
            sx={{
              alignSelf: {
                xs: 'flex-start',
                md: 'flex-end',
              },
              cursor: item.isButton ? 'pointer' : null,
              color: item.isButton ? 'blue' : null,
            }}
            onClick={() =>
              item.maintitle === 'Total Sites'
                ? navigate('sitelist')
                : item.maintitle === 'Tickets'
                ? navigate('ticket')
                : null
            }
          />
        </Box>
        <Box mt={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            {item.subtitles.map((subtitle, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mb={{ xs: 2, md: 0 }}
              >
                <TypographyStyled variant="subtitle1">
                  {subtitle.title}
                </TypographyStyled>
                <TypographyStyled variant="body2">
                  {subtitle.value}
                </TypographyStyled>
              </Box>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
);

const TotalCard = (props) => {
  const [cardsData, setCardsData] = useState([]);
  const data = props.dashboardDetails;
 let  subscriptionId=sessionStorage.getItem('subscriptionId')

  useEffect(() => {
    let mainTitle = (
      <span>
        CO{' '}
        <span
          style={{
            fontSize: '0.8rem',
            verticalAlign: 'sub',
            lineHeight: '0',
            marginLeft: '-5px',
          }}
        >
          2
        </span>{' '}
        Avoided
      </span>
    );

    if (data) {
      console.log(data,"data");
      const transformToCardsData = (data) => {
        const totalSites = data.siteCount;

        const cardsData = [
          {
            maintitle: 'Total Sites',
            maintitlevalue: String(totalSites),
            subtitles: [
              { title: 'Roof Top', value: String(data.roofTopCount) },
              { title: 'Utility', value: String(data.utilityCount) },
            ],
            Icon: ArrowForwardOutlinedIcon,
            isButton: true,
          
          },
          {
            maintitle: 'Alarms',
            maintitlevalue: '',
            subtitles: [
              { title: 'Today', value: String(data.alarmTodayCount) },
              { title: 'Total', value: String(data.alarmTotalCount) },
            ],
            Icon: AlarmAddIcon,
            isButton: false,
            
          },
          {
            maintitle: 'Tickets',
            maintitlevalue: '',
            subtitles: [
              { title: 'Open', value: data.totalOpenTickets },
              { title: 'Close', value: data.totalCloseTickets },
            ],
            Icon: TollIcon,
            isButton: true,
            
          },
          {
            title:"Events",
            maintitle: mainTitle,
            maintitlevalue: '',
            subtitles: [
              { title: "Today Emmison", value: data.co2 },
              
            ],
            Icon: TollIcon,
            isButton: false,
            
          },

          {
            maintitle: "Energy Generation ",
            maintitlevalue: '',
            subtitles: [
              { title: "Today's Energy", value: data.totalTodayEnergy },
              { title: 'Total Energy', value: data.sumOfTotalEnergy },
            ],
            Icon: CloudIcon,
            isButton: false,
  
          },
        ];
        const filteredCardsData = Number(subscriptionId) !== 2  ? cardsData.filter(card => card.title !== 'Events') :cardsData.filter(card => card.maintitle !== 'Tickets')

        return filteredCardsData;
      };

      setCardsData(transformToCardsData(data));
    }
  }, [data]);

  const navigate = useNavigate();

  return (
    <div
      className="card-main"
      style={{
        textTransform: 'capitalize',
        lineHeight: '14.52px !important',
        color: 'rgb(113, 118, 111)',
        fontSize: '14px !important',
      }}
    >
      <Grid container spacing={2} columns={24}>
        {cardsData.map((item, index) => (
          <CardItem key={index} item={item} navigate={navigate} />
        ))}
      </Grid>
    </div>
  );
};

export default TotalCard;
