import axios from "axios";

export const PostSignup = async (obj) => {
  try {
    // console.log(obj);
    let response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SIGNUP}`,
      obj
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const FetchUserType = async () => {
  try {
   
    let response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FETCH_USER_TYPE}`,
      
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const FetchSubscriptionType = async () => {
  try {
   
    let response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FETCH_SUBSCRIPTION}`,
      
    );
    return response;
  } catch (err) {
    return err.response;
  }
};


