import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

//------------- TableValues--------------//
export const fetchSiteTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_TABLE}`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchSiteTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_SITE_BYCOMPANY
          : process.env.REACT_APP_SITE_BYID
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

//------------- Table Edit -----------------//
export const FetchSiteEditValue = async (id) => {
  console.log(id);

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_TABLE_EDIT}/findBySiteId/${id}`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    //   console.error('Error fetching data:', error);
    console.log(error);
    throw error;
  }
};

/*-------------------------------------------------- New User Add ----------------------------------*/

export const postSiteSave = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_SAVE}`,
      obj
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
export const putsiteupdateEdit = async (data, id) => {
  try {
    console.log(data, 'data');
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_UPDATE}/${id}`,
      data
    );
    console.log(response, 'response');
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const Getdropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_dropdown_companylist}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetSiteTypedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_TYPE_dropdown}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchSectordropdownvalue = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_DROPDOWN}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchTimeZone = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_TIME_ZONE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
