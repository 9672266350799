import React, { useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button } from '@mui/material';

const ImageCropper = ({ imageSrc, onComplete }) => {
  const [image, setImage] = useState(imageSrc);
  useEffect(() => {
    setImage(imageSrc);
  }, [imageSrc]);
  // eslint-disable-next-line
  const [cropData, setCropData] = useState('#');
  const cropperRef = useRef(null);

  const handleCrop = () => {
    if (typeof cropperRef.current.cropper !== 'undefined') {
      const croppedDataURL = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData(croppedDataURL);
      onComplete(croppedDataURL);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {image && (
        <Cropper
          ref={cropperRef}
          style={{ height: 300, width: '100%' }}
          zoomTo={0}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          background={false}
          responsive={true}
          autoCropArea={10}
          checkOrientation={true}
          guides={true}
        />
      )}
      <Button
        variant="contained"
        style={{
          borderRadius: '25px',
          textTransform: 'capitalize',
          marginTop: '20px',
        }}
        onClick={handleCrop}
      >
        Crop Image
      </Button>
    </div>
  );
};

export default ImageCropper;
