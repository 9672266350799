export const filterRows = (rowsValue, filterObject) => {
  return rowsValue.filter((row) => {
    for (const key in filterObject) {
      if (Object.hasOwnProperty.call(filterObject, key)) {
        const selectedColumnValue = row[key];
        const filterValue = filterObject[key];

        // Check for null values in filterObject, exclude them from filter search
        if (filterValue === null) {
          continue;
        }

        // Check if filterValue is an array
        if (Array.isArray(filterValue)) {
          // Check if selectedColumnValue is a string and exists in filterValue array
          if (
            typeof selectedColumnValue === 'string' &&
            filterValue.includes(selectedColumnValue)
          ) {
            continue; // Include the row if selectedColumnValue exists in filterValue array
          } else {
            return false; // Exclude the row if selectedColumnValue doesn't exist in filterValue array
          }
        }

        // Handle date properties
        if (
          key === 'lastDataReceived' ||
          key === 'createdDate' ||
          key === 'lastUpdatedDate'
        ) {
          const selectedDate = new Date(
            selectedColumnValue
          ).toLocaleDateString();
          const filterDate = new Date(filterValue).toLocaleDateString();

          if (selectedDate !== filterDate) {
            return false; // If dates don't match, exclude the row
          }
        } else {
          // Handle string properties
          if (
            !String(selectedColumnValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
          ) {
            return false; // If any other property doesn't match, exclude the row
          }
        }
      }
    }
    return true; // Include the row if all properties match
  });
};
