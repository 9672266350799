import axios from 'axios';
export const fetchStandardTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMETER_TABLE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchStandardTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_PARAMETER_TABLE_BYID
      }/${sessionStorage.getItem('customerId')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const updatestdEditCall = async (data, id) => {
  console.log(data, id);
  let response = await axios.put(
    `${process.env.REACT_APP_PARAMETER_UPDATE}/update/${id}`,
    data
  );
  return response;
};

export const Getdropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_dropdown_companylist}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const Getdropdownvalue = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_Sector_dropdown_SectortypeList}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const postdstandardparameter = async (obj) => {
  console.log(obj);
  // console.log(data,'data');
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMETER_SAVE}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
export const postdstandardparametervalue = async (obj) => {
  console.log(obj);
  // console.log(data,'data');
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMETER__EXCEL_TABLE}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
export const putStandardEdit = async (data, id) => {
  console.log(data, 'data');
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMETER_UPDATE}/${id}`,
      data
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const postparameterexceldata = async (obj) => {
  try {
    const resolvedData = await Promise.all(obj);
    console.log(resolvedData, "ghj");
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMETER__EXCEL_TABLE}`,
      resolvedData
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};