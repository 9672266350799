import React from 'react';
import Highcharts, { Legend } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CheckForLight } from '../util/common/CommanFunction';
import { IconButton } from '@mui/material';

// Initialize the modules
HighchartsMore(Highcharts);
HighchartsDrilldown(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const WaterfallChart = ({ tableData, widgetName, from, fallBack }) => {
  console.log(tableData, 'tableData');
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState('');
  function convertDataToSeries(data) {
    // Create the output format as required
    const output = [
      {
        name: 'Actual Generation',
        y: data.todayEnergy,
      },
      {
        name: 'Deemed Generation',
        y: data.deenedGeneration,
      },
      {
        name: 'Potential Generation',
        isSum: true,
        y: data.potentialGeneration,
        color: Highcharts.getOptions().colors[1],
      },
    ];

    return output;
  }

  const tooltipFormatter = function () {
    return `<b>${this.series.name}</b><br/>${
      this.key === 'Potential Generation'
        ? tableData[0].potentialGeneration
        : this.y
    }`;
  };

  const options = {
    chart: {
      type: 'waterfall',
      backgroundColor: CheckForLight() ? 'white' : '#121212',
    },
    title: {
      text: widgetName,
      style: {
        color: CheckForLight() ? 'black' : 'white',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadJPEG',
            'downloadPNG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadCSV',
            'downloadXLS',
            from === 'Vanalytics'
              ? {
                  text: 'View Table',
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );
                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : '',
          ],
        },
      },
      enabled: true,
      columnHeaderFormatter: function (item, key) {
        if (item.isXAxis) {
          return item.options.title.text || item.name;
        }
        if (key === 'y') {
          return item.yAxis.options.title.text || item.name;
        }
      },
    },
    xAxis: {
      type: 'category',
      label: {
        style: {
          color: CheckForLight() ? 'black' : 'white',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Generation (kWh)',
        style: {
          color: CheckForLight() ? 'black' : 'white',
        },
      },
      label: {
        style: {
          color: CheckForLight() ? 'black' : 'white',
        },
      },
    },
    tooltip: {
      formatter: tooltipFormatter,
    },
    series: [
      {
        name: 'Generation (kWh)',
        data: convertDataToSeries(tableData[0]),
        pointPadding: 0,
        pointWidth: 150,
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.point.isSum) {
              return `${tableData[0].potentialGeneration}`;
            }
            return `${Highcharts.numberFormat(this.y, 2)}`;
          },
        },
        label: {
          style: {
            color: CheckForLight() ? 'black' : 'white',
          },
        },
      },
    ],
    credits: {
      enabled: false,
    },
    plotOption: {
      events: {
        legendItemClick: function () {
          const series = this.chart.series;
          const currentSeries = this;

          if (series.length === 2) {
            const [series1, series2] = series;

            if (currentSeries === series1) {
              // If series1 is clicked
              if (series1.visible && !series2.visible) {
                // Both series should be visible if series1 is visible and series2 is not
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              } else if (!series1.visible && series2.visible) {
                // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                series1.update({ visible: true }, false);
                series2.update({ visible: false }, false);
              } else if (series1.visible && series2.visible) {
                // If both are visible, make series2 invisible, and series1 remains visible
                series1.update({ visible: true }, false);
                series2.update({ visible: false }, false);
              } else if (!series1.visible && !series2.visible) {
                // If both are not visible, make both visible
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              }
            } else if (currentSeries === series2) {
              // If series2 is clicked
              if (series2.visible && !series1.visible) {
                // Both series should be visible if series2 is visible and series1 is not
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              } else if (!series2.visible && series1.visible) {
                // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                series1.update({ visible: false }, false);
                series2.update({ visible: true }, false);
              } else if (series1.visible && series2.visible) {
                // If both are visible, make series1 invisible, and series2 remains visible
                series1.update({ visible: false }, false);
                series2.update({ visible: true }, false);
              } else if (!series2.visible && !series1.visible) {
                // If both are not visible, make both visible
                series1.update({ visible: true }, false);
                series2.update({ visible: true }, false);
              }
            }

            // Finally, redraw the chart
            this.chart.redraw();
          } else {
            // Handle charts with more than two series or other cases
            this.chart.update(
              {
                series: series.map((s) => {
                  if (s === currentSeries) {
                    return {
                      visible: currentSeries.visible ? false : true,
                    };
                  } else {
                    const visbleSeries = series.filter(
                      (series) => series.visible
                    );
                    console.log(
                      visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1,
                      visbleSeries
                    );

                    if (
                      visbleSeries.length > 1 &&
                      visbleSeries.length < series.length - 1
                    ) {
                      visbleSeries.forEach((series) => {
                        series.update({ visible: false }, false);
                      });
                    }
                    return {
                      visible: s.visible
                        ? visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1
                          ? true
                          : false
                        : true,
                    };
                  }
                }),
              },
              true
            );
          }

          return false; // Prevent the default action of hiding the clicked series
        },
      },
    },
    legend: {
      enabled: true,
    },
  };
  console.log(options, 'options');

  return (
    <div
      style={{ width: '100%', height: from === 'Vanalytics' ? '500px' : null }}
    >
      {tableData.length > 0 ? (
        showTable ? (
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  height: '40px',
                  marginRight: '10px',
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{widgetName.split('-')[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: '100%',
                height: from === 'Vanalytics' ? '500px' : null,
              },
            }}
            options={options}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};

export default WaterfallChart;
