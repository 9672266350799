// import axios from 'axios';

// //------------- TableValues--------------//

// export const fetchAlertsTable = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${
//         process.env.REACT_APP_ALERTS_TABLE_BYUSER
//       }/${sessionStorage.getItem('id')}`
//     );

//     return response.data;
//   } catch (error) {
//     console.error('Error posting data:', error);
//     throw error;
//   }
// };

// /*--------------------- Edit Api Call ------------------*/
// export const FetchEditValue = async (id) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_EDIT}/findBySiteId/${id}`
//     );

//     return response.data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// /*-------------------- Update (PUT) Api Call ----------------------*/
// export const updateEditCall = async (data, alertId) => {
//   try {
//     const response = await axios.put(
//       `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_UPDATE}/${alertId}`,
//       data
//     );

//     return response;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// /*---------------------- POST Alert Save ---------------------*/
// export const postAlertsSave = async (obj) => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_SAVE}`,
//       obj
//     );

//     return response.data;
//   } catch (error) {
//     console.error('Error posting data:', error.message);
//     throw error;
//   }
// };
import axios from 'axios';

//------------- TableValues--------------//

export const fetchAlertsTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_ALERTS_TABLE_BYUSER
      }/${sessionStorage.getItem('id')}`
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*--------------------- Edit Api Call ------------------*/
export const FetchEditValue = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_EDIT}/findBySiteId/${id}`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/*-------------------- Update (PUT) Api Call ----------------------*/
export const updateEditCall = async (data, alertId) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_UPDATE}/${alertId}`,
      data
    );

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/*---------------------- POST Alert Save ---------------------*/
export const postAlertsSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ALERTS_TABLE_SAVE}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};