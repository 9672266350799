import React, { useEffect, useState } from 'react'
import TableTemplate from '../../Template/TableTemplate'
import { FadeLoader } from 'react-spinners';
import { taskGetAllProjects, taskGetAllUser, taskGetProjectValue, taskGetUserValue } from '../../Api/taskManagerAPI';
import Download from '../../Template/Excel/Download';
import { format, subDays } from 'date-fns';

const TaskManager = ({ SiteList }) => {
    const [loading, setloading] = useState(false);
    const [tableValue, setTableValue] = useState([]);
    const [tempData, setTempData] = useState([])
    const [userData, setUserData] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    let currentDate = new Date();
    let lastWeek = subDays(currentDate, 7);

    currentDate = format(currentDate, 'dd-MM-yyyy');
    let formattedDate = format(lastWeek, 'dd-MM-yyyy');
    const initialState = {
        user: null,
        project: null,
        dates: {
            apiStartDate: formattedDate,
            apiEndDate: currentDate
        },
        barDates: [
            '29-06-2024'
        ]
    }
    console.log(initialState, 'dataVal.dates');
    const [dataVal, setDataVal] = useState(initialState);
    const paths = [
        { label: 'Admin', path: '/config/admin/userconf' },
        { label: 'Task Manager', path: 'admin/taskManager' }
    ];
    const generateDateArray = (startDateStr, endDateStr) => {
        const [startDay, startMonth, startYear] = startDateStr?.split('-').map(Number);
        const [endDay, endMonth, endYear] = endDateStr?.split('-').map(Number);

        const startDate = new Date(startYear, startMonth - 1, startDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);

        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = currentDate.getFullYear();
            dateArray.push(`${day}-${month}-${year}`);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        setDataVal({ ...dataVal, barDates: dateArray });
    };
    const headCells = [
        {
            label: 'User Name',
            id: 'userName',
            view: true,
            default: true,
        },
        {
            label: 'Project Name',
            id: 'projectName',
            view: true,
        },
        {
            label: 'Currency',
            id: 'currency',
            view: true,
            default: true,
        },
        {
            label: 'Duration',
            id: 'duration',
            view: true,
        },
        {
            label: 'Amount',
            id: 'amount',
            view: true,
        },
    ];
    console.log(dataVal, 'dataValdataVal');
    const fetchUserData = async (userName, fromDate, toDate, projectName) => {
        let user;
        setloading(true);
        try {
            if (userName !== null) {
                // const [year, month, day] = fromDate.split('-');
                // fromDate = `${day}-${month}-${year}`;
                // const [yearTo, monthTo, dayTo] = toDate.split('-');
                // toDate = `${dayTo}-${monthTo}-${yearTo}`;
                console.log(fromDate, toDate, 'startstart');
                user = userName.replace(/&/g, '%26').replace(/ /g, '%20').replace(/\?/g, '%3F')
            }
            let data = await taskGetUserValue(user, fromDate, toDate);
            data.forEach(item => {
                // item['duration'] = (item['duration'] / 3600).toFixed(4) + ' Hrs'
                // item['duration'] = secondsFormatter(item['duration']);
                item['amount'] = item['amount'].toFixed(1)
            })
            if (projectName === null) {
                setTempData(data);
                setTableValue(data);
            } else if (dataVal.user === null) {
                setTableValue(data);
                setTempData(data);
                setDefaultData(data);
            } else if (userName === null) {
                setTempData(data);
                setTableValue(data);
            } else {
                const filteredData = data.filter((item) => item.projectName === projectName);
                setTableValue(filteredData);
            }
            setloading(false);
        } catch (e) {
            console.log(e, 'tableValues');
            setloading(false);
        }
    };
    const handleDownloadTable = async (val) => {
        await Download(val, 'Task Manager');
    };
    const fetchProjectData = async (projectName, fromDate, toDate) => {
        setloading(true);
        let project;
        try {
            if (projectName !== null) {
                const [year, month, day] = fromDate.split('-');
                fromDate = `${day}-${month}-${year}`;
                const [yearTo, monthTo, dayTo] = toDate.split('-');
                toDate = `${dayTo}-${monthTo}-${yearTo}`;
                project = projectName.replace(/&/g, '%26').replace(/ /g, '%20').replace(/\?/g, '%3F')
            }
            const data = await taskGetProjectValue(project, fromDate, toDate);
            console.log(data, 'datadata');
            setTempData(data);
            setTableValue(data);
            setloading(false);
        } catch (e) {
            console.log(e, 'tableValues');
            setloading(false);
        }
    };

    const fetchAllUsers = async () => {
        setloading(true);
        try {
            const response = await taskGetAllUser();
            setUserData(response);
        } catch (error) {
            console.error(error);
        }
    }
    const fetchAllProjects = async () => {
        setloading(true);
        try {
            const response = await taskGetAllProjects();
            setProjectData(response);
        } catch (error) {
            console.error(error);
        }
    }
    // const today = new Date();
    // const year = today.getFullYear();
    // const month = String(today.getMonth() + 1).padStart(2, '0');
    // const day = String(today.getDate()).padStart(2, '0');
    // const day7 = new Date();
    // day7.setDate(today.getDate() - 7);
    // const daySeven = String(day7.getDate()).padStart(2, '0');
    // const sevenDaysAgo = `${daySeven}-${month}-${year}`;
    // const todayDate = `${day}-${month}-${year}`;
    // console.log(daySeven, 'todayDate');
    const today = new Date();
    const sevenDaysAgoDate = new Date();
    sevenDaysAgoDate.setDate(today.getDate() - 7);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const todayDate = formatDate(today);
    const sevenDaysAgo = formatDate(sevenDaysAgoDate);
    useEffect(() => {
        fetchUserData(null, sevenDaysAgo, todayDate);
        fetchAllUsers();
        fetchAllProjects();
        setDataVal({ ...dataVal, dates: { apiStartDate: sevenDaysAgo, apiEndDate: todayDate } })
        generateDateArray(dataVal.dates.apiStartDate, dataVal.dates.apiEndDate);
    }, [todayDate]);
    console.log(dataVal.user, 'tableValuesa');
    console.log(projectData, 'tableValues');
    const handleChildData = async (selectVal, name, dates) => {
        console.log(dates, 'datassss');
        let data = [...tempData];
        let replaceVal;
        if (dates) {
            setDataVal({ ...dataVal, dates: dates });
        }
        if (name === 'apply' && dataVal.user !== null) {
            replaceVal = fetchUserData(dataVal.user, dates?.apiStartDate, dates?.apiEndDate, dataVal.project);
            let passData = { user: dataVal.user, project: dataVal.project ? dataVal.project : 'All Projects', fromDate: dates.apiStartDate, toDate: dates.apiEndDate };
            let passParam = new URLSearchParams(passData).toString();
            let encryptedParams = btoa(unescape(encodeURIComponent(passParam)));
            let url = `/config/admin/taskManager?data=${encryptedParams}`;
            window.history.pushState({}, '', url);
        } else if (name === 'apply' && dataVal.project !== null && dataVal.user === null) {
            replaceVal = fetchProjectData(dataVal.project, dates.apiStartDate, dates.apiEndDate);
            let passData = { user: dataVal.user ? dataVal.user : 'All Users', project: dataVal.project, fromDate: dates.apiStartDate, toDate: dates.apiEndDate };
            let passParam = new URLSearchParams(passData).toString();
            let encryptedParams = btoa(unescape(encodeURIComponent(passParam)));
            let url = `/config/admin/taskManager?data=${encryptedParams}`;
            window.history.pushState({}, '', url);
        }
        else if (name === 'clear') {
            replaceVal = defaultData;
            setDataVal(initialState);
        } else {
            if (name === 'userName') {
                setDataVal(prevDataVal => {
                    const newDataVal = { ...prevDataVal, user: selectVal };
                    replaceVal = data.filter(item =>
                        item.userName === newDataVal.user &&
                        (!newDataVal.project || item.projectName === newDataVal.project)
                    );
                    return newDataVal;
                });
            } else if (name === 'projectName') {
                setDataVal(prevDataVal => {
                    const newDataVal = { ...prevDataVal, project: selectVal };
                    replaceVal = data.filter(item =>
                        item.projectName === newDataVal.project &&
                        (!newDataVal.user || item.userName === newDataVal.user)
                    );
                    return newDataVal;
                });
            }
        }
        setTableValue(replaceVal);
    }
    console.log(dataVal, 'tableValuestableValues');
    return (
        <div>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20%',
                    }}
                >
                    <FadeLoader color="#000440" loading={true} />
                </div>
            ) : (
                <TableTemplate
                    PageName={'Task Manager'}
                    SearchLabel={'Search Here... '}
                    paths={paths}
                    header={headCells}
                    rowsValue={tableValue}
                    userRole={SiteList[0]}
                    handleDownloadExcel={(val) => {
                        handleDownloadTable(val);
                    }}
                    taskOptions={{ userData, projectData, dataVal }}
                    taskHandler={(value, name, dates) => handleChildData(value, name, dates)}
                    fallbackText={dataVal.user === null && dataVal.project === null ? 'No Data Found in Today' : dataVal.project === null ? 'No project has been created yet for the User' : dataVal.user === null ? 'No Task has been created yet for the User' : dataVal.user !== null && dataVal.project !== null ? 'No Task has been created yet for this combination' : ''}
                />
            )}
        </div>
    )
}

export default TaskManager