import React, { useEffect, useState } from 'react';
import { taskManagerList } from '../util/TextField';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import CustomDatePickers from './CustomDatePicker';

const TaskFields = ({ options, onChildData }) => {
  const taskOptions = options;
  const currentDate = dayjs();
  const minDate = currentDate.subtract(1, 'year');
  const maxDate = currentDate.add(1, 'year');
  const CustomDatePicker = ({ label, onChange, value }) => (
    <div>
      <Typography>{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          size="small"
          sx={{ width: '15vw' }}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          slotProps={{ textField: { size: 'small' } }}
        />
      </LocalizationProvider>
    </div>
  );
  const dateStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '25',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    paddingBottom: 2,
  };
  const [dateOpen, setDateOpen] = useState(false);
  const [textField, setTextField] = useState([]);
  const [apiDate, setApiDate] = useState(false);
  const initialState = {
    siteName: null,
    userName: null,
    projectName: null,
  };
  const [selectVal, setSelectVal] = useState(initialState);
  console.log(selectVal, 'selectVal');
  const initialDates = {
    fromDate: null,
    toDate: null,
  };
  const [dates, setDates] = useState(initialDates);
  const handleDateOpen = () => setDateOpen(true);
  const handleDateClose = () => {
    setDateOpen(false);
    !apiDate && setDates(initialDates);
  };
  const handleDateChange = (key) => (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
    setDates((prevDates) => ({
      ...prevDates,
      [key]: formattedDate,
    }));
  };
  const handleDropdown = (event, value, name) => {
    console.log(value, 'vaslue', name);
    setSelectVal({ ...selectVal, [name]: value });
    onChildData(value, name);
  };
  const getDataFromUrl = () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const encryptedData = urlParams.get('data');
      if (encryptedData) {
        const decryptedData = decodeURIComponent(escape(atob(encryptedData)));
        const params = new URLSearchParams(decryptedData);
        const user = params.get('user');
        const project = params.get('project');
        const fromDate = params.get('fromDate');
        const toDate = params.get('toDate');
        return { user, project, fromDate, toDate };
      }
    } catch (error) {
      console.error('Error in getDataFromUrl:', error);
    }
    return null;
  };
  const clearUrlParams = () => {
    const url = window.location.origin + window.location.pathname;
    window.history.pushState({}, '', url);
  };
  useEffect(() => {
    const data = getDataFromUrl();
    if (data) {
      console.log(data, 'datasetSelectVal');
      setSelectVal((prevState) => ({
        ...prevState,
        userName: data.user,
        projectName: data.project,
      }));
      setDates((prev) => ({
        ...prev,
        fromDate: data.fromDate,
        toDate: data.toDate,
      }));
      setApiDate(true);
      clearUrlParams();
    }
  }, [dates]);
  const handleGlobalFunction = async (val) => {
    try {
      console.log(val, 'valssd');
      await onChildData(selectVal, 'apply', val);
    } catch (error) {
      console.error('Error in handleGlobalFunction:', error);
    }
  };
  useEffect(() => {
    let data = taskManagerList();
    setTextField(data);
  }, []);

  console.log(selectVal, 'setSelectVal');
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        {textField.map((data, index) => {
          const options =
            data.name === 'userName'
              ? taskOptions.userData
                  ?.map((option) => option.name)
                  .filter((name) => name !== null)
              : data.name === 'projectName'
              ? taskOptions.projectData
                  ?.map((option) => option.name)
                  .filter((name) => name !== null)
              : [];
          return (
            <Autocomplete
              disablePortal
              disableClearable
              key={index}
              id="combo-box-demo"
              options={options}
              value={
                selectVal[data.name] !== null
                  ? selectVal[data.name]
                  : data.name === 'projectName'
                  ? 'All Projects'
                  : 'All Users'
              }
              sx={{ width: 200 }}
              onChange={(event, value) =>
                handleDropdown(event, value, data.name)
              }
              renderInput={(params) => (
                <TextField {...params} placeholder={data.label} size="small" />
              )}
            />
          );
        })}
        {/* <Button
                    variant="contained"
                    disabled={selectVal.userName === null && selectVal.projectName === null}
                    sx={{
                        borderRadius: '25px',
                        textTransform: 'capitalize',
                        marginRight: -0.5
                    }}
                    onClick={handleDateOpen}
                    startIcon={<CalendarMonthIcon />}
                >
                    Select Date
                </Button> */}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
        <CustomDatePickers apiCallFn={handleGlobalFunction} />
        {/* </LocalizationProvider> */}
        {/* <Button
                    variant="contained"
                    disabled={selectVal.userName === null && selectVal.projectName === null}
                    sx={{
                        borderRadius: '25px',
                        textTransform: 'capitalize',

                    }}
                    onClick={() => {
                        setSelectVal(initialState);
                        setDates(initialDates);
                        onChildData(null, 'clear', null);
                    }}
                    startIcon={<ClearIcon />}
                >
                    Clear
                </Button> */}
      </Box>
      {/* <Modal
                open={dateOpen}
                onClose={handleDateClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={dateStyle}>
                    <Stack direction="row" alignItems="center" style={{ marginTop: '-2.5%', marginBottom: '2%' }}>
                        <Typography textAlign="center" variant="h6" sx={{ flexGrow: 1, marginLeft: '5%' }}>
                            Select Date
                        </Typography>
                        <Tooltip title="Close">
                            <IconButton onClick={() => { handleDateClose(); setApiDate(false) }}>
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <CustomDatePicker label="From Date" onChange={handleDateChange('fromDate')} value={dates.fromDate ? dayjs(dates.fromDate) : null} />
                        <CustomDatePicker label="To Date" onChange={handleDateChange('toDate')} value={dates.toDate ? dayjs(dates.toDate) : null} />
                    </Stack>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%' }}>
                        <Button
                            variant="contained"
                            disabled={dates.fromDate === null || dates.toDate === null}
                            style={{
                                borderRadius: '25px',
                                textTransform: 'capitalize',
                            }}
                            onClick={() => {
                                handleGlobalFunction()
                                handleDateClose()
                            }}
                            startIcon={<SearchIcon />}
                        >
                            Find
                        </Button>
                    </div>
                </Box>
            </Modal> */}
    </div>
  );
};

export default TaskFields;
