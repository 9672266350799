import axios from 'axios';
/*---------------------------------------------- Get call------------------------------*/
let userId = sessionStorage.getItem('id');

const isOEM = sessionStorage.getItem('userTypeId') == 1;
export const fetchReportTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_REPORT_TABLE}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchReportTableById = async () => {
  try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_PATH}${
    //     process.env.REACT_APP_REPORT_NEW_TABLE
    //   }/${sessionStorage.getItem('id')}`
    // );
     const response = await axios.get(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_REPORT_NEW_TABLE}/${sessionStorage.getItem('id')}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
/*---------------------------------------------- Report Name list------------------------------*/
export const fetchParameternamelist = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_REPORT_PARAMETER}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
/*---------------------------------------------- Site Name list------------------------------*/
export const fetchSiteDetails = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITELIST_BYUSER
      }/${sessionStorage.getItem('id')}`
    );
    console.log(response, 'resssss');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error
  }
};
/*---------------------------------------------- update call------------------------------*/

export const putReportupdateEdit = async (data, id) => {
  try {
    console.log(data, 'data');
    const response = await axios.put(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_REPORT_NEW_UPDATE}/${id}`,
      data
    );
    console.log(response, 'response');
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
/*---------------------------------------------- Save ------------------------------*/

export const postreportdata = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_REPORT_SAVE}`,
      obj
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

/*------------------------------------------------ Excel Download -------------------------------*/

// export const DownloadReport = async (data) => {
//   const { fromDate, toDate, siteId } = data;
//   try {
//     window.open(
//       `${process.env.REACT_APP_API_PATH}`
//     );
//     // const response = await axios.get(`${process.env.REACT_APP_API_REPORT_PATH}/${process.env.REACT_APP_REPORT_DOWNLOAD}/siteid=${siteId}&fromDate=${fromDate}&todate=${toDate}`);
//     // return response.data
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
export const DownloadReport = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_API_REPORT_CUSTOME}`,
      obj,
      {
        responseType: 'blob', // This tells axios to handle the response as a binary file (blob)
      }
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

export const fetchReportDownload = async (data) => {
  const {reportId} = data;
  console.log(reportId);
  
  try {
    window.open(
        `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_REPORT_NEW_DOWNLOAD}/${reportId}`,
     
      
    );
  } catch (error) {
    console.error('Error fetching report:', error);
    throw error;
  }
};

export const postreportnew = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_REPORT_NEW_SAVE}`,
      obj
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};