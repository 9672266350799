import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Style.css';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { MasterTypeSchema } from '../util/ValidateSchema';
import CustomSnackbar from '../util/components/CustomSnackbar';
import CancelIcon from '@mui/icons-material/Cancel';
/*--------------------------- MUI components ------------------------*/
import {
  Paper,
  Box,
  Button,
  Modal,
  Divider,
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Stack,
  Backdrop,
  Fade,
  CardHeader,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import MuiAlert from '@mui/material/Alert';
/*----------------------------------- MUI Icons ----------------------------*/
import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
/*-------------------------------External Componnets -------------------*/
import ExcelUplaod from '../Excel/ExcelUpload';
import '../../Common.css';
import errorMsg from '../util/errorMessage.json';
import TableTemplate from '../../Template/TableTemplate';
import FadeLoader from 'react-spinners/FadeLoader';
/*------------------------------- APi calls -------------------------*/
import {
  fetchSiteDetailsdata,
  fetchStandardParams,
  fetchEquipmentIdDetailsdata,
  PostdataConfig,
  GetFilePath,
  PostdataAddParamter,
  PostAddNewMaster,
  GetFileParamCoff,
  dataloggerNamevalue,
  dataloggermaptable,
  putDataloggerMaster,
  putDatalogger,
  FilePathList,
  fetchFileGetFile,
} from '../../Api/ExcelUploadAPi';
import { fetchSiteDetails } from '../../Api/ReportApi';
import { dataloggerfilePath, dataloggerName } from '../../Api/DataLoggerAPi';
import { MasterField } from '../util/TextField';
import { Getdropdownvalue } from '../../Api/MapsiteApi';
import CustomBreadcrumbs from '../util/components/CustomBread';
// import { CheckForLight } from "../util/TextFormatter";
import { groupParameters } from '../util/dataLoggerExtractor';
import { TabsComponent } from '../util/components/Tabs';
export default function ExcelUploadClone({ Report }) {
  const alertStyle = {
    color: 'white', // Text color
    '& .MuiSvgIcon-root': {
      color: 'white', // Icon color
    },
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [edit, setEdit] = useState(false);
  const [editlog, seteditlog] = useState(false);
  const [excelParams, setExcelParams] = useState([]);
  const [restructed, setRestructed] = useState([]);
  const [equipment, setEquipment] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [fileName, setfileName] = React.useState('');
  const [site, setSite] = React.useState('');
  const [tableData, setTableData] = useState([]);
  const [siteIdName, setSiteId] = useState([]);
  console.log(siteIdName);
  const [fileMap, setFileMap] = useState({});
  const [equipmentData, setEquipmentData] = useState([]);
  const [stdParamId, setStandardParamsId] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [filePath, setFilePath] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [standardParams, setStandardParams] = useState([]);
  const [rows, setRows] = useState([]);
  const [coefficientValue, setCoefficientValue] = useState([]);
  const [stdValue, setStdValue] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [filePathData, setGetFilePathData] = useState([]);
  const [fileDataPath, setFileDataPath] = useState('');
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const [dataLoggerName, setDataloggerName] = useState([]);
  const [dataLoggerValue, setDataloggerValue] = useState('');
  const [value, setValue] = React.useState(0);
  const [editValue, setEditvalue] = useState(null);
  const inputRefs = useRef([]);
  const [variantText, setVarient] = React.useState('');
  const [textValue, setTextValue] = React.useState('');
  const [openMaster, setOpenMaster] = useState(false);
  const [textField, setTextField] = useState([]);
  const [paramCoff, setParamNameCoff] = useState([]);
  const [tablevalue, setTablevalue] = useState();
  const [tablemap, setTablemap] = useState();
  const [internalLoader, setInternalLoader] = useState(false);
  const [Fileget, setFileget] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setRows([]);
    setCoefficientValue([]);
    setstdField(initalState);
  };
  const handleCloseMaster = () => {
    setstdField(initalState);
    setEditvalue([]);
    setOpenMaster(false);
  };

  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const [status, setStatus] = useState([
    { id: 'active', Value: 'Active' },
    { id: 'inactive', Value: 'In active' },
  ]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: 'cornflowerblue',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const initalState = {
    siteName: '',
    dataLoggerName: '',
    // remarks: '',
    status: '',
  };
  const [stdField, setstdField] = useState(initalState);
  const handleDropDownChange = (event, value, name) => {
    console.log(name);
    setstdField({ ...stdField, [name]: value });
  };
  console.log(stdField);
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Data Logger', path: 'datalogger' },
  ];
  const headCells = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
      default: true,
    },
    {
      label: 'Data Logger',
      id: 'dataLoggerName',
      view: true,
    },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];
  const headCell = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
      default: true,
    },
    {
      label: 'Data Logger',
      id: 'dataLoggerName',
      view: true,
    },
    {
      label: 'File path',
      id: 'filePath',
      view: true,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];
  let head = value === 2 ? headCell : headCells;

  useEffect(() => {
    // Initialize the inputRefs array with null values
    inputRefs.current = excelParams.map(() => null);
  }, [excelParams]);
  useEffect(() => {
    getsitelist();
    getparamName();
    let text = MasterField();
    setTextField(text);
  }, []);
  const handleExcelparams = (data) => {
    setExcelParams(data);
  };
  /*-------------------------- Site Id Call --------------------------*/
  // const getSiteCall = async () => {
  //   try {
  //     let data = await fetchSiteDetailsdata();
  //     setSiteId(data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const getsitelist = async () => {
    try {
      let data = await fetchSiteDetails();
      // console.log(data,"sectorData");
      if (Array.isArray(data)) {
        setSiteId(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setSiteId([data]);
      }
      setLoading(false);
    } catch (e) {
      console.error(e, 'errrrrror');
    }
  };
  /*--------------------------  DataLogger Name -----------------------------------*/
  const getDataLoggerName = async (id) => {
    let data = await dataloggerName(id);
    setDataloggerName(data);
  };

  const getDataLoggertableName = async (id) => {
    setInternalLoader(true);
    let data = await dataloggerNamevalue(id);
    setInternalLoader(false);
    setTablevalue(data);
  };
  const getDataLoggerTableValue = async (id, Id) => {
    try {
      setInternalLoader(true);
      let data = await dataloggermaptable(`${id}\/${Id}`);
      let filePath = await FilePathList(Id);
      // await getDataLoggerN();
      const reStructuredData = await groupParameters(data);
      setInternalLoader(false);
      setTablemap(reStructuredData);
      setTableData(data);
      setRestructed(reStructuredData);

      if (filePath[0].loggerFlag == 1) {
        setFileDataPath([filePath[0].filePath]);
      }
    } catch (error) {
      setInternalLoader(false);
      console.error('Error fetching data logger table value:', error);
    }
  };

  const getFilePath = async (id) => {
    try {
      let data = await dataloggerfilePath(id);
      // Process each file path to get the last part
      const fileNames = data.map((path) => {
        const parts = path.split('/');
        return parts[parts.length - 1];
      });

      setFilePath(data);
      setFileNames(fileNames);
    } catch (e) {
      console.log(e);
    }
  };
  /*--------------------------------Equipment Id call ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    // console.log(eventt);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    // const dataloggerId = dataLoggerName?.find(data => data.dataLoggerName === eventt);
    // dataLoggerId: dataloggerId?.dataLoggerId
    try {
      getDataLoggertableName(siteIdFilter[0]?.siteId);
      //  getDataLoggertablevalue(siteIdFilter[0]?.siteId)
      getDataLoggerName(siteIdFilter[0]?.siteId);
      let data = await fetchEquipmentIdDetailsdata(siteIdFilter[0]?.siteId);
      setEquipmentData(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getfileget = async (id) => {
    const data = await fetchFileGetFile(id);
    if (Array.isArray(data)) {
      setFileget(data);
    } else if (typeof data === 'object' && data !== null) {
      // If data is an object, wrap it in an array and set tableValue
      setFileget([data]);
    }
  };
  const fetchEquipmentDetail = async (event, eventt) => {
    try {
      let siteIdFilter = siteIdName?.filter((data) => data.siteName === event);
      let datalogger = dataLoggerName?.find(
        (data) => data.dataLoggerName === eventt
      );

      // Check if siteIdFilter and datalogger are not null or undefined before logging
      if (siteIdFilter !== null && datalogger !== null) {
        await getDataLoggerTableValue(
          datalogger.siteId,
          datalogger.dataLoggerId
        ); // Logging filtered values for debugging
      }
    } catch (e) {
      console.error('Error fetching equipment detail:', e);
    }
  };
  const getparamName = async () => {
    let data = await GetFileParamCoff();
    setParamNameCoff(data);
  };

  /*------------------------- standard params --------------------------*/
  const getstandardParams = async () => {
    try {
      let data = await fetchStandardParams();
      setStandardParams(data);
    } catch (e) {
      console.log(e);
    }
  };
  /*---------------------- excel upload --------------------------*/
  const handleUploadExcel = async (event) => {
    const file = event.target.files[0];
    setfileName(file.name);
    const fileData = await ExcelUplaod(event);
    handleExcelparams(fileData?.data[0]);
    getstandardParams();
    handleOpen();
    event.target.value = null;
  };
  const handleEquipment = (data, event) => {
    if (event === null) {
      setEquipment('');
    } else {
      setEquipment(event);
    }
  };
  const handleSite = (data, event) => {
    if (event === null) {
      setSite('');
    } else {
      const filteredData = siteIdName.filter((data) => {
        return data.siteName === event;
      });
      console.log(filteredData, 'event');
      setSite(event);
      getFilePath(filteredData[0].siteCode);
      setRestructed([]);
      setFileDataPath('');
      setDataloggerValue('');
      setstdField(initalState);
      setValue(0);
      setTablemap([]);
      fetchEquipmentDetails(event);
    }
  };
  const handleDataLoggerName = (data, eventt) => {
    if (eventt === null) {
      setDataloggerValue('');
    } else {
      setDataloggerValue(eventt);
      handleChangee(2);
      setRestructed([]);
      setFileDataPath('');
      fetchEquipmentDetail(null, eventt);
    }
  };

  const handleEquipmentUnits = (index, col, value, row) => {
    let stdvalue = standardParams?.filter((data) => {
      const splitValue = value.split(',');
      const trimmedValue = splitValue[0].trim();
      if (splitValue.length === 1) {
        return data.standardparametername === trimmedValue;
      } else {
        return data.standardparametername === trimmedValue;
      }
    });
    const updatedUnit = [...stdValue];
    const updatedStdParamId = [...stdParamId];
    updatedUnit[index] = stdvalue[0]?.standardparameteruom;
    updatedStdParamId[index] = stdvalue[0]?.standardid;
    setStdValue(updatedUnit);
    setStandardParamsId(updatedStdParamId);
  };
  const handleEditOpen = async (val) => {
    setEditvalue(val);
    let filteredsectorId = Object.keys(initalState).reduce((acc, key) => {
      if (val.hasOwnProperty(key)) {
        acc[key] = val[key];
      }
      return acc;
    }, {});
    setstdField(filteredsectorId);
    seteditlog(true);
    if (value == 2) {
      setFileget(val.parameterName);
      setRows(val.stdParameterName);
      setCoefficientValue(val.coEfficient);
    }
    value === 2 ? setOpenModal(true) : setOpenMaster(true);
  };

  /*------------------------------------ Changing value  for tabel set value ----------------------------*/
  const handleChange = async (index, col, value, row) => {
    if (col === 'std_par') {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index] = value;
        return updatedRows;
      });

      handleEquipmentUnits(index, col, value, row);
    } else {
      const validValue = value?.replace(/[^0-9.]/g, '');

      setCoefficientValue((prevCoefficientValues) => {
        const updatedCoefficientValues = [...prevCoefficientValues];
        updatedCoefficientValues[index] = validValue;
        return updatedCoefficientValues;
      });

      setActiveInputIndex(index);
      inputRefs.current[index] = inputRefs.current[index] || React.createRef();
      inputRefs.current[index].focus();
    }
  };

  const handleStdParChange = useCallback((index, value, row) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = value;
      return updatedRows;
    });

    handleEquipmentUnits(index, 'std_par', value, row);
  }, []);

  const handleCoeffChange = useCallback((index, value) => {
    const validValue = value?.replace(/(?!^-)[^0-9.]/g, '');

    setCoefficientValue((prevCoefficientValues) => {
      const updatedCoefficientValues = [...prevCoefficientValues];
      updatedCoefficientValues[index] = validValue;
      return updatedCoefficientValues;
    });

    setActiveInputIndex(index);
    inputRefs.current[index] = inputRefs.current[index] || React.createRef();
    inputRefs.current[index].focus();
  }, []);

  const MemoizedTableRow = React.memo(({ row, index }) => (
    <StyledTableRow className="divider" key={index}>
      <StyledTableCell align="center" className="cell-with-border">
        {index + +1}
      </StyledTableCell>
      <StyledTableCell align="center" className="cell-with-border">
        {row}
      </StyledTableCell>
      <StyledTableCell align="center" className="cell-with-border">
        <Autocomplete
          disablePortal
          id={`combo-box-demo-${index}`}
          value={rows[index]}
          options={paramCoff.map(
            (data) =>
              `${data.stdParameterName}${
                data.stdUom ? `, (${data.stdUom})` : ''
              }`
          )}
          defaultValue={editValue ? editValue.parameterName : ''}
          onChange={(event, value) => handleStdParChange(index, value, row)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Standard Params"
              sx={{ align: 'center' }}
            />
          )}
        />
      </StyledTableCell>
      <StyledTableCell align="center" className="cell-with-border">
        <TextField
          id={`coefficient-input-${index}`}
          placeholder="Co-Efficient"
          variant="standard"
          value={coefficientValue[index]} // Assuming this is managed elsewhere in your state
          autoFocus={activeInputIndex === index}
          inputRef={(ref) => {
            inputRefs.current[index] = ref;
          }}
          defaultValue={editValue ? editValue.stdParameterName : ''}
          onChange={(e) => handleCoeffChange(index, e.target.value)}
        />
      </StyledTableCell>
    </StyledTableRow>
  ));

  console.log(tableData);
  /*------------------------------------------ handle Submit ------------------------------*/
  const handleSubmitValue = async (value) => {
    let datalogger = dataLoggerName?.find(
      (data) => data.dataLoggerName === dataLoggerValue
    );

    let Dummy = paramCoff?.find((data) => data.stdParameterName === 'Dummy');
    try {
      const payload = Fileget.map((item, idx) => {
        const param = Fileget[idx];
        const rowValue = rows[idx]; // Retrieve the updated row value
        const rowStdParamName = rowValue?.split(',')[0].trim();
        const rowFilteredData = paramCoff.filter(
          (data) => data.stdParameterName === rowStdParamName
        );
        const dataloggerId = dataLoggerName?.find(
          (data) => data.dataLoggerName === dataLoggerValue
        );
        const siteIdFilter = siteIdName?.find((data) => data.siteName === site);
        console.log(dataloggerId);
        return {
          parameterName: param.replace(/ - .*/, '') || 'Unknown Parameter',
          stdParamsId:
            rowFilteredData.length > 0 ? rowFilteredData[0].stdId : Dummy.stdId,
          uom:
            rowFilteredData.length > 0
              ? rowFilteredData[0].stdUom
              : Dummy.stdUom,
          sequence: idx + 1,
          status: 1,
          filePath: fileDataPath,
          dataLoggerId: dataloggerId?.dataLoggerId || '',
          createdBy: Number(sessionStorage.getItem('id')),
          siteId: siteIdFilter?.siteId || '',
          coEfficient:
            coefficientValue[idx]?.length > 0 ? coefficientValue[idx] : '1',
        };
      });

      let data = await PostdataConfig(payload);
      // await getDataLoggerTableValue(datalogger.siteId, datalogger.dataLoggerId);
      if (data.status === 200) {
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: 'success',
            message: 'Data Added Successfully',
          };
        });
        setOpenModal(false);
        setVarient('success');

        setTableData([]);
        setRows([]);
        setCoefficientValue([]);
        setToBackend(false);
        setTextValue('DataLogger Added Successfully');
      } else {
        setToBackend(false);
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: 'warning',
            message: 'Check Your Data & try Again',
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetFile = async () => {
    seteditlog(false);
    setEditvalue('');
    setOpenModal(true);
  };
  const handleFilepathChange = (data, event) => {
    // Split each path in data and filter based on the last segment
    const fileNames = filePath.filter((path) => {
      const segments = path.split('/');
      return segments[segments.length - 1] === event;
    });
    setFileDataPath(event);

    // Call getfileget with the event
    getfileget(fileNames);
  };

  /*-------------------------------- snack bar toast message--------------------*/

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const hanldeOpenMaster = () => {
    setOpenMaster(true);
    seteditlog(false);
    setEditvalue('');
  };

  const handleSubmit = async (value) => {
    console.log(value);
    setToBackend(true);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === value?.siteName;
    });
    let obj = {
      dataLoggerName: value?.dataLoggerName,
      siteId: siteIdFilter[0]?.siteId,
      // remarks: value?.remarks,
      createdBy: Number(sessionStorage.getItem('id')),
      status: 1,
    };
    try {
      let responseData = await PostAddNewMaster(obj);
      await getDataLoggerName(siteIdFilter[0]?.siteId);
      await getDataLoggertableName(siteIdFilter[0]?.siteId);
      console.log(responseData, 'obj');
      setSite(value.siteName);
      setOpenMaster(false);
      setToBackend(false);
      setstdField({ dataLoggerName: '', siteName: '' });
      setToBackend(false);
      setstdField(initalState);
      setSnack((prev) => {
        return {
          ...prev,
          open: true,
          severity: 'success',
          message: 'Master Added Successfully',
        };
      });
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('Error:', error.response.status);
      setToBackend(false);
      return 0;
    }
  };

  const handleUpdate = async (value) => {
    console.log(value);
    let id = editValue?.dataLoggerId;

    let datalogger = value?.dataLoggerName;
    let status = value?.status;
    let obj = {
      siteId: editValue?.siteId,
      status:
        stdField?.status === 'In active'
          ? '0'
          : stdField?.status === 'Active'
          ? '1'
          : stdField?.status === 0
          ? '0'
          : '1',
      lastUpdatedBy: sessionStorage.getItem('id'),
      dataLoggerName: datalogger,
    };
    try {
      setToBackend(true);
      await putDataloggerMaster(obj, id);
      await getDataLoggerName(editValue.siteId);
      await getDataLoggertableName(editValue.siteId);
      setSnack(errorMsg.success);
      setToBackend(false);
      setEditvalue([]);
      handleCloseMaster();
      return 1;
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('Error:', error.response.status);
      setToBackend(false);
      return 0;
    }
  };

  async function insertLastUpdatedBy(arr) {
    return await arr.map((item) => ({
      ...item,
      lastUpdatedBy: Number(sessionStorage.getItem('id')),
    }));
  }

  function updateValueClose() {
    setOpenModal(false);
    setstdField(initalState);
  }
  const handleUpdateValue = async () => {
    console.log(tableData);
    let Dummy = paramCoff?.find((data) => data.stdParameterName == 'Dummy');
    try {
      setToBackend(true);
      const payload = tableData.map((item, idx) => {
        const param = Fileget[idx];
        const rowValue = rows[idx]; // Retrieve the updated row value
        const rowStdParamName = rowValue?.split(',')[0].trim();
        const rowFilteredData = paramCoff.filter(
          (data) => data.stdParameterName === rowStdParamName
        );
        const dataloggerId = dataLoggerName?.find(
          (data) => data.dataLoggerName === dataLoggerValue
        );
        const siteIdFilter = siteIdName?.find((data) => data.siteName === site);
        console.log(rowFilteredData, Dummy);
        return {
          ...item,
          parameterName: param.replace(/ - .*/, '') || 'Unknown Parameter',
          stdParamsId:
            rowFilteredData.length > 0 ? rowFilteredData[0].stdId : Dummy.stdId,
          uom:
            rowFilteredData.length > 0
              ? rowFilteredData[0].stdUom
              : Dummy.stdUom,
          sequence: idx + 1,
          status: 1,
          filePath: Fileget,
          dataLoggerId: dataloggerId?.dataLoggerId || '',
          createdBy: Number(sessionStorage.getItem('id')),
          siteId: siteIdFilter?.siteId || '',
          coEfficient:
            coefficientValue[idx]?.length > 0 ? coefficientValue[idx] : '1',
        };
      });
      const finalPayload = await insertLastUpdatedBy(payload);
      await putDatalogger(finalPayload);
      setRows([]);
      setCoefficientValue([]);
      updateValueClose();
      await getDataLoggerTableValue(
        restructed[0].siteId,
        restructed[0].dataLoggerId
      );
      setSnack((prev) => {
        return {
          ...prev,
          open: true,
          severity: 'success',
          message: 'Data Added Successfully',
        };
      });

      setVarient('success');
      seteditlog(false);
      setToBackend(false);
      setTextValue('DataLogger Added Successfully');
    } catch (e) {
      console.error(e);
      setToBackend(false);
      setSnack((prev) => {
        return {
          ...prev,
          open: true,
          severity: 'warning',
          message: 'Check Your Data & try Again',
        };
      });
      throw e;
    }
  };
  const handleChangee = (newValue) => {
    setValue(newValue);
  };
  let table = value === 2 ? tablemap : tablevalue;
  const FallBack = () => {
    const isLoggerTable = value === 2;

    const forLogger = dataLoggerValue
      ? tablemap
        ? 'No data logger configured yet.'
        : null
      : 'Select a site and data logger to list the configuration.';

    const forLoggerMaster = site
      ? tablevalue
        ? 'No data logger master configured yet.'
        : null
      : 'Select a site to list data logger masters.';

    return isLoggerTable ? forLogger : forLoggerMaster;
  };
  const tabsData = [
    { name: 'Data Logger Master', value: 0 },
    { name: 'Data Logger', value: 2 },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div style={{ marginTop: '-1.2%' }}>
          <div>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
          </div>
          <div>
            <Box
              sx={{
                margin: '0 0.5% 0 0.5%',
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  mr: 0,
                  mb: 1,
                  // mt: 2,
                  borderRadius: 0,
                  width: '100%',
                  height: '7.5vh',
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: 'flex',
                  width: '101%',
                  height: '6.5vh',
                  borderRadius: '2px 2px 0px 0px',
                  userSelect: 'none',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </div>
                <div style={{ margin: '1%' }}>
                  <Stack spacing={2} direction="row">
                    <Box sx={{ width: '100%' }}>
                      <TabsComponent
                        isLogger={true}
                        loggerState={value}
                        tabs={tabsData}
                        onTabChange={(val) => handleChangee(val)}
                      />
                    </Box>

                    <Tooltip
                      key={'add master'}
                      title={'Add New Master'}
                      userSelect="none"
                      followCursor
                      arrow
                    >
                      <IconButton
                        variant="text"
                        style={{
                          textTransform: 'capitalize',
                          color: '#370c49',
                          padding: '0',
                          fontSize: '40px',
                          borderRadius: '20px 20px 20px 20px',
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        // onClick={(event) => handleClick(data.value, event)}
                      >
                        <AddCircleOutlineSharpIcon
                          onClick={hanldeOpenMaster}
                          style={{ color: '#004AFF', fontSize: '20px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </div>
              </Card>
            </Box>
          </div>

          <div>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  m: 1,
                  width: '101%',
                  height: '10vh',
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Grid
                    container
                    spacing={0}
                    columns={34}
                    style={{ marginLeft: '3%' }}
                  >
                    <Grid item xs={10}>
                      <Typography className="modal-typo">Site Name</Typography>
                      <div style={{ marginTop: '1%' }}>
                        <Tooltip title={site}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={siteIdName.map(
                              (option) => option.siteName
                            )}
                            defaultValue={editValue ? editValue.siteName : null}
                            onChange={(data, event) => handleSite(data, event)}
                            value={site}
                            sx={{ width: '16vw' }}
                            ListboxProps={{
                              style: {
                                maxHeight: '200px', // Set your desired height here
                              },
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={
                                  {
                                    // fieldset: {
                                    //   borderRadius: '10px',
                                    //   // background: 'rgb(247, 248, 249)',
                                    //   color: 'rgb(247, 248, 249)',
                                    //   borderColor: 'transparent',
                                    //   border: 'none',
                                    // },
                                  }
                                }
                                placeholder="IFP"
                              />
                            )}
                          />
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className="modal-typo" sx={{ width: '14vw' }}>
                        Data Logger Name
                      </Typography>
                      <div style={{ marginTop: '1%' }}>
                        <Tooltip
                          title={
                            editlog ? editValue.dataLoggerName : dataLoggerValue
                          }
                        >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            defaultValue={
                              editlog
                                ? editValue.dataLoggerName
                                : dataLoggerValue
                            }
                            value={dataLoggerValue}
                            options={dataLoggerName?.map(
                              (option) => option.dataLoggerName
                            )}
                            size="small"
                            onChange={(data, event) =>
                              handleDataLoggerName(data, event)
                            }
                            sx={{ width: '16vw' }}
                            ListboxProps={{
                              style: {
                                maxHeight: '200px',
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="SSP_logger_01"
                              />
                            )}
                          />
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className="modal-typo" sx={{ width: '10vw' }}>
                        File Path{' '}
                      </Typography>
                      <div style={{ marginTop: '1%' }}>
                        <Tooltip title={fileDataPath}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={fileDataPath}
                            options={fileNames}
                            size="small"
                            onChange={(data, event) =>
                              handleFilepathChange(data, event)
                            }
                            disabled={restructed.length !== 0}
                            sx={{ width: '18vw' }}
                            ListboxProps={{
                              style: {
                                maxHeight: '200px',
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="LnT_Wega.ini"
                              />
                            )}
                          />
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ marginTop: '1%' }}>
                        <Button
                          disabled={
                            site === '' ||
                            fileDataPath === '' ||
                            dataLoggerValue === '' ||
                            restructed.length !== 0
                          }
                          variant="contained"
                          component="label"
                          sx={{
                            width: '10vw',
                            marginLeft: '10px',
                            textTransform: 'capitalize',
                            borderRadius: '19px',
                            marginTop: '18px',
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: '200px',
                            },
                          }}
                          startIcon={<FileUploadOutlinedIcon />}
                          onClick={handleGetFile}
                        >
                          Get File
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Box>
          </div>

          {/* -------------------------------------------- get filepath ---------------------- */}
          <div>
            <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={openModal}
              onClose={handleCloseModal}
              closeAfterTransition
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 1,
                    width: '75vw',
                    height: '80vh',
                    marginLeft: '15vw',
                    marginTop: '23vh',
                  },
                }}
              >
                <Card
                  elevation={2}
                  style={{ marginTop: '5%', borderRadius: '20px' }}
                >
                  <CardHeader
                    sx={{
                      padding: '20px 20px 20px 20px',
                      // background: CheckForLight()
                      //   ? "rgb(246, 248, 252)"
                      //   : "#4f4f4f",
                    }}
                    action={
                      <Stack spacing={2} direction="row">
                        <Button
                          startIcon={<CancelIcon />}
                          color="primary"
                          variant="contained"
                          style={{
                            marginLeft: '10px',
                            textTransform: 'capitalize',
                            borderRadius: '19px',
                          }}
                          onClick={() => {
                            handleCloseModal();
                          }}
                        >
                          Close
                        </Button>
                      </Stack>
                    }
                    title="Data Logger"
                  />
                  <Divider style={{ borderColor: '#888' }} />
                  <TableContainer
                    sx={{
                      width: '75vw',
                      height: '58vh',
                      marginTop: '2vh',
                      // marginLeft: '5vw',
                    }}
                    component={Paper}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead style={{ marginBottom: '9vh' }}>
                        <StyledTableRow>
                          <StyledTableCell
                            align="center"
                            style={{ color: 'black' }}
                          >
                            {' '}
                            <Typography className="modal-typo">S.No</Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ color: 'black' }}
                          >
                            {' '}
                            <Typography className="modal-typo">
                              Parameter Name
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ color: 'black' }}
                          >
                            {' '}
                            <Typography className="modal-typo">
                              Standard Parameter
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ color: 'black' }}
                          >
                            {' '}
                            <Typography className="modal-typo">
                              Coefficient
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>

                      <TableBody className="table-body">
                        {Fileget?.map((row, index) => (
                          <MemoizedTableRow
                            key={index}
                            row={row}
                            index={index}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider style={{ borderColor: '#888' }} />
                  <div className="submit" style={{ marginTop: '2%' }}>
                    {editlog ? (
                      <Button
                        variant="contained"
                        style={{
                          width: '8vw',
                          marginLeft: '25vw',
                          borderRadius: '18px',
                          textTransform: 'capitalize',
                        }}
                        disabled={toBackend}
                        startIcon={
                          toBackend ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <SaveIcon />
                          )
                        }
                        onClick={handleUpdateValue}
                      >
                        {!toBackend ? 'Update' : 'Updating...'}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        style={{
                          width: '8vw',
                          marginLeft: '25vw',
                          borderRadius: '18px',
                          textTransform: 'capitalize',
                        }}
                        startIcon={
                          toBackend ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <SaveIcon />
                          )
                        }
                        disabled={toBackend}
                        onClick={handleSubmitValue}
                      >
                        {!toBackend ? 'Save' : 'Saving...'}
                      </Button>
                    )}
                  </div>
                </Card>
              </Box>
            </Modal>
          </div>

          {/* /*------------------------------------------------ Modal for New master Add--------------* */}
          <div>
            <Formik
              key={editlog ? 'edit' : 'create'}
              enableReinitialize={true}
              initialValues={stdField}
              validationSchema={MasterTypeSchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = values;
                if (editlog) {
                  const result = await handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSubmit(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openMaster}
                    // onClose={() => {
                    //   handleCloseMaster();
                    //   handleReset();
                    // }}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={openMaster}>
                      <Box
                        className={'styleModal'}
                        // sx={{ bgcolor: 'background.paper' }}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              // background: CheckForLight()
                              //   ? "rgb(246, 248, 252)"
                              //   : "#4f4f4f",
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {editlog ? (
                                  <Button
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    disabled={toBackend}
                                    type={'submit'}
                                    color="primary"
                                    variant="contained"
                                    style={{
                                      marginLeft: '10px',
                                      textTransform: 'capitalize',
                                      borderRadius: '19px',
                                    }}
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {!toBackend ? 'Update' : 'Updating...'}
                                  </Button>
                                ) : (
                                  <Button
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    disabled={toBackend}
                                    type={'submit'}
                                    color="primary"
                                    variant="contained"
                                    style={{
                                      marginLeft: '10px',
                                      textTransform: 'capitalize',
                                      borderRadius: '19px',
                                    }}
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {!toBackend ? 'Save' : 'Saving...'}
                                  </Button>
                                )}
                                <Button
                                  startIcon={<CancelIcon />}
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    marginLeft: '10px',
                                    textTransform: 'capitalize',
                                    borderRadius: '19px',
                                  }}
                                  onClick={() => {
                                    handleCloseMaster();
                                    handleReset();
                                  }}
                                >
                                  Close
                                </Button>
                              </Stack>
                            }
                            title="Add New Master"
                          />
                          <Divider style={{ borderColor: '#888' }} />

                          <div style={{ marginLeft: '2%', marginTop: '2%' }}>
                            <Card
                              sx={{
                                overflowY: 'visible',
                                marginLeft: '-2%',
                                // paddingLeft: '-1vw',
                                marginTop: '-2%',
                                paddingBottom: '2%',
                                scrollBehavior: 'smooth',
                                scrollbarGutter: 'stable',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                  width: '0.4em',
                                },
                                '&::-webkit-scrollbar-track': {
                                  background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: '#888',
                                  borderRadius: '20px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                  background: '#555',
                                },
                              }}
                              elevation={0}
                            >
                              <CardContent sx={{ paddingLeft: '2.5%' }}>
                                <Grid
                                  container
                                  spacing={2}
                                  rowSpacing={2}
                                  columns={16}
                                >
                                  <Grid item xs={4}>
                                    <Typography className="modal-typo">
                                      SiteName
                                    </Typography>
                                    <div style={{ marginTop: '5%' }}>
                                      <Field
                                        name={'siteName'}
                                        render={({ field, form }) => (
                                          <Autocomplete
                                            disableClearable
                                            options={siteIdName.map(
                                              (option) => option.siteName
                                            )}
                                            defaultValue={
                                              editValue
                                                ? editValue.siteName
                                                : null
                                            }
                                            disabled={editlog}
                                            onChange={(event, value) => {
                                              handleDropDownChange(
                                                event,
                                                value,
                                                'siteName'
                                              );
                                            }}
                                            size="small"
                                            sx={{ width: '12vw' }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="E.g: IFP"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        name={'siteName'}
                                        component="div"
                                        className="errorStyle"
                                        style={{
                                          color: 'red',
                                          marginTop: '1%',
                                          textAlign: 'left',
                                          marginLeft: '0%',
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography className="modal-typo">
                                      {/* Parameter Name */}
                                      DataLogger Name
                                    </Typography>
                                    <div style={{ marginTop: '5%' }}>
                                      <Field
                                        as={TextField}
                                        size="small"
                                        variant="outlined"
                                        name="dataLoggerName"
                                        value={values.dataLoggerName}
                                        // name={'description'}
                                        onChange={(e) => {
                                          handleChange(e);
                                          //   handleChangeTxt(e);
                                        }}
                                        // value={values[data.name]}
                                        sx={{ width: '12vw' }}
                                        placeholder="Enter Datalogger Name"
                                      />
                                      <ErrorMessage
                                        name={'dataLoggerName'}
                                        component="div"
                                        className="errorStyle"
                                        style={{
                                          color: 'red',
                                          marginTop: '1%',
                                          textAlign: 'left',
                                          marginLeft: '0%',
                                        }}
                                      />
                                    </div>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <Typography className="modal-typo">
                                      Status
                                    </Typography>
                                    <div style={{ marginTop: '5%' }}>
                                      <Field
                                        name={'status'}
                                        render={({ field, form }) => (
                                          <Autocomplete
                                            disableClearable
                                            disabled={!editlog}
                                            options={status.map(
                                              (option) => option.Value
                                            )}
                                            onChange={(event, value) => {
                                              handleDropDownChange(
                                                event,
                                                value,
                                                'status'
                                              );
                                            }}
                                            disablePortal
                                            size="small"
                                            defaultValue={
                                              editValue
                                                ? editValue.status === 1
                                                  ? 'Active '
                                                  : 'Inactive'
                                                : null
                                            }
                                            sx={{ width: '12vw' }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="E.g: Active"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        name={'Status'}
                                        component="div"
                                        className="errorStyle"
                                        style={{
                                          color: 'red',
                                          marginTop: '1%',
                                          textAlign: 'left',
                                          marginLeft: '0%',
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          {internalLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20%',
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </div>
          ) : (
            <div>
              <TableTemplate
                addButton={'datalogger'}
                header={head}
                rowsValue={table}
                handleEditPopupOpen={(val) => handleEditOpen(val)}
                userRole={Report[0]}
                fallbackText={FallBack()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
