import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  CircularProgress,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

// ========Modal================
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FadeLoader } from 'react-spinners';
import dayjs from 'dayjs';
import { removeQuestionMark } from '../../util/common/CommanFunction';
import { decryptPaddedQuery } from '../../util/security/Cipher';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import TableTemplate from '../../../Template/TableTemplate';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import CancelIcon from '@mui/icons-material/Cancel';
/*------------api call-----------------*/
import { fetchSiteDetails, DownloadReport } from '../../../Api/ReportApi';
import {
  fetchSiteDetailss,
  fetctDocumentDetails,
  postDocumentdata,
  putDocumentDelete,
  fetchDocumentTable,
} from '../../../Api/DocumentApi';
import Download from '../../../Template/Excel/Download';
import CustomSnackbar from '../../util/components/CustomSnackbar';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Document } from '../../util/TextField';
import { DocumentSchema } from '../../util/ValidateSchema';
import replaceObjectById from '../../util/StateUpdater';
import errorMsg from '../../util/errorMessage.json';
import { CheckForLight } from '../../util/common/CommanFunction';

import { useLocation } from 'react-router';
export default function Reporthome({ Report }) {
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const [Createreport, setCreatereport] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const location = useLocation();
  /*------------ modal state-----------------*/

  const [siteIdName, setSiteId] = useState([]);
  const [DocuemntIdName, setDocumentId] = useState([]);
  const [customFromDate, setFromDate] = useState('');
  const [SiteReport, setSiteReport] = useState([]);
  const [base64Image, setBase64Image] = useState('');
  const [editcall, seteditcall] = useState();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [fromDownloadDate, setFromDownloadDate] = useState('');
  const [toDownloadDate, setToDownloadDate] = useState('');
  const [siteTab, setSiteTab] = React.useState('');
  const [loading, setloading] = useState(true);
  const [selectedImgName, setSelectedImgName] = useState('');
  const [updatecall, setupdatecall] = useState({
    siteName: '',
    reportName: '',
    email: '',
    activeFlag: '',
    timePeriod: '',
  });
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const initalState = {
    siteName: '',
    documentname: '',
  };
  const [reportFields, setReportFields] = useState(initalState);
  console.log(reportFields, 'reportFields');

  const [reportValue, setReportValue] = useState({
    timePeriodValue: '',
    statusValue: '',
  });
  console.log(reportValue, 'reportValue');
  console.log(editcall, 'editcall');

  const [text, setTextField] = useState([]);
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  const isFromSiteList = Object.keys(params).length > 0;
  console.log(isFromSiteList);
  let data = params;
  useEffect(() => {
    let data = Document();
    setTextField(data);
  }, []);

  useEffect(() => {
    getSiteCall();

    fetchdocumentCategory();
  }, []);
  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'siteName':
        return (option = siteIdName?.map((option) => option.siteName));
      case 'documentname':
        return (option = DocuemntIdName?.map(
          (option) => option.documentTypeName
        ));

      default:
    }
    return option;
  }
  // const valuess = {
  //   siteName: siteIdName.length > 0 ? siteIdName[selectedIndex].siteName : '',
  //   documentname: DocuemntIdName.length > 0 ? DocuemntIdName[selectedIndex].documentTypeName : '',
  // };
  const defaultMail = sessionStorage.getItem('email');
  console.log(reportFields.mailId, 'emailemailemail');
  /*------------------------- Api --------------------------------------------*/

  const dayjs = require('dayjs');
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async () => {};

  console.log(selectedImgName);
  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async () => {
    try {
      let siteIdFilter = siteIdName?.filter((data) => {
        return data.siteName === reportFields.siteName;
      });
      let DocumentIdFilter = DocuemntIdName?.filter((data) => {
        return data.documentTypeName === reportFields.documentname;
      });
      if (selectedImgName) {
        let file = document.getElementById('upload-logo').files[0];
        let reader = new FileReader();

        reader.onloadend = async () => {
          let base64String = reader.result.split(',')[1]; // Extract base64 string part
          let fileExtensionvalue = selectedImgName.split('.');

          let fileExtension = '.' + selectedImgName.split('.').pop();
          let fileNameWithoutExtension = selectedImgName.substring(
            0,
            selectedImgName.lastIndexOf('.')
          );
          let data = {
            document: base64String,
            documentName: fileNameWithoutExtension,
            fileFormat: fileExtension,
            createdBy: sessionStorage.getItem('id'),
            documentTypeId: DocumentIdFilter[0]?.documentTypeId,
            siteId: isFromSiteList ? params.id : siteIdFilter[0]?.siteId,
            status: 1,
          };

          try {
            setToBackend(true);
            const responseData = await postDocumentdata(data);
            if (responseData.status === 200) {
              setSnack(errorMsg.success);
              handleClose();
              setToBackend(false);
              setOpen(false);
              getDataTable();
              window.location.reload();
              return 1;
            }
          } catch (e) {
            setSnack(errorMsg.failure);
            console.error('Error:', e.response?.status);
            setToBackend(false);
            console.log('error:', e.message);
            return 0;
          }
        };

        reader.readAsDataURL(file); // Read file as data URL (base64)
      }
    } catch (error) {
      console.error('Error occurred during file processing:', error);
    }
  };
  const handleDelete = async (val) => {
    console.log(val?.documentId);
    const dele = await putDocumentDelete(val?.documentId)
      .then(() => {
        getDataTable();
        window.location.reload();
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: 'success',
            message: 'Data deleted successfully',
          };
        });
      })
      .catch((err) => {
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: 'warning',
            message: 'Unable to delete try again later',
          };
        });
        console.log(err);
      });
  };

  const handlesitevalue = (val) => {
    console.log(val);

    const siteIdFilter = siteIdName?.filter((data) => data.siteName === val);
    console.log(siteIdFilter[0]?.siteId);
    // getDataTable(siteIdFilter[0]?.siteId)
  };
  useEffect(() => {
    if (isFromSiteList) {
      console.log('runsss');
      setReportFields(() => {
        return { ...reportFields, siteName: params.name };
      });
      console.log(params.id);
      getDataTable(params.id);
    }
  }, []);
  /*-------------------------Table get call --------------------------------------------*/
  const getDataTable = async (id) => {
    console.log(data);
    try {
      const data = await fetchDocumentTable(id);
      // const reverse = [...data].reverse();
      if (Array.isArray(data)) {
        // If data is an array, set tableValue to data directly
        setSiteReport(data);
        setloading(false);
      } else if (typeof data === 'object' && data !== null) {
        // If data is an object, wrap it in an array and set tableValue
        setSiteReport([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  /*------------------------- Site Name list dropdown --------------------------------------------*/
  const getSiteCall = async () => {
    try {
      console.log('start');
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDetails();
      console.log(data, 'sitelist');
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*-------------------------report Name list dropdown --------------------------------------------*/
  const fetchdocumentCategory = async () => {
    try {
      let data = await fetctDocumentDetails();
      setDocumentId(data);
    } catch (e) {
      console.error(e);
    }
  };

  /*---------------------------------- New Report Call -----------------------*/
  const handleCrate = () => {
    setCreatereport(true);
    setOpen(true);
    setEdit(false);
    seteditcall();
  };
  const handledocumentdownload = (val) => {
    if (val?.documentUrl) {
      window.open(val.documentUrl, '_blank');
    } else {
      console.error('No document URL provided.');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setReportFields(initalState);
    setSelectedImgName('');
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };

  const Type = [{ label: 'Generation', value: 'generation' }];
  const statusList = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];
  const optionTime = [
    { label: 'Today', value: 'Today' },
    { label: 'Yesterday', value: 'Yesterday' },
    { label: 'Last Week', value: 'Weekly' },
    { label: 'Last Month', value: 'Monthly' },
    { label: 'Custom Range', value: 'custom' },
  ];

  function filterOptions(hideCustom) {
    const reportName =
      edit && reportFields.reportName === ''
        ? editcall.reportName
        : reportFields.reportName;
    const optionsMap = {
      'Daily Generation Report': ['Today', 'Yesterday'],
      'Asset Management Report': ['Weekly', 'Monthly'],
    };

    let options = optionsMap[reportName] || [];

    if (!hideCustom && reportFields.reportName) {
      options.push('custom');
    }

    return optionTime.filter((option) => options.includes(option.value));
  }

  const headCells = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
      default: true,
    },
    {
      label: 'Document Name',
      id: 'documentTypeName',
      view: true,
    },
    {
      label: 'Created By',
      id: 'userName',
      view: true,
    },
    {
      label: 'Created Date',
      id: 'createdDate',
      view: true,
    },

    {
      label: 'Action',
      id: 'action',
      view: true,
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleDropdownChange = (event, value, name) => {
    console.log(value, 'dropValue');
    if (name === 'timePeriod' || name === 'status') {
      setReportFields({ ...reportFields, [name]: value.label });
      setReportValue({ ...reportValue, [name]: value.value });
    } else {
      setReportFields({ ...reportFields, [name]: value });
    }
  };
  // ========customrange=========
  const handleChangeFromDate = (data, value) => {
    if (
      data !== null &&
      typeof data === 'object' &&
      typeof data.format === 'function'
    ) {
      const formattedDatetime = data.format('YYYY-MM-DD');
      setFromDate(formattedDatetime);
    } else {
      console.error("Invalid data format or 'data' is null");
    }
  };
  const handleFileChange = (event) => {
    setSelectedImgName("");

    
    const file = event.target.files[0];
    const reader = new FileReader();
  
  
    setBase64Image('');
    setSnack({ ...initialSnack, open: false });
  
    if (file) {
      const fileSize = file.size;
      const fileType = file.type;
      console.log(fileSize, 'fileSize');
  
      const unsupportedTextTypes = ['text/plain', 'text/csv', 'text/html', 'text/xml'];
      const fileTypeCheck = !unsupportedTextTypes.includes(fileType);
      // const fileSizeCheck = fileSize < 2000000;
  
      if (fileTypeCheck ) {
        reader.onloadend = () => {
          setBase64Image(reader.result);
          setSelectedImgName(file.name);
        };
  
        reader.readAsDataURL(file);
        console.log(reader, 'imageFile');
      } else {
        setSnack({
          ...initialSnack,
          open: true,
          severity: 'warning',
          message:  'Text file  is not supported',
        });
      }
    } else {
      // Reset the image name if no file is selected
      setBase64Image('');
      setSelectedImgName('');
    }
  };
  

  const handleTxtChange = (event) => {
    console.log(event.target.name, event.target.value);
    setReportFields({
      ...reportFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Document');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const tableValues = SiteReport.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const Total = isFromSiteList
    ? SiteReport.filter((site) => site.siteName === params.name).length
    : SiteReport.length;

  const paths = isFromSiteList
    ? [
        { label: 'Home', path: '/menu' },
        { label: 'Site List', path: -1 },
        { label: 'document', path: 'document' },
      ]
    : [
        { label: 'Home', path: '/menu' },
        { label: 'document', path: 'document' },
      ];

  return (
    <div>
      {/* {loading ? ( */}
      {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div> */}
      {/* ) : ( */}
      <div>
        {/* ---------search---bar--- Card----------- */}

        <div>
          {/* ---------   -----save andupdate---- popup----------- */}
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <Formik
            enableReinitialize={true}
            initialValues={reportFields}
            validationSchema={DocumentSchema}
            onSubmit={async (values, { resetForm }) => {
              let submitValue = {
                siteName: values.siteName,
                documentName: values.documentName,
              };
              if (edit) {
                const result = await handleUpdate(submitValue);
                console.log(result, 'result');
                if (result === 1) {
                  resetForm();
                }
              } else {
                const result = await handleSave(submitValue);
                console.log(result, 'result');
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  closeAfterTransition
                >
                  <Fade in={open}>
                    <Box
                      className={'styleModalSmall'}
                      // style={{ height: '40vh' }}
                      // sx={{ bgcolor: 'background.paper' }}
                    >
                      <Card
                        sx={{
                          borderRadius: '20px',
                          height: 'fit-content',
                        }}
                      >
                        <CardHeader
                          sx={{
                            padding: '20px 20px 20px 20px',
                            background: CheckForLight()
                              ? 'rgb(246, 248, 252)'
                              : '#4f4f4f',
                          }}
                          action={
                            <Stack spacing={2} direction="row">
                              {edit ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSubmit}
                                >
                                  {toBackend ? 'Updating...' : 'Update'}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  s
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSave}
                                >
                                  {toBackend ? 'Uploading...' : 'Upload'}
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                disabled={toBackend}
                                style={{
                                  borderRadius: '25px',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => {
                                  handleClose();
                                  handleReset();
                                }}
                                startIcon={<CancelIcon />}
                              >
                                Cancel
                              </Button>
                            </Stack>
                          }
                          title={edit ? 'Update Document' : 'Add New Document'}
                        />
                        <Divider sx={{ borderColor: '#888' }} />
                        <div
                          style={{
                            height: '30vh',
                            padding: '20px',
                          }}
                        >
                          <Grid container spacing={2} columns={16}>
                            {text?.length > 0
                              ? text?.map((data, index) => (
                                  <Grid item xs={4}>
                                    <div key={index}>
                                      {data.type === 'textField' ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Field
                                            as={TextField}
                                            disabled={toBackend}
                                            id={`outlined-basic-${index}`}
                                            size="small"
                                            variant="outlined"
                                            name={data.name}
                                            inputProps={{
                                              maxLength: data.length,
                                            }}
                                            placeholder={data.placeholder}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleTxtChange(e);
                                            }}
                                            value={
                                              values.mailId === null ||
                                              values.mailId === ''
                                                ? ''
                                                : values[data.name]
                                            }
                                            sx={{ width: '12vw' }}
                                          />
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : data.type === 'dropdown' ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Tooltip
                                            title={
                                              !edit && data.name === 'status'
                                                ? 'Active'
                                                : edit
                                                ? data.name === 'status'
                                                  ? typeof values[data.name] ===
                                                    'string'
                                                    ? values[data.name]
                                                    : values.status === 1
                                                    ? 'Active'
                                                    : 'Inactive'
                                                  : values[data.name]
                                                : values[data.name]
                                            }
                                          >
                                            <Field
                                              render={({ field, form }) => (
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit
                                                      ? data.name === 'status'
                                                        ? typeof values[
                                                            data.name
                                                          ] === 'string'
                                                          ? values[data.name]
                                                          : values.status === 1
                                                          ? 'Active'
                                                          : 'Inactive'
                                                        : values[data.name]
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Autocomplete
                                                    options={
                                                      getOptions(data?.name) ||
                                                      []
                                                    } // Set the default value to the first item's siteName
                                                    size="small"
                                                    id={`combo-box-demo-${index}`}
                                                    onChange={(event, value) =>
                                                      handleDropdownChange(
                                                        event,
                                                        value,
                                                        data.name
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                      />
                                                    )}
                                                  />
                                                </Tooltip>
                                              )}
                                            />
                                          </Tooltip>
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : reportFields.timePeriod ==
                                          'Custom Range' &&
                                        data.type === 'datepic' ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {reportFields.reportName ===
                                            'Daily Generation Report'
                                              ? 'Select Date'
                                              : data.label}
                                            :
                                          </Typography>
                                          <Tooltip
                                            title={
                                              !edit && data.name === 'status'
                                                ? 'Active'
                                                : edit
                                                ? data.name === 'status'
                                                  ? typeof values[data.name] ===
                                                    'string'
                                                    ? values[data.name]
                                                    : values.status === 1
                                                    ? 'Active'
                                                    : 'Inactive'
                                                  : values[data.name]
                                                : values[data.name]
                                            }
                                          >
                                            <div>
                                              <Field
                                                render={({ field, form }) => (
                                                  <div>
                                                    <LocalizationProvider
                                                      dateAdapter={AdapterDayjs}
                                                    >
                                                      <DatePicker
                                                        placeholder="From"
                                                        disabled={
                                                          reportFields.timePeriod !==
                                                          'Custom Range'
                                                        }
                                                        disableFuture
                                                        sx={{ width: '13vw' }}
                                                        onChange={(date) =>
                                                          handleChangeFromDate(
                                                            date
                                                          )
                                                        } // Pass the selected date to handleChangeFromDate
                                                        slotProps={{
                                                          textField: {
                                                            size: 'small',
                                                          },
                                                        }}
                                                      />
                                                    </LocalizationProvider>
                                                  </div>
                                                )}
                                              />
                                            </div>
                                          </Tooltip>
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : reportFields.timePeriod ==
                                          'Custom Range' &&
                                        reportFields.reportName !==
                                          'Daily Generation Report' &&
                                        data.type === 'datepi' ? (
                                        <div>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Tooltip
                                            title={
                                              !edit && data.name === 'status'
                                                ? 'Active'
                                                : edit
                                                ? data.name === 'status'
                                                  ? typeof values[data.name] ===
                                                    'string'
                                                    ? values[data.name]
                                                    : values.status === 1
                                                    ? 'Active'
                                                    : 'Inactive'
                                                  : values[data.name]
                                                : values[data.name]
                                            }
                                          ></Tooltip>
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </Grid>
                                ))
                              : null}

                            <Grid item xs={3} style={{ marginTop: '2%' }}>
                              <div>
                                <label
                                  htmlFor="upload-logo"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <TextField
                                    type="file"
                                    id="upload-logo"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                      handleFileChange(event);
                                    }}
                                  />
                                  <Button variant="contained" component="span">
                                    Select File
                                  </Button>
                                </label>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{ marginTop: '2%', marginLeft: '-4%' }}
                            >
                              <div>{selectedImgName}</div>
                            </Grid>
                          </Grid>
                        </div>
                      </Card>

                      <div></div>
                    </Box>
                  </Fade>
                </Modal>
              </Form>
            )}
          </Formik>
        </div>
        {/* ---------------pass props value----------- */}

        <div style={{ marginTop: '-0.5%' }}>
          <TableTemplate
            PageName={'Docuemnt'}
            addButton={'Document'}
            SearchLabel={'Search Document Here... '}
            header={headCells}
            rowsValue={tableValues}
            // tabsData={tabsData}
            handledownloaddocument={(val) => handledocumentdownload(val)}
            handleAddPopupOpen={(val) => handleCrate(val)}
            handleSitedropdownvalue={(val) => handlesitevalue(val)}
            userRole={Report[0]}
            siteNameList={siteIdName}
            siteNamevalue={siteIdName.length > 0}
            handleDelete={(val) => {
              handleDelete(val);
            }}
            handleDownloadExcel={(val) => {
              handleDownloadTable(val);
            }}
            paths={paths}
            // fallbackText={'No reports available'}
            fallbackText={'No Document are available at this time.'}
          />
        </div>
      </div>
      {/* // )} */}
    </div>
  );
}