import axios from 'axios';

export const postsubscriptiondata = async (obj) => {

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_SAVE}`,
        obj
      );
      return response;
    } catch (error) {
      console.error('Error posting data:', error.message);
      throw error;
    }
  };

  export const fetchsubscriptionTable = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${
          process.env.REACT_APP_SUBSCRIPTION_TABLE
        }/${sessionStorage.getItem('id')}`
      );
     
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };

  export const putSubscriptionEdit = async (data, id) => {
    console.log(id);
    try {
     
      const response = await axios.put(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_UPDATE}/${id}`,
        data
      );
   
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };