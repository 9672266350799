import axios from 'axios';
const isOEM = sessionStorage.getItem('userTypeId') == 1;
export const fetchEquipmentreplaceTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${isOEM
        ? process.env.REACT_APP_EQUIPMENTREPLACE_HISTORY_BYCOMPANY
        : process.env.REACT_APP_EQUIPMENTREPLACE_HISTORY_BYCUSTOMER
      }/${isOEM
        ? sessionStorage.getItem('companyId')
        : sessionStorage.getItem('customerId')
      }`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchEachEquipHistory = async (equipID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIP_REPLACE_HISTORY}/${equipID}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export const fetchEventTable = async (Id) => {
  try {
    const response = await axios.get(
      // `${process.env.REACT_APP_EVENT_TABLE_USING_SITEID}=${Id}`
      // 132
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EVENT_TABLE}/${Id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchEquipTickets = async (equipId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TICKET_TABLE}/${equipId}`);
    // 96
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
