import axios from "axios";

export const fetchPiehartGet = async (id) => {
 
  try {
    let response = await axios.get(
    `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PIECHARTDATA}/${id}`
    );
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error.message);
  }
};

export const fetchPiehartGett = async (id) => {
 
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PIECHARTDATA_SITEID}/${id}`)
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error.message);
  }
};

export const fetchBarChartGet = async (id) => {

  try {
    const response = await axios.get( `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_BARCHARTDATA}/${id}`
        
    );
    console.log(response, "responseBar");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
  }
};

export const fetchBarChartGett = async (id) => {
 
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_BARCHARTDATA_SITEID}/${id}`)
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error.message);
  }
};