import axios from 'axios';
export const fetchequipmentcategoryTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY_TABLE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const postequipmentcategory = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY_POST}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const putequipmentcategory = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY_PUT}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchEquipmentTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_EQUIPMENTTYPE_TABLE_BYID
      }/${sessionStorage.getItem('customerId')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const putEquipmentcategory = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTTYPE_UPDATE}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const postEquipmentcategorySave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY_SAVE}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
export const Getdropdownvalue = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_Sector_dropdown_SectortypeList}`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
