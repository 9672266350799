import axios from 'axios';
export const fetchSiteDetailss  = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${
          process.env.REACT_APP_SITELIST_BYUSERR
        }/${sessionStorage.getItem('id')}`
      );
      console.log(response, 'resssss');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error
    }
  };

export const fetctDocumentDetails  = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${
          process.env.REACT_APP_API_DOCUMENT_CATEGORY_DROPDOWN
        }`
      );
      console.log(response, 'resssss');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error
    }
  };
  export const fetchDocumentTable = async (Id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DOCUMENT_TABLE}/${Id}`
      );
    
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };
  export const postDocumentdata = async (obj) => {

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DOCUMENT_SAVE}`,
        obj
      );
      return response;
    } catch (error) {
      console.error('Error posting data:', error.message);
      throw error;
    }
  };
  export const putDocumentDelete = async (id) => {
    console.log(id);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DOCUMENT_DELETE}/${id}`,
      );
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };