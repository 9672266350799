import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Slide,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveAltIcon from '@mui/icons-material/Save';
import { filterRows } from './FilterFunction';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '56%',
  top: '15%',
  left: '57%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  height: 'fit-Content',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px 0 0 10px',
  p: 4,
};

export default function TransitionsModal({
  state,
  handleClose,
  header,
  onApplyFilter,
  dropData,
  pageName,
  rowsValue,
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [filterValues, setFilterValues] = useState({});
  const [FilteredValue, setFilterValue] = useState([]);
  
  const handleCloseModal = () => {
    handleClose();
  };

  const handleTextChange = (label, value) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [label]: value,
    }));
  };

  const handleDateChange = (label, date) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [label]: date,
    }));
  };

  const handleDropChange = (name, values) => {
    // let data = value;
    // if (label === 'priority') {
    //   data = value === 'High' ? 3 : value === 'Medium' ? 2 : 1;
    // }
    
    // setFilterValues((prevValues) => ({
    //   ...prevValues,
    //   [label]: data,
    // }));
    const labelCounts = {};
    values.forEach((item) => {
      labelCounts[item.label] = (labelCounts[item.label] || 0) + 1;
    });

    const filteredValues = values.filter(
      (item) => labelCounts[item.label] === 1
    );

   

    setFilterValues((prev) => {
      const updated = {
        ...prev,
        [name]: filteredValues.map((value) => value.label),
      };
     
      return updated;
    });
  };

  const handleFilter = () => {
    onApplyFilter(filterValues);
  
    setTimeout(() => {
      handleCloseModal();
    }, 10);
  };

  const handleClear = () => {
    onApplyFilter({});
    setFilterValue([]);
    setFilterValues({});
    handleCloseModal();
  };


  function convertToOptions(array) {
    return array?.map((label) => ({ label }));
  }

  useEffect(() => {
    if (Object.keys(filterValues).length !== 0) {
      if (Object.keys(filterValues).length !== 0) {
        const filtered = filterRows(rowsValue, filterValues);
        setFilterValue(filtered);
      }
    }
  }, [filterValues]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={state}
        hideBackdrop={false}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Slide direction="left" in={state} mountOnEnter unmountOnExit>
          <Box sx={style}>
            <Grid container spacing={0} columns={1}>
              {header.map(
                (headCell) =>
                  headCell.label !== 'Inverters' &&
                  headCell.label !== 'Action' && (
                    <div style={{ padding: '2px' }} key={headCell.id}>
                      <Grid item xs={10}>
                        <Typography
                          style={{
                            lineHeight: '12px',
                            paddingBottom: '5px',
                            fontFamily:
                              'Inter, Ubuntu, "Roboto Slab", "Fira Sans" !important',
                          }}
                        >
                          {headCell.label}
                        </Typography>
                        {[
                          'Last Updated',
                          'Updated On',
                          'Created On',
                          'Created Date',
                        ].includes(headCell.label) ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              slotProps={{ text: { size: 'small' } }}
                              sx={{
                                width: '16vw',
                                marginRight: '1vw',
                                '& .MuiInputBase-input': {
                                  height: '6px',
                                },
                              }}
                              value={filterValues[headCell.id] || null}
                              onChange={(date) =>
                                handleDateChange(headCell.id, date)
                              }
                            />
                          </LocalizationProvider>
                        ) : pageName !== 'Ticket Overview' ? (
                          <TextField
                            id="outlined-basic"
                            size="small"
                            sx={{
                              width: '16vw',
                              marginRight: '1vw',
                              marginBottom: '10px',
                            }}
                            variant="outlined"
                            value={filterValues[headCell.id] || ''}
                            onChange={(e) =>
                              handleTextChange(headCell.id, e.target.value)
                            }
                            fullWidth
                          />
                        ) : (
                          <Autocomplete
                            id="outlined-basic"
                            size="small"
                            disableClearable
                            disableCloseOnSelect
                            sx={{
                              width: '18.2vw',
                              marginRight: '1vw',
                              marginBottom: '10px',
                            }}
                            limitTags={1}
                            variant="outlined"
                            value={
                              headCell.id === 'priority'
                                ? filterValues[headCell.id] == 3
                                  ? 'High'
                                  : filterValues[headCell.id] == 2
                                  ? 'Medium'
                                  : filterValues[headCell.id] == 1
                                  ? 'Low'
                                  : []
                                : convertToOptions(filterValues[headCell.id]) ||
                                  []
                            }
                            onChange={(e, values) =>
                              handleDropChange(headCell.id, values)
                            }
                            multiple
                            options={
                              convertToOptions(dropData[headCell.id]) || []
                            }
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  cle
                                  style={{ marginRight: 8 }}
                                  checked={
                                    filterValues[headCell.id] &&
                                    filterValues[headCell.id].includes(
                                      option.label
                                    )
                                  }
                                />
                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={`Select ${headCell.label}`}
                              />
                            )}
                          />
                        )}
                      </Grid>
                    </div>
                  )
              )}
            </Grid>
            {FilteredValue.length !== 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                  marginBottom: '-10px',
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleClear}
                  startIcon={<CancelIcon />}
                  sx={{
                    marginRight: '20px',
                    textTransform: 'capitalize',
                    borderRadius: '25px',
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  startIcon={<SaveAltIcon fontSize="small" />}
                  onClick={handleFilter}
                  style={{ textTransform: 'capitalize', borderRadius: '25px' }}
                >
                  {FilteredValue.length == 0
                    ? `Apply`
                    : `Show ${FilteredValue.length} results`}
                </Button>
              </div>
            )}
          </Box>
        </Slide>
      </Modal>
    </div>
  );
}
