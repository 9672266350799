import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import BarChart from './BarChart';
import PieChart from '../../Ticket/PieChartdata';

const Content1 = (props) => {
  const { EventStatistcs } = props;
  console.log(EventStatistcs, 'EventStatistcs');
  return (
    <Grid container spacing={2} padding={0}>
      <Grid item xs={12} sm={12} md={12} padding={0}>
        <Card
          elevation={0}
          sx={{
            width: '100%',
            height: "auto",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px',
          }}
        >
          {/* <PieChart /> */}
          <BarChart
            EventStatistcs={EventStatistcs}
            size={{ height: '450px' }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Content1;
