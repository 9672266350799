import axios from 'axios';

export const getDashboardValues = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DASHBOARD}/${id}`
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
