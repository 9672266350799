import React, { useState } from 'react';
import { Paper, Box, Typography, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import './Loginpage.css';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//------------------- Icons ---------------//
import { AiFillLock } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import eira from '../../images/headerLogo.svg';
import { ForgotPassAPI } from '../../Api/PasswordControlApi';

const Loginpage = () => {
  const [emailId, setEmailId] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [open, setOpen] = useState(false);
  const [variantText, setVarient] = useState('info');
  const [textValue, setTextValue] = useState('');
  const [toBackEnd, setToBackend] = useState(false);

  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleNavigate = async () => {
    if (emailId == null) {
      setOpen(true);
      setVarient('warning');
      setTextValue('Please Enter the EmailId');
    } else {
      if (emailError !== true) {
        console.log(emailId);
        try {
          setToBackend(true);
          let res = { emailId: emailId.toLowerCase() };
          let respone = await ForgotPassAPI(res);
          setToBackend(false);
          if (respone.status === 200) {
            setOpen(true);
            setTextValue('reset password sent to your mail Id');
            setVarient('success');
            navigate('/', {
              state: { changePassword: true, emailId: emailId },
            });
          }
        } catch (error) {
          setOpen(true);
          setTextValue('mailId does not exists');
          setVarient('error');
          console.error(error);
        }
      } else {
        setOpen(true);
        setTextValue('Enter a valid mail Id');
        setVarient('warning');
      }
    }
  };
  const handleBackToLogin = () => {
    console.log('navigate');
    navigate('/');
  };
  const handleEmailField = (e) => {
    setEmailId(e.target.value);
    setEmailError(!isValidEmail(e.target.value));
  };
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  return (
    <>
      <div className="background-image-container"></div>
      <div className="overlay"></div>
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '95vh',
          }}
        >
          <Paper
            elevation={5}
            style={{
              boxSizing: 'border-box',
              padding: '10px 10px 20px 0px',
              width: '30rem',
              height: '28rem',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center',
                  // marginLeft: '5vw',
                  marginBottom: '-2%',
                  marginTop: '2%',
                }}
              >
                <img src={eira} alt="eiralogo" style={{ width: '120px' }} />
                {/* <Typography style={{ marginTop: "-20%", marginLeft: "53%", fontSize: "30px", fontWeight: "550" }}>Eira</Typography> */}
              </div>
              <Typography
                style={{
                  marginLeft: '10%',
                  fontSize: '26px',
                  fontWeight: '550',
                  marginTop: '10%',
                  color: '#5b5e5c',
                }}
              >
                Forgot Password <AiFillLock />
              </Typography>
              <Typography
                style={{
                  marginLeft: '10%',
                  color: '#6c756f',
                  marginTop: '1%',
                }}
              >
                Enter your email and we'll send you instructions to reset your
                password
              </Typography>
            </div>
            <div
              style={{ width: '330px', marginLeft: '15px', marginTop: '10px' }}
            >
              <TextField
                id="outlined-basic"
                placeholder="Email"
                variant="outlined"
                style={{
                  borderRadius: '9PX',
                  hight: '10px',
                  width: '38ch',
                  marginLeft: '10%',
                  marginTop: '5%',
                }}
                onChange={handleEmailField}
                error={emailError}
                helperText={
                  emailError ? (
                    <span style={{ color: '#ff0f0f' }}>
                      Please enter a valid email address
                    </span>
                  ) : null
                }
              />
            </div>
            <div style={{ marginLeft: '7%' }}>

              <Button
                variant="contained"
                startIcon={toBackEnd && <CircularProgress size={20} color="inherit" />}
                disabled={toBackEnd}
                sx={{
                  fontSize: '26px',
                  width: '26rem',
                  backgroundColor: '#000440',
                  margin: '20px 0  0',
                  mt: 3,
                  height: '45px',
                  letterSpacing: '0.5px',
                  textTransform: 'capitalize',
                  borderRadius: '20px 20px 20px 20px',
                  ':hover': {
                    backgroundColor: '#FFFFFF',
                    color: '#000440',
                  },
                }}
                onClick={handleNavigate}
              >
                Reset Password
              </Button>

            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                marginLeft: '32%',
                marginTop: '5%',
              }}
              onClick={handleBackToLogin}
            >
              <IoIosArrowBack style={{ color: 'blue', marginRight: '5px' }} />
              <Typography style={{ color: 'blue', fontSize: '20px' }}>
                Back to login
              </Typography>
            </div>
            <div>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert
                  onClose={handleClose}
                  severity={variantText}
                  sx={{ width: '100%' }}
                >
                  {textValue}
                </Alert>
              </Snackbar>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default Loginpage;
