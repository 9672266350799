import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material';

const ColumnChart = ({ date, chart, firstBar, secondBar }) => {
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const isLight = colorMode === 'light';
  console.log(secondBar, 'datedatedate');
  const chartName =
    chart === 'Task Manager' ? 'Task Manager' : 'Events and Tickets';
  const options = {
    chart: {
      type: 'column',
      height: '375vh',
      //375px
      backgroundColor: isLight ? null : '#121212',
      style: {
        fontFamily: 'Arial, sans-serif',
      },
    },
    title: {
      text: chartName,
      style: {
        color: isLight ? 'black' : 'white',
      },
    },
    xAxis: {
      categories: date,
      labels: {
        style: {
          fontSize: '12px',
          color: isLight ? 'black' : 'white',
        },
      },
      lineColor: isLight ? '#ccd6eb' : '#707073',
      tickColor: isLight ? '#ccd6eb' : '#707073',
      scrollbar: {
        enabled: true, // Enable scrollbar
      },
    },
    yAxis: {
      title: {
        text: chartName === 'Task Manager' ? 'Hours' : 'Count',
        style: {
          color: isLight ? 'black' : 'white',
        },
      },
      labels: {
        style: {
          color: isLight ? 'black' : 'white',
        },
      },
      gridLineColor: isLight ? '#e6e6e6' : '#444444',
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: isLight ? 'black' : 'white',
      },
      itemHoverStyle: {
        color: isLight ? 'gray' : 'lightgray',
      },
    },
    series:
      chart === 'Task Manager'
        ? [
          {
            name: chart === 'Task Manager' ? 'Projects' : 'User',
            data: chart === 'Task Manager' && firstBar,
          },
        ]
        : [
          {
            name: 'Events',
            data: firstBar,
            // color: isLight ? '#7cb5ec' : '#1e90ff' // Example color, adjust as needed
          },
          {
            name: 'Tickets',
            data: secondBar,
            // color: isLight ? '#32cd32' : '#90ed7d' // Example color, adjust as needed
          },
        ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ColumnChart;
