import React, { useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Card,
  CardActions,
  Button,
  Stack,
  Menu,
  MenuItem,
  CardContent,
  Divider,
  Modal,
  TextField,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const SOP = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [anchorAction, setAnchorAction] = React.useState(null);

  const handleNewDocument = () => {
    navigate("/menu/newdocument");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAction = Boolean(anchorAction);
  const handleClickAction = (event) => {
    setAnchorAction(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorAction(null);
  };

  const DocumentTemplate = [
    { label: "Ventus" },
    { label: "CleanTech" },
    { label: "Selvi Solor" },
  ];

  const TableTemplateData = [
    {
      SequenceOrder: "1",
      WorkDescription: "Check the Energy meter display functioning properly.",
      Field: "textField",
      Remarks: "Energy meter display functioning properly",
    },
    {
      SequenceOrder: "2",
      WorkDescription: "Check the logbook maintain daily",
      Field: "Dropdown",
      Remarks: "logbook maintain daily",
    },
    {
      SequenceOrder: "3",
      WorkDescription: " Check the relay warning and alarms",
      Field: "Checkbox",
      Remarks: "relay warning and alarms",
    },
    {
      SequenceOrder: "4",
      WorkDescription: "Check for any abnormal noise from panel",
      Field: "textField",
      Remarks: "abnormal noise from panel",
    },
    {
      SequenceOrder: "5",
      WorkDescription:
        "Check all trip indications and warning alarms working properly.",
      Field: "DropDown",
      Remarks: "trip indications and warning alarms working properly",
    },
    {
      SequenceOrder: "6",
      WorkDescription: "Checking of the trip circuit healthy.",
      Field: "Image",
      Remarks: "trip circuit healthy.",
    },
    {
      SequenceOrder: "7",
      WorkDescription: "Check for any physical damage.",
      Field: "Checkbox",
      Remarks: "physical damage.",
    },
    {
      SequenceOrder: "8",
      WorkDescription:
        "Check as per electrical standard rubber mat laid near the Panel area.",
      Field: "textfield",
      Remarks: "rubber mat laid near the Panel area.",
    },
  ];

  const handleOpenModel = () => {
    setTemplateOpen(true);
  };
  const handleCloseTemplate = () => {
    setTemplateOpen(false);
  };

  return (
    <div>
      <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Paper
          elevation={0}
          style={{
            width: "100%",
            borderRadius: "2px 2px 0px 0px",
            userSelect: "none",
            height: "5vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            width={"100%"}
            direction="row"
            justifyContent="space-between"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon
                style={{
                  marginRight: "10px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              />
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "16px",
                  userSelect: "none",
                }}
              >
                Standard Operating Procedure
              </Typography>
            </Grid>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ textTransform: "capitalize" }}
              variant="contained"
            >
              Template
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Template Download</MenuItem>
              <MenuItem onClick={handleClose}>Upload</MenuItem>
            </Menu>
          </Grid>
        </Paper>
      </Box>

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginTop: "2%",
        }}
      >
        <Paper
          elevation={0}
          style={{
            width: "100%",
            borderRadius: "2px 2px 0px 0px",
            userSelect: "none",
            height: "75vh",
            display: "flex",
          }}
        >
          <Grid container spacing={2} style={{ padding: "2%" }}>
            <Grid item xs={2}>
              <Card
                onClick={handleNewDocument}
                sx={{
                  height: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
                variant="outlined"
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AddIcon />
                    <Typography>New Document</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {DocumentTemplate.map((data, index) => (
              <Grid item xs={2} key={index}>
                <Card onClick={handleOpenModel}
                  sx={{
                    height: "20vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                        }}
                      >
                        <RemoveRedEyeIcon onClick={handleOpenModel} />
                      </Box>
                      <Typography>{data.label}</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>

      {/* ----------------------------------------Template preview modal--------------- */}
      <Modal
        open={templateOpen}
        onClose={handleCloseTemplate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>Ventus Template  Preview</h3>
              </div>
              <div>
                <Stack direction="row" spacing={2}>
                
                  <Button
                    id="basic-button"
                    aria-controls={openAction ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAction ? "true" : undefined}
                    onClick={handleClickAction}
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Actions
                  </Button>
                  <Button
                    id="basic-button"
                    onClick={handleCloseTemplate}
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Exit
                  </Button>
                </Stack>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorAction}
                  open={openAction}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleActionClose}>Edit Template</MenuItem>
                  <MenuItem onClick={handleActionClose}>Delete Template</MenuItem>
                  
                </Menu>
              </div>
            </div>
            <Grid container>
              <Grid xs={3}>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    SOP for Period :
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: 200, marginLeft: "3%" }}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    O&M Code{" "}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: 200, marginLeft: "3%" }}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid xs={3}>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Date of Maintenance{" "}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: 200, marginLeft: "3%" }}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>

            <div>
              <Table style={{ marginTop: "3%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sequence Order</TableCell>
                    <TableCell align="center">Work Description</TableCell>
                    <TableCell align="center">Field</TableCell>
                    {/* <TableCell align="center">
                      <div> Previous & After Image / Count</div>
                    </TableCell> */}
                    <TableCell align="center">Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TableTemplateData?.map((data) => (
                    <TableRow>
                      <TableCell align="center">{data.SequenceOrder}</TableCell>
                      <TableCell align="center">
                        {data.WorkDescription}
                      </TableCell>
                      <TableCell align="center">{data.Field}</TableCell>
                      {/* <TableCell align="center">{data.PreviousAfterImageCount}</TableCell> */}
                      <TableCell align="center">{data.Remarks}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SOP;
