import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

//------------- TableValues--------------//
export const fetchUserTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USER_DETAILS}`
    );
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchUserTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_USER_BYCOMPANY
          : process.env.REACT_APP_USER_BYID
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

//------------- Table Edit -----------------//

export const fetchTableEdit = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TABLE_EDIT}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*-------------------------------------------------- New User Add ----------------------------------*/

export const postUserSave = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USER_TABLE_SAVE}`,
      obj
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

export const fetchTableEditList = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TABLE_EDIT}/${data}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const FetchEditValue = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TABLE}/findByUserId/${id}`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const updateEditCall = async (data, id) => {
  //  console.log(data);
  console.log(data, id);
  let response = await axios.put(
    `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TABLE}/${id}`,
    data
  );
  // console.log(response);
  return response;
};

export const Getdropdowndata = async () => {
  // console.log(datas,'data');
  try {
    //   const response = await axios.get(`${process.env.REACT_APP_CUSTOMER_EDIT}/${data}`);
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_dropdown_companylist}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/*-------------------------User Role Table Api -----------------------------------*/

//-------------User Role TableValues--------------//
export const fetchUserRoleTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USERROLE_TABLE}`
    );
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchUserRoleTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_USERROLE_BYCOMPANY
          : process.env.REACT_APP_USERROLE_BYID
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*------------------------ New User Role Add --------------------------*/

export const postUserRoleSave = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USERROLE_SAVE}`,
      obj
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

/*-------------------------- User Role Update (Edit)----------------------*/
export const updateUserRoleEdit = async (data, id) => {
  //  console.log(data);
  console.log(data, id);
  let response = await axios.put(
    `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USERROLE_UPDATE}/${id}`,
    data
  );
  // console.log(response);
  return response;
};

export const GetRoledropdowndata = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_USER_ROLE_LIST_BYCOMPANY
          : process.env.REACT_APP_USER_ROLE_LIST
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GetCustomerdropdowndata = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_USERCONFIG_CUSTOMERLIST
      }/${sessionStorage.getItem('companyId')}`
    );
    console.log(response.data, 'API Response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
