import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography, Autocomplete, CircularProgress, Modal } from "@mui/material";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import './Signup.css';
import Eira from '../../images/headerLogo.svg';
import {
  PostSignup,
  FetchUserType,
  FetchSubscriptionType,
} from '../../Api/SignupApi.js';
import errorMsg from '../util/errorMessage.json';
import { Alert, Paper, Snackbar, Stack } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { signUpField } from '../util/TextField.js';
import { useNavigate } from 'react-router-dom';
import TermsAndCondition from './TermsandConditions.js';
import CustomSnackbar from '../util/components/CustomSnackbar.jsx';

export function Copyright(props) {
  return (
    <Typography variant="body2" color="black" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" target="_blank" href="https://inspire-ce.com">
        Inspire-ce.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  useEffect(() => {
    userType();
    fetchSubscription();
    let data = signUpField();
    setTextField(data);
  }, []);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [terms, setTerms] = useState(false);
  const [updateMail, setUpdateMail] = useState('');
  // const [isValidContact, setIsValidContact] = useState(true);
  const [lock, setLock] = useState(false);
  let initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [variantText, setVariantText] = useState('info');
  const [defaultValue, setDefaultValue] = useState(null);
  const [checked, setChecked] = useState(false);
  const [toBackEnd, setToBackend] = useState(false);
  const [defaultSubscriptionValue, setDefaultSubscriptionValue] =
    useState(null);

  const [subscription, setSupscription] = useState([]);
  const [textField, setTextField] = useState([]);
  const [userTypeValue, setUserType] = useState([]);
  const [values, setValues] = useState({
    firstName: null,
    lastName: null,
    emailId: null,
    mobileNo: null,
    customerName: null,
    userTypeId: 2,
    subscriptionId: 1,
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleTermsClose = () => {
    setTerms(false);
  }
  const handleAccept = () => {
    setChecked(true);
    setTerms(false);
  }
  useEffect(() => {
    if (userTypeValue.length > 0) {
      setDefaultValue(userTypeValue[0]?.userTypeName);
    } else if (subscription?.length > 0) {
      setDefaultSubscriptionValue(subscription[0]?.subscriptionName);
    }
  }, [userTypeValue, subscription]);

  // const navigate = useNavigate();
  const fetchSubscription = async () => {
    let responseData = await FetchSubscriptionType();
    console.log(responseData.data[0].subscriptionName, 'fetched data');
    setSupscription(responseData?.data);
    setDefaultSubscriptionValue(responseData?.data[0].subscriptionName);
  };

  // const handleContactChange = (event) => {
  //   const newContact = event.target.value;
  //   setValues((prevValue) => ({
  //     ...prevValue,
  //     mobileNo: newContact,
  //   }));
  //   setIsValidContact(/^\d{10}$/.test(newContact));
  // };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setValues((prevValue) => ({
      ...prevValue,
      emailId: newEmail,
    }));
    setEmailError(!isValidEmail(newEmail));
  };
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  // function extractQuotesText(text) {
  //   const output = text.match(/"(.*?)"/);
  //   return output ? output[1] : null;
  // }
  const extractQuotesText = (text) => text.match(/"(.*?)"/)[1];
  const keysToSkip = ['subscriptionId', 'userTypeId'];
  const handleSubmit = async () => {
    const isEmpty = Object.entries(values).filter(([key]) => !keysToSkip.includes(key)).some(([, value]) => value === null);
    console.log(isEmpty, 'isEmpty');
    if (isEmpty === false) {
      let obj = {
        firstName: values.firstName,
        lastName: values.lastName,
        emailId: values.emailId,
        mobileNo: values.mobileNo,
        customerName: values.customerName,
        userTypeId:
          values.userTypeId === null
            ? userTypeValue[0]?.userTypeId
            : values.userTypeId,
        subscriptionId:
          values.subscriptionId === null
            ? subscription[0]?.subscriptionId
            : values.subscriptionId,
      };
      console.log(obj, 'values');
      if (!checked) {
        // setOpen(true);
        // setVariantText('error');
        // setTextValue('Please Accept the Terms and Condition!');
        setSnack(errorMsg.terms)
      } else {
        setToBackend(true);
        setLock(true);
        let responsedata = await PostSignup(obj);
        console.log(responsedata, 'responseData');
        // console.log(extractQuotesText(responsedata.data.statusDescription), 'responseData');
        if (responsedata.status === 200) {
          // setOpen(true);
          setSnack(errorMsg.signup);
          setLock(false);
          setToBackend(false);
          // setVariantText('success');
          // setTextValue('Sign Up Successfully');
          navigate('/', { state: { firstLogin: true } });
          // setTimeout(() => {
          // window.location.href = '/eirasaas/';
          // window.location.reload();
          // }, 1000);
        } else if (responsedata.status === 500) {
          // let text = extractQuotesText(responsedata.data.statusDescription);
          // setOpen(true);
          // setVariantText('error');
          setSnack({ ...snack, open: true, severity: 'error', message: responsedata.data.statusDescription });
          // setTextValue(responsedata.data.statusDescription);
          setLock(false);
          setToBackend(false);
        } else {
          // setOpen(true);
          // setVariantText('error');
          // setTextValue('Sign Up Failed! Try again');
          setSnack({ ...snack, open: true, severity: 'error', message: 'Sign Up Failed! Try again' });
          setLock(false);
          setToBackend(false);
        }
      }
    } else {
      setSnack(errorMsg.required);
    }
  };

  const userType = async () => {
    let responseData = await FetchUserType();
    // console.log(responseData.data, 'responseData');
    setDefaultValue(responseData.data[0].userTypeName);
    setUserType(responseData.data);
  };
  const handleChangeEnduser = (name, value) => {
    console.log(name, value, 'value');
    if (name === 'userType') {
      let valueId = userTypeValue?.filter(
        (data) => data.userTypeName === value
      );
      setValues({ ...values, userTypeId: valueId[0]?.userTypeId });
      setDefaultValue(valueId[0]?.userTypeName);
    } else {
      let valueId = subscription?.filter(
        (data) => data.subscriptionName === value
      );
      setValues({ ...values, subscriptionId: valueId[0]?.subscriptionId });
      setDefaultSubscriptionValue(valueId[0]?.subscriptionName);
    }
  };
  const getOptions = (propName) => {
    let option;
    switch (propName) {
      case 'userType':
        option = userTypeValue?.map((data) => data?.userTypeName);
        break;
      case 'SubscriptionType':
        option = subscription?.map((data) => data?.subscriptionName);
        break;
      default:
        break;
    }
    return option;
  };
  return (
    // <ThemeProvider theme={defaultTheme}>
    <>
      <div className="background-container"></div>
      <div className="overlay"></div>
      <div>
        {/* <CssBaseline /> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '95vh',
          }}
        >
          <Paper
            elevation={5}
            style={{
              padding: '10px 40px 10px 40px',
              boxSizing: 'border-box',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                // marginLeft: '5vw',
                marginBottom: '-2%',
                marginTop: '2%',
              }}
            >
              <img
                src={Eira}
                alt="Eira"
                // className="image"
                style={{ width: '120px', height: '60px' }}
              />
            </div>
            <Typography
              style={{
                margin: '30px 0 0 0',
                color: '#6c756f',
                textAlign: 'left',
                padding: '0',
                letterSpacing: '0.5px',
                fontWeight: '550',
                fontSize: '16px',
                marginBottom: '5%',
                marginLeft: '-2%',
              }}
            >
              Start your journey with us. Please Register!
            </Typography>
            {textField?.length > 0
              ? textField.map((data, index) => (
                <Grid container>
                  <Grid item lg={12}>
                    {data?.type !== 'dropdown' ? (
                      <TextField
                        id={`outlined-basic-${index}`}
                        placeholder={data.label}
                        size="small"
                        name={data.name}
                        inputProps={{ maxLength: data.length }}
                        type={data.type}
                        value={values[data.name]}

                        onChange={(e) => {
                          if (
                            data.name === 'mobileNo' &&
                            e.target.value.length <= 10
                          ) {
                            setValues({
                              ...values,
                              [data.name]: e.target.value,
                            });
                          } else if (data.name !== 'mobileNo') {
                            data.name === 'emailId'
                              ? handleEmailChange(e)
                              : setValues({
                                ...values,
                                [data.name]: e.target.value,
                              });
                          }
                        }}
                        style={{
                          marginTop: '2%',
                          marginBottom: '2%',
                          width: '21rem',
                        }}
                        InputProps={
                          data.name === 'mobileNo' ? { maxLength: 10 } : {}
                        }
                        error={data.name === 'emailId' ? emailError : null}
                        helperText={
                          data.name === 'emailId' && emailError ? (
                            <span style={{ color: '#ff0f0f' }}>
                              Please enter a valid email address
                            </span>
                          ) : null
                        }
                      />
                    ) : (
                      <Autocomplete
                        style={{
                          marginTop: '2%',
                          marginBottom: '2%',
                          width: '21rem',
                        }}
                        disableClearable
                        disablePortal
                        id="combo-box-demo"
                        options={getOptions(data.name)}
                        sx={{ width: '21rem' }}
                        value={
                          data?.name === 'userType'
                            ? defaultValue
                            : data?.name === 'SubscriptionType'
                              ? defaultSubscriptionValue
                              : null
                        }
                        onChange={(event, value) =>
                          handleChangeEnduser(data?.name, value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder={
                              data.name === 'userType'
                                ? 'user Type'
                                : 'Subscription Type'
                            }
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
              ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start"
                // marginRight: "2%",
              }}
            >
              <Link
                variant="body2"
                className="link-termsandconditions"
                style={{ cursor: "pointer", marginRight: "10px", userSelect: 'none' }}
              >
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  color="primary"
                />
                <span onClick={() => setTerms(true)}>
                  Terms and Conditions
                </span>
              </Link>
            </div>
            <div>

            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '2%',
              }}
            >
              <Link
                href="/eirasaas/"
                variant="body2"
                className="link-signup"
                style={{ cursor: 'pointer' }}
              >
                Already have an account? Sign in
              </Link>
            </div>

            <Button
              startIcon={lock && <CircularProgress size={20} color="inherit" />}
              disabled={lock}
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{
                fontSize: '26px',
                width: '100%',
                backgroundColor: '#000440',
                margin: '20px 0  0',
                mt: 3,
                height: '45px',
                letterSpacing: '0.5px',
                textTransform: 'capitalize',
                borderRadius: '20px 20px 20px 20px',
                ':hover': {
                  backgroundColor: '#FFFFFF',
                  color: '#000440',
                },
              }}
            >
              Sign Up
            </Button>
            <Copyright sx={{ mt: 2, marginBottom: '2%' }} />
          </Paper>
        </Box>
      </div>
      <div>
        {/* <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity={variantText}
            sx={{ width: '100%' }}
          >
            {textValue}
          </Alert>
        </Snackbar> */}
        <CustomSnackbar
          open={snack.open}
          onClose={handleSnackClose}
          autoHideDuration={5000}
          severity={snack.severity}
          message={snack.message}
        />
      </div>
      <div>
        <Modal
          open={terms}
          onClose={() => handleTermsClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <TermsAndCondition handleAccept={handleAccept}
            handleClose={() => handleTermsClose()} />
        </Modal>
      </div>
    </>
    // </ThemeProvider>
  );
}