export async function groupParameters(data) {
  const result = [];

  // Sort the data array based on the sequence property
  data.sort((a, b) => a.sequence - b.sequence);
  console.log(data, 'sequence');
  data.forEach((item) => {
    // Find existing group based on siteId, dataLoggerId, and filePath
    let group = result.find(
      (group) =>
        group.siteId === item.siteId &&
        group.dataLoggerId === item.dataLoggerId &&
        group.filePath === item.filePath
    );

    // If no group found, create a new one
    if (!group) {
      group = {
        siteId: item.siteId,
        siteName: item.siteName,
        filePath: item.filePath,
        dataLoggerName: item.dataLoggerName,
        dataLoggerId: item.dataLoggerId,
        parameterName: [],
        stdParameterName: [],
        coEfficient: [],
      };
      result.push(group);
    }

    // Add the parameter details to the group
    group.parameterName.push(item.parameterName);
    group.stdParameterName.push(
      `${item.stdParameterName}${item.uom ? `, (${item.uom})` : ''}`
    );
    group.coEfficient.push(item.coEfficient.toString());
  });

  return await result;
}
