import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Box from '@mui/material/Box';
import './Errorconfig.css';
import FadeLoader from 'react-spinners/FadeLoader';
import * as yup from 'yup';
import { downloadExcelTemplate } from '../../Template/ExcelTemplates/ExcelTemplate';

import {
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Modal,
  Divider,
  Backdrop,
  Fade,
  TableContainer,
  Card,
  Paper,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tooltip,
  CardHeader,
} from '@mui/material';
import {
  fetchStandardTable,
  postdstandardparameter,
  putStandardEdit,
  postdstandardparametervalue,
} from '../../Api/StandardApi';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { standardParameterSchema } from '../util/ValidateSchema';

import Download from '../../Template/Excel/Download';
import TableTemplate from '../../Template/TableTemplate';
import { StandardParameter } from '../util/TextField';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { Statusdropdown } from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import errorMsg from '../util/errorMessage.json';
import { GetCustomerdropdowndata } from '../../Api/UserConfigurationAPI';
import { CheckForLight } from '../util/common/CommanFunction';
export default function Standardparameter({ standardParameter }) {
  const [open, setOpen] = useState(false);
  const [edit, setedit] = useState(false);
  const [stdedit, setstdedit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stdTable, setstdTable] = useState([]);
  const [excelOpen, setExcelOpen] = React.useState(false);
  const [SectorId, setSectorId] = useState([]);
  const [text, setTextField] = useState([]);
  const [errorIndex, setErrorIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [indexvalue, setindexvalue] = useState('');
  console.log(errorMessage);
  console.log(indexvalue);
  const [statusDrop, setStatusDrop] = useState([]);
  const [siteTab, setSiteTab] = React.useState('');
  const isOEM = sessionStorage.getItem('userTypeId') == 1;
  const initalState = {
    createdDate: '',
    lastUpdatedDate: '',
    createdBy: '',
    description: '',
    status: '',
    stdParameterName: '',
    lastUpdatedBy: '',
    companyName: '',
    stdId: '',
    stdUom: '',
    customerId: !isOEM ? sessionStorage.getItem('customerId') : '',
    customerName: !isOEM ? sessionStorage.getItem('customerName') : '',
  };
  const [stdField, setstdField] = useState(initalState);
  const [toBackend, setToBackend] = useState(false);
  const [customerId, setCustomerId] = useState([]);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const handleClose = () => {
    setstdField(initalState);
    setOpen(false);
    setModalvalue(true);
    setExcelOpen(false);
    setSelectedFileData(null);
  };
  // ========Table Data==== //
  useEffect(() => {
    fetchData();
    fetchStatus();
    getCustomerList();
    // getcompanylist();
    // getsectorlist();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchStandardTable();
      // const reverse = [...data].reverse();
      if (Array.isArray(data)) {
        setstdTable(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setstdTable([data]);
      }
      setstdTable(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getCustomerList = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      console.log(data, 'GetCustomerdropdowndata');
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === 'object' && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  useEffect(() => {
    let data = StandardParameter();
    // console.log(data, "data");
    setTextField(data);
  }, []);
  // ==============Edit api ===========
  const handleEdit = async (stdParameterId) => {
    let filteredstdParameterId = Object.keys(initalState).reduce((acc, key) => {
      if (stdParameterId.hasOwnProperty(key)) {
        acc[key] = stdParameterId[key];
      }
      return acc;
    }, {});
    setedit(true);
    setOpen(true);
    console.log(filteredstdParameterId, 'filtered');
    setstdField(filteredstdParameterId);
    setstdedit(stdParameterId);
  };
  console.log(stdedit);

  const handleTxtChange = (event) => {
    setstdField({ ...stdField, [event.target.name]: event.target.value });
    console.log(event.target.length);
  };

  const handleDropdownChange = (event, value, name) => {
    setstdField({ ...stdField, [name]: value });
  };

  // ============================Save Api====================

  const handleSave = async (values) => {
    const customerIdFilter = isOEM
      ? customerId?.find((data) => data.customerName === values.customerName)
        ?.customerId
      : sessionStorage.getItem('customerId');
    console.log(customerIdFilter);
    // const customer =sessionStorage.getItem('customerId')

    const data = [
      {
        createdBy: Number(sessionStorage.getItem('id')),
        description: values?.description,
        status: 1,
        stdParameterName: values?.stdParameterName,
        stdUom: values?.stdUom,
        customerId: customerIdFilter,
      },
    ];

    console.log(data, 'save');
    try {
      setToBackend(true);

      const responseData = await postdstandardparameter(data);
      fetchData();
      setSnack({
        ...snack,
        open: true,
        severity: 'success',
        message: responseData.statusDescription,
      });
      handleClose();
      setOpen(false);
      setToBackend(false);
      console.log(responseData, 'responseData');

      return 1;
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error('Error:', e.responseData.status);
      setToBackend(false);
      return 0;
    }
  };

  const handleCreate = () => {
    setedit(false);
    setOpen(true);
    setstdedit('');
  };
  const handleUpdate = async (values) => {
    console.log(values, 'update');
    let id = stdedit?.stdId;
    console.log(id, 'id');
    console.log(values, 'values');
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    let obj = {
      lastUpdatedBy: Number(sessionStorage.getItem('id')),
      description: values?.description,
      customerId: values.customerId,
      status: edit
        ? typeof values.status === 'string'
          ? status[0].value
          : values.status
        : status[0].value,
      stdParameterName: values?.stdParameterName,
      stdUom: values?.stdUom,
    };
    console.log(obj, 'save');
    try {
      setToBackend(true);
      let responsedata = await putStandardEdit(obj, id);
      // fetchData();
      console.log(responsedata, 'responsedata');
      const updatedTable = replaceObjectById(
        stdTable,
        'stdId',
        id,
        responsedata
      );
      setSnack(errorMsg.success);
      handleClose();
      setOpen(false);
      setToBackend(false);
      return 1;
    } catch (err) {
      setSnack(errorMsg.failure);
      console.error('Error:', err.response.status);
      console.log(err);
      setToBackend(false);
      return 0;
    }

    // console.log(obj);
  };

  // ============dropdown============
  // const getcompanylist = async () => {
  //   try {
  //     let data = await Getdropdown();
  //     setcustomerId(data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // const getsectorlist = async () => {
  //   try {
  //     let data = await Getdropdownvalue();
  //     // console.log(data,"sectorData");
  //     if (Array.isArray(data)) {
  //       setSectorId(data);
  //     } else if (typeof data === 'object' && data !== null) {
  //       setSectorId([data]);
  //     }
  //   } catch (e) {
  //     console.error(e, 'errrrrror');
  //   }
  // };

  const headCells = [
    {
      label: 'Customer Name',
      id: 'customerName',
      view: false,
    },
    {
      label: 'Parameter Name',
      id: 'stdParameterName',
      view: true,
      default: true,
    },
    {
      label: 'UOM',
      id: 'stdUom',
      view: true,
    },
    {
      label: 'Description',
      id: 'description',
      view: true,
    },
    {
      label: 'Created On',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'Updated On',
      id: 'lastUpdatedDate',
      view: false,
    },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];
  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Standard parameter');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  // -------- Excel Bulk Upload Function --------

  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const styleTable = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
  };
  const requiredHeaders = ['Parameter Name', 'UOM', 'Description'];
  if (isOEM) {
    requiredHeaders.push('Customer Name');
  }
  const handleFileUpload = (e) => {
    setOpen(true);
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);

      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const headers = [];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeaders
          .map((header) => header.toLowerCase().replace(/ /g, ''))
          .every((header) =>
            headers
              .map((head) => head.toLowerCase().replace(/ /g, ''))
              .includes(header)
          );
        const updatedJsonData = jsonData.map((row) => {
          requiredHeaders.forEach((header) => {
            if (!row.hasOwnProperty(header)) {
              row[header] = ''; // Add missing header with empty string value
            }
          });
          return row;
        });
        console.log(updatedJsonData, 'updatedJsonData');
        if (!headersPresented || jsonData.length === 0) {
          setSnack({
            ...snack,
            open: true,
            severity: 'error',
            message: !headersPresented
              ? 'Enter the correct format'
              : jsonData.length === 0
                ? "Excel couldn't be empty"
                : null,
          });
          setExcelOpen(false);
          handleClose();
        } else {
          setExcelOpen(true);
          setSelectedFileData(updatedJsonData);
        }

        const errors = [];
        jsonData.forEach((row, rowIndex) => {
          let header = Object.keys(row);
          let cellValue = Object.values(row);
          cellValue.forEach((value, cellIndex) => {
            let columnName = header[cellIndex];
            if (value === undefined || value === null || value === '') {
              errors.push({ rowIndex, columnName });
            }
          });
        });
        setErrorIndex(errors);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleExcelUpload = async () => {
    const file = selectedFile;

    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const errors = [];
        selectedFileData.forEach((row, rowIndex) => {
          const ParameterName = row[requiredHeaders[0]].length;
          const UOM = row[requiredHeaders[1]]?.toString().length;
          const Description = row[requiredHeaders[2]]?.toString().length;
          const validationForm = [
            {
              condition: ParameterName && ParameterName > 100,
              errorMessage: 'Parameter Name cannot exceed 100 characters.',
            },
            {
              condition: UOM && UOM > 100,
              errorMessage: 'UOM cannot exceed 100 characters.',
            },
            {
              condition: Description && Description > 50,
              errorMessage: 'Description cannot exceed 50 characters.',
            },
          ];
          validationForm.forEach((validation) => {
            if (validation.condition) {
              errors.push(validation.errorMessage);
              let error = [
                {
                  rowIndex,
                  columnName: validation.errorMessage,
                  lengthValidation: true,
                },
              ];
              setErrorIndex([...errorIndex, ...error]);
            }
          });
        });

        const processedData = jsonData.map((item, rowIndex) => {
          const lowercaseItem = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              lowercaseItem[key.toLowerCase().replace(/\s/g, '')] =
                typeof item[key] === 'string' ? item[key].trim() : item[key];
            }
          }
          let customername = isOEM
            ? lowercaseItem.customername
            : sessionStorage.getItem('customerName');
          const baseData = {
            stdParameterName: lowercaseItem.parametername,
            stdUom: lowercaseItem.uom,
            createdBy: Number(sessionStorage.getItem('id')),
            description: lowercaseItem.description,
            status: 1,
            customerName: customername,
          };
          return baseData;
        });

        console.log(processedData, 'processedData');

        // Process data only if there are no errors
        try {
          if (errorIndex.length > 0) {
            setSnack({
              ...snack,
              open: true,
              severity: 'error',
              message: `${errorIndex[0].columnName} is missing`,
            });
          } else if (errors.length > 0) {
            setSnack({
              ...snack,
              open: true,
              severity: 'error',
              message: `${errors[0]}`,
            });
          } else {
            const responseData = await postdstandardparametervalue(
              processedData
            );
            console.log(responseData);
            if (responseData.status === 200) {
              setSnack({
                open: true,
                severity: 'success',
                message: responseData.data.statusDescription,
              });
            }
            fetchData();
            handleClose();
            setOpen(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            const errorResponse = error.response.data;
            const indexValues = Object.keys(errorResponse).map(
              (key) => errorResponse[key].index
            );
            const indexValuess = Object.keys(errorResponse).map(
              (key) => errorResponse[key].error
            );
            setErrorMessage(indexValuess);
            setindexvalue(indexValues);
            if (indexValuess.length == 1) {
              setSnack({
                ...snack,
                open: true,
                severity: 'error',
                message: indexValuess,
              });
            } else {
              setSnack(errorMsg.failure);
            }
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const tableValues = stdTable.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = stdTable?.length;

  const Active = stdTable?.filter((site) => site?.status == 1).length;

  const Inactive = stdTable?.filter((site) => site?.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Parameter', value: 'all', badgeCount: Total },
    { name: 'Active Parameter', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Parameter',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Standard Parameter', path: 'Standardparameter' },
  ];
  const customerAddedSchema = yup.object().shape({
    customerName: yup.string().required('Customer Name is Required'),
  });
  console.log(errorIndex, 'errorIndexy');

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div className="standard-whole">
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <TableTemplate
              PageName={'Standard Parameter'}
              addButton={'Parameter'}
              uploadButton={true}
              SearchLabel={'Search Parameter Here... '}
              header={headCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              handleChange={handleChangeTab}
              rawData={stdTable}
              handleDownloadTemplate={() =>
                downloadExcelTemplate(
                  'Standard Parameter',
                  isOEM ? true : false
                )
              }
              handleUploadProp={(e) => handleFileUpload(e)}
              userRole={standardParameter[0]}
              handleAddPopupOpen={(val) => handleCreate(val)}
              handleEditPopupOpen={(val) => handleEdit(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No${siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''} Standard parameters added yet`}
            />
          </div>
          {modalvalue ? (
            <div>
              <Formik
                key={edit ? 'edit' : 'create'}
                enableReinitialize={true}
                initialValues={stdField}
                validationSchema={
                  isOEM
                    ? standardParameterSchema.concat(customerAddedSchema)
                    : standardParameterSchema
                }
                onSubmit={async (values, { resetForm }) => {
                  let submitValue = values;
                  if (edit) {
                    console.log(values);
                    const result = await handleUpdate(submitValue);
                    if (result === 1) {
                      resetForm();
                    }
                  } else {
                    const result = await handleSave(submitValue);
                    if (result === 1) {
                      resetForm();
                    }
                  }
                }}
              >
                {({ values, handleChange, handleSubmit, handleReset }) => (
                  <Form>
                    <Modal
                      open={open}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      sx={{
                        marginTop: '15vh',
                      }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Box
                        className={'styleModalSmall'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    s
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={
                              edit
                                ? 'Update Standard Parameter'
                                : 'Add Standard Parameter'
                            }
                          />
                          <Divider
                            style={{ borderColor: '#888', marginBottom: '-2%' }}
                          />

                          <div className="modelGrid">
                            {/* <Box sx={{ flexGrow: 3, marginBottom: '20px', marginLeft: '20px', paddingBottom: '10px' }}> */}
                            <Card
                              sx={{
                                overflowY: 'visible',
                                marginLeft: '-1vw',
                                paddingLeft: '1vw',
                                // paddingLeft: '-1vw',
                                marginTop: '-2%',
                                paddingBottom: '1%',
                                scrollBehavior: 'smooth',
                                scrollbarGutter: 'stable',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                  width: '0.4em',
                                },
                                '&::-webkit-scrollbar-track': {
                                  background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: '#888',
                                  borderRadius: '20px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                  background: '#555',
                                },
                              }}
                              elevation={0}
                            >
                              <CardContent sx={{}}>
                                <Grid container spacing={2} columns={24}>
                                  {text?.length > 0
                                    ? text.map(
                                      (data, index) =>
                                        data.view && (
                                          <Grid item xs={8}>
                                            <div key={index}>
                                              <Typography className="modal-typo">
                                                {data.label}
                                              </Typography>
                                              {data.type === 'textField' ? (
                                                <>
                                                  <Field
                                                    as={TextField}
                                                    disabled={toBackend}
                                                    id={`outlined-basic-${index}`}
                                                    size="small"
                                                    variant="outlined"
                                                    name={data.name}
                                                    inputProps={{
                                                      maxLength: data.length,
                                                    }}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                      handleTxtChange(e);
                                                    }}
                                                    placeholder={
                                                      data.placeholder
                                                    }
                                                    defaultValue={
                                                      values[data.name]
                                                    }
                                                    sx={{ width: '14vw' }}
                                                  />
                                                  <ErrorMessage
                                                    name={data.name}
                                                    component="div"
                                                    className="errorStyle"
                                                  />
                                                </>
                                              ) : data.type === 'dropdown' ? (
                                                <>
                                                  <Tooltip
                                                    title={
                                                      !edit &&
                                                        data.name === 'status'
                                                        ? 'Active'
                                                        : data.name ===
                                                          'status'
                                                          ? typeof values[
                                                            data.name
                                                          ] === 'number'
                                                            ? values.status == 1
                                                              ? 'Active'
                                                              : 'Inactive'
                                                            : values[data.name]
                                                          : values[data.name]
                                                    }
                                                  >
                                                    <Field
                                                      name={data.name}
                                                      render={({
                                                        field,
                                                        form,
                                                      }) => (
                                                        <Tooltip
                                                          title={
                                                            !edit &&
                                                              data.name ===
                                                              'status'
                                                              ? 'Active'
                                                              : data.name ===
                                                                'status'
                                                                ? typeof values[
                                                                  data.name
                                                                ] === 'number'
                                                                  ? values.status ==
                                                                    1
                                                                    ? 'Active'
                                                                    : 'Inactive'
                                                                  : values[
                                                                  data.name
                                                                  ]
                                                                : values[
                                                                data.name
                                                                ]
                                                          }
                                                        >
                                                          <Autocomplete
                                                            disableClearable
                                                            options={
                                                              data.name ===
                                                                'customerName'
                                                                ? customerId.map(
                                                                  (
                                                                    option
                                                                  ) => {
                                                                    return option.customerName;
                                                                  }
                                                                )
                                                                : statusDrop.map(
                                                                  (
                                                                    option
                                                                  ) =>
                                                                    option.label
                                                                )
                                                            }
                                                            disabled={
                                                              toBackend ||
                                                              (!edit &&
                                                                data.name ===
                                                                'status') ||
                                                              (edit &&
                                                                data.name ===
                                                                'customerName')
                                                            }
                                                            disablePortal
                                                            size="small"
                                                            id={`combo-box-demo-${index}`}
                                                            onChange={(
                                                              event,
                                                              value
                                                            ) =>
                                                              handleDropdownChange(
                                                                event,
                                                                value,
                                                                data.name
                                                              )
                                                            }
                                                            value={
                                                              !edit &&
                                                                data.name ===
                                                                'status'
                                                                ? 'Active'
                                                                : data.name ===
                                                                  'status'
                                                                  ? typeof values[
                                                                    data.name
                                                                  ] ===
                                                                    'number'
                                                                    ? values.status ==
                                                                      1
                                                                      ? 'Active'
                                                                      : 'Inactive'
                                                                    : values[
                                                                    data
                                                                      .name
                                                                    ]
                                                                  : values[
                                                                  data.name
                                                                  ]
                                                            }
                                                            sx={{
                                                              width: '14vw',
                                                            }}
                                                            ListboxProps={{
                                                              style: {
                                                                maxHeight:
                                                                  '200px',
                                                              },
                                                            }}
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                placeholder={
                                                                  data.placeholder ||
                                                                  'Enter value'
                                                                } //
                                                                {...params}
                                                              />
                                                            )}
                                                          />
                                                        </Tooltip>
                                                      )}
                                                    />
                                                  </Tooltip>
                                                  <ErrorMessage
                                                    name={data.name}
                                                    component="div"
                                                    className="errorStyle"
                                                  />
                                                </>
                                              ) : null}
                                            </div>
                                          </Grid>
                                        )
                                    )
                                    : null}
                                </Grid>
                              </CardContent>
                            </Card>
                            {/* </Box> */}
                          </div>
                        </Card>
                      </Box>
                    </Modal>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={excelOpen}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={excelOpen}>
                <Box className={'styleModalSmall'}
                  sx={{
                    border: 'none',
                    outline: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}>
                  <Card
                    sx={{
                      borderRadius: '20px',
                      height: 'fit-content',
                      width: 'fit-content',
                      marginLeft: '-2.5%',
                    }}
                  >
                    <CardHeader
                      sx={{
                        padding: '20px 20px 20px 20px',
                        background: CheckForLight()
                          ? 'rgb(246, 248, 252)'
                          : '#4f4f4f',
                      }}
                      action={
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            disabled={errorIndex?.length > 0 || indexvalue?.length > 0}
                            style={{
                              borderRadius: '25px',
                              textTransform: 'capitalize',
                            }}
                            startIcon={<SaveIcon />}
                            onClick={() => handleExcelUpload()}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              borderRadius: '25px',
                              textTransform: 'capitalize',
                            }}
                            onClick={handleClose}
                            startIcon={<CancelIcon />}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      }
                      title={
                        'Add New Parameters'
                      }
                    />
                    <Divider style={{ marginTop: '0%', borderColor: '#888' }} />
                    <CardContent>
                      <Card
                        sx={{
                          overflowY: 'auto',
                          maxHeight: '40vh',
                          marginLeft: '0vw',
                          // padding: '-1vw',
                          marginTop: '0%',
                          marginBottom: '-1%',
                          scrollBehavior: 'smooth',
                          scrollbarGutter: 'stable',
                          scrollbarWidth: 'thin',
                          '&::-webkit-scrollbar': {
                            width: '0.4em',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '20px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                          },
                        }}
                        elevation={2}
                      >
                        <CardContent sx={{ padding: 0, paddingLeft: '10px' }}>
                          {selectedFileData && (
                            <div>
                              <Table
                                sx={{ minWidth: 650 }}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableHead style={{ borderBottom: 'none' }}>
                                  <TableRow>
                                    {requiredHeaders.map((header, index) => (
                                      <TableCell
                                        sx={{ pl: '35px', borderBottom: 'none' }}
                                        key={index}
                                      >
                                        {header}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedFileData.map((row, rowIndex) => (
                                    <Tooltip
                                      key={rowIndex}
                                      title={(() => {
                                        let errorMessage = '';
                                        errorIndex.forEach((error) => {
                                          if (
                                            !errorMessage &&
                                            error.rowIndex === rowIndex
                                          ) {
                                            errorMessage = error?.lengthValidation
                                              ? error?.columnName
                                              : `${error?.columnName} is Missing`;
                                          }
                                          if (
                                            !errorMessage &&
                                            indexvalue?.includes(rowIndex)
                                          ) {
                                            errorMessage =
                                              errorMessage[
                                              indexvalue.indexOf(rowIndex)
                                              ];
                                          }
                                        });
                                        return errorMessage;
                                      })()}
                                      placement="top"
                                    >
                                      <TableRow
                                        key={rowIndex}
                                        style={
                                          errorIndex.some(
                                            (error) => error.rowIndex === rowIndex
                                          ) || indexvalue?.includes(rowIndex)
                                            ? {
                                              border: '2px solid red',
                                              // borderBottom: '2px solid red',
                                              backgroundColor:
                                                '#ff070721',
                                            }
                                            : null
                                        }
                                      >
                                        {requiredHeaders.map((header, colIndex) => (
                                          <TableCell sx={{ pl: '5px' }}
                                            key={colIndex}
                                            style={{
                                              border: 'none',
                                              borderTop: 'none',
                                              textAlign: 'center',
                                            }}>
                                            {row[header]}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </Tooltip>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    </CardContent>
                  </Card>
                </Box>
              </Fade>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
}
