import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Autocomplete,
  TextField,
  Tooltip,
  Box,
  IconButton,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import Apexchart from '../../Components/AnalyticsDashboard/ApexexChart';
import '../Style.css';
import {
  fetchDailyGenerationEira,
  fetchSpecificYieldEira,
  SpecificYeildForDaily,
} from '../../../Api/DataAnalystApi';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  CheckForLight,
  roundedUp,
  transformDataForSpecificYield,
} from '../../util/common/CommanFunction';
import { FadeLoader } from 'react-spinners';
import CombinedChart from '../../Charts/CombainedChart';
import {
  fetchEquipDropdownBySite,
  fetchSiteDropdownByUser,
} from '../../../Api/CommonApi';

const Contact3 = ({ name }) => {
  const [site, setSite] = useState('');
  const [siteIdName, setSiteId] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [date, setDate] = useState('');
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const chartType = [
    { label: 'Daily Generation', value: 'daily_generation' },
    { label: 'Specific Yield', value: 'specific_yield' },
  ];

  useEffect(() => {
    getSiteCall();
  }, []);

  useEffect(() => {
    fetchApplyChange();
  }, [site]);

  useEffect(() => {
    if (siteIdName.length > 0) {
      setChartName('Daily Generation');
      setSelectedOption('Daily Generation');
      setSite(siteIdName[0].siteName);
      let date = new Date();
      let today = moment(date).format('YYYY-MM-DD');
      setDate(today);
      let meter = [];
      let filteredObjects = equipments?.filter((obj) =>
        ['Weather Station', 'Energy Meter'].includes(obj.equipmentCategory)
      );
      const equipmentIds = filteredObjects?.map((data) => {
        return data.equipmentId;
      });
      const dataFrequency = siteIdName[0].siteFrequency ?? 5;
      meter = equipmentIds;
      const dataModel = {
        fromDate: today,
        graphType: 'Daily Generation',
        siteId: siteIdName[0].siteId,
        toDate: today,
        range: 'daily',
        equipmentIds: meter,
        timeperiod: 'Today',
        intervalMins: dataFrequency,
        energyGenBasedOn: siteIdName[0].energyGenBasedOn,
        energyFlag: 0,
      };
      setCustomView(dataModel);
      const getEquip = async () => {
        const data = await fetchEquipDropdownBySite(siteIdName[0]?.siteId);
        setEquipments(data);
      };
      getEquip();
      fetchApplyChange();
    }
  }, [siteIdName]);

  const fetchApplyChange = async () => {
    setLoading(true);
    if (
      customView !== undefined &&
      customView !== null &&
      chartName === 'Daily Generation' &&
      !siteCall
    ) {
      let responseData = await fetchDailyGenerationEira(customView);

      setLoading(false);
      setTableData(responseData);
      if (customView?.range === 'daily') {
        let seriesData = [];
        const irradiationSeries = {
          name: 'irradiation',
          data: [],
        };
        const todayEnergySeries = {
          name: 'todayenergy',
          data: [],
        };
        responseData.forEach((dataPoint) => {
          const timeStamp = new Date(dataPoint.timeStamp).getTime();
          irradiationSeries.data.push([timeStamp, dataPoint.irradiation || 0]);
          todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy || 0]);
        });
        seriesData.push(irradiationSeries);
        seriesData.push(todayEnergySeries);
        setMultichartData(seriesData);
      }
    }
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ['Weather Station', 'Energy Meter'].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.date ? value.date : date,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.date ? value.date : date,
      range: 'daily',
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: 'Today',
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === 'Specific Yield' || name === 'Specific Yield - Heatmap') {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    if (name === 'Daily Generation') {
      const responseData = await fetchDailyGenerationEira(data);
      setLoading(false);
      setTableData(responseData);
      let seriesData = [];
      const irradiationSeries = {
        name: 'irradiation',
        data: [],
      };
      const todayEnergySeries = {
        name: 'todayenergy',
        data: [],
      };
      responseData.forEach((dataPoint) => {
        const timeStamp = new Date(dataPoint.timeStamp).getTime();
        irradiationSeries.data.push([timeStamp, dataPoint.irradiation || 0]);
        todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy || 0]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      setMultichartData(seriesData);
    }
    if (name === 'Specific Yield') {
      delete data.equipmentIds;
      let responseData = await fetchSpecificYieldEira(data);

      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        equipm.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // Transform the initialData array
        const transformedData = initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : 'Unknown',
          };
        });

        return transformedData;
      }
      const result = transformToChartData(responseData);
      setLoading(false);
      setTableData(result);
    }
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSite = (data, val) => {
    if (val === null) {
      setSite('');
    } else {
      setSiteCall(true);
      setSite(val);
      ApplyChange({ siteName: val });
    }
  };

  const handleChart = (data, event) => {
    setChartName(event);
    setSelectedOption(event);
    ApplyChange({ chartName: event });
  };

  const handleChangeDate = (data) => {
    let newDate;
    const currentDate = moment(date);

    if (data === 'left') {
      newDate = currentDate.subtract(1, 'days');
    } else if (data === 'right') {
      newDate = currentDate.add(1, 'days');
    } else {
      return; // Do nothing if data is not 'left' or 'right'
    }

    const today = moment().startOf('day');

    if (newDate.isAfter(today)) {
      // If the new date is greater than today, set the date to today
      newDate = today;
    }

    const formattedDateTime = newDate.format('YYYY-MM-DD');
    setDate(formattedDateTime);
    ApplyChange({ date: formattedDateTime });
  };

  const NodataAvailable =
    (tableData.length === 0 || multiChartData.length === 0) &&
    siteIdName.length === 0;

  const fallBack = (
    <Card
      sx={{
        width: '100%',
        height: '43vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '43vh',
        }}
      >
        <Typography
          style={{
            fontSize: '1.5rem',
            textTransform: 'capitalize',
            lineHeight: '14.52px !important',
            color: ' rgb(113, 118, 111)',
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );
  return NodataAvailable ? (
    <Card
      sx={{
        width: '100%',
        height: '43vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '43vh',
        }}
      >
        <Typography
          style={{
            fontSize: '1.5rem',
            textTransform: 'capitalize',
            lineHeight: '14.52px !important',
            color: ' rgb(113, 118, 111)',
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: '100%', height: '100%', paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent
        sx={{ width: '58vw', height: '100%', paddingBottom: '0 !important' }}
      >
        <Grid>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div>
              <Tooltip title={site}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  disableClearable
                  value={site}
                  options={siteIdName.map((option) => option.siteName)}
                  onChange={(data, event) => handleSite(data, event)}
                  sx={{ width: '15vw' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Site ..."
                      // sx={{
                      //   fieldset: {
                      //     border: 'none',
                      //     boxShadow: 'none',
                      //   },
                      // }}
                    />
                  )}
                  classes={{ option: 'autocomplete' }}
                />
              </Tooltip>
            </div>
            <div>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={chartType.map((option) => option.label)}
                onChange={(data, event) => handleChart(data, event)}
                sx={{ width: '17vw' }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Chart Type ..." />
                )}
                classes={{ option: 'autocomplete' }}
                disabled={!site}
                value={selectedOption}
              />
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? '1px solid #c4c4c4'
                    : '1px solid #494949',
                }}
              >
                <IconButton onClick={() => handleChangeDate('left')}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: 'pointer',
                    flex: '1',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? 'black' : 'white',
                    }}
                  >
                    {moment(date).format('DD/MM/YYYY')}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(date).isSame(moment().startOf('day'))}
                  onClick={() => handleChangeDate('right')}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: '100%',
              height: '43vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '43vh',
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 || multiChartData.length === 0 ? (
          fallBack
        ) : chartName === 'Daily Generation' ? (
          <Apexchart
            chartName={'Daily Generation'}
            tableData={tableData}
            multiChartData={multiChartData}
            key="1"
            name="chartData"
            widgetName={`${site}`}
            isDashboard={true}
          />
        ) : (
          <CombinedChart
            tableData={tableData}
            customView={customView}
            WidgetName={`${chartName}`}
            chartName={`${chartName}`}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default Contact3;
