import React, { useState } from 'react';
import { Paper, Box, Typography, Button, Stack, Alert, Divider, IconButton } from '@mui/material';
import eira from '../../images/headerLogo.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const TermsAndCondition = ({ handleAccept, handleClose }) => {
    const handleCancel = () => {
        handleClose();
    }

    const handleSubmit = () => {
        handleAccept();
    }

    return (
        <>
            <div>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '95vh',
                        //borderRadius: '25px',
                    }}
                >
                    <Paper
                        elevation={5}
                        style={{
                            boxSizing: 'border-box',
                            padding: '10px 10px 20px 0px',
                            width: '60%',
                            height: '48rem',
                            overflowY: "scroll"
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginBottom: '-2%',
                                    marginTop: '2%',
                                }}
                            >
                                <img src={eira} alt="eiralogo" style={{ width: '120px', marginLeft: 'auto', marginRight: 'auto' }} />
                                <IconButton onClick={handleCancel} style={{ backgroundColor: 'transparent' }}><CloseIcon /></IconButton>
                            </div>
                            <Divider style={{ borderColor: '#888', marginTop: '4%', marginLeft: '1%' }} />
                            <div style={{ paddingLeft: '3%', marginTop: '-2%' }}>
                                <Typography style={{ fontSize: "1.2rem", fontWeight: 'bold', padding: "10px", marginTop: "50px" }}>
                                    How does SaaS work?
                                </Typography>
                                <Typography style={{ padding: "20px" }}>
                                    Software is computer programs or apps that help us do different tasks on our computers or devices.      <br />
                                    It can be anything from simple text editor to complex video editing or accounting software.      <br />
                                    Software as a Service (SaaS)is a way of using software where the user doesn't have to      <br />
                                    download or install it on their devices to use it. Instead, we can directly access and      <br />
                                    use it over the internet using an internet browser like Google Chrome, Microsoft Edge, etc.     <br />
                                </Typography>
                                <Typography style={{ padding: "20px" }}>
                                    When a company offers a platform or software through SaaS, they give the business or people the right to use their      <br />
                                    software online without transferring any ownership rights. These permissions or arrangements are called licenses that     <br />
                                    outline the rules for using the software. The rules for this kind of licensing specify things like how many people can use     <br />
                                    the software, how long they can use it, and any limitations or restrictions. This is the way the software company protects their     <br />
                                    software and any unauthorized use or misuse.
                                </Typography>
                                <Typography style={{ padding: "20px" }}>
                                    The SaaS Agreement and Website Terms and Conditions are different documents. SaaS is about     <br />
                                    the rules and regulations pertaining to the use of software online without downloading it.     <br />
                                    It is only applicable when someone subscribes to the SaaS. On the other hand, Website Terms and Conditions are     <br />
                                    rules and regulations concerning the use of a website. Whenever a user visits a website, the Website Terms and     <br />
                                    Conditions will be applicable to such users.
                                </Typography>
                                <Typography style={{ fontSize: "1.2rem", fontWeight: 'bold', padding: "10px" }}>
                                    How to use this document?
                                </Typography>

                                <Typography style={{ padding: "20px" }}>
                                    You can use this Agreement by filling in the relevant details of your SaaS service. The Agreement helps     <br />
                                    in defining the rights, obligations, and limitations of both the service provider and the user/subscriber.     <br />
                                    It is essential to carefully review and understand the Agreement, making any necessary modifications to     <br />
                                    ensure it aligns with your specific business requirements.
                                </Typography>
                                <Typography style={{ fontSize: "1.2rem", fontWeight: 'bold', padding: "10px" }}>
                                    Major clauses covered under this Agreement are:
                                </Typography>

                                <Typography style={{ padding: "20px" }}>
                                    Service delivery: defines the scope and specifics of the SaaS service provided.     <br />
                                    Payment terms: defines the payment structure, payment methods, frequency and so on.     <br />
                                    Intellectual property: specifies the ownership and usage of intellectual property rights associated with the SaaS and end-user data.     <br />
                                    Data protection and privacy: details the measures taken to protect the subscriber/end-user data.     <br />
                                    Limitation of liability: defines the extent of liability for the service provider in case of any direct or indirect damage to the subscriber or end-user.     <br />
                                    Termination or suspension: outlines the conditions and procedures for terminating or suspending the SaaS services. This also outlines the refund eligibility of Subscribers.     <br />
                                    Dispute resolution: outlines the process for resolving disputes such as arbitration and litigation.     <br />
                                    To make the Agreement a valid document you can either take a printout and get it signed by both parties, but, since it is a SaaS product,      <br />
                                    it is not viable to get each agreement signed in physical copies. For this, you can take the customer consent online by implementing a robust solution,      <br />
                                    including a secure and reliable online consent mechanism, such as a checkbox or "I Agree" button, to capture customer consent. The consent data shall include the date, time,     <br />
                                    and relevant customer details associated with the consent. You need to make sure the mechanism used to take consent from the customer complies with the applicable      <br />
                                    laws of the jurisdiction where the consent has been taken.
                                </Typography>

                                <Typography style={{ fontSize: "1.2rem", fontWeight: 'bold', padding: "10px" }}>
                                    Applicable Laws
                                </Typography>

                                <Typography style={{ padding: "20px" }}>
                                    The general laws applicable to a SaaS Agreement in India are Information Technology Act, 2000, Indian Contract Act, 1872, Consumer Protection Act, 2019.
                                </Typography>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Stack spacing={2} direction="row">
                                    <Button
                                        variant="contained"
                                        style={{
                                            borderRadius: '25px',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        Accept
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            borderRadius: '25px',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </div>
                        </div>
                    </Paper>
                </Box>
            </div>
        </>
    );
};

export default TermsAndCondition;
