import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  CircularProgress,
  Card,
  CardHeader,
  Tooltip,
} from '@mui/material';
import '../../Common.css';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { CheckForLight } from '../util/common/CommanFunction';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { alermsview } from '../util/TextField';
// ========Modal================
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import TableTemplate from '../../Template/TableTemplate';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { FadeLoader } from 'react-spinners';
import { AlarmsSchema } from '../util/ValidateSchema';
/*------------api call-----------------*/
import {
  fetchSiteEquipment,
  fetchSiteAlerms,
  fetchSiteAlermsfilter,
} from '../../Api/SiteListApi';
import Download from '../../Template/Excel/Download';
import errorMsg from '../util/errorMessage.json';
import CustomSnackbar from '../util/components/CustomSnackbar';
import {
  getFormatISTDate,
  removeQuestionMark,
  roundedUp,
} from '../util/common/CommanFunction';
import { addDays } from 'date-fns';
import { decryptPaddedQuery } from '../util/security/Cipher';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ticketConfigField } from '../util/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function Alerms({ Multisite }) {
  const initialState = {
    siteName: '',
    siteId: ',',
    mobile: '',
    postalCode: '',
    siteCode: '',
    priority: '',
  };

  const equipmentHeadCells = [
    {
      label: 'Equipment Name',
      id: 'displayName',
      view: true,
    },
    {
      label: 'Error Code',
      id: 'errorCode',
      view: true,
    },
    {
      label: 'Error Message',
      id: 'errorMessage',
      view: true,
    },
    {
      label: 'Event Time',
      id: 'lastOccurence',
      view: true,
    },
    {
      label: 'Occurrence',
      id: 'eventOccurence',
      view: true,
    },
    {
      label: 'Priority',
      id: 'priority',
      view: true,
    },
    {
      label: 'Capacity',
      id: 'dcCapacity',
      view: true,
    },
  ];

  const userDefinedHeadCells = [
    {
      label: 'Equipment Name',
      id: 'displayName',
      view: true,
    },
    {
      label: 'Error Code',
      id: 'errorCode',
      view: true,
    },
    {
      label: 'Error Message',
      id: 'errorMessage',
      view: true,
    },
    {
      label: 'Event Time',
      id: 'lastOccurence',
      view: true,
    },
    {
      label: 'Occurrence',
      id: 'eventOccurence',
      view: true,
    },
    {
      label: 'Priority',
      id: 'priority',
      view: true,
    },
    {
      label: 'Capacity',
      id: 'dcCapacity',
      view: true,
    },
  ];
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`${queryParam}`);
  const params = {};

  for (const [key, value] of queryParams) {
    params[key] = value;
  }

  let SiteId = location?.state?.id,
    flog;
  console.log(params, 'SiteIdss');
  /*------------ modal state-----------------*/

  const [loading, setLoading] = useState(true);
  const [headcells, setHeadCells] = useState(equipmentHeadCells); // Initial value for headcells
  const [selectedTab, setSelectedTab] = useState('all');
  const [siteEquipment, setSiteEquipment] = useState([]);
  const [siteAlarms, setSiteAlerms] = useState([]);
  const initialSnack = { open: false, severity: '', message: '' };
  const [value, setvalue] = useState(siteEquipment);
  const [snack, setSnack] = useState(initialSnack);
  const [openfilter, setopenfilter] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [tableToShow, setTableToShow] = useState('equipmetName');
  const [filterdata, setfilterData] = useState();
  const [filterBardata, setfilterBardata] = useState();
  const [filterTable, setfilterTable] = useState([]);
  const [text, setTextField] = useState([]);
  const [textt, setTextFieldd] = useState([]);
  const [Data, setData] = useState(true);
  const [stdField, setstdField] = useState({});
  const [todate, setTodate] = useState();
  const [fromDate, setFromDate] = useState();
  const [Sitefilter, setSitefilter] = useState();
  const [filter, setfilter] = useState(false);
  console.log(Sitefilter);
  console.log(filter);

  const [ticketFields, setTicketFields] = useState(initialState);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  useEffect(() => {
    setvalue(siteEquipment);
  }, [siteEquipment]);
  console.log(todate ? addDays(todate, 1).toISOString().substring(0, 10) : '');

  //   console.log(siteEquipment,"siteEquipment");

  console.log(value, 'value');
  const handleChange = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };

  const handleTxtChange = (event, name) => {
    console.log(name, event);
    setTicketFields({ ...ticketFields, [name]: event.target.value });
  };

  useEffect(() => {
    let data = ticketConfigField();
    setTextField(Data);
  });

  const handleDropdownChange = (event, value, name) => {
    if (name === 'equipmentType') {
      setTicketFields({
        ...ticketFields,
        equipmentType: value,
        equipmentName: null,
        equipementArray: null,
      });
    } else {
      setTicketFields({ ...ticketFields, [name]: value });
    }
  };

  const handledatepicchange = (date, name) => {
    console.log(date.toString());
    setFromDate(date.toString());
  };

  const handledatepic = (date, name) => {
    console.log(date.toString());
    setTodate(date.toString());
  };

  useEffect(() => {
    handlefetchData();
  }, []);

  /*------------------------- Api --------------------------------------------*/

  const handlefetchData = async () => {
    try {
      const data = await fetchSiteEquipment(params.id);
      data?.forEach((val) => {
        if (val?.lastOccurence) {
          const date = new Date(val?.lastOccurence);
          val.lastOccurence = getFormatISTDate(date);
        }
        if (val?.dcCapacity) {
          val.dcCapacity = roundedUp(val?.dcCapacity);
        }
      });
      if (Array.isArray(data)) {
        setSiteEquipment(data);
      } else if (typeof data === 'object' && data !== null) {
        setSiteEquipment([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  console.log(siteEquipment, 'siteEquipment');
  /*-------------------------Table get call --------------------------------------------*/
  console.log(location, 'location');

  console.log(siteEquipment, 'dfghyujik');
  console.log(ticketFields?.priority);

  const handleAlermsfilter = async () => {
    let PostData = {
      siteId: params.id,
      fromDate: fromDate
        ? addDays(fromDate, 1).toISOString().substring(0, 10)
        : '',
      toDate: todate ? addDays(todate, 1).toISOString().substring(0, 10) : '',
      Priority:
        ticketFields?.priority === 'Medium'
          ? '2'
          : ticketFields?.priority === 'Low'
          ? '1'
          : ticketFields?.priority === 'High'
          ? '3'
          : '',
    };
    try {
      const data = await fetchSiteAlermsfilter(PostData);
      data?.forEach((val) => {
        if (val?.lastOccurence) {
          const date = new Date(val?.lastOccurence);
          val.lastOccurence = getFormatISTDate(date);
        }
        if (val?.dcCapacity) {
          val.dcCapacity = roundedUp(val?.dcCapacity);
        }
      });
      if (Array.isArray(data)) {
        setSitefilter(data);
        setfilter(true);
      } else if (typeof data === 'object' && data !== null) {
        setSitefilter([data]);
      }

      handlefilterClose();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const fetchDataTable = async () => {
      setLoading(true);
      try {
        const data = await fetchSiteAlerms(params.id);
        if (Array.isArray(data)) {
          setSiteAlerms(data);
          setLoading(false);
        } else if (typeof data === 'object' && data !== null) {
          setSiteAlerms([data]);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    fetchDataTable();
  }, []);
  console.log(siteAlarms, 'siteAlarms');

  /*---------------------------------- New Report Call -----------------------*/

  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Alarms');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const handlefilter = () => {
    setopenfilter(true);
    setFromDate();
    setTodate();
  };

  const handlefilterClose = () => {
    setopenfilter(false);
    setFromDate();
    setTodate();
  };

  const handleChangeTab = (newValue) => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 'all':
        if (siteEquipment.length === 0) {
          setHeadCells([]);
          setvalue([]);
          setTableToShow('equipmetName');
        } else {
          setHeadCells(equipmentHeadCells);
          setvalue(siteEquipment);
        }

        break;
      case 'active':
        if (siteAlarms.length === 0) {
          setHeadCells([]);
          setvalue([]);
          setTableToShow('userdefine');
        } else {
          setHeadCells(userDefinedHeadCells);
          setvalue(siteAlarms);
        }
        break;
      default:
        setHeadCells(equipmentHeadCells);
        setvalue(siteEquipment);
        break;
    }
  };
  console.log(selectedTab, 'selectedTab');
  const Total = siteEquipment.length;

  const alarmCount = siteAlarms.length;
  console.log(alarmCount, 'alermCountsss');

  const tabsData = [
    { name: 'Equipment ', value: 'all', badgeCount: Total },
    { name: 'User Defined', value: 'active', badgeCount: alarmCount },
  ];

  // console.log(tabsData, 'tvalueb');

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Site List', path: -1 },
    { label: 'Alarms', path: 'alarmsconfig' },
  ];
  useEffect(() => {
    let data = alermsview();
    setTextFieldd(data);
  }, []);
  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case 'priority':
        return (option = Priority?.map((option) => option.label));
    }
  }
  const Priority = [
    { label: 'High', value: '3' },
    { label: 'Medium', value: '2' },
    { label: 'Low', value: '1' },
  ];
  const table = filter === true ? Sitefilter : value;
  console.log(table);

  return (
    <div>
      {/* ---------------pass props value----------- */}

      <div>
        <Formik
          key={'edit'}
          enableReinitialize={true}
          initialValues={ticketFields}
          validationSchema={AlarmsSchema}
          onSubmit={async (values, { resetForm }) => {
            let submitValue = {
              siteName: values.siteName,
              reportName: values.reportName,
              mailId: values.mailId,
              status: values.status,
              timePeriod: values.timePeriod,
            };
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset }) => (
            <Form>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openfilter}
                closeAfterTransition
              >
                <Fade in={openfilter}>
                  <Box className={'styleModalSmall'} style={{ height: '50vh' }}>
                    <Card
                      sx={{
                        borderRadius: '20px',
                        height: 'fit-content',
                      }}
                    >
                      <CardHeader
                        sx={{
                          padding: '20px 20px 20px 20px',
                          background: CheckForLight()
                            ? 'rgb(246, 248, 252)'
                            : '#4f4f4f',
                        }}
                        action={
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: '25px',
                                textTransform: 'capitalize',
                              }}
                              startIcon={<SaveIcon />}
                              onClick={handleAlermsfilter}
                              disabled={!(fromDate && todate)}
                            >
                              View
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: '25px',
                                textTransform: 'capitalize',
                              }}
                              onClick={handlefilterClose}
                              startIcon={<CancelIcon />}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        }
                        title={'Customize Filters'}
                      />
                      <Divider sx={{ borderColor: '#888' }} />
                      <div
                        style={{
                          height: '27vh',
                          padding: '20px',
                        }}
                      >
                        <Grid container spacing={2} columns={16}>
                          {textt?.length > 0
                            ? textt?.map((data, index) => (
                                <Grid item xs={4}>
                                  <div key={index}>
                                    <>
                                      {/* {data.type === 'date' && (
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              <DatePicker
                                                size="small"
                                                sx={{
                                                  width: '12vw',
                                                }}
                                                name={data.name}
                                                
                                                onChange={(e,) => {
                                                  handledatepicchange(
                                                    e,data.name
                                            
                                                  );
                                                }}
                                                slotProps={{
                                                  textField: {
                                                    size: 'small',
                                                  },
                                                }}
                                              />
                                            </LocalizationProvider>
                                          )} */}
                                    </>

                                    {data.type === 'textField' ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          {data.label}
                                        </Typography>
                                        <Field
                                          as={TextField}
                                          disabled={toBackend}
                                          id={`outlined-basic-${index}`}
                                          size="small"
                                          variant="outlined"
                                          name={data.name}
                                          inputProps={{
                                            maxLength: data.length,
                                          }}
                                          placeholder={data.placeholder}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleTxtChange(e);
                                          }}
                                          sx={{ width: '12vw' }}
                                        />
                                        <ErrorMessage
                                          name={data.name}
                                          component="div"
                                          className="error"
                                          style={{
                                            color: 'red',
                                            marginTop: '1%',
                                            textAlign: 'left',
                                            marginLeft: '0%',
                                          }}
                                        />
                                      </>
                                    ) : data.type === 'dropdown' ? (
                                      <>
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          {data.label}
                                        </Typography>
                                        <Tooltip>
                                          <Field
                                            render={({ field, form }) => (
                                              <Tooltip>
                                                <Autocomplete
                                                  options={getOptions(
                                                    data.name
                                                  )}
                                                  size="small"
                                                  id={`combo-box-demo-${index}`}
                                                  onChange={(event, value) =>
                                                    handleDropdownChange(
                                                      event,
                                                      value,
                                                      data.name
                                                    )
                                                  }
                                                  sx={{ width: '12vw' }}
                                                  ListboxProps={{
                                                    style: {
                                                      maxHeight: '200px', // Set your desired height here
                                                    },
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      placeholder={
                                                        data.placeholder
                                                      }
                                                    />
                                                  )}
                                                />
                                              </Tooltip>
                                            )}
                                          />
                                        </Tooltip>
                                        <ErrorMessage
                                          name={data.name}
                                          component="div"
                                          className="error"
                                          style={{
                                            color: 'red',
                                            marginTop: '1%',
                                            textAlign: 'left',
                                            marginLeft: '0%',
                                          }}
                                        />
                                      </>
                                    ) : data.type === 'date' ? (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          {data.label}
                                        </Typography>
                                        <DatePicker
                                          size="small"
                                          sx={{
                                            width: '12vw',
                                          }}
                                          name={data.name}
                                          onChange={(e) => {
                                            handledatepicchange(e, data.name);
                                          }}
                                          slotProps={{
                                            textField: {
                                              size: 'small',
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    ) : data.type === 'datee' ? (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          {data.label}
                                        </Typography>

                                        <DatePicker
                                          size="small"
                                          sx={{
                                            width: '12vw',
                                          }}
                                          // minDate={fromDate}

                                          shouldDisableDate={(date) =>
                                            dayjs(date).isBefore(
                                              dayjs(fromDate),
                                              'day'
                                            )
                                          }
                                          name={data.name}
                                          onChange={(e) => {
                                            handledatepic(e, data.name);
                                          }}
                                          slotProps={{
                                            textField: {
                                              size: 'small',
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    ) : null}
                                  </div>
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      </div>
                    </Card>
                  </Box>
                </Fade>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>

      <div style={{ marginTop: '-0.5%' }}>
        <CustomSnackbar
          open={snack.open}
          onClose={handleSnackClose}
          autoHideDuration={5000}
          severity={snack.severity}
          message={snack.message}
        />
        <div>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20%',
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </div>
          ) : (
            <TableTemplate
              PageName={'Alarms'}
              // addButton={'Alarme'}
              SearchLabel={'Search Alarms Here... '}
              header={headcells}
              rowsValue={table}
              handleChange={handleChangeTab}
              userRole={Multisite[0]}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              handlefilterPopupOpen={(val) => handlefilter(val)}
              tabsData={tabsData}
              rawData={siteEquipment}
              tableToShow={tableToShow}
              paths={paths}
            />
          )}
        </div>
      </div>
    </div>
  );
}
