import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Modal,
  Fade,
  Card,
  Stack,
  CardHeader,
  Autocomplete,
  CircularProgress,
  Tooltip,
  CardContent,
  MenuItem 
} from '@mui/material';
import '../../Common.css';
import { useNavigate } from 'react-router';
import {
  fetchFormulaTable,
  putFormalaupdateEdit,
  postFormaladata,
  fetchSiteDetails,
  EquipmentlistDropdown,
  GetFileParamCoff,
} from '../../Api/FormulaApi';
// ========Modal================
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import FadeLoader from 'react-spinners/FadeLoader';
import moment from 'moment';
import TableTemplate from '../../Template/TableTemplate';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Backdrop from '@mui/material/Backdrop';
/*------------api call-----------------*/
import { CheckForLight } from '../util/common/CommanFunction';
import Download from '../../Template/Excel/Download';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Formula } from '../util/TextField';
import { FormulaSchema } from '../util/ValidateSchema';
import replaceObjectById from '../util/StateUpdater';
import errorMsg from '../util/errorMessage.json';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableItem from '../util/components/DragableItem';
const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export default function Formalahome({ standardParameter }) {
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const [CreateFormala, setCreateFormala] = useState(false);
  const [Refresh, setRefresh] = useState(false);

  /*------------ modal state-----------------*/

  const [siteIdName, setSiteId] = useState([]);
  const [stdparameterId, setparameterId] = useState([]);
  const [FormalaIdName, setFormalaId] = useState([]);
  const [customFromDate, setFromDate] = useState('');
  const [SiteFormala, setSiteFormala] = useState([]);
  const [activeFlag, setactiveFlag] = useState();
  const [timePeriod, settimePeriod] = useState();
  const [siteFormula, setSiteFormula] = useState([]);
  const [editcall, seteditcall] = useState();
  const [loading, setLoading] = useState();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [EquipmetId, setEquipmentId] = useState([]);
  const [email, setEmail] = useState(sessionStorage.getItem('email'));
  const [validEmail, setValidEmail] = useState(true);
  const [FormalaData, setFormalaData] = useState();
  const [minToDate, setMinToDate] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [fromDownloadDate, setFromDownloadDate] = useState('');
  const [toDownloadDate, setToDownloadDate] = useState('');
  const [siteTab, setSiteTab] = React.useState('');
  const [fieldCount, setFieldCount] = useState(1);
  const [parametersByEquipment, setParametersByEquipment] = useState({});
  const [updatecall, setupdatecall] = useState({
    siteName: '',
    displayName: '',
    stdParameterName: '',
    formula: '',
    timePeriod: '',
  });
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const initalState = {
    siteName: '',
    displayName: '',
    stdParameterName: '',
    formula: '',
    equipmentId: null, // Add this line
  };
  const [FormalaFields, setFormalaFields] = useState(initalState);

  //Drag and Drop logic
  const initialItems = [
    { id: '1', content: '3.14', type: 'number' },
    { id: '2', content: 'PR', type: 'text' },
  ];

  const initialOperators = [
    { id: '12', content: '(', type: 'operator' },
    { id: '13', content: ')', type: 'operator' },
    { id: '3', content: '+', type: 'operator' },
    { id: '4', content: '-', type: 'operator' },
    { id: '5', content: '*', type: 'operator' },
    { id: '6', content: '/', type: 'operator' },
    { id: '9', content: '%', type: 'operator' },
    { id: '10', content: '^', type: 'operator' },
    { id: '11', content: '!', type: 'operator' },
    { id: '14', content: '=', type: 'operator' },
  ];

  const initialParams = [
    { id: '7', content: 'param1', type: 'param' },
    { id: '8', content: 'param2', type: 'param' },
  ];

  const [items, setItems] = useState(initialItems);
  const [operators, setOperators] = useState(initialOperators);
  const [params, setParams] = useState([]);
  const [formula, setFormula] = useState([]);

  function splitExpression(expression, param) {
    const operators = ['+', '-', '*', '/', '(', ')'];
    const result = [];
    let buffer = '';

    for (let i = 0; i < expression.length; i++) {
      const char = expression[i];

      if (operators.includes(char)) {
        if (buffer) {
          result.push(createItem(buffer, '', param));
          buffer = '';
        }
        result.push(createItem(char, 'operator', param));
      } else if (char === ' ') {
        if (buffer) {
          result.push(createItem(buffer, '', param));
          buffer = '';
        }
      } else {
        buffer += char;
      }
    }

    if (buffer) {
      result.push(createItem(buffer, '', param));
    }

    return result;
  }

  function createItem(content, type = null, param) {
    let itemType = type;

    const isParams = param.filter((element) => {
      return element.content === content;
    });

    console.log(isParams);

    if (!itemType) {
      if (!isNaN(content)) {
        itemType = 'number';
      } else if (isParams) {
        itemType = 'param';
      } else {
        itemType = 'text';
      }
    }

    return {
      id: `${Date.now().toString()}${content}`,
      content: content,
      type: itemType,
    };
  }

  console.log(FormalaFields, 'editcall');
  const [text, setTextField] = useState([]);
  useEffect(() => {
    let data = Formula();
    setTextField(data);
  }, []);

  useEffect(() => {
    fetchData();
    getSiteCall();
  }, []);
  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'siteName':
        console.log(siteIdName, 'siteIddrop');
        return (option = siteIdName?.map((option) => option.siteName));
      case 'displayName':
        return (option = EquipmetId?.map((option) => option.displayName));
      // case 'stdParameterName':
      //   return (option = stdparameterId?.map(
      //     (option) => option.stdParameterName
      //   ));
      case 'stdParameterName':
      const selectedEquipmentId = FormalaFields.equipmentId;
      if (selectedEquipmentId && parametersByEquipment[selectedEquipmentId]) {
        return parametersByEquipment[selectedEquipmentId].map(param => param.stdParameterName);
      }
      return [];
      case 'status':
        return (option = statusList);
      default:
    }
    return option;
  }
  console.log(stdparameterId, 'parameterId');
  const defaultMail = sessionStorage.getItem('email');
  console.log(FormalaFields.mailId, 'emailemailemail');
  /*------------------------- Api --------------------------------------------*/
  const getSiteCall = async () => {
    try {
      console.log('start');
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDetails();
      console.log(data, 'sitelist');
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const getparameterCall = async (id) => {
    try {
      let data = await GetFileParamCoff(id);
      const result = data
        .filter((item) => {
          return item.stdParameterName !== 'Dummy';
        })
        .map((item) => {
          return {
            id: item.stdParameterName,
            content: item.stdParameterName,
            type: 'param',
          };
        });
      console.log(result, 'result');

      setParams(result);
      setparameterId(result);
      return result;
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (FormalaFields.siteName !== '') {
      let SitenameID = siteIdName?.filter((data) => {
        return data.siteName === FormalaFields?.siteName;
      });
      console.log(SitenameID, 'SitenameID');
      getEquipmentCall(SitenameID[0]?.siteId);
    }
  }, [FormalaFields.siteName]);
  const getEquipmentCall = async (Id) => {
    try {
      let data = await EquipmentlistDropdown(Id);
      setEquipmentId(data);
    } catch (e) {
      console.error(e);
    }
  };
  console.log(FormalaFields);
  const dayjs = require('dayjs');
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async () => {
    let id = editcall.formulaId;
    const formulaStr = formula.map((item) => item.content).join(' ');
    let obj = {
      siteId: editcall?.siteId,
      equipmentId: editcall?.equipmentId,

      formula: formulaStr,
      parameterId: editcall?.parameterId,

      status: editcall?.status,
      lastUpdatedBy: sessionStorage.getItem('id'),
    };

    try {
      setToBackend(true);
      let responsedata = await putFormalaupdateEdit(obj, id);
      if (responsedata.status === 200) {
        const updatedTable = replaceObjectById(
          SiteFormala,
          'FormalaMapId',
          id,
          responsedata.data
        );
        // fetchData();
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        handleClose();
        window.location.reload();
        return 1;
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('Error:', error.response.status);
      setToBackend(false);
      return 0;
    }
  };

  const isCustomValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#\$%\^&\*]/;
    const letterRegex = /[a-zA-Z]/;

    return (
      emailRegex.test(email) &&
      numberRegex.test(email) &&
      specialCharRegex.test(email) &&
      letterRegex.test(email)
    );
  };
  console.log(stdparameterId);
  console.log(FormalaFields, 'FormalaFields');

  const handleDndClose = () => {
    setItems(initialItems);
    setOperators(initialOperators);
    setParams([]);
    setFormula([]);
  };

  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async () => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === FormalaFields.siteName;
    });
    let equipmentnameIdFilter = EquipmetId?.filter((data) => {
      return data.displayName === FormalaFields.displayName;
    });
    let ParameterIdFilter = stdparameterId?.filter((data) => {
      return data.stdParameterName === FormalaFields.stdParameterName;
    });

    const formulaStr = formula.map((item) => item.content).join(' ');

    console.log(ParameterIdFilter, '  ParameterIdFilter');
    let data = {
      siteId: siteIdFilter[0]?.siteId,
      equipmentId: equipmentnameIdFilter[0]?.equipmentId,
      formula: formulaStr,
      parameterId: ParameterIdFilter[0]?.stdId || 0,
      createdBy: sessionStorage.getItem('id'),
      status: 1,
    };

    try {
      setToBackend(true);
      const responseData = await postFormaladata(data);
      console.log(responseData.statusCode);
      console.log(responseData);
      if (responseData.status === 200) {
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: 'success',
          message: responseData.data.statusDescription,
        });
        handleClose();
        setToBackend(false);
        setOpen(false);
        return 1;
      }
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error('Error:', e.response.status);
      setToBackend(false);
      console.log('error:', e.message);
      return 0;
    }
  };

  /*-------------------------Table get call --------------------------------------------*/

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchFormulaTable();
      if (Array.isArray(data)) {
        setSiteFormula(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setSiteFormula([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  /*------------------------- Site Name list dropdown --------------------------------------------*/

  /*-------------------------Formala Name list dropdown --------------------------------------------*/

  const userId = sessionStorage.getItem('id');

  const handleactiveFlag = (data, event) => {
    setactiveFlag(event?.value);
  };
  const handletimePeriod = (data, event) => {
    if (event !== null) {
      settimePeriod(event.value);
    }
  };

  /*---------------------------------- New Formala Call -----------------------*/
  const handleCrate = () => {
    setCreateFormala(true);
    setOpen(true);
    setEdit(false);
    seteditcall();
  };
  const handleRefresh = () => {
    setRefresh(true);
    setEdit(false);
    setCreateFormala(false);
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setFormalaFields(initalState);
    setEquipmentId([]);
    handleDndClose();
  };

  const Type = [{ label: 'Generation', value: 'generation' }];
  const statusList = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];
  const optionTime = [
    // { label: "Last 7 days", value: "last 7 days" },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Custom Range', value: 'custom' },
  ];
  const equipmentHeadCells = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
    },

    {
      label: 'Equipment',
      id: 'displayName',
      view: true,
    },
    {
      label: 'Parameter',
      id: 'stdParameterName',
      view: true,
    },
    {
      label: 'Formula',
      id: 'formula',
      view: true,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
    },
  ];

  /*------------ modal state-----------------*/

  const [headcells, setHeadCells] = useState(equipmentHeadCells);
  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = '';
    let toDate = '';
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    switch (timePeriod) {
      case 'last 7 days':
        fromDate.setDate(date.getDate() - 6);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD  ');
        setFromDownloadDate(responseDate);
        setToDownloadDate(toDate);
        break;
      case 'Monthly':
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDownloadDate(formatDate(firstDayOfLastMonth));
        setToDownloadDate(formatDate(lastDayOfLastMonth));
        break;
      default:
    }
  }, [timePeriod]);

  const functionDate = (inputValue) => {
    let date = new Date();
    let fromDate = new Date();
    let toDateFormat = new Date();
    let responseDate = '';
    let toDate = '';
    let dateFormat = {
      initalDate: '',
      finalDate: '',
    };
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    switch (inputValue) {
      case 'Weekly':
        fromDate.setDate(date.getDate() - date.getDay() - 6);
        const firstDayOfPastWeek = new Date(fromDate);
        toDateFormat.setDate(date.getDate() - date.getDay());
        const lastDayOfPastWeek = new Date(toDateFormat);
        const formattedFirstDay = formatDate(firstDayOfPastWeek);
        const formattedLastDay = formatDate(lastDayOfPastWeek);
        dateFormat.initalDate = formattedFirstDay;
        dateFormat.finalDate = formattedLastDay;
        break;

      case 'Monthly':
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDownloadDate(formatDate(firstDayOfLastMonth));
        setToDownloadDate(formatDate(lastDayOfLastMonth));
        dateFormat.initalDate = formatDate(firstDayOfLastMonth);
        dateFormat.finalDate = formatDate(lastDayOfLastMonth);
        break;
      default:
    }

    return dateFormat;
  };

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (formulaId) => {
    let filteredFormalaMapId = Object.keys(initalState).reduce((acc, key) => {
      if (formulaId.hasOwnProperty(key)) {
        acc[key] = formulaId[key];
      }
      return acc;
    }, {});

    seteditcall(formulaId);
    setFormalaFields(filteredFormalaMapId);
    const param = await getparameterCall(formulaId.equipmentId);
    setFormula(splitExpression(filteredFormalaMapId.formula, param));
    setEdit(true);
    setOpen(true);
  };

  const handleFormala = (data, value) => {
    if (value === null) {
      setFormalaData('');
    } else {
      setFormalaData(value);
    }
  };
  const [called, setCalled] = useState(false);
  const handleDropdownChange = async (event, value, name) => {
    if (name === 'displayName') {
      const equipID = EquipmetId.filter((item) => item.displayName === value);
      await getparameterCall(equipID[0].equipmentId);
      setCalled(true);
    }
    console.log(value, 'dropValue');
    if (name === 'siteName') {
      setFormalaFields({ ...FormalaFields, [name]: value, displayName: null });
      setParams([]);
      setCalled(false);
    } else {
      setFormalaFields({ ...FormalaFields, [name]: value });
    }
  };
  // const handleDropdownChange = (event, value, name) => {
  //   console.log(value, 'dropValue');
  //   if (name === 'siteName') {
  //     setFormalaFields({ ...FormalaFields, [name]: value, displayName: null });
  //   } else {
  //     setFormalaFields({ ...FormalaFields, [name]: value });
  //   }
  // };
  // ========customrange=========
  const handleChangeFromDate = (data, value) => {
    if (
      data !== null &&
      typeof data === 'object' &&
      typeof data.format === 'function'
    ) {
      const formattedDatetime = data.format('YYYY-MM-DD');
      setFromDate(formattedDatetime);
    } else {
      console.error("Invalid data format or 'data' is null");
    }
  };

  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDatetime = data.format('YYYY-MM-DD');
      setMinToDate(formattedDatetime);
      console.log(formattedDatetime, 'setFromDate ');
    }
  };
  const filterAlign = {
    visibility: timePeriod !== 'custom' ? 'hidden' : 'visible',
  };
  const downloadENableIcon = {
    visibility: timePeriod !== 'custom' ? 'hidden' : 'visible',
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  const handleEmailChangevalue = (e) => {
    setEmail(e.target.value);
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };

  const buttonStyle = {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  };

  const [searchInput, setSearchInput] = useState('');
  const filteredSiteFormalaData = SiteFormala.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )
  );

  const handleChange = (e) => {
    console.log(e, 'gatedown');
    setFormalaFields(e);
  };
  const handleTxtChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;
    console.log(value, 'fgh');
    // if (!isNaN(newValue) || newValue === 'x' || newValue === 'y') {
    setFormalaFields({
      ...FormalaFields,
      [name]: newValue,
    });
    // }
  };
  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Formala');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const tableValues = SiteFormala.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };

  const Total = SiteFormala?.length;

  const Active = SiteFormala?.filter((site) => site?.status == 1).length;

  const Inactive = SiteFormala?.filter((site) => site?.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Formala', value: 'all', badgeCount: Total },
    { name: 'Active Formala', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Formala',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Formula', path: 'formula' },
  ];

  //Drag and Drop logic
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Check if the destination is defined
    if (!destination) return;

    const sourceId = source.droppableId;
    const destId = destination.droppableId;

    // Check if sourceId and destId are valid
    if (
      ![sourceId, destId].every((id) =>
        ['items', 'operators', 'params', 'formula'].includes(id)
      )
    ) {
      return;
    }

    // Handle reorder within the same droppable
    if (sourceId === destId) {
      const list =
        sourceId === 'items'
          ? items
          : sourceId === 'operators'
          ? operators
          : sourceId === 'params'
          ? params
          : formula;

      const reorderedList = reorder(list, source.index, destination.index);

      if (sourceId === 'items') {
        setItems(reorderedList);
      } else if (sourceId === 'operators') {
        setOperators(reorderedList);
      } else if (sourceId === 'params') {
        setParams(reorderedList);
      } else {
        setFormula(reorderedList);
      }
    } else {
      // Handle move between different droppables
      const sourceClone = Array.from(
        sourceId === 'items'
          ? items
          : sourceId === 'operators'
          ? operators
          : sourceId === 'params'
          ? params
          : formula
      );
      const destClone = Array.from(
        destId === 'items'
          ? items
          : destId === 'operators'
          ? operators
          : destId === 'params'
          ? params
          : formula
      );

      const [removed] = sourceClone.splice(source.index, 1);

      // Create a new item with a unique ID
      const newItem = { ...removed, id: `${removed.id}-${Date.now()}` };

      // Add to the destination list
      if (destId === 'items' || destId === 'operators') {
        // Check if an item with the same content exists in the destination list
        const existingItemIndex = destClone.findIndex(
          (item) => item.content === newItem.content
        );

        if (existingItemIndex !== -1) {
          // Remove the existing item with the same content
          destClone.splice(existingItemIndex, 1);
        }

        // Add the new item to the end of the destination list if moving to 'items' or 'operators'
        destClone.push(newItem);

        // Update the state for the destination list
        if (destId === 'items') {
          setItems(destClone);
        } else if (destId === 'operators') {
          setOperators(destClone);
        } else if (destId === 'params') {
          setParams(destClone);
        }
      } else {
        // Add the new item to the destination list
        destClone.splice(destination.index, 0, newItem);

        // Update the state for the destination list
        if (destId === 'params') {
          setParams(destClone);
        } else {
          setFormula(destClone);
        }
      }

      // Update the state for the source list
      if (sourceId === 'items') {
        setItems(initialItems);
      } else if (sourceId === 'operators') {
        setOperators(initialOperators);
      } else if (sourceId === 'params') {
        setParams(sourceClone);
      } else {
        setFormula(sourceClone);
      }
    }
  };

  const handleChangeDnd = (index, value) => {
    const newFormula = [...formula];
    newFormula[index].content = value;
    setFormula(newFormula);
  };

  return (
    <div>
      {' '}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          {/* ---------search---bar--- Card----------- */}

          <div>
            {/* ---------   -----save andupdate---- popup----------- */}
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <Formik
              key={edit ? `edit` : `add`}
              enableReinitialize={true}
              initialValues={FormalaFields}
              validationSchema={FormulaSchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = {
                  siteName: values.siteName,
                  FormalaName: values.FormalaName,
                  mailId: values.mailId,
                  status: values.status,
                  timePeriod: values.timePeriod,
                };
                if (edit) {
                  const result = await handleUpdate(submitValue);
                  console.log(result, 'result');
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSave(submitValue);
                  console.log(result, 'result');
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    sx={{ marginTop: '12vh' }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalSmall'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                          height: '40rem',
                          width: '65rem',
                          padding: '20px 20px 20px 20px',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            height: '35rem',
                            // height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={edit ? 'Update Formula' : 'Add New Formula'}
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          {/* <div className="modelTypoSmall"> */}
                          <div className="site-tab">
                            <Box
                              style={{
                                width: '100%',
                                background: CheckForLight() ? null : '#121212',
                                // height: 'fit-content',
                              }}
                            >
                              <div>
                                <div
                                  className="modelGrid"
                                  style={{ marginTop: '0' }}
                                >
                                  <Card
                                    sx={{
                                      overflowY: 'visible',
                                      marginLeft: '-1vw',
                                      scrollBehavior: 'smooth',
                                      scrollbarGutter: 'stable',
                                      scrollbarWidth: 'thin',
                                      '&::-webkit-scrollbar': {
                                        width: '0.4em',
                                      },
                                      '&::-webkit-scrollbar-track': {
                                        background: '#f1f1f1',
                                      },
                                      '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#888',
                                        borderRadius: '20px',
                                      },
                                      '&::-webkit-scrollbar-thumb:hover': {
                                        background: '#555',
                                      },
                                    }}
                                    elevation={0}
                                  >
                                    <CardContent>
                                      <Grid container spacing={2} columns={16}>
                                        {text?.length > 0
                                          ? text?.map((data, index) => (
                                              <Grid item xs={4}>
                                                <div key={index}>
                                                  <Typography
                                                    variant="body1"
                                                    className="modal-typo"
                                                    gutterBottom
                                                  >
                                                    {data.label}
                                                  </Typography>
                                                  {data.type === 'textField' ? (
                                                    <>
                                                      <Field
                                                        as={TextField}
                                                        disabled={toBackend}
                                                        id={`outlined-basic-${index}`}
                                                        size="small"
                                                        variant="outlined"
                                                        name={data.name}
                                                        inputProps={{
                                                          maxLength:
                                                            data.length,
                                                        }}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                        onChange={(e) => {
                                                          const inputValue =
                                                            e.target.value;
                                                          const validValuesRegex =
                                                            /^[0-9xy()%-+*/,]*$/;
                                                          if (
                                                            validValuesRegex.test(
                                                              inputValue
                                                            )
                                                          ) {
                                                            handleChange(e);
                                                            handleTxtChange(e);
                                                          }
                                                        }}
                                                      />
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="error"
                                                        style={{
                                                          color: 'red',
                                                          marginTop: '1%',
                                                          textAlign: 'left',
                                                          marginLeft: '0%',
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type ===
                                                    'dropdown' ? (
                                                    <>
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit
                                                            ? data.name ===
                                                              'status'
                                                              ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values.status ===
                                                                  1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : values[
                                                                  data.name
                                                                ]
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Field
                                                          render={({
                                                            field,
                                                            form,
                                                          }) => (
                                                            <Tooltip
                                                              title={
                                                                !edit &&
                                                                data.name ===
                                                                  'status'
                                                                  ? 'Active'
                                                                  : edit
                                                                  ? data.name ===
                                                                    'status'
                                                                    ? typeof values[
                                                                        data
                                                                          .name
                                                                      ] ===
                                                                      'string'
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                      : values.status ===
                                                                        1
                                                                      ? 'Active'
                                                                      : 'Inactive'
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                  : values[
                                                                      data.name
                                                                    ]
                                                              }
                                                            >
                                                              <Autocomplete
                                                                disableClearable
                                                                disabled={
                                                                  toBackend ||
                                                                  (!edit &&
                                                                    data.name ===
                                                                      'status') ||
                                                                  (edit &&
                                                                    data.name ===
                                                                      'siteName') ||
                                                                  (data.name ===
                                                                    'displayName' &&
                                                                  !edit
                                                                    ? !values.siteName
                                                                    : edit) ||
                                                                  // (data.name ===
                                                                  //   'displayName' &&
                                                                  //   edit)
                                                                  (edit &&
                                                                    data.name ===
                                                                      'stdParameterName')
                                                                }
                                                                options={getOptions(
                                                                  data.name
                                                                )}
                                                                size="small"
                                                                id={`combo-box-demo-${index}`}
                                                                onChange={(
                                                                  event,
                                                                  value
                                                                ) =>
                                                                  handleDropdownChange(
                                                                    event,
                                                                    value,
                                                                    data.name
                                                                  )
                                                                }
                                                                value={
                                                                  !edit &&
                                                                  data.name ===
                                                                    'status'
                                                                    ? 'Active'
                                                                    : edit
                                                                    ? data.name ===
                                                                      'status'
                                                                      ? typeof values[
                                                                          data
                                                                            .name
                                                                        ] ===
                                                                        'string'
                                                                        ? values[
                                                                            data
                                                                              .name
                                                                          ]
                                                                        : values.status ===
                                                                          1
                                                                        ? 'Active'
                                                                        : 'Inactive'
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                }
                                                                sx={{
                                                                  width: '16vw',
                                                                }}
                                                                renderInput={(
                                                                  params
                                                                ) => (
                                                                  <TextField
                                                                    {...params}
                                                                    placeholder={
                                                                      data.placeholder
                                                                    }
                                                                    autoComplete={
                                                                      edit
                                                                        ? 'on'
                                                                        : 'off'
                                                                    } // This line is added to conditionally set autocomplete
                                                                  />
                                                                )}
                                                              />
                                                            </Tooltip>
                                                          )}
                                                        />
                                                      </Tooltip>
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="error"
                                                        style={{
                                                          color: 'red',
                                                          marginTop: '1%',
                                                          textAlign: 'left',
                                                          marginLeft: '0%',
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type ===
                                                    'datepic' ? (
                                                    <>
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit
                                                            ? data.name ===
                                                              'status'
                                                              ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values.status ===
                                                                  1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : values[
                                                                  data.name
                                                                ]
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <div>
                                                          <Field
                                                            render={({
                                                              field,
                                                              form,
                                                            }) => (
                                                              <div>
                                                                <LocalizationProvider
                                                                  dateAdapter={
                                                                    AdapterDayjs
                                                                  }
                                                                >
                                                                  <DatePicker
                                                                    placeholder="From"
                                                                    disabled={
                                                                      FormalaFields.timePeriod !==
                                                                      'Custom Range'
                                                                    }
                                                                    disableFuture
                                                                    sx={{
                                                                      width:
                                                                        '13vw',
                                                                    }}
                                                                    onChange={(
                                                                      date
                                                                    ) =>
                                                                      handleChangeFromDate(
                                                                        date
                                                                      )
                                                                    } // Pass the selected date to handleChangeFromDate
                                                                    slotProps={{
                                                                      textField:
                                                                        {
                                                                          size: 'small',
                                                                        },
                                                                    }}
                                                                  />
                                                                </LocalizationProvider>
                                                              </div>
                                                            )}
                                                          />
                                                        </div>
                                                      </Tooltip>
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="error"
                                                        style={{
                                                          color: 'red',
                                                          marginTop: '1%',
                                                          textAlign: 'left',
                                                          marginLeft: '0%',
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type === 'datepi' ? (
                                                    <>
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit
                                                            ? data.name ===
                                                              'status'
                                                              ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values.status ===
                                                                  1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : values[
                                                                  data.name
                                                                ]
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Field
                                                          render={({
                                                            field,
                                                            form,
                                                          }) => (
                                                            <div>
                                                              <LocalizationProvider
                                                                dateAdapter={
                                                                  AdapterDayjs
                                                                }
                                                              >
                                                                <DatePicker
                                                                  placeholder="To"
                                                                  disabled={
                                                                    FormalaFields.timePeriod !==
                                                                    'Custom Range'
                                                                  }
                                                                  disableFuture
                                                                  shouldDisableDate={(
                                                                    date
                                                                  ) =>
                                                                    dayjs(
                                                                      date
                                                                    ).isBefore(
                                                                      dayjs(
                                                                        customFromDate
                                                                      ),
                                                                      'day'
                                                                    )
                                                                  }
                                                                  onChange={(
                                                                    date
                                                                  ) =>
                                                                    handleChangeToDate(
                                                                      date
                                                                    )
                                                                  } // Pass the selected date to handleChangeToDate
                                                                  slotProps={{
                                                                    textField: {
                                                                      size: 'small',
                                                                    },
                                                                  }}
                                                                />
                                                              </LocalizationProvider>
                                                            </div>
                                                          )}
                                                        />
                                                      </Tooltip>
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="error"
                                                        style={{
                                                          color: 'red',
                                                          marginTop: '1%',
                                                          textAlign: 'left',
                                                          marginLeft: '0%',
                                                        }}
                                                      />
                                                    </>
                                                  ) : null}
                                                </div>
                                              </Grid>
                                            ))
                                          : null}
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                  <DragDropContext onDragEnd={onDragEnd}>
                                    <div>
                                      <Typography
                                        variant="body1"
                                        className="modal-typo"
                                        gutterBottom
                                      >
                                        Formula*
                                      </Typography>
                                      <Droppable
                                        droppableId="formula"
                                        direction="horizontal"
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{
                                              padding: '10px',
                                              marginLeft: '8%',
                                              border: '1px solid #ddd',
                                              width: '80%',
                                              height: '80px',
                                              display: 'flex',
                                              flexDirection: 'row',
                                              flexWrap: 'nowrap', // Ensure items don't wrap to the next line
                                              overflowX: 'auto', // Enable horizontal scrolling
                                              overflowY: 'hidden', // Hide vertical scrollbar
                                              whiteSpace: 'nowrap', // Prevent breaking of items to a new line
                                            }}
                                          >
                                            {formula.map((item, index) => (
                                              <DraggableItem
                                                key={item.id}
                                                item={item}
                                                index={index}
                                                onChange={handleChangeDnd}
                                                length={formula.length}
                                                from={'formula'}
                                              />
                                            ))}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        padding: '10px',
                                        marginLeft: '-4.5%',
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          Fields
                                        </Typography>
                                        <Droppable droppableId="items">
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              style={{
                                                padding: '10px',
                                                border: '1px solid #ddd',
                                                width: '100px',
                                                height: '200px',
                                              }}
                                            >
                                              {items.map((item, index) => (
                                                <DraggableItem
                                                  key={item.id}
                                                  item={item}
                                                  index={index}
                                                  from={'items'}
                                                />
                                              ))}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </div>
                                      <div>
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          Operators
                                        </Typography>
                                        <Droppable droppableId="operators">
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              style={{
                                                padding: '10px',
                                                border: '1px solid #ddd',
                                                width: '100px',
                                                height: '200px',
                                                overflowY: 'auto',
                                              }}
                                            >
                                              {operators.map((item, index) => (
                                                <div>
                                                  <DraggableItem
                                                    key={item.id}
                                                    item={item}
                                                    index={index}
                                                    from={'operators'}
                                                  />
                                                </div>
                                              ))}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </div>
                                      <div>
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          Parameters
                                        </Typography>

                                        <Droppable droppableId="params">
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              style={{
                                                padding: '10px',
                                                border: '1px solid #ddd',
                                                width: '300px',
                                                height: '200px',
                                                overflowY: 'auto',
                                              }}
                                            >
                                              {params.length > 0 ? (
                                                params.map((item, index) => (
                                                  <DraggableItem
                                                    key={item.id}
                                                    item={item}
                                                    index={index}
                                                    from={'params'}
                                                  />
                                                ))
                                              ) : (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <Typography
                                                    variant="body1"
                                                    className="modal-typo"
                                                    gutterBottom
                                                  >
                                                    {called
                                                      ? 'No Parameters available'
                                                      : 'Select a equipment to add parameters'}
                                                  </Typography>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </Droppable>
                                      </div>
                                    </div>
                                  </DragDropContext>
                                </div>
                              </div>
                            </Box>
                          </div>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          {/* ---------------pass props value----------- */}

          <div style={{ marginTop: '-0.5%' }}>
            <TableTemplate
              PageName={'Formula'}
              addButton={'Formula'}
              SearchLabel={'Search Formula Here... '}
              header={headcells}
              rowsValue={siteFormula}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              userRole={standardParameter[0]}
              paths={paths}
              // isSiteformula={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}
