import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  CircularProgress,
  Tooltip,
  CardHeader,
  Card,
} from '@mui/material';
import '../../../Common.css';

import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import TableTemplate from '../../../Template/TableTemplate';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
/*------------api call-----------------*/
import {
  fetchSiteDetails,
  fetchReportnamelist,
  DownloadReport,
} from '../../../Api/ReportApi';
import {
  fetchReportTableById,
  putActive,
  postActive,
} from '../../../Api/UserRoleApi';

import Download from '../../../Template/Excel/Download';
import CustomSnackbar from '../../util/components/CustomSnackbar';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ActiveName } from '../../util/TextField';
import { RoleActive } from '../../util/ValidateSchema';
import replaceObjectById from '../../util/StateUpdater';
import errorMsg from '../../util/errorMessage.json';
import { CheckForLight } from '../../util/common/CommanFunction';
import FadeLoader from 'react-spinners/FadeLoader';
const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export default function Reporthome({ Multisite }) {
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const [Createreport, setCreatereport] = useState(false);
  /*------------ modal state-----------------*/
  const [SiteReport, setSiteReport] = useState([]);
  const [activeFlag, setactiveFlag] = useState();
  const [editcall, seteditcall] = useState();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const initalState = {
    activityName: '',
  };
  const [reportFields, setReportFields] = useState(initalState);
  const [reportValue, setReportValue] = useState({
    timePeriodValue: '',
    statusValue: '',
  });
  const [updatedKey, setUpdatedKey] = useState(0);
  const [text, setTextField] = useState([]);
  useEffect(() => {
    let data = ActiveName();
    setTextField(data);
  }, []);

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
  }, []);
  const dayjs = require('dayjs');
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async () => {
    console.log(activeFlag);
    let id = editcall.activityId;
    let obj = {
      activityName:
        reportFields.activityName !== ''
          ? reportFields.activityName
          : editcall?.activityName,
      lastUpdatedBy: sessionStorage.getItem('id'),
    };
    console.log(obj, 'objUpdate');

    try {
      setToBackend(true);
      let responsedata = await putActive(obj, id);
      if (responsedata.status === 200) {
        const updatedTable = replaceObjectById(
          SiteReport,
          'reportMapId',
          id,
          responsedata.data
        );
        // fetchData();
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        setOpen(false);
        return 1;
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('Error:', error.response.status);
      setToBackend(false);
      return 0;
    }
  };

  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async () => {
    let data = {
      activityName: reportFields.activityName,
      createdBy: sessionStorage.getItem('id'),
    };
    console.log(data, 'datadatadata');

    // console.log(responseData);
    try {
      setToBackend(true);
      const responseData = await postActive(data);
      console.log(responseData, 'responseData');
      if (responseData === 200) {
        fetchData();
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        setOpen(false);
        return 1;
      }
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error('Error:', e.response.status);
      // console.log(responseData.status);
      // console.log(responseData);
      // console.log(status);
      setToBackend(false);
      console.log('error:', e.message);
      return 0;
    }
  };
  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchReportTableById();
      // const reverse = [...data].reverse();
      if (Array.isArray(data)) {
        // If data is an array, set tableValue to data directly
        setSiteReport(data);
      } else if (typeof data === 'object' && data !== null) {
        // If data is an object, wrap it in an array and set tableValue
        setSiteReport([data]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  /*---------------------------------- New Report Call -----------------------*/
  const handleCrate = () => {
    setCreatereport(true);
    setOpen(true);
    setEdit(false);
    seteditcall();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setReportFields(initalState);
  };

  const headCells = [
    {
      label: 'Page Name',
      id: 'activityName',
      view: true,
      default: true,
    },
    {
      label: 'Created By',
      id: 'userName',
      view: true,
      default: true,
    },
    {
      label: 'Created On',
      id: 'createdDate',
      view: true,
    },
    {
      label: 'Status',
      id: 'value',
      view: true,
      default: true,
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  // const handleActivee = async (row) => {
  //   let filterederrorId = Object.keys(initalState).reduce((acc, key) => {
  //     if (errorId.hasOwnProperty(key)) {
  //       acc[key] = errorId[key];
  //     }
  //     return acc;
  //   }, {});
  // seteditcall(activityId);
  //   setReportFields(filterederrorId);
  //   setCategory(errorId.equipmentCategory);

  // };
  const handleActivee = async (row) => {
    const id = row.activityId;
    const obj = {
      status: Number(row.status == 1 ? 0 : 1),
    };
    try {
      let responsedata = await putActive(obj, id);
      const updatedTable = replaceObjectById(
        SiteReport,
        'activityId',
        id,
        responsedata
      );
      // fetchData();
      setUpdatedKey((prev) => prev + 1);
      console.log(updatedTable, 'rest');
    } catch (error) {
      console.error(error);
    }
  };
  console.log(SiteReport, 'ghj');

  const [searchInput, setSearchInput] = useState('');
  const filteredSiteReportData = SiteReport.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const handleDropdownChange = (event, value, name) => {
    console.log(value, 'dropValue');
    if (name === 'timePeriod' || name === 'status') {
      setReportFields({ ...reportFields, [name]: value.label });
      setReportValue({ ...reportValue, [name]: value.value });
    } else {
      setReportFields({ ...reportFields, [name]: value });
    }
  };
  const handleChange = (e) => {
    console.log(e, 'gatedown');
    setReportFields(e);
  };
  const handleTxtChange = (event) => {
    console.log(event.target.name, event.target.value);
    setReportFields({
      ...reportFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Page List');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const paths = [
    { label: 'Admin', path: '/config/admin/userconf' },
    { label: 'Page List', path: 'admin/userroletable' },
  ];

  return (
    <div>
      {/* ---------search---bar--- Card----------- */}

      <div>
        {/* ---------   -----save andupdate---- popup----------- */}

        <Formik
          key="add"
          enableReinitialize={true}
          initialValues={reportFields}
          validationSchema={RoleActive}
          onSubmit={async (values, { resetForm }) => {
            let submitValue = {
              siteName: values.siteName,
              reportName: values.reportName,
              mailId: values.mailId,
              status: values.status,
              timePeriod: values.timePeriod,
            };
            if (edit) {
              const result = await handleUpdate(submitValue);
              console.log(result, 'result');
              if (result === 1) {
                resetForm();
              }
            } else {
              const result = await handleSave(submitValue);
              console.log(result, 'result');
              if (result === 1) {
                resetForm();
              }
            }
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset }) => (
            <Form>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
              >
                <Fade in={open}>
                  <Box className={'styleModalSmall'} sx={{ outline: 'none' }}>
                    <Card
                      sx={{
                        borderRadius: '20px',
                        // height: '68vh',
                        height: 'fit-content',
                      }}
                    >
                      <CardHeader
                        sx={{
                          padding: '20px 20px 20px 20px',
                          background: CheckForLight()
                            ? 'rgb(246, 248, 252)'
                            : '#4f4f4f',
                        }}
                        action={
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: '25px',
                                textTransform: 'capitalize',
                              }}
                              startIcon={
                                toBackend ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  <SaveIcon />
                                )
                              }
                              onClick={toBackend ? null : handleSubmit}
                            >
                              {toBackend ? 'Saving...' : 'Save'}
                            </Button>
                            <Button
                              variant="contained"
                              disabled={toBackend}
                              style={{
                                borderRadius: '25px',
                                textTransform: 'capitalize',
                              }}
                              onClick={() => {
                                handleClose();
                                handleReset();
                              }}
                              startIcon={<CancelIcon />}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        }
                        title="Add Activity"
                      />
                      <Divider style={{ borderColor: '#888' }} />
                      <div
                        style={{
                          marginTop: '2%',
                          marginLeft: '2%',
                          paddingBottom: '3%',
                        }}
                      >
                        <Grid container spacing={2} columns={16}>
                          {text?.length > 0
                            ? text?.map((data, index) => (
                                <Grid item xs={4}>
                                  <div key={index}>
                                    <Typography
                                      variant="body1"
                                      className="modal-typo"
                                      gutterBottom
                                    >
                                      {data.label}
                                    </Typography>

                                    {data.type === 'textField' ? (
                                      <>
                                        <Field
                                          as={TextField}
                                          disabled={toBackend}
                                          id={`outlined-basic-${index}`}
                                          size="small"
                                          variant="outlined"
                                          name={data.name}
                                          inputProps={{
                                            maxLength: data.length,
                                          }}
                                          placeholder={data.placeholder}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleTxtChange(e);
                                          }}
                                          value={
                                            values.mailId === null ||
                                            values.mailId === ''
                                              ? ''
                                              : values[data.name]
                                          }
                                          sx={{ width: '12vw' }}
                                        />
                                        <ErrorMessage
                                          name={data.name}
                                          component="div"
                                          className="error"
                                          style={{
                                            color: 'red',
                                            marginTop: '1%',
                                            textAlign: 'left',
                                            marginLeft: '0%',
                                          }}
                                        />
                                      </>
                                    ) : data.type === 'dropdown' ? (
                                      <>
                                        <Tooltip
                                          title={
                                            !edit && data.name === 'status'
                                              ? 'Active'
                                              : edit
                                              ? data.name === 'status'
                                                ? typeof values[data.name] ===
                                                  'string'
                                                  ? values[data.name]
                                                  : values.status === 1
                                                  ? 'Active'
                                                  : 'Inactive'
                                                : values[data.name]
                                              : values[data.name]
                                          }
                                        >
                                          <Field
                                            name={data.name}
                                            render={({ field, form }) => (
                                              <Tooltip
                                                title={
                                                  !edit &&
                                                  data.name === 'status'
                                                    ? 'Active'
                                                    : edit
                                                    ? data.name === 'status'
                                                      ? typeof values[
                                                          data.name
                                                        ] === 'string'
                                                        ? values[data.name]
                                                        : values.status === 1
                                                        ? 'Active'
                                                        : 'Inactive'
                                                      : values[data.name]
                                                    : values[data.name]
                                                }
                                              >
                                                <Autocomplete
                                                  disableClearable
                                                  disabled={
                                                    toBackend ||
                                                    (!edit &&
                                                      data.name === 'status')
                                                  }
                                                  size="small"
                                                  id={`combo-box-demo-${index}`}
                                                  onChange={(event, value) =>
                                                    handleDropdownChange(
                                                      event,
                                                      value,
                                                      data.name
                                                    )
                                                  }
                                                  value={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit
                                                      ? data.name === 'status'
                                                        ? typeof values[
                                                            data.name
                                                          ] === 'string'
                                                          ? values[data.name]
                                                          : values.status === 1
                                                          ? 'Active'
                                                          : 'Inactive'
                                                        : values[data.name]
                                                      : values[data.name]
                                                  }
                                                  sx={{ width: '12vw' }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      placeholder={
                                                        data.placeholder
                                                      }
                                                    />
                                                  )}
                                                />
                                              </Tooltip>
                                            )}
                                          />
                                        </Tooltip>
                                        <ErrorMessage
                                          name={data.name}
                                          component="div"
                                          className="error"
                                          style={{
                                            color: 'red',
                                            marginTop: '1%',
                                            textAlign: 'left',
                                            marginLeft: '0%',
                                          }}
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      </div>
                    </Card>
                    <div>
                      <Grid container spacing={2} columns={32}></Grid>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
      {/* ---------------pass props value----------- */}
      <div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20%',
            }}
          >
            <FadeLoader color="#000440" loading={true} />
          </div>
        ) : (
          <div style={{ marginTop: '-0.5%' }}>
            <TableTemplate
              key={updatedKey}
              PageName={'Page List'}
              addButton={'Page'}
              SearchLabel={'Search Pages Here... '}
              header={headCells}
              rowsValue={SiteReport}
              handleAddPopupOpen={(val) => handleCrate(val)}
              userRole={Multisite[0]}
              handleActive={(val) => handleActivee(val)}
              // handleEditPopupOpen={SiteReport.some(item => item.status === 1)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
            />
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
          </div>
        )}
      </div>
    </div>
  );
}
