import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
// import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import ConstructionIcon from '@mui/icons-material/Construction';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Link, Stack, Typography } from '@mui/material';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import { useNavigate } from 'react-router';
import FadeLoader from 'react-spinners/FadeLoader';
import { paddedEncryptQuery } from '../security/Cipher';
const Accordian = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(
      props?.overview?.length === 0 || (!props?.overview && !props.siteId)
    );
  }, [props]);
  console.log(props, 'props');
  const {
    overview,
    TicketIsActive,
    siteId,
    specificYield,
    siteName,
    irradiation,
  } = props;
  const overviewData = overview[0];
  console.log(props);
  function navigateEncrpytedURL(URL) {
    let passSite = { id: siteId, name: siteName, irradiation: irradiation };
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }
  const handleAlarmsClick = () => {
    navigateEncrpytedURL('/menu/alarmsconfig');
  };
  const handleTicketClick = (flogCondition) => {
    navigateEncrpytedURL('/menu/ticket');
  };
  const handleDocumentClick = () => {
    navigateEncrpytedURL('/menu/document');
  };

  const handleEquipmentClick = () => {
    navigateEncrpytedURL('/menu/equipmentdetails');
  };
  const handleAnalytics = () => {
    navigateEncrpytedURL('/menu/view-analyst');
  };

  const HoverableIcon = ({ icon: Icon, text, style, mainStyle }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <div
        style={{ display: 'inline-block', position: 'relative', ...mainStyle }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon
          style={{
            color: '#0058ff',
            transition: 'transform 0.3s',
            transform: 'scale(1)',
          }}
          onMouseEnter={(e) => (e.target.style.transform = 'scale(1.2)')}
          onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
        />
        {isHovered && (
          <div
            style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              whiteSpace: 'nowrap',
              ...style,
            }}
          >
            {text}
          </div>
        )}
      </div>
    );
  };

  const renderInfo = (label1, value1, label2, value2) => (
    <div>
      <div style={{ textAlign: 'center', padding: 8 }}>
        <Typography
          style={{ lineHeight: '16px', fontSize: '0.75rem', fontWeight: '410' }}
        >
          {label1}
        </Typography>
        <Typography style={{ lineHeight: '16px', fontSize: '0.75rem' }}>
          {!value1 ? '-' : value1}
        </Typography>
      </div>
      <div style={{ textAlign: 'center', padding: 8 }}>
        <Typography
          style={{ lineHeight: '16px', fontSize: '0.75rem', fontWeight: '410' }}
        >
          {label2}
        </Typography>
        <Typography style={{ lineHeight: '16px', fontSize: '0.75rem' }}>
          {!value2 ? '-' : value2}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <Grid
          container
          spacing={0}
          sx={{ marginTop: '1px', '& > .MuiGrid-item': { paddingTop: 0 } }}
        >
          <Grid item xs={10} sx={{ paddingTop: '0' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: '20px',
              }}
            >
              {renderInfo(
                'Specific Yield (AC)',
                specificYield,
                'Inverter Capacity',
                overviewData?.invertersCapacity
              )}
              {renderInfo(
                'Today Energy',
                overviewData?.sumOfTodayEnergy,
                'CUF',
                overviewData?.cuf
              )}
              {renderInfo(
                'Total Energy',
                overviewData?.sumOfTotalEnergy,
                'Co2 Avoided',
                overviewData?.co2
              )}
              {renderInfo(
                'Active Power',
                overviewData?.sumOfActivePower,
                'Today Event',
                overviewData?.todayEvents
              )}
              {renderInfo(
                'Capacity',
                // `${overviewData?.installationCapacity} kWp`,
                `${overviewData?.installationCapacity} kWp`,
                'Total Events',
                overviewData?.totalEvents
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ alignItems: 'center', display: 'flex', paddingTop: '0' }}
          >
            <Stack direction="row" spacing={2} ml="auto" mr="auto">
              <div>
                <Link
                  underline="hover"
                  // onClick={handleClick}
                  onClick={() => handleAlarmsClick()}
                  style={{
                    cursor: 'pointer',
                    lineHeight: '16px',
                    fontSize: '0.80rem',
                  }}
                >
                  <HoverableIcon
                    // onClick={handleClick}
                    icon={AddAlertOutlinedIcon}
                    text="Alarms"
                    style={{ left: '-10px', bottom: '-20px' }}
                  />
                </Link>
              </div>
              <div>
                <Link
                  underline="hover"
                  onClick={() => handleEquipmentClick()}
                  style={{
                    cursor: 'pointer',
                    lineHeight: '16px',
                    fontSize: '0.80rem',
                  }}
                >
                  <HoverableIcon
                    icon={ConstructionIcon}
                    text="Equipment"
                    // text="EquipmentFilterTable"
                    style={{ left: '-20px', bottom: '-20px' }}
                  />
                </Link>
              </div>
              <div>
                <Link
                  underline="hover"
                  style={{
                    cursor: 'pointer',
                    lineHeight: '16px',
                    fontSize: '0.80rem',
                  }}
                  onClick={() => handleAnalytics()}
                >
                  <HoverableIcon
                    icon={AssessmentOutlinedIcon}
                    text="Analytics"
                    style={{
                      left: '-15px',
                      bottom: '-20px',
                      backgroundcolor: 'red',
                    }}
                    mainStyle={{ left: '3px' }}
                  />
                </Link>
              </div>
              {TicketIsActive && (
                <div>
                  <Link
                    underline="hover"
                    style={{
                      cursor: 'pointer',
                      lineHeight: '16px',
                      visibility: TicketIsActive ? 'visible' : 'hidden',
                      fontSize: '0.80rem',
                    }}
                    onClick={() => handleTicketClick()}
                  >
                    <HoverableIcon
                      icon={LocalActivityOutlinedIcon}
                      text="Tickets"
                      style={{ left: '-10px', bottom: '-20px' }}
                      mainStyle={{ left: '5px' }}
                    />
                  </Link>
                </div>
              )}
              <div>
                <Link
                  underline="hover"
                  style={{
                    cursor: 'pointer',
                    lineHeight: '16px',
                    fontSize: '0.80rem',
                  }}
                  onClick={() => handleDocumentClick()}
                >
                  <HoverableIcon
                    icon={TextSnippetOutlinedIcon}
                    text="Doucment"
                    style={{ left: '-10px', bottom: '-20px' }}
                    mainStyle={{ left: '5px' }}
                  />
                </Link>
              </div>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Accordian;
