import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  Backdrop,
  Divider,
  CircularProgress,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import FadeLoader from 'react-spinners/FadeLoader';
// ========Modal================

import { styled } from '@mui/material/styles';

/*------------api call-----------------*/
import {
  fetchEquipmentTable,
  putEquipmenttype,
  postEquipmentSave,
  Equipmentcategorydropdown,
  Equipmenttypedropdown,
  fetchEquipmentTableById,
} from '../../Api/EquipmenttypeApi';
import TableTemplate from '../../Template/TableTemplate';
import Download from '../../Template/Excel/Download';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import errorMsg from '../util/errorMessage.json';
import {
  fetchequipmentcategoryTable,
  putEquipmentcategory,
  postEquipmentcategorySave,
  Getdropdownvalue,
  postequipmentcategory,
  putequipmentcategory,
} from '../../Api/EquipmencategoryApi';

import { Equipmentcategory } from '../util/TextField';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { equipCategorySchema } from '../util/ValidateSchema';
import { Statusdropdown } from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import { CheckForLight } from '../util/common/CommanFunction';

export default function Equipmenthome({ EquipmentCategory }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [category, setCategory] = useState();
  const [equipment, setEquipment] = useState();
  const [editcall, seteditcall] = useState();
  const [activeFlag, setactiveFlag] = useState();
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [siteTab, setSiteTab] = useState('');
  const [SectorId, setSectorId] = useState([]);
  const [text, setTextField] = useState([]);
  const initalState = {
    categoryCode: '',
    categoryDescription: '',
    categoryGroup: '',
    equipmentCategory: '',
    status: '',
    sectorTypeId: '',
    sectorName: '',
  };
  const [stdField, setstdField] = useState(initalState);
  const initialSnack = { open: false, severity: '', message: '' };
  const [toBackend, setToBackend] = useState(false);
  const [snack, setSnack] = useState(initialSnack);
  const [statusDrop, setStatusDrop] = useState([]);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    getsectorlist();
    fetchStatus();
    let data = Equipmentcategory();
    setTextField(data);
    // fetchEquipmenttype();
    // fetchEquipmentcategory();
  }, []);

  const getsectorlist = async () => {
    try {
      let data = await Getdropdownvalue();
      if (Array.isArray(data)) {
        setSectorId(data);
      } else if (typeof data === 'object' && data !== null) {
        setSectorId([data]);
      }
    } catch (e) {
      console.error(e, 'errrrrror');
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  console.log(SectorId, 'ghjk');
  const handleChange = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };

  const handleChangeTxt = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };
  const handleDropdownChange = (event, value, name) => {
    setstdField({ ...stdField, [name]: value });
  };

  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async (values) => {
    let id = editcall?.categoryId;
    let sectorTypeIdFilter = SectorId?.filter((data) => {
      return data.sectorName === stdField.sectorName;
    });
    let status = statusDrop?.filter((data) => {
      return data.label === stdField.status;
    });
    let data = {
      categoryCode:
        stdField?.categoryCode === ''
          ? editcall.categoryCode
          : stdField?.categoryCode,
      categoryDescription:
        stdField?.categoryDescription === ''
          ? editcall.categoryDescription
          : stdField?.categoryDescription,
      lastUpdatedBy: sessionStorage.getItem('id'),
      categoryGroup:
        stdField?.categoryGroup === ''
          ? editcall.categoryGroup
          : stdField?.categoryGroup,
      equipmentCategory:
        stdField?.equipmentCategory === ''
          ? editcall.equipmentCategory
          : stdField?.equipmentCategory,
      status: edit
        ? typeof values.status === 'string'
          ? status[0].value
          : values.status
        : status[0].value,
      sectorTypeId:
        stdField.sectorName === ''
          ? editcall.sectorTypeId
          : sectorTypeIdFilter[0]?.sectorTypeId,
    };
    // console.log(data);
    try {
      setToBackend(true);
      let responsedata = await putequipmentcategory(data, id);
      // console.log(responsedata);
      const updatedTable = replaceObjectById(
        SiteEquipment,
        'categoryId',
        id,
        responsedata
      );
      // fetchData();
      setSnack(errorMsg.success);
      handleClose();
      setToBackend(false);
      return 1;
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.equipmntCategory);
      } else {
        setSnack(errorMsg.failure);
        console.log('equipment:', e.message);
      }
      setToBackend(false);
      return 0;
    }
  };

  const handleSave = async (value) => {
    console.log(stdField, 'stdField');
    let status = statusDrop?.filter((data) => {
      return data.label === stdField.status;
    });
    let sectorTypeIdFilter = SectorId?.filter((data) => {
      return data.sectorName === stdField.sectorName;
    });
    console.log(SectorId, 'tyujk');
    let data = {
      categoryCode: stdField?.categoryCode,
      categoryDescription: stdField?.categoryDescription,
      createdBy: sessionStorage.getItem('id'),
      categoryGroup: stdField?.categoryGroup,
      equipmentCategory: stdField?.equipmentCategory,
      status: 1,
      sectorTypeId: sectorTypeIdFilter[0]?.sectorTypeId,
    };
    console.log(data, 'data');
    try {
      setToBackend(true);
      const responseData = await postequipmentcategory(data);
      console.log(responseData);
      console.log();
      fetchData(data, 'ghjm');
      setSnack({
        ...snack,
        open: true,
        severity: 'success',
        message: responseData.statusDescription,
      });
      handleClose();
      setToBackend(false);
      return 1;
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.equipmntCategory);
      } else {
        setSnack(errorMsg.failure);
        console.log('equipment:', e.message);
      }
      setToBackend(false);
      return 0;
    }
  };

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setloading(true);
    try {
      const data = await fetchequipmentcategoryTable();
      // const reverse = [...data].reverse();
      if (Array.isArray(data)) {
        setSiteEquipment(data);
        setloading(false);
      } else if (typeof data === 'object' && data !== null) {
        setSiteEquipment([data]);
      }

      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };
  console.log(SiteEquipment, 'ghjkl;');

  /*---------------------------------- New Equipment Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall('');
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    seteditcall('');
    setEdit(false);
    setstdField(initalState);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  const statusvalue = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (categoryId) => {
    let filteredcategoryId = Object.keys(initalState).reduce((acc, key) => {
      if (categoryId.hasOwnProperty(key)) {
        acc[key] = categoryId[key];
      }
      return acc;
    }, {});
    seteditcall(categoryId);
    setstdField(filteredcategoryId);
    setEdit(true);
    setOpen(true);
  };

  const headCells = [
    {
      label: 'Sector Name',
      id: 'sectorName',
      view: true,
      default: true,
    },
    {
      label: 'Equipment Category',
      id: 'equipmentCategory',
      view: true,
      default: true,
    },
    {
      label: 'Category Code',
      id: 'categoryCode',
      view: false,
    },
    {
      label: 'Category Description',
      id: 'categoryDescription',
      view: true,
    },
    {
      label: 'CategoryGroup',
      id: 'categoryGroup',
      view: false,
    },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },

    {
      label: 'Created Date',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'Updated On',
      id: 'lastUpdatedDate',
      view: false,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];

  const handleDownloadTable = (val) => {
    Download(val, 'Equipment Category');
  };

  const tableValues = SiteEquipment.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = SiteEquipment.length;

  const Active = SiteEquipment.filter((site) => site.status == 1).length;

  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;

  const tabsData = [
    { name: 'All Category', value: 'all', badgeCount: Total },
    { name: 'Active Category', value: 'Active', badgeCount: Active },
    { name: 'Inactive Category', value: 'Inactive', badgeCount: Inactive },
  ];

  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'status':
        return statusDrop.map((option) => option.label);
      case 'sectorName':
        let name = SectorId?.map((option) => option.sectorName);
        return name;
      default:
    }
    return option;
  }

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Equipment Category', path: 'equipmentCategory' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <div>
            {/* ---------   -----save andupdate---- popup----------- */}
            <Formik
              key={edit ? 'edit' : 'create'}
              enableReinitialize={true}
              initialValues={stdField}
              validationSchema={equipCategorySchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = values;
                if (edit) {
                  console.log(values);
                  const result = await handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSave(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalSmall'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    s
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={
                              edit
                                ? 'Update Equipment Category'
                                : 'Add New Equipment Category'
                            }
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          <Card
                            sx={{
                              overflowY: 'visible',
                              marginLeft: '-1vw',
                              // paddingLeft: '-1vw',
                              // marginTop: '-2%',
                              // marginBottom: '-2%',
                              scrollBehavior: 'smooth',
                              scrollbarGutter: 'stable',
                              scrollbarWidth: 'thin',
                              '&::-webkit-scrollbar': {
                                width: '0.4em',
                              },
                              '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '20px',
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                background: '#555',
                              },
                            }}
                            elevation={0}
                          >
                            <CardContent sx={{ paddingTop: 0 }}>
                              {text?.length > 0 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    marginBottom: '2.2vh',
                                  }}
                                  className="modelGrid"
                                >
                                  <Grid container spacing={3} columns={32}>
                                    {text?.map((data, index) => {
                                      return (
                                        <>
                                          {data.type === 'dropdown' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit &&
                                                        data.name === 'status'
                                                      ? typeof values[
                                                          data.name
                                                        ] === 'string'
                                                        ? values[data.name]
                                                        : values.status === 1
                                                        ? 'Active'
                                                        : 'Inactive'
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Field
                                                    name={data.name}
                                                    render={({
                                                      field,
                                                      form,
                                                    }) => (
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit &&
                                                              data.name ===
                                                                'status'
                                                            ? typeof values[
                                                                data.name
                                                              ] === 'string'
                                                              ? values[
                                                                  data.name
                                                                ]
                                                              : values.status ===
                                                                1
                                                              ? 'Active'
                                                              : 'Inactive'
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Autocomplete
                                                          disabled={
                                                            toBackend ||
                                                            (!edit &&
                                                              data.name ===
                                                                'status')
                                                          }
                                                          disableClearable
                                                          options={getOptions(
                                                            data.name
                                                          )}
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) => {
                                                            handleDropdownChange(
                                                              event,
                                                              value,
                                                              data.name
                                                            );
                                                          }}
                                                          disablePortal
                                                          size="small"
                                                          value={
                                                            !edit &&
                                                            data.name ===
                                                              'status'
                                                              ? 'Active'
                                                              : edit &&
                                                                data.name ===
                                                                  'status'
                                                              ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values.status ===
                                                                  1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : values[
                                                                  data.name
                                                                ]
                                                          }
                                                          id={`combo-box-demo-${index}`}
                                                          sx={{ width: '15vw' }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                            />
                                                          )}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  />
                                                </Tooltip>
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : data.type === 'textField' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  as={TextField}
                                                  disabled={toBackend}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  inputProps={{
                                                    maxLength: data.length,
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleChangeTxt(e);
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={values[data.name]}
                                                  sx={{ width: '15vw' }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </div>
                              ) : null}
                            </CardContent>
                          </Card>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: '-0.5%' }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={'Equipment Category'}
              addButton={'Equipment Category'}
              SearchLabel={'Search category Here... '}
              header={headCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              handleChange={handleChangeTab}
              rawData={SiteEquipment}
              userRole={EquipmentCategory[0]}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No${
                siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''
              } Equipment category configured yet`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
