import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  Backdrop,
  Divider,
  CircularProgress,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import FadeLoader from 'react-spinners/FadeLoader';
// ========Modal================

import { styled } from '@mui/material/styles';

/*------------api call-----------------*/
import {
  fetchEquipmentTable,
  putEquipmenttype,
  postEquipmentSave,
  Equipmentcategorydropdown,
  Equipmenttypedropdown,
  fetchEquipmentTableById,
  CategorylistDropdown,
  Getdropdownvalue,
} from '../../Api/EquipmenttypeApi';
import TableTemplate from '../../Template/TableTemplate';
import Download from '../../Template/Excel/Download';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import errorMsg from '../util/errorMessage.json';
import { EquipmentType } from '../util/TextField';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { equipmentTypeSchema } from '../util/ValidateSchema';
import { Statusdropdown, fetchInstalltionCapacity } from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import { CheckForLight } from '../util/common/CommanFunction';

export default function Equipmenthome({ equipmentTypeConfiguration }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [category, setCategory] = useState();
  const [equipment, setEquipment] = useState();
  const [editcall, seteditcall] = useState();
  const [activeFlag, setactiveFlag] = useState();
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [siteTab, setSiteTab] = useState('');
  const [unitsIC, setUnitsIC] = useState([]);
  const [text, setTextField] = useState([]);
  const [SectorId, setSectorId] = useState([]);
  const initalState = {
    createdDate: '',
    lastUpdatedDate: '',
    equipmentCategory: '',
    createdBy: '',
    displayName: '',
    status: '',
    categoryId: '',
    manufacturer: '',
    equipmentTypeId: '',
    sectorName: '',
    equipmentType: '',
    remarks: '',
    equipmentTypeCode: '',
    lastUpdatedBy: '',
  };
  const [stdField, setstdField] = useState(initalState);
  const [statusDrop, setStatusDrop] = useState([]);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  console.log(SectorId);
  console.log(stdField);
  console.log(categoryvalue);
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    getsectorlist();
    fetchUnits();
    // fetchEquipmentcategory();
    // getEquipmentcategory();
  }, []);

  useEffect(() => {
    let data = EquipmentType();
    // console.log(data, "data");
    setTextField(data);
  }, []);
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };

  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, 'dataOutside');
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };
  const getsectorlist = async () => {
    try {
      let data = await Getdropdownvalue();
      if (Array.isArray(data)) {
        setSectorId(data);
      } else if (typeof data === 'object' && data !== null) {
        setSectorId([data]);
      }
    } catch (e) {
      console.error(e, 'errrrrror');
    }
  };
  useEffect(() => {
    if (stdField.sectorName !== '') {
      let NameID = SectorId?.filter((data) => {
        return data.sectorName === stdField?.sectorName;
      });
      console.log(NameID, 'NameID');
      getEquipmentcategory(NameID[0]?.sectorTypeId);
    }
  }, [stdField.sectorName]);
  const getEquipmentcategory = async (Id) => {
    try {
      let data = await CategorylistDropdown(Id);
      console.log(data, 'data');
      setCategoryvalue(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async (values) => {
    let id = editcall?.equipmentTypeId;
    console.log(id, 'id');
    let categorytype =
      stdField.equipmentCategory !== ''
        ? stdField.equipmentCategory
        : editcall?.equipmentCategory;
    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === categorytype;
    });
    let status = statusDrop?.filter((data) => {
      return data.label === stdField.status;
    });
    let equipmentType =
      stdField?.equipmentType !== ''
        ? stdField.equipmentType
        : editcall?.equipmentType;
    let manufacturer =
      stdField?.manufacturer !== ''
        ? stdField.manufacturer
        : editcall?.manufacturer;
    let remarks =
      stdField?.remarks !== '' ? stdField.remarks : editcall?.remarks;
    let EquipmentIdcategory =
      CategoryTypeIdFilter.length > 0
        ? CategoryTypeIdFilter[0].categoryId
        : editcall?.categoryId;
    let obj = {
      equipmentType: equipmentType,
      manufacturer: manufacturer,
      remarks: remarks,
      status: edit
        ? typeof values.status === 'string'
          ? status[0].value
          : values.status
        : status[0].value,
      categoryId: EquipmentIdcategory,
      lastUpdatedBy: sessionStorage.getItem('id'),
    };

    try {
      setToBackend(true);
      let responsedata = await putEquipmenttype(obj, id);
      const updatedTable = replaceObjectById(
        SiteEquipment,
        'equipmentTypeId',
        id,
        responsedata
      );
      // fetchData();
      setSnack(errorMsg.success);
      handleClose();
      setToBackend(false);
      setOpen(false);
      return 1;
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.equipmentType);
      } else {
        setSnack(errorMsg.failure);
        console.log('equipment:', e.message);
      }
      setToBackend(false);
      return 0;
    }
  };
  console.log(SectorId);
  const handleSave = async (values) => {
    let sectorTypeIdFilter = SectorId?.filter((data) => {
      return data.sectorName === stdField.sectorName;
    });
    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === stdField.equipmentCategory;
    });
    let status = statusDrop?.filter((data) => {
      return data.label === stdField.status;
    });
    let data = {
      sectorTypeId: sectorTypeIdFilter[0]?.sectorTypeId,
      equipmentType: stdField.equipmentType,
      createdBy: sessionStorage.getItem('id'),
      manufacturer: stdField?.manufacturer,
      remarks: stdField?.remarks,
      status: 1,
      categoryId: CategoryTypeIdFilter[0]?.categoryId,
    };
    console.log(data);
    try {
      setToBackend(true);
      const responseData = await postEquipmentSave(data);
      console.log(responseData);
      if (responseData.statusCode === 200) {
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: 'success',
          message: responseData.statusDescription,
        });
        handleClose();
        setOpen(false);
        setToBackend(false);
        return 1;
      }
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.equipmentType);
      } else {
        setSnack(errorMsg.failure);
      }
      console.error('Error:', e.response.status);
      console.log('equipment:', e.message);
      setToBackend(false);
      return 0;
    }
  };

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setloading(true);
    try {
      const data = await fetchEquipmentTable();
      if (Array.isArray(data)) {
        setSiteEquipment(data);
        setloading(false);
      } else if (typeof data === 'object' && data !== null) {
        setSiteEquipment([data]);
      }

      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchEquipmenttype = async () => {
    const data = await Equipmenttypedropdown();
    if (Array.isArray(data)) {
      // If data is an array, set tableValue to data directly
      setEquipmevalue(data);
    } else if (typeof data === 'object' && data !== null) {
      // If data is an object, wrap it in an array and set tableValue
      setEquipmevalue([data]);
    }
    console.log(data);
  };

  const fetchEquipmentcategory = async () => {
    const data = await Equipmentcategorydropdown();
    if (Array.isArray(data)) {
      setCategoryvalue(data);
    } else if (typeof data === 'object' && data !== null) {
      setCategoryvalue([data]);
    }
  };

  const Priority = [
    { label: 'High', value: 3 },
    { label: ' Medium', value: 2 },
    { label: 'Low', value: 1 },
  ];

  /*-------------------------report Name list dropdown --------------------------------------------*/

  //
  const handleactiveFlag = (data, event) => {
    setactiveFlag(event?.value);
  };

  const handleEquipmentvalue = (data, value) => {
    if (value === null) {
      setEquipment('');
    } else {
      setEquipment(value);
    }
  };

  const handleCategoryvalue = (data, value) => {
    if (value === null) {
      setCategory('');
    } else {
      setCategory(value);
    }
  };
  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'status':
        return statusDrop.map((option) => option.label);
      case 'equipmentCategory':
        let name = categoryvalue?.map((option) => option.equipmentCategory);
        return name;
      case 'units':
        let unit = unitsIC?.map((option) => option.units);
        return unit;
      case 'sectorName':
        return SectorId?.map((option) => option.sectorName);

      default:
    }
    return option;
  }
  const handleTxtChange = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };
  const handleDropdownChange = (event, value, name) => {
    console.log(value, name);
    setstdField({ ...stdField, [name]: value });
  };
  /*---------------------------------- New Equipment Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall('');
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    seteditcall([]);
    setstdField(initalState);
    setEdit(false);
    setCategoryvalue([]);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  const statusvalue = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (equipmenttypeId) => {
    let filteredequipmenttypeId = Object.keys(initalState).reduce(
      (acc, key) => {
        if (equipmenttypeId.hasOwnProperty(key)) {
          acc[key] = equipmenttypeId[key];
        }
        return acc;
      },
      {}
    );
    setstdField(filteredequipmenttypeId);
    seteditcall(equipmenttypeId);
    setEdit(true);
    setOpen(true);
  };

  const headCells = [
    {
      label: 'Equipment Type',
      id: 'equipmentType',
      view: true,
      default: true,
    },
    {
      label: 'Equipment Category',
      id: 'equipmentCategory',
      view: true,
      default: true,
    },
    {
      label: 'Manufacture',
      id: 'manufacturer',
      view: true,
      default: true,
    },
    {
      label: 'Remarks',
      id: 'remarks',
      view: false,
    },
    // {
    //   label: 'Manufacture',
    //   id: 'manufacturer',
    //   view: true
    // },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },
    {
      label: 'Created On',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'Updated On',
      id: 'lastUpdatedDate',
      view: false,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];

  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'EquipmentType');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const tableValues = SiteEquipment.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = SiteEquipment.length;

  const Active = SiteEquipment.filter((site) => site.status == 1).length;

  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Type', value: 'all', badgeCount: Total },
    { name: 'Active Type', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Type',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Equipment Type', path: 'equipmenttype' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            {/* ---------   -----save andupdate---- popup----------- */}

            <Formik
              key={edit ? 'edit' : 'create'}
              enableReinitialize={true}
              initialValues={stdField}
              validationSchema={equipmentTypeSchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = values;
                if (edit) {
                  console.log(values);
                  const result = await handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSave(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalSmall'}
                        // sx={{ bgcolor: 'background.paper' }}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    s
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={
                              edit
                                ? 'Update Equipment Type'
                                : 'Add New Equipment Type'
                            }
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          <Card
                            sx={{
                              overflowY: 'visible',
                              marginLeft: '-1vw',
                              scrollBehavior: 'smooth',
                              scrollbarGutter: 'stable',
                              scrollbarWidth: 'thin',
                              '&::-webkit-scrollbar': {
                                width: '0.4em',
                              },
                              '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '20px',
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                background: '#555',
                              },
                            }}
                            elevation={0}
                          >
                            <CardContent sx={{ paddingTop: 0 }}>
                              {text?.length > 0 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    marginBottom: '2.2vh',
                                  }}
                                  className="modelGrid"
                                >
                                  <Grid container spacing={3} columns={32}>
                                    {text?.map((data, index) => {
                                      return (
                                        <>
                                          {data.type === 'dropdown' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                      data.name === 'status'
                                                      ? 'Active'
                                                      : edit &&
                                                        data.name === 'status'
                                                        ? typeof values[
                                                          data.name
                                                        ] === 'string'
                                                          ? values[data.name]
                                                          : values.status === 1
                                                            ? 'Active'
                                                            : 'Inactive'
                                                        : values[data.name]
                                                  }
                                                >
                                                  <Field
                                                    name={data.name}
                                                    render={({
                                                      field,
                                                      form,
                                                    }) => (
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                            data.name === 'status'
                                                            ? 'Active'
                                                            : edit &&
                                                              data.name ===
                                                              'status'
                                                              ? typeof values[
                                                                data.name
                                                              ] === 'string'
                                                                ? values[
                                                                data.name
                                                                ]
                                                                : values.status ===
                                                                  1
                                                                  ? 'Active'
                                                                  : 'Inactive'
                                                              : values[data.name]
                                                        }
                                                      >
                                                        <Autocomplete
                                                          disabled={
                                                            toBackend ||
                                                            (!edit &&
                                                              data.name ===
                                                              'status') ||
                                                            (!edit &&
                                                              data.name === 'equipmentCategory'
                                                              && !values.sectorName)
                                                            ||
                                                            (edit &&
                                                              data.name ===
                                                              'sectorName')
                                                          }
                                                          disableClearable
                                                          options={getOptions(
                                                            data.name
                                                          )}
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) => {
                                                            handleDropdownChange(
                                                              event,
                                                              value,
                                                              data.name
                                                            );
                                                          }}
                                                          disablePortal
                                                          size="small"
                                                          value={
                                                            !edit &&
                                                              data.name ===
                                                              'status'
                                                              ? 'Active'
                                                              : edit &&
                                                                data.name ===
                                                                'status'
                                                                ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                  ? values[
                                                                  data.name
                                                                  ]
                                                                  : values.status ===
                                                                    1
                                                                    ? 'Active'
                                                                    : 'Inactive'
                                                                : values[
                                                                data.name
                                                                ]
                                                          }
                                                          id={`combo-box-demo-${index}`}
                                                          sx={{ width: '15vw' }}
                                                          ListboxProps={{
                                                            style: {
                                                              maxHeight:
                                                                '200px', // Set your desired height here
                                                            },
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                            />
                                                          )}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  />
                                                </Tooltip>
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : data.type === 'textField' ? (
                                            <>
                                              <Grid
                                                item
                                                xs={
                                                  data.label ===
                                                    'Equipment Type'
                                                    ? 12
                                                    : 10
                                                }
                                              >
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  as={TextField}
                                                  disabled={toBackend}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  inputProps={{
                                                    maxLength: data.length,
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleTxtChange(e);
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={values[data.name]}
                                                  sx={{ width: '15vw' }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : data.type === 'capacity' ? (
                                            <>
                                              <Grid item xs={12}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  disabled={toBackend}
                                                  as={TextField}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  type="number"
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleTxtChange(e);
                                                  }}
                                                  placeholder={data.placeholder}
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Autocomplete
                                                        size="small"
                                                        disableClearable
                                                        options={getOptions(
                                                          data.option
                                                        )}
                                                        onChange={(
                                                          event,
                                                          value
                                                        ) =>
                                                          handleDropdownChange(
                                                            event,
                                                            value,
                                                            'units'
                                                          )
                                                        }
                                                        sx={{
                                                          width: '12vw',
                                                        }}
                                                        ListboxProps={{
                                                          style: {
                                                            maxHeight: '200px',
                                                          },
                                                        }}
                                                        value={values['units']}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            sx={{
                                                              fieldset: {
                                                                border: 'none',
                                                                boxShadow:
                                                                  'none',
                                                              },
                                                            }}
                                                            {...params}
                                                            placeholder={
                                                              data.placeholder
                                                            }
                                                          />
                                                        )}
                                                      />
                                                    ),
                                                    style: {
                                                      paddingRight: 0,
                                                    },
                                                  }}
                                                  value={
                                                    edit
                                                      ? values[data.name]
                                                      : null
                                                  }
                                                  sx={{
                                                    width: '18vw',
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name={'units'}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : data.type === 'number' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  as={TextField}
                                                  disabled={toBackend}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  type={data.type}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleTxtChange(e);
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={
                                                    values[data.name] === null
                                                      ? ''
                                                      : values[data.name]
                                                  }
                                                  sx={{ width: '12vw' }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </div>
                              ) : null}
                            </CardContent>
                          </Card>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: '-0.5%' }}>
            <TableTemplate
              PageName={'Equipment Type'}
              addButton={'Equipment Type'}
              SearchLabel={'Search Equipment Here... '}
              header={headCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              handleChange={handleChangeTab}
              rawData={SiteEquipment}
              userRole={equipmentTypeConfiguration[0]}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No${siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''
                } Equipment type configured yet`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
