import * as XLSX from 'xlsx';


// ------------------------ coulmns format ------------------------------
// const columns = [
//   { "SequenceNo.": "", "Work_Description": "","Verification":"", "Field":"", "Remarks":"" }
// ];

const Download = async (columns, fileName) => {
  const withoutNull = columns.map(data => {
    let newItem = { ...data };
    for (let key in newItem) {
      if (newItem[key] === undefined || newItem[key] === null) {
        newItem[key] = 0;
      }
    }
    return newItem;
  })
  const worksheet = XLSX.utils.json_to_sheet(withoutNull);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // // Generate a download link for the Excel file
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.xlsx`);
  console.log(link);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return 1;
}
export default Download;
