import axios from 'axios';

export const PostPassReset = async (obj) => {
  try {
    let response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_RESET
      }/${sessionStorage.getItem('id')}`,
      obj
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const ForgotPassAPI = async (obj) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FORGOT_PASS}`,
      obj
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const ChangePassAPI = async (id, obj) => {
  try {
    let response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PASS_CHANGE}/${id}`,
      obj
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const PostChangePassAPI = async (obj) => {
  try {
    let response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_CHANGE_PASS
      }/${sessionStorage.getItem('id')}`,
      obj
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};
