import axios from 'axios';

export const getCompanyList = async (companyID) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_COMPANY_LIST}/${companyID}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCompanyUpdateList = async (data, companyID) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_COMPANY_UPDATE}/${companyID}`,
      data
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    throw err;
  }
};
