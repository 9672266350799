import axios from 'axios';

export const Statusdropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_STATUS_DROPDOWN}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const CountryDropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_COUNTRY_DROPDOWN}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const StateDropdown = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_STATE_DROPDOWN}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const SiteNamelist = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_NAME
      }/${sessionStorage.getItem('customerId')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchInstalltionCapacity = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_INSTALLATION_CAPICITY}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchInverterCapacityUnits = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_INVERTERCAPACITY_UNITS}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const categoryByCustomerId = async (customerId) => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_CATEGORY_BY_CUSTOMERID}/${customerId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  } 
}
export const Getdropdownvalue = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_Sector_dropdown_SectortypeList}`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchSiteDropdownByUser = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_CHARTS_SITE_LIST
      }/${sessionStorage.getItem('id')}`
    );
    console.log(response, 'resssss');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchEquipDropdownBySite = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUPBYSITE}/${id}`
    );
    console.log(response, 'resssss');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchParamsDropdownByLogger = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_CHARTS_PARAMS_LIST}`,
      data
    );
    console.log(response, 'resssss');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
