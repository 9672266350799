import axios from 'axios';

export const taskGetAllUser = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_TASK_ALLUSERS}`,
      {
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_TASK_AUTH_ID}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const taskGetAllProjects = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_TASK_ALLPROJECTS}?page-size=5000`,
      {
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_TASK_AUTH_ID}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const taskGetUserValue = async (userName, fromDate, toDate) => {
  try {
    const baseURL = `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_USERNAME_BASED}`;
    const headers = {
      'x-api-key': `${process.env.REACT_APP_API_TASK_AUTH_ID}`,
    };
    const url = userName
      ? `${baseURL}?userName=${userName}&dateRangeStart=${fromDate}&dateRangeEnd=${toDate}`
      : `${baseURL}?dateRangeStart=${fromDate}&dateRangeEnd=${toDate}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const taskGetProjectValue = async (projectName, fromDate, toDate) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_PROJECTNAME_BASED}?projectName=${projectName}&dateRangeStart=${fromDate}&dateRangeEnd=${toDate}`,
      {
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_TASK_AUTH_ID}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
