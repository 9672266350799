import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  Backdrop,
  Divider,
  CircularProgress,
  IconButton,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
// import { ErrorMessage, Field, } from "formik";
// import { Field , ErrorMessage} from "formik";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FadeLoader from 'react-spinners/FadeLoader';
// ========Modal================

import { styled } from '@mui/material/styles';

/*------------api call-----------------*/
import {
  fetchEquipmentTable,
  putEquipmenttype,
  postEquipmentSave,
  Equipmentcategorydropdown,
  Equipmenttypedropdown,
  fetchEquipmentTableById,
  CategorylistDropdown,
  //   Getdropdownvalue,
} from '../../Api/EquipmenttypeApi';
import { Equipmentsitedropdowns } from '../../Api/EquipmentApi';
import { Getdropdownvalue } from '../../Api/MapsiteApi';
import CustomBreadcrumbs from '../util/components/CustomBread';
import Download from '../../Template/Excel/Download';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import errorMsg from '../util/errorMessage.json';
import { EquipmentType } from '../util/TextField';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { equipmentTypeSchema } from '../util/ValidateSchema';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Statusdropdown, fetchInstalltionCapacity } from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CheckForLight } from '../util/common/CommanFunction';
import { Field } from 'formik';

export default function Equipmenthome({ dailyGeneration }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  console.log(SiteEquipment, 'SiteEquipment');
  const [equipmentvalue, setEquipmentValue] = useState([]);
  console.log(equipmentvalue, 'equipmentvalue');
  const [selectedDate, setSelectedDate] = useState(null);
  const [equipment, setEquipment] = useState('');
  const [toBackend, setToBackend] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editlog, seteditlog] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const [siteName, setSiteNameList] = useState([]);
  const [sitedropvalue, setSitedropvalue] = useState([]);
  const [remarks, setRemarks] = useState(['']);
  const [remarkFields, setRemarkFields] = useState([{ remark: '' }]);
  const [sitestatus, setSitestatus] = useState('');
  const remarkRefs = useRef([]);
  const [category, setCategory] = useState();
  const [editcall, seteditcall] = useState();
  const [activeFlag, setactiveFlag] = useState();
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [siteTab, setSiteTab] = useState('');
  const [unitsIC, setUnitsIC] = useState([]);
  const [text, setTextField] = useState([]);
  const [SectorId, setSectorId] = useState([]);
  const initalState = {
    createdDate: '',
    lastUpdatedDate: '',
    equipmentCategory: '',
    createdBy: '',
    displayName: '',
    status: '',
    categoryId: '',
    equipmentTypeId: '',
    equipmentType: '',
    remarks: '',
    equipmentTypeCode: '',
    lastUpdatedBy: '',
  };
  const [stdField, setStdField] = useState([initalState]);
  const [statusDrop, setStatusDrop] = useState([]);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  console.log(SectorId);
  console.log(stdField);
  console.log(categoryvalue);

  const refs = useRef([]);
  const [fields, setFields] = useState([{ id: 1 }]);

  const addField = () => {
    setFields([...fields, { id: fields.length + 1 }]);
  };

  /*--------------------- Accept Numeric Only ---------------------*/
  const handleInput = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, '');
    if (input.length > 15) {
      input = input.slice(0, 15);
    }
    event.target.value = input;
  };

  //   const getEquipment = async (id) => {
  //     console.log(id);
  //     const data = await Equipmentsitedropdowns(id);

  //     setEquipmentValue(data);
  //   };
  //   console.log(equipmentvalue, "jhdslh");
  /*----------------------------Fields-------------------------------*/
  const [fieldSets, setFieldSets] = useState([
    { equipmentName: '', remarks: [''] },
  ]);
  //   const [fieldSets, setFieldSets] = useState([]);

  const [selectedSiteName, setSelectedSiteName] = useState(null);
  //   const handleInputChange = (index, field, event) => {
  //     const newFieldSets = [...fieldSets];
  //     newFieldSets[index][field] = event.target.value;
  //     setFieldSets(newFieldSets);
  //   };

  //   const handleKeyPress = (event) => {
  //     if (event.key === "Enter") {
  //       addFieldSet();
  //     }
  //   };

  //   const handleSite = (data, event) => {
  //     setSitestatus(data.target.innerHTML);
  //     const siteName = siteIdName.find(
  //       (item) => item.siteName === data.target.innerHTML
  //     );
  //     if (siteName) {
  //       getEquipment(siteName.siteId);
  //     }
  //   };
  const handleSite = async (event, value) => {
    setSitestatus(value); // Update the selected site name
    const site = siteIdName.find((item) => item.siteName === value);
    if (site) {
      const equipmentData = await Equipmentsitedropdowns(site.siteId);
      setEquipmentValue(equipmentData);
      const newFieldSets = equipmentData.map((equipment, index) => ({
        equipmentName: equipment.displayName,
        value: '',
        // remark: "",
        remarks: fieldSets[index]?.remarks || [''],
      }));
      setFieldSets(newFieldSets);
    }
  };
  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(selectedDate);
    console.log(selectedDate);
  };

  const getEquipment = async (id) => {
    const data = await Equipmentsitedropdowns(id);
    setEquipmentValue(data);
    // return(data)
  };
  const handleInputChange = (index, field, event) => {
    const newFieldSets = [...fieldSets];
    newFieldSets[index][field] = event.target.value;
    setFieldSets(newFieldSets);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addFieldSet();
    }
  };

  const addFieldSet = () => {
    setFieldSets([...fieldSets, { equipmentName: '', remarks: [''] }]);
  };

  const handleRemarkChange = (fieldSetIndex, remarkIndex, value) => {
    const newFieldSets = [...fieldSets];
    if (!newFieldSets[fieldSetIndex].remarks) {
      newFieldSets[fieldSetIndex].remarks = [];
    }
    newFieldSets[fieldSetIndex].remarks[remarkIndex] = value;
    setFieldSets(newFieldSets);
  };
  const addRemark = (fieldSetIndex) => {
    const newFieldSets = [...fieldSets];
    if (!newFieldSets[fieldSetIndex].remarks) {
      newFieldSets[fieldSetIndex].remarks = [];
    }
    newFieldSets[fieldSetIndex].remarks.push('');
    setFieldSets(newFieldSets);
  };
  const removeRemark = (fieldSetIndex, remarkIndex) => {
    const newFieldSets = [...fieldSets];
    newFieldSets[fieldSetIndex].remarks.splice(remarkIndex, 1);
    if (newFieldSets[fieldSetIndex].remarks.length === 0) {
      newFieldSets[fieldSetIndex].remarks = [''];
    }
    setFieldSets(newFieldSets);
  };
  const handleSiteNameChange = (event, newValue) => {
    setSelectedSiteName(newValue);
    if (newValue) {
      setFieldSets([{ equipmentName: '', value: '', remark: '' }]);
    } else {
      setFieldSets([]);
    }
  };

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    // getsectorlist();
    fetchUnits();
    // fetchEquipmentcategory();
    // getEquipmentcategory();
    getsitelist();
    // fetchsitedrop();
    // fetchSitenameList();
  }, []);

  useEffect(() => {
    let data = EquipmentType();
    // console.log(data, "data");
    setTextField(data);
  }, []);
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };

  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, 'dataOutside');
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getsitelist = async () => {
    try {
      let data = await Getdropdownvalue();
      // console.log(data,"sectorData");
      if (Array.isArray(data)) {
        setSiteId(data);

        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setSiteId([data]);
      }
      setLoading(false);
    } catch (e) {
      console.error(e, 'errrrrror');
    }
  };
  console.log(siteIdName, 'siteIdName');

  const getsectorlist = async () => {
    try {
      let data = await Getdropdownvalue();
      if (Array.isArray(data)) {
        setSectorId(data);
      } else if (typeof data === 'object' && data !== null) {
        setSectorId([data]);
      }
    } catch (e) {
      console.error(e, 'errrrrror');
    }
  };
  useEffect(() => {
    if (stdField.sectorName !== '') {
      let NameID = SectorId?.filter((data) => {
        return data.sectorName === stdField?.sectorName;
      });
      console.log(NameID, 'NameID');
      getEquipmentcategory(NameID[0]?.sectorTypeId);
    }
  }, [stdField.sectorName]);
  const getEquipmentcategory = async (Id) => {
    try {
      let data = await CategorylistDropdown(Id);
      console.log(data, 'data');
      setCategoryvalue(data);
    } catch (e) {
      console.error(e);
    }
  };

  /*------------------------- handleSave ---------------------------*/
  const handleSave = async (values) => {
    let sectorTypeIdFilter = SectorId?.filter((data) => {
      return data.sectorName === stdField.sectorName;
    });
    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === stdField.equipmentCategory;
    });
    let status = statusDrop?.filter((data) => {
      return data.label === stdField.status;
    });

    const equipmentDetails = fieldSets.map((fieldSet) => ({
      equipmentName: fieldSet.equipmentName,
      value: fieldSet.value,
    }));

    let data = {
      sectorTypeId: sectorTypeIdFilter[0]?.sectorTypeId,
      equipmentType: stdField.equipmentType,
      createdBy: sessionStorage.getItem('id'),
      remarks: stdField?.remarks,
      status: 1,
      categoryId: CategoryTypeIdFilter[0]?.categoryId,
      equipmentDetails, // Include fieldSets data
    };
    console.log('Saved Data:', data);
    try {
      setToBackend(true);
      const responseData = await postEquipmentSave(data);
      console.log(responseData);
      if (responseData === 200) {
        fetchData();
        setSnack(errorMsg.success);
        handleClose();
        setOpen(false);
        setToBackend(false);
        return 1;
      }
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.displayName);
      } else {
        setSnack(errorMsg.failure);
      }
      console.error('Error:', e.response.status);
      console.log('equipment:', e.message);
      setToBackend(false);
      return 0;
    }
  };

  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async (values) => {
    let id = editcall?.equipmentTypeId;
    console.log(id, 'id');
    let categorytype =
      stdField.equipmentCategory !== ''
        ? stdField.equipmentCategory
        : editcall?.equipmentCategory;
    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === categorytype;
    });
    let status = statusDrop?.filter((data) => {
      return data.label === stdField.status;
    });
    let equipmentType =
      stdField?.equipmentType !== ''
        ? stdField.equipmentType
        : editcall?.equipmentType;
    let manufacturer =
      stdField?.manufacturer !== ''
        ? stdField.manufacturer
        : editcall?.manufacturer;
    let remarks =
      stdField?.remarks !== '' ? stdField.remarks : editcall?.remarks;
    let EquipmentIdcategory =
      CategoryTypeIdFilter.length > 0
        ? CategoryTypeIdFilter[0].categoryId
        : editcall?.categoryId;
    let obj = {
      equipmentType: equipmentType,
      manufacturer: manufacturer,
      remarks: remarks,
      status: edit
        ? typeof values.status === 'string'
          ? status[0].value
          : values.status
        : status[0].value,
      categoryId: EquipmentIdcategory,
      lastUpdatedBy: sessionStorage.getItem('id'),
    };

    try {
      setToBackend(true);
      let responsedata = await putEquipmenttype(obj, id);
      const updatedTable = replaceObjectById(
        SiteEquipment,
        'equipmentTypeId',
        id,
        responsedata
      );
      // fetchData();
      setSnack(errorMsg.success);
      handleClose();
      setToBackend(false);
      setOpen(false);
      return 1;
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.equipmentType);
      } else {
        setSnack(errorMsg.failure);
        console.log('equipment:', e.message);
      }
      setToBackend(false);
      return 0;
    }
  };
  console.log(SectorId);

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchEquipmentTable();
      if (Array.isArray(data)) {
        setSiteEquipment(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setSiteEquipment([data]);
      }
      setLoading(false);
      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };

  //   const handleSite = (data, event) => {
  //     setSitestatus(data.target.innerHTML);
  //     let siteName = siteIdName?.filter((data) => {
  //       return data.siteName === sitestatus;
  //     });
  //     console.log(siteName);
  //     console.log(siteName[0]?.siteId);
  //     getEquipment(siteName[0]?.siteId);
  //   };
  //   console.log(sitestatus, "sitejkhkle");

  //
  const handleactiveFlag = (data, event) => {
    setactiveFlag(event?.value);
  };

  //   const handleEquipmentvalue = (data, value) => {
  //     if (value === null) {
  //       setEquipment("");
  //     } else {
  //       setEquipment(value);
  //     }
  //   };

  const handleCategoryvalue = (data, value) => {
    if (value === null) {
      setCategory('');
    } else {
      setCategory(value);
    }
  };
  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'status':
        return statusDrop.map((option) => option.label);
      case 'equipmentCategory':
        let name = categoryvalue?.map((option) => option.equipmentCategory);
        return name;
      case 'units':
        let unit = unitsIC?.map((option) => option.units);
        return unit;
      case 'sectorName':
        return SectorId?.map((option) => option.sectorName);

      default:
    }
    return option;
  }
  const handleTxtChange = (e) => {
    setStdField({ ...stdField, [e.target.name]: e.target.value });
  };
  const handleDropdownChange = (event, value, name) => {
    console.log(value, name);
    setStdField({ ...stdField, [name]: value });
  };
  /*---------------------------------- New Equipment Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall('');
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    seteditcall([]);
    setStdField(initalState);
    setEdit(false);
    setEquipment('');
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  const statusvalue = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (equipmenttypeId) => {
    let filteredequipmenttypeId = Object.keys(initalState).reduce(
      (acc, key) => {
        if (equipmenttypeId.hasOwnProperty(key)) {
          acc[key] = equipmenttypeId[key];
        }
        return acc;
      },
      {}
    );
    setStdField(filteredequipmenttypeId);
    seteditcall(equipmenttypeId);
    setEdit(true);
    setOpen(true);
    setEquipment(equipmenttypeId.displayName);
  };

  const headCells = [
    {
      label: 'Equipment Type',
      id: 'equipmentType',
      view: true,
      default: true,
    },
    {
      label: 'Equipment Category',
      id: 'equipmentCategory',
      view: true,
      default: true,
    },
    {
      label: 'Manufacture',
      id: 'manufacturer',
      view: true,
      default: true,
    },
    {
      label: 'Remarks',
      id: 'remarks',
      view: false,
    },
    // {
    //   label: 'Manufacture',
    //   id: 'manufacturer',
    //   view: true
    // },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },
    {
      label: 'Created On',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'Updated On',
      id: 'lastUpdatedDate',
      view: false,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];

  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'EquipmentType');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const tableValues = SiteEquipment.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = SiteEquipment.length;

  const Active = SiteEquipment.filter((site) => site.status == 1).length;

  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Type', value: 'all', badgeCount: Total },
    { name: 'Active Type', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Type',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Daily Generation Report', path: 'dgr' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <div>
            <Box
              sx={{
                margin: '0 0.5% 0 0.5%',
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  mr: 0,
                  mb: 1,
                  mt: 2,
                  borderRadius: 0,
                  width: '100%',
                  height: '7.5vh',
                },
              }}
            >
              <Paper
                elevation={0}
                style={{
                  width: '100%',
                  borderRadius: '2px 2px 0px 0px',
                  userSelect: 'none',
                }}
                component="div"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '11px 0 0 0',
                    }}
                  >
                    <CustomBreadcrumbs
                      paths={paths || []}
                      separatorSize="18px"
                      fontSize="14px"
                    />
                  </div>
                </div>
              </Paper>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  m: 1,
                  width: '101%',
                  height: '15vh',
                },
              }}
            >
              <Paper elevation={0}>
                <div>
                  <Grid
                    container
                    spacing={0}
                    columns={20}
                    style={{ marginLeft: '3%', marginTop: '2%' }}
                  >
                    <div>
                      <Grid item xs={10}>
                        <Typography className="modal-typo">
                          Site Name
                        </Typography>
                        <div style={{ marginTop: '5%' }}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={siteIdName.map(
                              (option) => option.siteName
                            )}
                            defaultValue={edit ? edit.siteName : null}
                            onChange={(data, event) => handleSite(data, event)}
                            // value={site}
                            sx={{ width: '14vw' }}
                            ListboxProps={{
                              style: {
                                maxHeight: '200px', // Set your desired height here
                              },
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{}}
                                placeholder="Select  Site ..."
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </div>
                    <div style={{ marginLeft: '5%' }}>
                      <Grid item xs={14}>
                        <Typography
                          className="modal-typo"
                          sx={{ width: '14vw' }}
                        >
                          Value
                        </Typography>
                        <div style={{ marginTop: '3%' }}>
                          <TextField
                            as={TextField}
                            size="small"
                            variant="outlined"
                            name={'remarks'}
                            sx={{ width: '19vw' }}
                          />
                        </div>
                      </Grid>
                    </div>

                    <div style={{ marginLeft: '30vw' }}>
                      <Grid item xs={6}></Grid>
                      <Stack
                        spacing={2}
                        direction="row"
                        style={{ marginLeft: '35px' }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            borderRadius: '25px',
                            textTransform: 'capitalize',
                          }}
                          s
                          startIcon={
                            toBackend ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <SaveIcon />
                            )
                          }
                          onClick={handleSave}
                        >
                          {toBackend ? 'Saving...' : 'Save'}
                        </Button>

                        <Button
                          variant="contained"
                          disabled={toBackend}
                          style={{
                            borderRadius: '25px',
                            textTransform: 'capitalize',
                          }}
                          onClick={() => {
                            handleClose();
                            // handleReset();
                          }}
                          startIcon={<CancelIcon />}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </div>
                  </Grid>
                </div>
              </Paper>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': { m: 1, width: '101%', height: '45vh' },
              }}
            >
              <Paper elevation={0}></Paper>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}
