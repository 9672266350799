import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useUserContext } from '../Auth/UserContext';

import TableTemplate from '../../Template/TableTemplate';
import {
  fetchsectorTable,
  Getdropdownsectorlist,
  Getdropdowndata,
  putupdatesectorvalue,
  postsectordata,
  // fetchStatusTable,
} from '../../Api/SectorApi';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  fetchStatusTable,
  fetchStatusTableById,
  fetchStatusTableByStatusId,
  GetDreviedDrop,
  postStatus,
  PostStatusConfig,
  putStatus,
  PutStatusConfig,
} from '../../Api/StatusApi';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import Autocomplete from '@mui/material/Autocomplete';
import { Card, CardContent, CardHeader, CircularProgress, Divider, Tooltip } from '@mui/material';
import Download from '../../Template/Excel/Download';
import { Statusconfig, StatusMaster } from '../util/TextField';
import { StatusConfigSchema, StatusSchema } from '../util/ValidateSchema';
import { Statusdropdown } from '../../Api/CommonApi';
import CustomSnackbar from '../util/components/CustomSnackbar';
import replaceObjectById from '../util/StateUpdater';
import errorMsg from '../util/errorMessage.json';
import { GetCustomerdropdowndata } from '../../Api/UserConfigurationAPI';
import { useLocation } from 'react-router-dom';
import { CheckForLight, removeQuestionMark } from '../util/common/CommanFunction';
import { decryptPaddedQuery, queryDecrypt } from '../util/security/Cipher';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const headCells = [
  {
    label: 'Status Name',
    id: 'statusMasterName',
    view: true,
    default: true,
  },
  {
    label: 'Status Code',
    id: 'statusCode',
    view: true,
    default: true,
  },
  {
    label: 'Derived Status',
    id: 'derivedStatusName',
    view: true,
    default: true,
  },
  {
    label: 'Status',
    id: 'statusMessage',
    view: true,
    default: true,
  },
  {
    label: 'Created On',
    id: 'createdDate',
    view: false,
  },
  {
    label: 'Updated On',
    id: 'lastUpdatedDate',
    view: true,
  },
  {
    label: 'Status Flag',
    id: 'status',
    view: true,
    default: true,
  },
  {
    label: 'Action',
    id: 'action',
    view: true,
    default: true,
  },
];

const StatusMasterList = ({ StatusConfiguration }) => {
  const { userContextValue } = useUserContext();
  const { userMapDetails } = userContextValue || {};
  const [loading, setLoading] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState();
  const [customerId, setCustomerId] = useState([]);

  const initalConfig = {
    createdDate: '',
    lastUpdatedDate: '',
    createdBy: '',
    status: '',
    statusMessage: '',
    lastUpdatedBy: '',
    statusMasterId: '',
    derivedStatusName: '',
    statusCode: '',
    derivedStatus: '',
    description: '',
  };
  const [toBackend, setToBackend] = useState(false);
  const [statusDrop, setStatusDrop] = useState([]);
  const [statusName, setstatusName] = useState('');
  const [Derived, setDerived] = useState([]);
  const [siteTab, setSiteTab] = React.useState('');
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const [config, setConfig] = useState(false);
  const [configTo, setConfigTo] = useState(null);
  const [ConfigVal, setConfigVal] = useState(initalConfig);
  const [configField, setConfigField] = useState([]);

  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);

  useEffect(() => {
    let config = StatusMaster();
    setConfigField(config);
  }, []);

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchDerived();
  }, [userMapDetails]);
  // console.log(SectorConfigurations, 'SectorConfigurations');
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  console.log(params, 'paramas');
  /*---------------------------------------Table Fetch data ------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchStatusTableByStatusId(params.statusId);
      if (Array.isArray(data)) {
        setTableValue(data);
      } else if (typeof data === 'object' && data !== null) {
        setTableValue([data]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /*-----------------------------Drop Down ----------------------------------*/
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchDerived = async () => {
    const data = await GetDreviedDrop();
    if (Array.isArray(data)) {
      setDerived(data);
    } else if (typeof data === 'object' && data !== null) {
      setDerived([data]);
    }
  };
  /*------------------------------------- Onchange Values --------------------*/

  const handleConfig = (val) => {
    setConfig(!config);
    setConfigTo(val);
    setConfigVal(initalConfig);
    setEdit(false);
    setEditValue([]);
    setOpen(false);
  };

  const handleChangeTxt = (e, state, set) => {
    set({ ...state, [e.target.name]: e.target.value });
  };
  const handleDropdownChange = (event, value, name, state, set) => {
    set({ ...state, [name]: value });
  };

  const handleCrate = () => {
    setConfig(true);
  };
  /*--------------------------------------- Edit popup Open----------------------------*/
  const handleEditOpen = (sectorId) => {
    let filteredsectorId = Object.keys(initalConfig).reduce((acc, key) => {
      if (sectorId.hasOwnProperty(key)) {
        acc[key] = sectorId[key];
      }
      return acc;
    }, {});
    setConfig(true);
    setEditValue(sectorId);
    const fnalValue = {
      ...filteredsectorId,
      derivedStatus: filteredsectorId.derivedStatusName,
    };

    setConfigVal(fnalValue);
    setstatusName();
    setEdit(true);
    setOpen(true);
  };

  /*------------------------------------------Update Sector ---------------------------*/
  const handleUpdate = async (values) => {
    console.log(values);
    let Ds = Derived?.filter((data) => {
      return data.derivedStatusName === values.derivedStatus;
    });
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    const payLoad = {
      statusMessage: values.statusMessage,
      derivedStatus: Ds[0].derivedStatusId,
      description: values.description,
      statusMasterId: values.statusMasterId,
      statusCode: values.statusCode,
      status: edit
        ? typeof values.status === 'string'
          ? status[0].value
          : values.status
        : status[0].value,
      lastUpdatedBy: sessionStorage.getItem('id'),
    };
    console.log(payLoad, 'payload');
    try {
      setToBackend(true);
      const response = await PutStatusConfig(payLoad, editValue.statusMapingId);
      const updatedTable = replaceObjectById(
        tableValue,
        'statusMasterId',
        editValue.statusMapingId,
        response
      );
      handleConfig();
      fetchData();
      setSnack(errorMsg.success);
      setToBackend(false);
      return 1;
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('status:', error.message);
      setToBackend(false);
      return 0;
    }
  };

  const handleConfigPost = async (values) => {
    let Ds = Derived?.filter((data) => {
      return data.derivedStatusName === values.derivedStatus;
    });
    const payLoad = {
      statusMessage: values.statusMessage,
      derivedStatus: Ds[0].derivedStatusId,
      description: values.description,
      statusMasterId: params.statusId,
      statusCode: values.statusCode,
      status: 1,
      createdBy: sessionStorage.getItem('id'),
    };
    console.log(payLoad, 'payload');
    try {
      setToBackend(true);
      const response = PostStatusConfig(payLoad);
      handleConfig();
      fetchData();
      setSnack(errorMsg.success);
      setToBackend(false);
      return 1;
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('status:', error.message);
      setToBackend(false);
      return 0;
    }
  };
  /*-----------------------------------------Table Download ----------------------------*/
  const handleDownloadTable = (val) => {
    Download(val, 'StatusConfig');
  }; // in val we can modify which coulmn need to download as of now i download entire value.
  const tableValues = tableValue.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };

  const Total = tableValues.length;

  const Active = tableValues.filter((site) => site.status == 1).length;

  const Inactive = tableValues.filter((site) => site.status == 0).length;

  const tabsData = [
    { name: 'All Status', value: 'all', badgeCount: Total },
    { name: 'Active Status', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Status',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Status Master', path: -1 },
    { label: params.StatusName, path: 'statusMaster' },
  ];
  function getStatusNameById(statusArray, id) {
    for (let i = 0; i < statusArray.length; i++) {
      if (statusArray[i].derivedStatusId === id) {
        return statusArray[i].derivedStatusName;
      }
    }
    return ' '; // Return this if the ID doesn't match any status
  }

  return (
    <div>
      <CustomSnackbar
        open={snack.open}
        onClose={handleSnackClose}
        autoHideDuration={5000}
        severity={snack.severity}
        message={snack.message}
      />
      <TableTemplate
        keyId={`${Total + Active + Inactive}`}
        PageName={'Status Master'}
        addButton={'Status master'}
        SearchLabel={'Search Status Here... '}
        header={headCells}
        rowsValue={tableValues}
        tabsData={tabsData}
        handleChange={handleChangeTab}
        rawData={tableValue}
        userRole={StatusConfiguration[0]}
        handleAddPopupOpen={(val) => handleCrate(val)}
        handleEditPopupOpen={(val) => handleEditOpen(val)}
        handleCofigOpen={(val) => handleConfig(val)}
        handleDownloadExcel={(val) => {
          handleDownloadTable(val);
        }}
        paths={paths}
        fallbackText={'No Inactive status found'}
        rawData={tableValue}
      />
      <div className="Modal">
        <Formik
          key={edit ? 'edit' : 'create'}
          enableReinitialize={true}
          initialValues={ConfigVal}
          validationSchema={StatusConfigSchema}
          onSubmit={async (values, { resetForm }) => {
            console.log(values);
            let submitValue = values;
            if (edit) {
              console.log(values);
              const result = await handleUpdate(submitValue);
              if (result === 1) {
                resetForm();
              }
            } else {
              const result = await handleConfigPost(submitValue);
              if (result === 1) {
                resetForm();
              }
            }
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset }) => (
            <Form>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={config}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={config}>
                  <Box
                    className={'styleModalSmall'}
                  >
                    <Card
                      sx={{
                        borderRadius: '20px',
                        // height: '68vh',
                        height: 'fit-content',
                        marginTop: '4.5%',
                      }}
                    >
                      <CardHeader
                        sx={{
                          padding: '20px 20px 20px 20px',
                          background: CheckForLight()
                            ? 'rgb(246, 248, 252)'
                            : '#4f4f4f',
                        }}
                        action={
                          <Stack spacing={2} direction="row">


                            {edit ? (
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: '25px',
                                  textTransform: 'capitalize',
                                }}
                                startIcon={
                                  toBackend ? (
                                    <CircularProgress size={20} color="inherit" />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                                onClick={toBackend ? null : handleSubmit}
                              >
                                {toBackend ? 'Updating...' : 'Update'}
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: '25px',
                                  textTransform: 'capitalize',
                                }}
                                s
                                startIcon={
                                  toBackend ? (
                                    <CircularProgress size={20} color="inherit" />
                                  ) : (
                                    <SaveIcon />
                                  )
                                }
                                onClick={toBackend ? null : handleSubmit}
                              >
                                {toBackend ? 'Saving...' : 'Save'}
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              disabled={toBackend}
                              style={{
                                borderRadius: '25px',
                                textTransform: 'capitalize',
                              }}
                              onClick={() => {
                                handleConfig();
                                handleReset();
                              }}
                              startIcon={<CancelIcon />}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        }
                        title={
                          edit
                            ? 'Update Status Master'
                            : 'Add New Status Master'
                        }
                      />
                      <Divider style={{
                        borderColor: '#888',
                      }} />
                      <Card
                        sx={{
                          // marginLeft: '-1vw',
                          // paddingLeft: '1vw',
                          marginBottom: '1vh',
                          scrollBehavior: 'smooth',
                          scrollbarGutter: 'stable',
                          scrollbarWidth: 'thin',
                          '&::-webkit-scrollbar': {
                            width: '0.4em',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '20px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                          },
                        }}
                        elevation={0}
                      >
                        <CardContent sx={{ paddingTop: 0 }}>
                          {configField?.length > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                              }}
                              className="modelGrid"
                            >
                              <Grid container spacing={3} columns={32}>
                                {configField?.map((data, index) => {
                                  return (
                                    <>
                                      {data.type === 'dropdown' && data.view ? (
                                        <>
                                          <Grid item xs={10}>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Tooltip
                                              title={
                                                !edit && data.name === 'status'
                                                  ? 'Active'
                                                  : edit && data.name === 'status'
                                                    ? typeof values[data.name] ===
                                                      'string'
                                                      ? values[data.name]
                                                      : values.status === 1
                                                        ? 'Active'
                                                        : 'Inactive'
                                                    : values[data.name]
                                              }
                                            >
                                              <Field
                                                name={data.name}
                                                render={({ field, form }) => (
                                                  <Tooltip
                                                    title={
                                                      !edit && data.name === 'status'
                                                        ? 'Active'
                                                        : edit &&
                                                          data.name === 'status'
                                                          ? typeof values[data.name] ===
                                                            'string'
                                                            ? values[data.name]
                                                            : values.status === 1
                                                              ? 'Active'
                                                              : 'Inactive'
                                                          : values[data.name]
                                                    }
                                                  >
                                                    <Autocomplete
                                                      disableClearable
                                                      disabled={
                                                        toBackend ||
                                                        (!edit &&
                                                          data.name === 'status') ||
                                                        (edit &&
                                                          data.name ===
                                                          'customerName')
                                                      }
                                                      // disableClearable
                                                      options={
                                                        data.name === 'customerName'
                                                          ? customerId.map(
                                                            (option) => {
                                                              return `${option.customerName}`;
                                                            }
                                                          )
                                                          : data.name ===
                                                            'derivedStatus'
                                                            ? Derived.map(
                                                              (op) =>
                                                                op.derivedStatusName
                                                            )
                                                            : statusDrop?.map(
                                                              (option) => option.label
                                                            )
                                                      }
                                                      onChange={(event, value) => {
                                                        handleDropdownChange(
                                                          event,
                                                          value,
                                                          data.name,
                                                          ConfigVal,
                                                          setConfigVal
                                                        );
                                                      }}
                                                      disablePortal
                                                      size="small"
                                                      value={
                                                        !edit &&
                                                          data.name === 'status'
                                                          ? 'Active'
                                                          : edit &&
                                                            data.name === 'status'
                                                            ? typeof values[
                                                              data.name
                                                            ] === 'string'
                                                              ? values[data.name]
                                                              : values.status === 1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                            : edit &&
                                                              data.name ===
                                                              'derivedStatus'
                                                              ? values[
                                                              'derivedStatusName'
                                                              ]
                                                              : values[data.name]
                                                      }
                                                      id={`combo-box-demo-${index}`}
                                                      sx={{ width: '14vw' }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          placeholder={
                                                            data.placeholder
                                                          }
                                                        />
                                                      )}
                                                    />
                                                  </Tooltip>
                                                )}
                                              />
                                            </Tooltip>
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }}
                                            />
                                          </Grid>
                                        </>
                                      ) : data.type === 'textField' ? (
                                        <>
                                          <Grid item xs={10}>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Field
                                              as={TextField}
                                              disabled={toBackend}
                                              id={`outlined-basic-${index}`}
                                              size="small"
                                              variant="outlined"
                                              name={data.name}
                                              inputProps={{ maxLength: data.length }}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleChangeTxt(
                                                  e,
                                                  ConfigVal,
                                                  setConfigVal
                                                );
                                              }}
                                              placeholder={data.placeholder}
                                              value={values[data.name]}
                                              sx={{ width: '15vw' }}
                                            />
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }}
                                            />
                                          </Grid>
                                        </>
                                      ) : data.type === 'number' ? (
                                        <>
                                          <Grid item xs={10}>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Field
                                              as={TextField}
                                              id={`outlined-basic-${index}`}
                                              size="small"
                                              variant="outlined"
                                              name={data.name}
                                              type={data.type}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleChangeTxt(
                                                  e,
                                                  ConfigVal,
                                                  setConfigVal
                                                );
                                              }}
                                              inputProps={{
                                                maxLength: 10,
                                              }}
                                              placeholder={data.placeholder}
                                              value={
                                                values[data.name] === null
                                                  ? ''
                                                  : values[data.name]
                                              }
                                              sx={{ width: '15vw' }}
                                            />
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }}
                                            />
                                          </Grid>
                                        </>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </Grid>
                            </div>
                          ) : null}
                        </CardContent>
                      </Card>
                    </Card>
                  </Box>
                </Fade>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default StatusMasterList;
