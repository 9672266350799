import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Helper function to convert "hh:mm:ss" to hours
const convertDurationToHours = (duration) => {
    const [hours, minutes, seconds] = duration.split(':').map(part => parseFloat(part.replace(/h|m|s/g, '')));
    return hours + (minutes / 60) + (seconds / 3600);
};

// Helper function to convert hours to "hh:mm:ss"
const convertHoursToHMS = (hours) => {
    const h = Math.floor(hours);
    const m = Math.floor((hours - h) * 60);
    const s = Math.floor((((hours - h) * 60) - m) * 60);
    return `${String(h).padStart(2, '0')}h:${String(m).padStart(2, '0')}m:${String(s).padStart(2, '0')}s`;
};

const DonutChartRace = ({ projectData }) => {
    const processedData = projectData.map(project => ({
        name: project.projectName,
        hours: convertDurationToHours(project.duration),
    }));

    const totalHours = processedData.reduce((sum, project) => sum + project.hours, 0);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'pie',
            events: {
                render() {
                    if (!this.customLabel) {
                        this.customLabel = this.renderer
                            .text(
                                `Total: ${convertHoursToHMS(totalHours)}`,
                                this.plotLeft + this.plotWidth / 2,
                                this.plotTop + this.plotHeight / 2
                            )
                            .css({
                                color: '#000',
                                fontSize: '16px',
                                textAlign: 'center',
                            })
                            .attr({
                                align: 'center',
                            })
                            .add();
                    } else {
                        this.customLabel.attr({
                            text: `${convertHoursToHMS(totalHours)}`,
                        });
                    }

                    // if (!this.yearLabel) {
                    //     this.yearLabel = this.renderer
                    //         .text(
                    //             'Project Hours',
                    //             this.plotLeft + this.plotWidth / 2,
                    //             this.plotTop + this.plotHeight / 2 - 30
                    //         )
                    //         .css({
                    //             color: '#000',
                    //             fontSize: '24px',
                    //             textAlign: 'center',
                    //         })
                    //         .attr({
                    //             align: 'center',
                    //         })
                    //         .add();
                    // } else {
                    //     this.yearLabel.attr({
                    //         text: 'Project Hours',
                    //     });
                    // }
                },
            },
        },
        title: {
            text: 'Project hours distribution',
        },
        plotOptions: {
            pie: {
                innerSize: '50%',
                depth: 45,
                dataLabels: {
                    enabled: false,
                },
            },
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle',
        },
        credits: {
            enabled: false,  // Disable credits
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${convertHoursToHMS(this.point.y)}`;
            }
        },
        series: [
            {
                name: 'Hours',
                data: processedData.map(project => ({
                    name: project.name,
                    y: parseFloat(project.hours.toFixed(2)),  // Ensuring 'hours' is a number
                })),
            },
        ],
    });

    useEffect(() => {
        setChartOptions(prevOptions => ({
            ...prevOptions,
            series: [
                {
                    name: 'Hours',
                    data: processedData.map(project => ({
                        name: project.name,
                        y: parseFloat(project.hours.toFixed(2)),  // Ensuring 'hours' is a number
                    })),
                },
            ],
        }));
    }, [projectData]);

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default DonutChartRace;
