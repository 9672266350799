import axios from 'axios';
export const fetchActivityName = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USER_TABLE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchReportTableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USER_TABLE}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const postActive = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ACTIVITY_SAVE}`,
      obj
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const putActive = async (obj, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_USER_TABLE_UPDATE}/${id}`,
      obj
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
