import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  Backdrop,
  Divider,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import FadeLoader from 'react-spinners/FadeLoader';
// ========Modal================

import { styled } from '@mui/material/styles';

/*------------api call-----------------*/
import { fetchEquipmentreplaceTable } from '../../Api/EquipmenReplaceApi';
import TableTemplate from '../../Template/TableTemplate';
import Download from '../../Template/Excel/Download';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import errorMsg from '../util/errorMessage.json';
import CustomSnackbar from '../util/components/CustomSnackbar';
import {
  Equipmentcategorydropdown,
  Equipmenttypedropdown,
} from '../../Api/EquipmentApi';
import { useLocation } from 'react-router-dom';

export default function Equipmentreplace({ equipmentHistory }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [siteTab, setSiteTab] = React.useState('');
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [category, setCategory] = useState();
  const [equipment, setEquipment] = useState();
  const [editcall, seteditcall] = useState();
  const [activeFlag, setactiveFlag] = useState();
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  let initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const location = useLocation();
  const response = location?.state?.response;
  console.log(response, 'responseresponse');
  const [stdField, setstdField] = useState({
    equipmentType: '',
    capacity: '',
    description: '',
    manufacturer: '',
    remarks: '',
    status: '',
    categoryId: '',
    updatedBy: '',
    equipmentTypeCode: '',
    displayName: '',
  });
  const handleChange = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchEquipmenttype();
    fetchEquipmentcategory();
  }, []);
  /*------------------------- handle Update --------------------------------------------*/

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    try {
      const data = await fetchEquipmentreplaceTable();
      // const reverse = [...data].reverse();
      setSiteEquipment(data);
      console.log(SiteEquipment, 'dfghjk');

      setloading(false);
      console.log(data);
    } catch (e) {
      console.equipment(e);
    }
  };

  const Priority = [
    { label: 'High', value: 3 },
    { label: ' Medium', value: 2 },
    { label: 'Low', value: 1 },
  ];

  /*-------------------------report Name list dropdown --------------------------------------------*/

  //
  const handleactiveFlag = (data, event) => {
    setactiveFlag(event?.value);
  };

  const handleEquipmentvalue = (data, value) => {
    if (value === null) {
      setEquipment('');
    } else {
      setEquipment(value);
    }
  };

  const fetchEquipmenttype = async () => {
    const data = await Equipmenttypedropdown();
    if (Array.isArray(data)) {
      setEquipmevalue(data);
    } else if (typeof data === 'object' && data !== null) {
      setEquipmevalue([data]);
    }
  };

  const fetchEquipmentcategory = async () => {
    const data = await Equipmentcategorydropdown();
    if (Array.isArray(data)) {
      setCategoryvalue(data);
    } else if (typeof data === 'object' && data !== null) {
      setCategoryvalue([data]);
    }
  };

  const handleCategoryvalue = (data, value) => {
    if (value === null) {
      setCategory('');
    } else {
      setCategory(value);
    }
  };

  /*---------------------------------- New Equipment Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall('');
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  const statusvalue = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (equipmenttypeId) => {
    seteditcall(equipmenttypeId);
    setEdit(true);
    setOpen(true);
  };

  const headCells = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
      default: true,
    },
    {
      label: 'Equipment Name',
      id: 'displayName',
      view: true,
      default: true,
    },
    // {
    //   label: "Equipmentid",
    //   id: "equipmentid",
    // view:true
    // },
    // {
    //   label: "Primary Account",
    //   id: "isprimaryequipment",
    // view:true
    // },
    // {
    //   label: "Serial NO",
    //   id: "primaryserialnumber",
    // view:true
    // },
    // {
    //   label: "replacedon",
    //   id: "replacedon",
    // view:true
    // },
    {
      label: 'Equipment CategoryID',
      id: 'equipmentCategoryId',
      view: true,
      default: true,
    },
    {
      label: 'Equipment Type',
      id: 'equipmentType',
      view: true,
    },
    {
      label: 'Serial No',
      id: 'serialNo',
      view: true,
    },
    {
      label: 'AC Capacity',
      id: 'acCapacity',
      view: true,
    },
    {
      label: 'DC Capacity',
      id: 'dcCapacity',
      view: true,
    },
    {
      label: 'Additional Fields',
      id: 'additionalfields',
      view: false,
    },
    {
      label: 'Created Date',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'LastUpdated Date',
      id: 'lastUpdatedDate',
      view: false,
    },
    {
      label: 'Created By',
      id: 'userName',
      view: false,
    },
    //   {
    //     label: "Lastupdateddate",
    //     id: "astupdateddate",
    // view:true
    //   },
    //   {
    //     label: "Lastupdatedby",
    //     id: "lastupdatedby",
    // view:true
    //   },
  ];

  useEffect(() => {
    setloading(true);
    let viewArr = [...headCells];
    response?.equipReplacementhistory.forEach((obj) => {});
  }, []);
  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Equipment History');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  let tableValues;
  if (response) {
    tableValues = response?.equipReplacementhistory.filter((row) => {
      return row;
    });
  } else {
    tableValues = SiteEquipment.filter((row) => {
      if (siteTab !== '') {
        return Number(row.status) === Number(siteTab);
      } else {
        return row;
      }
    });
  }
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = SiteEquipment?.length;

  const Active = SiteEquipment?.filter((site) => site?.status == 1).length;

  const Inactive = SiteEquipment?.filter((site) => site?.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Equipment', value: 'all', badgeCount: Total },
    { name: 'Active Equipment', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Equipment',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Equipment History', path: 'equipmentreplace' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: '-0.5%' }}>
            <CustomSnackbar
              open={snack.open}
              severity={snack.severity}
              message={snack.message}
              onClose={handleSnackClose}
              autoHideDuration={5000}
            />
            <TableTemplate
              PageName={'Equipment  History'}
              SearchLabel={'Search History Here... '}
              addButton={'History'}
              header={headCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              handleChange={handleChangeTab}
              rawData={SiteEquipment}
              // rowsValue={SiteEquipment}
              userRole={equipmentHistory[0]}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={'To replacement history available'}
            />
          </div>
        </div>
      )}
    </div>
  );
}
