import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import axios from 'axios';
import { useIdleTimer } from 'react-idle-timer';
import './App.css';
/*----------------------- Login Components ------------------------*/

import LoginPage from './UiComponents/Login/LoginPage';
import ForgotPass from './UiComponents/Login/ForgotPass';
import ResetPass from './UiComponents/Login/ResetPass';
import ChangePass from './UiComponents/Login/ChangePass';
// import Signup from "./UiComponents/Signup/Signup";
import SOP from './UiComponents/SOP/SOP';
/*------------------------Sidebar ---------------------------*/

import MenuBar from './UiComponents/SideMenu/MenuBar';

/*-------------------------- Components --------------------*/

import ExceluploadClone from './UiComponents/Components/ExcelUploadClone';
import EquipmentFilterTable from './UiComponents/EquipmentFilter/EquipmentFilterTable';
// import Analytics from './UiComponents/Components/AnalyticsDashboard/Analytics';
// import AddAnalyst from './UiComponents/Components/AnalyticsDashboard/AddAnalyst';
import Analytics from './UiComponents/Components/AnalyticsDashboard/Analytics';
import AddAnalyst from './UiComponents/Components/AnalyticsDashboard/AddAnalyst';
import { ViewAnalytics } from './UiComponents/Components/AnalyticsDashboard/ViewAnalyst';
import Site from './UiComponents/SiteList/Site';
import AddTable from './UiComponents/UserConfiguration/UserRole/AddTable';
import AddRoleTable from './UiComponents/UserConfiguration/UserRole/AddRoleTable';
import UserConfiguration from './UiComponents/UserConfiguration/UserConfig';
import EquipmentConfiguration from './UiComponents/EquipmentConfiguration/Equipment';
import Configuration from './UiComponents/ConfigurationEquipment/Configuration';
import ExpectedData from './UiComponents/Master/ExpectedData';
// import CustomerConfiguration from './UiComponents/customer/Customerconfiguration'
import Standardparameter from './UiComponents/Master/Standardparameter';
import Errorconfig from './UiComponents/Master/Errorconfig';
import Sector from './UiComponents/Master/Sector';
import CustomizeAlerts from './UiComponents/Components/AlertsComponents/CustomizeAlerts';
import SiteConfiguration from './UiComponents/SiteConfiguration/SiteConfig';
import StatusConfig from './UiComponents/Master/StatusConfig';
import Customerlist from './UiComponents/customer/Customerlist';
import Equipmentcategory from './UiComponents/Master/Equipmentcategory';
import Alerms from './UiComponents/SiteConfiguration/Alerms';
import Sectortype from './UiComponents/Master/Sectortype';
import EquipmentType from './UiComponents/Master/EquipmentType';
import Dgr from './UiComponents/Master/Dgr';
import DailyGeneration from './UiComponents/Master/DailyGeneration';
import ValueEnter from './UiComponents/Master/ValueEnter';
import Report from './UiComponents/Report/Report';
import SignUp from './UiComponents/Signup/Signup';
import TermsandConditions from './UiComponents/Signup/TermsandConditions';
import Ticket from './UiComponents/Ticket/Ticket';
import EquipmentList from './UiComponents/EquipmentList/EquipmentList';
import Equipmentreplace from './UiComponents/Master/Equipmentreplace';
import MultiSite from './UiComponents/SiteConfiguration/Multisite';
import Ftp from './UiComponents/Components/FTP/Ftp';
import Formula from './UiComponents/Master/Formula';
import CompanyList from './UiComponents/Components/Company/CompanyList';
import { ProfilePage } from './Profile/Profile';
import TicketView from './UiComponents/Ticket/TicketView';
import { AuthProvider } from './UiComponents/Auth/AuthContext';
import EquipmentOverview from './UiComponents/EquipmentFilter/EquipmentOverview';
import Document from './UiComponents/Components/Document/Document';
import SopNewDocument from './UiComponents/SOP/SopNewDocument';
import Subscription from './UiComponents/Master/Subscriptions';
import {
  getBroadcastChannel,
  initializeBroadcastChannel,
} from './UiComponents/util/security/BroadcastChannel';
import StatusMasterList from './UiComponents/Master/StatusMasterList';
import { queryDecrypt } from './UiComponents/util/security/Cipher';
import TaskManager from './UiComponents/TaskManager/TaskManager';
import { SaasDashboardHome } from './UiComponents/EiraDashboard/SaasDashboardHome';
import { NewViewAnalytics } from './UiComponents/Components/AnalyticsDashboard/newViewAnalytics';
// import { Datalogger } from './UiComponents/Components/datalogger';
function App() {
  const [showPrompt, setShowPrompt] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [rolesString, setRolesString] = useState('');
  const token = sessionStorage.getItem('jwtToken');
  initializeBroadcastChannel(token);
  const broadcastChannel = getBroadcastChannel();
  const handleClose = (event, reason) => {
    setShowPrompt(false);
  };

  const prompt = {
    showPrompt,
    handleClose,
  };

  if (isLoggedIn) {
    setTimeout(() => {
      handleLogout();
    }, 3600000);
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
    broadcastChannel.postMessage({ type: 'logout', token: token });
  };

  const showIdlePrompt = () => {
    setShowPrompt(true);
  };

  const { reset, start, pause, getRemainingTime } = useIdleTimer({
    timeout: 1800000,
    promptBeforeIdle: 10000,
    onIdle: handleLogout,
    onAction: (event) => {
      pause();
      reset();
      setShowPrompt(false);
    },
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      // "visibilitychange",
      'focus',
    ],
    immediateEvents: [],
    onPrompt: showIdlePrompt,
    startManually: true,
    debounce: 200,
  });
  useEffect(() => {
    if (token !== undefined && token !== 'undefined' && token !== null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axios.defaults.headers.common['allowCredentials'] = true;
      setIsLoggedIn(true);
      const rolesString = queryDecrypt(sessionStorage.getItem('roles'));
      setRolesString(rolesString);
      reset();
      start();
    } else {
      handleLogout();
    }
  }, [start, reset, getRemainingTime, token]);

  async function hasPermission(activityName) {
    const rolesArray = rolesString ? JSON.parse(rolesString) : [];
    const activityEntry = rolesArray.find(
      (entry) => entry.activityName === activityName
    );
    const result = await (activityEntry &&
      activityEntry.status == 1 &&
      activityEntry.view == 1);
    return result;
  }

  const rolesArray = rolesString ? JSON.parse(rolesString) : [];
  return (
    <div>
      <AuthProvider>
        <Router
        // basename="/eirasaas/"
        // basename="/saas/"
        // basename="/eirabeta/"
        >
          <Routes>
            <Route path="signup" element={<SignUp />} />
            <Route path="termsandconditions" element={<TermsandConditions />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="forgot" element={<ForgotPass />} />
            <Route path="resetpass" element={<ResetPass />} />
            <Route path="changepass" element={<ChangePass />} />

            {sessionStorage.getItem('token') && (
              <>
                {/*Main Menu Routes*/}
                <Route path="/menu" element={<MenuBar showPrompt={prompt} />}>
                  <Route
                    path=""
                    element={
                      hasPermission('dashboard') ? (
                        <>
                          {/* <DashboardDndkit />
                          <DashboardBeautifulDnd /> */}
                          <SaasDashboardHome />
                        </>
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="sitelist"
                    element={
                      hasPermission('siteList') ? (
                        <Site
                          SiteList={rolesArray.filter(
                            (config) => config.activityName === 'siteList'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route path="analytics" element={<Analytics />} />
                  <Route path="add_analyst" element={<AddAnalyst />} />
                  {/* Actual production component */}
                  <Route path="view-analyst" element={<ViewAnalytics />} />
                  {/* Component in testing */}
                  {/* <Route path="view-analyst" element={<NewViewAnalytics />} /> */}
                  <Route path="sop_file" element={<SOP />} />
                  {/* <Route path="dataloggerValue" element={<Datalogger />} /> */}
                  <Route path="newdocument" element={<SopNewDocument />} />
                  <Route
                    path="companylist"
                    element={
                      hasPermission('companyConfiguration') ? (
                        <CompanyList
                          companyConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'companyConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="customerlist"
                    element={
                      hasPermission('customerConfiguration') ? (
                        <Customerlist
                          customerConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'customerConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="view_ticket"
                    element={
                      hasPermission('ticketConfiguration') ? (
                        <TicketView
                          viewTicket={rolesArray.filter(
                            (config) =>
                              config.activityName === 'ticketConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="Standardparameter"
                    element={
                      hasPermission('standardParameter') ? (
                        <Standardparameter
                          standardParameter={rolesArray.filter(
                            (config) =>
                              config.activityName === 'standardParameter'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="error"
                    element={
                      hasPermission('errorConfiguration') ? (
                        <Errorconfig
                          errorConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'errorConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="expecteddata"
                    element={
                      hasPermission('expectedData') ? (
                        <ExpectedData
                          errorConfiguration={rolesArray.filter(
                            (config) => config.activityName === 'expectedData'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="sector"
                    element={
                      hasPermission('sectorConfiguration') ? (
                        <Sector
                          SectorConfigurations={rolesArray.filter(
                            (config) =>
                              config.activityName === 'sectorConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="equipmentCategory"
                    element={
                      hasPermission('equipmentCategory') ? (
                        <Equipmentcategory
                          EquipmentCategory={rolesArray.filter(
                            (config) =>
                              config.activityName === 'equipmentCategory'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="statusConfiguration"
                    element={
                      hasPermission('statusConfiguration') ? (
                        <StatusConfig
                          StatusConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'statusConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="statusMaster"
                    element={
                      hasPermission('statusConfiguration') ? (
                        <StatusMasterList
                          StatusConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'statusConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="report"
                    element={
                      hasPermission('report') ? (
                        <Report
                          Report={rolesArray.filter(
                            (config) => config.activityName === 'report'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="ticket"
                    element={
                      hasPermission('ticketConfiguration') ? (
                        <Ticket
                          ticketConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'ticketConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />

                  <Route
                    path="datalogger"
                    element={
                      hasPermission('dataLoggerConfiguration') ? (
                        <ExceluploadClone
                          Report={rolesArray.filter(
                            (config) =>
                              config.activityName === 'dataLoggerConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />

                  <Route
                    path="subscription"
                    element={
                      hasPermission('subscription') ? (
                        <Subscription
                          Report={rolesArray.filter(
                            (config) => config.activityName === 'subscription'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="customise-alerts"
                    element={
                      hasPermission('customizedAlert') ? (
                        <CustomizeAlerts
                          CustomizedAlert={rolesArray.filter(
                            (config) =>
                              config.activityName === 'customizedAlert'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="equipmentconfiguration"
                    element={
                      hasPermission('equipmentConfiguration') ? (
                        <EquipmentConfiguration
                          equipmentConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'equipmentConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="Configuration"
                    element={
                      hasPermission('equipmentConfiguration') ? (
                        <Configuration
                          equipmentConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'equipmentConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="siteconfiguration"
                    element={
                      hasPermission('siteConfiguration') ? (
                        <SiteConfiguration
                          siteConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'siteConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="equipmenttype"
                    element={
                      hasPermission(
                        'equipmentTypeConfiguration',
                        rolesArray
                      ) ? (
                        <EquipmentType
                          equipmentTypeConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName ===
                              'equipmentTypeConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="dgr"
                    element={
                      hasPermission('downTime', rolesArray) ? (
                        <Dgr
                          dailyGeneration={rolesArray.filter(
                            (config) => config.activityName === 'downTime'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="dailygenerationValue"
                    element={
                      hasPermission('dailyGeneration', rolesArray) ? (
                        <DailyGeneration
                          dailyGeneration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'dailyGeneration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="industrytype"
                    element={
                      hasPermission('industryConfiguration') ? (
                        <Sectortype
                          industryConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'industryConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="equipmentlist"
                    element={
                      hasPermission('equipmentList') ? (
                        <EquipmentList
                          equipmentList={rolesArray.filter(
                            (config) => config.activityName === 'equipmentList'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />

                  <Route
                    path="equipmentdetails"
                    element={
                      <EquipmentFilterTable
                        errorConfiguration={rolesArray.filter(
                          (config) => config.activityName === 'equipmentList'
                          // 'siteList'
                        )}
                      />
                    }
                  />

                  <Route
                    path="formula"
                    element={
                      hasPermission('formula') ? (
                        <Formula
                          standardParameter={rolesArray.filter(
                            (config) => config.activityName === 'formula'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="alarmsconfig"
                    element={
                      <Alerms
                        Multisite={rolesArray.filter(
                          (config) => config.activityName === 'sitelist'
                        )}
                      />
                    }
                  />

                  <Route
                    path="equipmentreplace"
                    element={
                      hasPermission('equipmentHistory') ? (
                        <Equipmentreplace
                          equipmentHistory={rolesArray.filter(
                            (config) =>
                              config.activityName === 'equipmentHistory'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="ftpConfiguration"
                    element={
                      hasPermission('ftpConfiguration') ? (
                        <Ftp
                          ftpConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'ftpConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="equipmentOverview"
                    element={
                      hasPermission('siteList') ? (
                        <EquipmentOverview
                          ftpConfiguration={rolesArray.filter(
                            (config) => config.activityName === 'siteList'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  {/*Not ready for production yet*/}
                  <Route
                    path="document"
                    element={
                      <Document
                        Report={rolesArray.filter(
                          (config) => config.activityName === 'siteList'
                        )}
                      />
                    }
                  />
                  <Route
                    path="valueScreen"
                    element={
                      hasPermission(
                        'equipmentTypeConfiguration',
                        rolesArray
                      ) ? (
                        <ValueEnter
                          dailyGeneration={rolesArray.filter(
                            (config) =>
                              config.activityName ===
                              'equipmentTypeConfiguration' //need to change control variable after DB update
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                </Route>

                {/*Configuration Menu Routes*/}
                <Route path="/config" element={<MenuBar showPrompt={prompt} />}>
                  <Route path="profile/profilePage" element={<ProfilePage />} />
                  <Route
                    path="admin/multiSite"
                    element={
                      hasPermission('userSiteMap') ? (
                        <MultiSite
                          Multisite={rolesArray.filter(
                            (config) => config.activityName === 'userSiteMap'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="admin/userconf"
                    element={
                      hasPermission('userConfiguration') ? (
                        <UserConfiguration
                          userConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'userConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="admin/userrole"
                    element={
                      hasPermission('userRoleConfiguration') ? (
                        <AddRoleTable
                          userRoleConfiguration={rolesArray.filter(
                            (config) =>
                              config.activityName === 'userRoleConfiguration'
                          )}
                        />
                      ) : (
                        <Navigate to="/menu" replace={true} />
                      )
                    }
                  />
                  <Route
                    path="admin/userroletable"
                    element={
                      <AddTable
                        Multisite={rolesArray.filter(
                          (config) => config.activityName === 'activityName'
                        )}
                      />
                    }
                  />
                  <Route
                    path="admin/taskmanager"
                    element={
                      <TaskManager
                        SiteList={rolesArray.filter(
                          (config) => config.activityName === 'siteList' //need to change control variable after DB update
                        )}
                      />
                    }
                  />
                </Route>
              </>
            )}
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}
export default App;
