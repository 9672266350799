import axios from 'axios';
export const fetchFormulaTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_FORMULA_TABLE
      }/${sessionStorage.getItem('id')}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const putFormalaupdateEdit = async (data, id) => {
  try {
    console.log(data, 'data');
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FORMULA_UPDATE}/${id}`,
      data
    );
    console.log(response, 'response');
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const postFormaladata = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FORMULA_SAVE}`,
      obj
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
/*---------------------------------------------- Site Name list------------------------------*/
export const fetchSiteDetails = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITELIST_BYUSER          
      }/${sessionStorage.getItem('id')}`
    );
    console.log(response, 'resssss');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const EquipmentlistDropdown = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_LI_DROPDOWN}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const GetFileParamCoff = async (id) => {
  try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_ALL_PARAM_COFF}`
    // );
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_PARAMETERLIST_BYEQUIP}/${id}`
    );
    console.log(response,"response");
    
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
