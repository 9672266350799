import axios from 'axios';
/*------------------------------------------- Site Details ----------------------------*/

export const fetchSiteDetails = async (id) => {
  // const nameMapping = {
  //   'Ventus Energy Consultant': 'Demo Site_1',
  //   'Baskara Clean Energy': 'Demo Site_2',
  //   'IOCL, Manmad Terminal': 'Demo Site_3',
  //   IFP: 'Demo Site_4',
  //   'ICE PV Dg Grid ': 'Demo Site_5',
  //   'SVS 1MWP ': 'Demo Site_6',
  // }; //remove unwanted this after demo
  // const mapSiteNames = (sites) => {
  //   return sites.map((site) => ({
  //     ...site,
  //     siteName: nameMapping[site.siteName] || site.siteName,
  //   }));
  // }; //remove unwanted this after demo
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_SITELIST_WEBDYN}=${id}`
    );
    // const data = mapSiteNames(response.data); //remove unwanted this after demo
    // return await data;
    return await response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchSiteDetailsAlert = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SITELIST}=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
/*---------------------------------------------- EquipmentId------------------------------*/

export const fetchEquipmentIdDetails = async (data) => {
  console.log(data, 'data');
  try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_EQUIPMENTLIST}/${data}`
    // );
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH_WEBDYN}${process.env.REACT_APP_EQUIPMENTLIST}/${data}`
    );
    console.log(response, 'data');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
/*-----------------------------------------------parameter Api -------------------------------*/

export const fetchParameterListDetails = async (data) => {
  try {
    const response = await axios.get(
      // `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_EQUIPMENTLIST}/${data}`
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_PARAMETERLIST}`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
/*----------------------------- Standard Params ------------------------*/
export const fetchStandardParams = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DATA_LOGGER}/standardparams`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
/*-----------------------------------Post Data config -------------------------------*/

export const PostdataConfig = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DATA_LOGGER}`,
      obj
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*------------------------------------------- Site Details ----------------------------*/

export const fetchSiteDetailsdata = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITE_DROP_DOWN
      }/${sessionStorage.getItem('customerId')}`
      // `${process.env.REACT_APP_SITE_DROP_DOWN}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/*---------------------------------------------- EquipmentId------------------------------*/

export const fetchEquipmentIdDetailsdata = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_DETAILS}/${data}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/*------------------------------------------------- Add New ---------------------------- */
export const GetFilePath = async (fileDataPath) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DATALOGGERCALL}/${fileDataPath}`
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const PostdataAddParamter = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ADD_PARAMETER}`,
      obj
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const PostAddNewMaster = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ADDNEWMASTER}`,
      obj
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const GetFileParamCoff = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_ALL_PARAM_COFF}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const fetchDataTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_REPORT_TABLE}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const dataloggerNamevalue = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DATALOGGER_TABLE}/${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const dataloggermaptable = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DATALOGGER_MAPPING_TABLE}/${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const putDataloggerMaster = async (data, id) => {
  try {
    console.log(data, 'data');
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DATALOGGER_MAPPING_PUT}/${id}`,
      data
    );
    console.log(response, 'response');
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const putDatalogger = async (data) => {
  try {
    console.log(data, 'data');
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_DATALOGGER_TABLE_PUT}`,
      data
    );
    console.log(response, 'response');
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const FilePathList = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DATA_LOGGER_FILEPATH_MUST}/${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchFileGetFile = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_FTP_API_PATH}${process.env.REACT_APP_API_DATALOGGER_TABLE_GET_FILE}=${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
