import { useContext, useEffect, useRef, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  Grid,
  CircularProgress,
} from '@mui/material';
import {
  UserContext,
  UserContextProvider,
  useUserContext,
} from '../Auth/UserContext';
import './Loginpage.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import eira from '../../images/headerLogo.svg';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import './Loginpage.css'; // Adjust the path to match your CSS file location
import FadeLoader from 'react-spinners/FadeLoader';
import Skeleton from '@mui/material/Skeleton';
import errorMsg from '../util/errorMessage.json';

/*------------------------------- APi calls -------------------------*/
import { PostLogin } from '../../Api/LoginApi';
import {
  PassEncrypt,
  encryptData,
  queryEncrypt,
} from '../util/security/Cipher';
import CustomSnackbar from '../util/components/CustomSnackbar';
import axios from 'axios';

const Loginpage = () => {
  const { setUserContextValue } = useContext(UserContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [Email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [Password, setPassword] = useState('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [open, setOpen] = useState(false);
  const [variantText, setVarient] = useState('info');
  const [textValue, setTextValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [toBackEnd, setToBackEnd] = useState(false);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  console.log(apiResponse);
  // Access the state from the location object
  const location = useLocation();
  const isChange = location.state?.changePassword === true ? true : false;
  const emailId = location.state?.emailId;
  const firstLogin = location.state?.firstLogin;
  const resetPass = location.state?.resetPass;
  console.log(firstLogin, 'cccccc');
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!isValidEmail(newEmail));
  };

  useEffect(() => {
    if (firstLogin || isChange) {
      setSnack(errorMsg.firstLogin);
    } else if (resetPass) {
      setSnack(errorMsg.resetPass);
    }
  }, [firstLogin, resetPass, isChange]);
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  useEffect(() => {
    if (
      apiResponse &&
      apiResponse.data.isNewUser !== true &&
      apiResponse.data.first_Login !== true &&
      isChange === false &&
      apiResponse.status === 200
    ) {
      sessionStorage.setItem('token', `${apiResponse.data.userName}`);
      sessionStorage.setItem('id', `${apiResponse.data.userId}`);
      sessionStorage.setItem('customerId', `${apiResponse.data.customerId}`);
      sessionStorage.setItem('jwtToken', `${apiResponse.data.accesstoken}`);
      sessionStorage.setItem('roleId', `${apiResponse.data.roleId}`);
      sessionStorage.setItem('email', `${apiResponse.data.emailId}`);
      sessionStorage.setItem('roleName', `${apiResponse.data.roleName}`);
      sessionStorage.setItem(
        'customerName',
        `${apiResponse.data.customerName}`
      );
      sessionStorage.setItem(
        'customerLogoPath',
        `${apiResponse.data.customerLogopath}`
      );

      sessionStorage.setItem(
        'roles',
        queryEncrypt(JSON.stringify(apiResponse.data.userMapDetails))
      );
      sessionStorage.setItem(
        'companyLogoPath',
        `${apiResponse.data.companyLogopath}`
      );
      const typeMap = {
        OEM: 1,
        'End Customer': 2,
        IPP: 3,
      };
      const userTypeId = typeMap[apiResponse.data.userTypeName];
      sessionStorage.setItem(
        'userTypeName',
        `${apiResponse.data.userTypeName}`
      );
      sessionStorage.setItem('userTypeId', `${userTypeId}`);
      sessionStorage.setItem('companyId', `${apiResponse.data.companyId}`);
      sessionStorage.setItem('companyName', `${apiResponse.data.companyName}`);
      sessionStorage.setItem(
        'subscriptionName',
        `${apiResponse.data.subscriptionName}`
      );
      sessionStorage.setItem(
        'subscriptionId',
        `${apiResponse.data.subscriptionId}`
      );
      //This is main login route to dashboard.
      //Don't delete this redirect and reload if you want this application to work properly.
      // window.location.href = 'menu';
      navigate('menu');
      window.location.reload();
    } else if (
      apiResponse &&
      apiResponse.data.isNewUser === true &&
      apiResponse.data.first_Login !== true &&
      isChange === false &&
      apiResponse.status === 200
    ) {
      //This is main login route to reset password
      //Please don't delete this navigation
      sessionStorage.setItem('id', `${apiResponse.data.userId}`);
      navigate('/resetpass');
    } else if (
      apiResponse &&
      apiResponse.data.isNewUser === true &&
      apiResponse.data.first_Login !== true &&
      isChange === true &&
      apiResponse.status === 200
    ) {
      //This is main login route to change password
      //Please don't delete this navigation
      sessionStorage.setItem('id', `${apiResponse.data.userId}`);
      navigate('/changepass', {
        state: { changePassword: isChange, emailId: emailId },
      });
    } else if (
      apiResponse &&
      apiResponse.data.isNewUser === false &&
      apiResponse.data.first_Login === true &&
      isChange === false &&
      apiResponse.status === 200
    ) {
      sessionStorage.setItem('token', `${apiResponse.data.userName}`);
      sessionStorage.setItem('id', `${apiResponse.data.userId}`);
      sessionStorage.setItem('customerId', `${apiResponse.data.customerId}`);
      sessionStorage.setItem('jwtToken', `${apiResponse.data.accesstoken}`);
      sessionStorage.setItem('roleId', `${apiResponse.data.roleId}`);
      sessionStorage.setItem('email', `${apiResponse.data.emailId}`);
      sessionStorage.setItem('roleName', `${apiResponse.data.roleName}`);
      sessionStorage.setItem(
        'subscriptionName',
        `${apiResponse.data.subscriptionName}`
      );
      sessionStorage.setItem(
        'subscriptionId',
        `${apiResponse.data.subscriptionId}`
      );
      sessionStorage.setItem(
        'customerLogoPath',
        `${apiResponse.data.customerLogopath}`
      );
      sessionStorage.setItem('firstTime', 1);
      sessionStorage.setItem(
        'companyLogoPath',
        `${apiResponse.data.companyLogopath}`
      );
      const typeMap = {
        OEM: 1,
        'End Customer': 2,
        IPP: 3,
      };
      const userTypeId = typeMap[apiResponse.data.userTypeName];
      sessionStorage.setItem(
        'userTypeName',
        `${apiResponse.data.userTypeName}`
      );
      sessionStorage.setItem('userTypeId', `${userTypeId}`);
      sessionStorage.setItem('companyId', `${apiResponse.data.companyId}`);
      sessionStorage.setItem(
        'roles',
        queryEncrypt(JSON.stringify(apiResponse.data.userMapDetails))
      );
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${apiResponse.data.token}`;
      //This is main login route to first time login based on user type
      //Please don't delete this navigation and reload if you want this application to work properly.
      const roles = apiResponse.data.userMapDetails;
      const customer = roles.filter(
        (role) => role.activityName === 'customerConfiguration'
      );
      const company = roles.filter(
        (role) => role.activityName === 'companyConfiguration'
      );
      const CanAccessCustomer = customer[0].view == 1;
      const CanAccessCompany = company[0].view == 1;
      if (apiResponse.data.userTypeId == 2) {
        CanAccessCustomer ? navigate('menu/Customerlist') : navigate('menu');
        window.location.reload();
      } else {
        CanAccessCompany ? navigate('menu/companylist') : navigate('menu');
        window.location.reload();
      }
    }
    setLoading(false);
    setToBackEnd(false);
  }, [apiResponse, navigate, isChange]);

  const setUserContext = (userData) => {
    // console.log(userData);
    setUserContextValue({
      user: userData.userName,
      userId: userData.userId,
      accessToken: userData.accessToken,
      email: userData.emailID,
      userMapDetails: userData.userMapDetails,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack(initialSnack);
  };
  const alertStyle = {
    backgroundColor: 'red', // Background color
    color: 'white', // Text color
    '& .MuiSvgIcon-root': {
      color: 'white', // Icon color
    },
  };

  const handleNavigate = async (event) => {
    const result = PassEncrypt(Password);

    let data = {
      email: Email.toLowerCase(),
      password: result,
    };
    const isEmpty = Object.values(data).some((value) => value === '');
    console.log(isEmpty, 'dayaaa');
    // console.log(data);
    // const decrypt = PassDecrypt(result);
    // const decryptedBytes = decryptedText.toString(CryptoJS.enc.Utf8);
    // console.log(decrypt);
    if (!isEmpty) {
      try {
        setLoading(true);
        setToBackEnd(true);
        let responsedata = await PostLogin(data); // Assuming PostLogin is defined and works
        setApiResponse(responsedata); // Store the API response
        // console.log(responsedata, 'responsedata');

        if (responsedata && responsedata.status === 200) {
          setUserContext({
            userName: responsedata.data.userName,
            userId: responsedata.data.userId,
            customerLogopath: responsedata.data.customerLogopath,
            accessToken: responsedata.data.accesstoken,
            emailID: responsedata.data.emailId,
            userMapDetails: responsedata.data.userMapDetails,
            subscriptionName: responsedata.data.subscriptionName,
            subscriptionId: responsedata.data.subscriptionId,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 406) {
          setLoading(false);
          setSnack(errorMsg.inValidPass);
          setToBackEnd(false);
        } else if (error.response && error.response.status === 500) {
          setLoading(false);
          setSnack({
            ...snack,
            open: true,
            severity: 'error',
            message: error.response.data.statusDescription,
          });
          setToBackEnd(false);
        } else {
          setLoading(false);
          setSnack(errorMsg.failure);
          setToBackEnd(false);
        }
      }
    } else {
      setSnack(errorMsg.required);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleNavigate();
    }
  };

  const handleNextfield = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      passwordRef.current.focus();
    }
  };

  const passwordRef = useRef(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
    handleEmailChange();
  };
  return (
    <div className="scroll-lock">
      <div className="main-log no-scroll">
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FadeLoader color="#000440" loading={true} />
          </div>
        ) : (
          <>
            <div
              className="background-image-container"
              key={Math.random()}
            ></div>
            <div className="overlay"></div>
            <Box
              sx={{
                margin: '0 auto 0 auto',
                height: 'max-content',
                boxSizing: 'border-box',
                width: 'max-content',
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: '10px 30px 10px 30px',
                  boxSizing: 'border-box',
                }}
              >
                <div className="main">
                  <div className="eira-logo">
                    <img alt="eiralogo" src={eira} style={{ width: '120px' }} />
                  </div>
                  <Typography
                    style={{
                      fontSize: '26px',
                      textAlign: 'center',
                      fontWeight: '550',
                      color: '#5b5e5c',
                      margin: '20px 0 0 0',
                      padding: '0',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Welcome to
                  </Typography>
                  <Typography
                    style={{
                      textAlign: 'center',
                      margin: '0px 0 0 0',
                      fontSize: '26px',
                      fontWeight: '550',
                      color: '#5b5e5c',
                      padding: '0',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Centralised Web Portal!{' '}
                  </Typography>
                  <Typography
                    style={{
                      margin: '30px 0 0 0',
                      color: '#6c756f',
                      textAlign: 'left',
                      padding: '0',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Please sign-in to your account
                  </Typography>
                  <div className="mail-pass">
                    <TextField
                      id="outlined-basic"
                      placeholder="Email"
                      onChange={(e) => handleEmailChange(e)}
                      error={emailError}
                      onKeyDown={handleNextfield}
                      helperText={
                        emailError ? (
                          <span style={{ color: '#ff0f0f' }}>
                            Please enter a valid email address
                          </span>
                        ) : (
                          ''
                        )
                      }
                      variant="outlined"
                      style={{
                        borderRadius: '9PX',
                        hight: '10px',
                        width: '100%',
                        marginTop: '5px',
                      }}
                    />
                    {/* <FormControl sx={{ m: 3, width: '38ch', borderRadius: "90px", }} variant="outlined"> */}
                    <OutlinedInput
                      sx={{ width: '100%', margin: '20px 0 0 0' }}
                      onKeyDown={handleEnterKeyPress}
                      inputRef={passwordRef}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      onChange={(m) => setPassword(m.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-placeholder="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password"
                    />
                    {/* </FormControl> */}
                  </div>
                  <Stack direction="row" justifyContent="space-around">
                    <Link href="forgot" variant="body2" className="link-signup">
                      Forgot Password?
                    </Link>
                    <Link
                      style={{ marginLeft: '25%' }}
                      href="signup"
                      variant="body2"
                      className="link-signup"
                    >
                      Don't have an account? Sign up
                    </Link>
                  </Stack>
                  <Button
                    variant="contained"
                    disabled={toBackEnd}
                    sx={{
                      fontSize: '26px',
                      width: '100%',
                      backgroundColor: '#000440',
                      margin: '20px 0 20px 0',
                      height: '45px',
                      letterSpacing: '0.5px',
                      textTransform: 'capitalize',
                      borderRadius: '20px 20px 20px 20px',
                      ':hover': {
                        backgroundColor: '#FFFFFF', // Change background color on hover
                        color: '#000440', // Change text color on hover
                      },
                    }}
                    onClick={handleNavigate}
                  >
                    Login
                  </Button>
                </div>
                <div>
                  <CustomSnackbar
                    open={snack.open}
                    onClose={handleClose}
                    autoHideDuration={5000}
                    severity={snack.severity}
                    message={snack.message}
                  />
                </div>
              </Paper>
            </Box>
          </>
        )}
      </div>
    </div>
  );
};
export default Loginpage;
