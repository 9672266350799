import React, { useState, useEffect, useContext } from 'react';
import { useUserContext } from '../Auth/UserContext';

import TableTemplate from '../../Template/TableTemplate';
import {
  fetchsectorTable,
  Getdropdownsectorlist,
  Getdropdowndata,
  putupdatesectorvalue,
  postsectordata,
  // fetchStatusTable,
} from '../../Api/SectorApi';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  fetchStatusTable,
  fetchStatusTableById,
  GetDreviedDrop,
  postStatus,
  PostStatusConfig,
  putStatus,
} from '../../Api/StatusApi';
import Backdrop from '@mui/material/Backdrop';
import FadeLoader from 'react-spinners/FadeLoader';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import Autocomplete from '@mui/material/Autocomplete';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Tooltip,
} from '@mui/material';
import Download from '../../Template/Excel/Download';
import { Statusconfig, StatusMaster } from '../util/TextField';
import { StatusConfigSchema, StatusSchema } from '../util/ValidateSchema';
import { Statusdropdown } from '../../Api/CommonApi';
import CustomSnackbar from '../util/components/CustomSnackbar';
import replaceObjectById from '../util/StateUpdater';
import errorMsg from '../util/errorMessage.json';
import { GetCustomerdropdowndata } from '../../Api/UserConfigurationAPI';
import { CheckForLight } from '../util/common/CommanFunction';
import * as yup from 'yup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const headCells = [
  {
    label: 'Status Name',
    id: 'statusMasterName',
    view: true,
    default: true,
  },
  {
    label: 'Created On',
    id: 'createdDate',
    view: false,
  },
  {
    label: 'Updated On',
    id: 'lastUpdatedDate',
    view: false,
  },
  {
    label: 'Status',
    id: 'status',
    view: true,
    default: true,
  },
  {
    label: 'Action',
    id: 'action',
    view: true,
    default: true,
  },
];

const Status = ({ StatusConfiguration }) => {
  const { userContextValue } = useUserContext();
  const { userMapDetails } = userContextValue || {};
  const [loading, setLoading] = useState(true);
  const [tableValue, setTableValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState();
  const [customerId, setCustomerId] = useState([]);
  const [customerIds, setcustomerId] = useState([]);
  const [sectorIds, setSectorId] = useState([]);
  const [masterName, setMasterName] = useState('');
  const [sectorName, setSectorName] = useState('');
  const initalState = {
    createdDate: '',
    lastUpdatedDate: '',
    createdBy: '',
    status: '',
    statusMasterName: '',
    lastUpdatedBy: '',
    statusMasterId: '',
    customerId: '',
    customerName: '',
  };
  const initalConfig = {
    createdDate: '',
    lastUpdatedDate: '',
    createdBy: '',
    status: '',
    statusMessage: '',
    lastUpdatedBy: '',
    statusMasterId: '',
    statusCode: '',
    derivedStatus: '',
    description: '',
  };
  const [toBackend, setToBackend] = useState(false);
  const [stsField, setStsField] = useState(initalState);
  const [statusDrop, setStatusDrop] = useState([]);
  const [Derived, setDerived] = useState([]);
  const [statusName, setstatusName] = useState('');
  const [siteTab, setSiteTab] = React.useState('');
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const [config, setConfig] = useState(false);
  const [configTo, setConfigTo] = useState(null);
  const [ConfigVal, setConfigVal] = useState(initalConfig);

  const [text, setTextField] = useState([]);
  const [configField, setConfigField] = useState([]);
  useEffect(() => {
    let data = Statusconfig();
    let config = StatusMaster();
    setTextField(data);
    setConfigField(config);
  }, []);

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  useEffect(() => {
    fetchData();
    fetchStatus();
    getCustomerList();
    fetchDerived();
  }, [userMapDetails]);
  // console.log(SectorConfigurations, 'SectorConfigurations');

  /*---------------------------------------Table Fetch data ------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchStatusTableById();
      if (Array.isArray(data)) {
        setTableValue(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setTableValue([data]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCustomerList = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      console.log(data, 'GetCustomerdropdowndata');
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === 'object' && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  console.log(tableValue, 'ghjk');
  /*-----------------------------Drop Down ----------------------------------*/
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchDerived = async () => {
    const data = await GetDreviedDrop();
    if (Array.isArray(data)) {
      setDerived(data);
    } else if (typeof data === 'object' && data !== null) {
      setDerived([data]);
    }
  };
  /*------------------------------------- Onchange Values --------------------*/

  const handleConfig = (val) => {
    setConfig(!config);
    setConfigVal(initalConfig);
    setConfigTo(val);
  };

  const handleChangeTxt = (e, state, set) => {
    set({ ...state, [e.target.name]: e.target.value });
  };
  const handleDropdownChange = (event, value, name, state, set) => {
    set({ ...state, [name]: value });
  };

  const handleClose = () => {
    setStsField(initalState);
    setEdit(false);
    setEditValue([]);
    setMasterName('');
    setOpen(false);
  };

  const handleMasterName = (event) => {
    console.log(event.target.value);
    if (event.target.value === null) {
      setMasterName('');
    } else {
      setMasterName(event.target.value);
    }
  };

  const handleCrate = () => {
    setOpen(true);
    // seteditcall("");
  };
  const handleStatus = (event) => {
    setstatusName(event?.target.value);
  };

  const isOEM = sessionStorage.getItem('userTypeId') == 1;

  /*-------------------------------------Add Sector Call ---------------------*/
  const handleSubmit = async (values) => {
    let customerIdFilter = customerId?.filter((data) => {
      return data.customerName === values.customerName;
    });
    let data = {
      customerId: isOEM
        ? customerIdFilter[0].customerId
        : Number(sessionStorage.getItem('customerId')),
      createdBy: sessionStorage.getItem('id'),
      status: 1,
      statusMasterName: values.statusMasterName,
    };
    console.log(values);
    console.log(data);
    try {
      setToBackend(true);
      const responseData = await postStatus(data);
      console.log(responseData, 'responseData');
      fetchData();
      setSnack({
        ...snack,
        open: true,
        severity: 'success',
        message: responseData.statusDescription,
      });
      handleClose();
      setToBackend(false);
      return 1;
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('status:', error.message);
      setToBackend(false);
      return 0;
    }
  };
  /*--------------------------------------- Edit popup Open----------------------------*/
  const handleEditOpen = (sectorId) => {
    let filteredsectorId = Object.keys(initalState).reduce((acc, key) => {
      if (sectorId.hasOwnProperty(key)) {
        acc[key] = sectorId[key];
      }
      return acc;
    }, {});
    setEditValue(sectorId);
    setStsField(filteredsectorId);
    setstatusName();
    setEdit(true);
    setOpen(true);
  };

  /*------------------------------------------Update Sector ---------------------------*/
  const handleUpdate = async (values) => {
    const statusId = editValue.statusMasterId;
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    let customerIdFilter = customerId?.filter((data) => {
      return data.customerName === values.customerName;
    });
    let obj = {
      customerId: isOEM
        ? values.customerId
        : Number(sessionStorage.getItem('customerId')),
      statusMasterName: values.statusMasterName,
      status: edit
        ? typeof values.status === 'string'
          ? status[0].value
          : values.status
        : status[0].value,
      lastUpdatedBy: sessionStorage.getItem('id'),
    };
    console.log(obj, 'obj');
    try {
      setToBackend(true);
      let responsedata = await putStatus(obj, statusId);
      // console.log(responsedata);
      const updatedTable = replaceObjectById(
        tableValue,
        'statusMasterId',
        statusId,
        responsedata
      );
      // fetchData();
      handleClose();
      setSnack(errorMsg.success);
      setToBackend(false);
      return 1;
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('status:', error.message);
      setToBackend(false);
      return 0;
    }
  };

  const handleConfigPost = async (values) => {
    let Ds = Derived?.filter((data) => {
      return data.derivedStatusName === values.derivedStatus;
    });
    const payLoad = {
      statusMessage: values.statusMessage,
      derivedStatus: Ds[0].derivedStatusId,
      description: values.description,
      statusMasterId: configTo.statusMasterId,
      statusCode: values.statusCode,
      status: 1,
      createdBy: sessionStorage.getItem('id'),
    };
    console.log(payLoad, 'payload');
    try {
      setToBackend(true);
      const response = PostStatusConfig(payLoad);
      handleConfig();
      setSnack(errorMsg.success);
      setToBackend(false);
      window.location.reload();
      return 1;
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('status:', error.message);
      setToBackend(false);
      return 0;
    }
  };
  /*-----------------------------------------Table Download ----------------------------*/
  const handleDownloadTable = (val) => {
    Download(val, 'StatusConfig');
  }; // in val we can modify which coulmn need to download as of now i download entire value.
  const tableValues = tableValue.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = tableValue.length;

  const Active = tableValue.filter((site) => site.status == 1).length;

  const Inactive = tableValue.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Status', value: 'all', badgeCount: Total },
    { name: 'Active Status', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Status',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Status Master', path: 'statusConfiguration' },
  ];

  return (
    <div>
      {' '}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <TableTemplate
            PageName={'Status'}
            addButton={'Status'}
            SearchLabel={'Search Status Here... '}
            header={headCells}
            rowsValue={tableValues}
            tabsData={tabsData}
            handleChange={handleChangeTab}
            rawData={tableValue}
            userRole={StatusConfiguration[0]}
            handleAddPopupOpen={(val) => handleCrate(val)}
            handleEditPopupOpen={(val) => handleEditOpen(val)}
            handleCofigOpen={(val) => handleConfig(val)}
            handleDownloadExcel={(val) => {
              handleDownloadTable(val);
            }}
            paths={paths}
            fallbackText={`No${
              siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''
            } Status has been created yet`}
          />

          <div className="Modal">
            <Formik
              key={edit ? 'edit' : 'create'}
              enableReinitialize={true}
              initialValues={stsField}
              validationSchema={
                isOEM
                  ? StatusSchema.concat(
                      yup.object().shape({
                        customerName: yup
                          .string()
                          .required('Customer Name is required'),
                      })
                    )
                  : StatusSchema
              }
              onSubmit={async (values, { resetForm }) => {
                let submitValue = values;
                if (edit) {
                  console.log(values);
                  const result = await handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSubmit(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    sx={{
                      marginTop: '15vh',
                    }}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalSmall'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '20vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    s
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={edit ? 'Update Status' : 'Add New Status'}
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          <Card
                            sx={{
                              overflowY: 'visible',
                              marginLeft: '-1vw',
                              scrollBehavior: 'smooth',
                              scrollbarGutter: 'stable',
                              scrollbarWidth: 'thin',
                              '&::-webkit-scrollbar': {
                                width: '0.4em',
                              },
                              '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '20px',
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                background: '#555',
                              },
                            }}
                            elevation={0}
                          >
                            <CardContent sx={{ marginTop: '-1%' }}>
                              {text?.length > 0 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    marginLeft: '1.75vw',
                                    paddingBottom: '10px',
                                  }}
                                  className="modelGrid"
                                >
                                  <Grid container spacing={3} columns={32}>
                                    {text?.map((data, index) => {
                                      return (
                                        <>
                                          {data.type === 'dropdown' &&
                                          data.view ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit &&
                                                        data.name === 'status'
                                                      ? typeof values[
                                                          data.name
                                                        ] === 'string'
                                                        ? values[data.name]
                                                        : values.status === 1
                                                        ? 'Active'
                                                        : 'Inactive'
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Field
                                                    name={data.name}
                                                    render={({
                                                      field,
                                                      form,
                                                    }) => (
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit &&
                                                              data.name ===
                                                                'status'
                                                            ? typeof values[
                                                                data.name
                                                              ] === 'string'
                                                              ? values[
                                                                  data.name
                                                                ]
                                                              : values.status ===
                                                                1
                                                              ? 'Active'
                                                              : 'Inactive'
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Autocomplete
                                                          disabled={
                                                            toBackend ||
                                                            (!edit &&
                                                              data.name ===
                                                                'status') ||
                                                            (edit &&
                                                              data.name ===
                                                                'customerName')
                                                          }
                                                          disableClearable
                                                          options={
                                                            data.name ===
                                                            'customerName'
                                                              ? customerId.map(
                                                                  (option) => {
                                                                    return `${option.customerName}`;
                                                                  }
                                                                )
                                                              : statusDrop?.map(
                                                                  (option) =>
                                                                    option.label
                                                                )
                                                          }
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) => {
                                                            handleDropdownChange(
                                                              event,
                                                              value,
                                                              data.name,
                                                              stsField,
                                                              setStsField
                                                            );
                                                          }}
                                                          disablePortal
                                                          size="small"
                                                          value={
                                                            edit &&
                                                            data.name ===
                                                              'customerName'
                                                              ? values.customerName
                                                              : !edit &&
                                                                data.name ===
                                                                  'status'
                                                              ? 'Active'
                                                              : edit &&
                                                                data.name ===
                                                                  'status'
                                                              ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values.status ===
                                                                  1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : values[
                                                                  data.name
                                                                ]
                                                          }
                                                          id={`combo-box-demo-${index}`}
                                                          sx={{ width: '14vw' }}
                                                          ListboxProps={{
                                                            style: {
                                                              maxHeight:
                                                                '200px', // Set your desired height here
                                                            },
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                            />
                                                          )}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  />
                                                </Tooltip>
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : data.type === 'textField' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  as={TextField}
                                                  disabled={toBackend}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  inputProps={{
                                                    maxLength: data.length,
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleChangeTxt(
                                                      e,
                                                      stsField,
                                                      setStsField
                                                    );
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={values[data.name]}
                                                  sx={{ width: '15vw' }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </div>
                              ) : null}
                            </CardContent>
                          </Card>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          <div className="Modal">
            <Formik
              key={edit ? 'edit' : 'create'}
              enableReinitialize={true}
              initialValues={ConfigVal}
              validationSchema={StatusConfigSchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = values;

                const result = await handleConfigPost(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={config}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={config}>
                      <Box
                        className={'styleModalSmall'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '20vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    s
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleConfig();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={
                              edit
                                ? 'Update Configuration'
                                : 'Add Status Configuration'
                            }
                          />
                          <Divider
                            style={{
                              borderColor: '#888',
                              marginBottom: '-2vh',
                            }}
                          />
                          {configField?.length > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                // marginTop: '1vh',
                                marginLeft: '3vh',
                              }}
                              className="modelGrid"
                            >
                              <Card
                                sx={{
                                  overflowY: 'visible',
                                  marginLeft: '-2vw',
                                  paddingLeft: '1vw',
                                  paddingBottom: '1%',
                                  scrollBehavior: 'smooth',
                                  scrollbarGutter: 'stable',
                                  scrollbarWidth: 'thin',
                                  '&::-webkit-scrollbar': {
                                    width: '0.4em',
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888',
                                    borderRadius: '20px',
                                  },
                                  '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                  },
                                }}
                                elevation={0}
                              >
                                <CardContent sx={{}}>
                                  <Grid container spacing={3} columns={32}>
                                    {configField?.map((data, index) => {
                                      return (
                                        <>
                                          {data.type === 'dropdown' &&
                                          data.view ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit &&
                                                        data.name === 'status'
                                                      ? typeof values[
                                                          data.name
                                                        ] === 'string'
                                                        ? values[data.name]
                                                        : values.status === 1
                                                        ? 'Active'
                                                        : 'Inactive'
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Field
                                                    name={data.name}
                                                    render={({
                                                      field,
                                                      form,
                                                    }) => (
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit &&
                                                              data.name ===
                                                                'status'
                                                            ? typeof values[
                                                                data.name
                                                              ] === 'string'
                                                              ? values[
                                                                  data.name
                                                                ]
                                                              : values.status ===
                                                                1
                                                              ? 'Active'
                                                              : 'Inactive'
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Autocomplete
                                                          disabled={
                                                            toBackend ||
                                                            (!edit &&
                                                              data.name ===
                                                                'status') ||
                                                            (edit &&
                                                              data.name ===
                                                                'customerName')
                                                          }
                                                          // disableClearable
                                                          options={
                                                            data.name ===
                                                            'customerName'
                                                              ? customerId.map(
                                                                  (option) => {
                                                                    return `${option.customerName}`;
                                                                  }
                                                                )
                                                              : data.name ===
                                                                'derivedStatus'
                                                              ? Derived.map(
                                                                  (op) =>
                                                                    op.derivedStatusName
                                                                )
                                                              : statusDrop?.map(
                                                                  (option) =>
                                                                    option.label
                                                                )
                                                          }
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) => {
                                                            handleDropdownChange(
                                                              event,
                                                              value,
                                                              data.name,
                                                              ConfigVal,
                                                              setConfigVal
                                                            );
                                                          }}
                                                          disablePortal
                                                          size="small"
                                                          value={
                                                            !edit &&
                                                            data.name ===
                                                              'status'
                                                              ? 'Active'
                                                              : edit &&
                                                                data.name ===
                                                                  'status'
                                                              ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values.status ===
                                                                  1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : values[
                                                                  data.name
                                                                ]
                                                          }
                                                          id={`combo-box-demo-${index}`}
                                                          sx={{ width: '14vw' }}
                                                          ListboxProps={{
                                                            style: {
                                                              maxHeight:
                                                                '200px',
                                                            },
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                            />
                                                          )}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  />
                                                </Tooltip>
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : data.type === 'textField' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  as={TextField}
                                                  disabled={toBackend}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleChangeTxt(
                                                      e,
                                                      ConfigVal,
                                                      setConfigVal
                                                    );
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={values[data.name]}
                                                  sx={{ width: '15vw' }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : data.type === 'number' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  as={TextField}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  type={data.type}
                                                  onChange={(e) => {
                                                    let numLength;
                                                    if (
                                                      e.target.name ===
                                                      'mobileNumber'
                                                    ) {
                                                      numLength = 10;
                                                    } else {
                                                      numLength = 7;
                                                    }
                                                    if (
                                                      e.target.value.length <=
                                                      numLength
                                                    ) {
                                                      handleChange(e);
                                                      handleChangeTxt(
                                                        e,
                                                        ConfigVal,
                                                        setConfigVal
                                                      );
                                                    }
                                                  }}
                                                  inputProps={{
                                                    maxLength: 10,
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={values[data.name]}
                                                  sx={{ width: '15vw' }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </CardContent>
                              </Card>
                            </div>
                          ) : null}
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};
export default Status;
