import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const CustomSnackbar = ({
  open,
  onClose,
  autoHideDuration,
  severity,
  message,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      key={message}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%', borderRadius: '10px' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
