// ExcelTemplateDownload.js
import * as XLSX from "xlsx";

export const TemplateCheck = (prop, OEM) => {
  const workbook = XLSX.utils.book_new();
  const ErrorSheet = [
    [
      'Error Code',
      "Equipment Category",
      "Equipment Type",
      "Description",
      "Error Message",
      "Error Type",
      "Priority",
      "Approved By",
    ],
  ];
  const ExpectedDataSheet = [["Site Name", "Month", "Expected Data"]];
  const StandardParamSheet = [["Parameter Name", "UOM", "Description"]];
  const EquipDetails = [
    [
      "Site Name",
      "Display Name",
      "Equipment Category",
      "Equipment Type",
      "AC Capacity",
      "DC Capacity",
      "AcUnits",
      "DcUnits",
      "Serial No",
      "Latitude",
      "Longitude",
      "Primary Equipment",
      "No Of Components",
      "Remark",
      "Installation Date",
      "Warranty Date"
      // Address
    ],
  ];
  // const EquipConfig = [[
  //   "Daily GenBased On",
  //   "DataLogger Id",
  //   "FTPDef Devicesn",
  //   "Modbus Address",
  //   "ProdFlag",
  //   "Status Master Id"
  // ]]
  let resultSheet;
  switch (prop) {
    case "Error":
      resultSheet = ErrorSheet;
      break;
    case "Standard Parameter":
      // alert('standard Data')
      resultSheet = StandardParamSheet;
      break;
    case "Equip Details":
      resultSheet = EquipDetails;
      break;
    case "Expected Data":
      resultSheet = ExpectedDataSheet;
      break;
    // case 'Equip Config':
    //   resultSheet = EquipConfig;
    //   break;
    default:
      break;
  }
  console.log(resultSheet, "proptesttt");

  // prop !== "Equip Details" && resultSheet[0].push("Customer Name");

  /*----------------- Except Customer Name for the Expect Data Page ----------------------*/

  const includeCustomerName = prop !== "Expected Data";
  if (includeCustomerName && prop !== "Equip Details" && OEM) {
    resultSheet[0].push("Customer Name");
  }

  const worksheet = XLSX.utils.aoa_to_sheet(resultSheet);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelData = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  const blob = new Blob([s2ab(excelData)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return blob;
};

export const downloadExcelTemplate = (prop, OEM) => {
  const excelBlob = TemplateCheck(prop, OEM);
  const url = window.URL.createObjectURL(excelBlob);
  const a = document.createElement("a");
  a.href = url;
  a.download = prop + " Template.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Helper function to convert string to ArrayBuffer
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}
