import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Divider,
  Modal,
  Fade,
  Card,
  Stack,
  CardHeader,
  Autocomplete,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import '../../Common.css';
import { useNavigate } from 'react-router';
import {
  fetchSitesubcriptionTable,
  putFormalaupdateEdit,
  // postFormaladata,
  fetchSiteDetails,
} from '../../Api/FormulaApi';
import {
  postsubscriptiondata,
  fetchsubscriptionTable,
  putSubscriptionEdit,
} from '../../Api/SubscriptionApi';
// ========Modal================
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FadeLoader from 'react-spinners/FadeLoader';
import TableTemplate from '../../Template/TableTemplate';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';
/*------------api call-----------------*/

import CustomSnackbar from '../util/components/CustomSnackbar';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Scriptions } from '../util/TextField';
import { subcriptionSchema } from '../util/ValidateSchema';
import replaceObjectById from '../util/StateUpdater';
import errorMsg from '../util/errorMessage.json';
import Switch from '@mui/material/Switch';

export default function Subcriptionhome({ Report }) {
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const [CreateFormala, setCreateFormala] = useState(false);

  /*------------ modal state-----------------*/
  const [siteIdName, setSiteId] = useState([]);
  const [stdparameterId, setparameterId] = useState([]);
  const [Sitesubcription, setSiteFormala] = useState([]);
  const [siteSitesubcription, setSiteSitesubcription] = useState([]);
  const [editcall, seteditcall] = useState();
  const [loading, setLoading] = useState();
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDatee, setSelectedDatee] = useState(null);
  const [edit, setEdit] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [checked, setChecked] = useState(false);
  const [toggle, settoggle] = useState();
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const initalState = {
    poNumber: "",
    siteName: "",
    PoFromDate: "",
    PoToDate: "",
    curing_Data: "",
    status: "",

  };
  const [FormalaFields, setFormalaFields] = useState(initalState);
  console.log(FormalaFields, 'editcall');
  const [text, setTextField] = useState([]);
  useEffect(() => {
    let data = Scriptions();
    setTextField(data);
  }, []);

  useEffect(() => {
    fetchData();
    getSiteCall();
  }, []);
  const Flog = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'yes' },
  ];
  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'siteName':
        console.log(siteIdName, 'siteIddrop');
        return (option = siteIdName?.map((option) => option.siteName));
      case 'flog':
        console.log(siteIdName, 'siteIddrop');
        return (option = Flog?.map((option) => option.label));
      case 'status':
        return (option = statusList);
      default:
    }
    return option;
  }
  console.log(stdparameterId, 'parameterId');
  const defaultMail = sessionStorage.getItem('email');
  console.log(FormalaFields.mailId, 'emailemailemail');
  /*------------------------- Api --------------------------------------------*/
  const getSiteCall = async () => {
    try {
      console.log('start');
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDetails();
      console.log(data, 'sitelist');
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (FormalaFields.siteName !== '') {
      let SitenameID = siteIdName?.filter((data) => {
        return data.siteName === FormalaFields?.siteName;
      });
      console.log(SitenameID, 'SitenameID');
    }
  }, [FormalaFields.siteName]);

  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async () => {
    let id = editcall?.sub_detailsId;
    console.log(id);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === FormalaFields.siteName;
    });
    function addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate());
      return result;
    }
    let obj = {
      siteId: siteIdFilter[0]?.siteId,
      poNumber: FormalaFields?.poNumber,
      PoFromDate: FormalaFields.PoFromDate
        ? addDays(FormalaFields.PoFromDate, 1).toISOString().substring(0, 10)
        : null,
      PoToDate: FormalaFields.PoToDate
        ? addDays(FormalaFields.PoToDate, 1).toISOString().substring(0, 10)
        : null,
      curingData: toggle,
      status: FormalaFields.status === 'Inactive' ? '0' : FormalaFields.status === ' Active' ? '1' : FormalaFields.status === 0 ? '0' : '1',
    };
    console.log(FormalaFields, 'FormalaFields');

    try {
      setToBackend(true);
      let responsedata = await putSubscriptionEdit(obj, id);
      if (responsedata.status === 200) {
        const updatedTable = replaceObjectById(
          Sitesubcription,
          'sub_detailsId',
          id,
          responsedata.data
        );
        fetchData();
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        handleClose();
        seteditcall('');
        return 1;
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('Error:', error.response.status);
      setToBackend(false);
      return 0;
    }
  };
  useEffect(() => {
    if (edit && editcall) {
      setChecked(editcall.curing_Data === 'Yes');
    } else {
      setChecked(false);
    }
  }, [edit, editcall]);

  const handlecheckChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    const valueToSend = isChecked ? 'Yes' : 'No';
    console.log(valueToSend);
    settoggle(valueToSend);
  };
  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async () => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === FormalaFields.siteName;
    });

    function addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    let data = {
      siteId: siteIdFilter[0]?.siteId,
      poNumber: FormalaFields.poNumber,
      PoFromDate: FormalaFields.PoFromDate
        ? addDays(FormalaFields.PoFromDate, 1).toISOString().substring(0, 10)
        : null,
      PoToDate: FormalaFields.PoToDate
        ? addDays(FormalaFields.PoToDate, 1).toISOString().substring(0, 10)
        : null,
      createdBy: sessionStorage.getItem('id'),
      curingData: toggle,
      status: 1,
    };

    console.log(data);
    try {
      setToBackend(true);
      const responseData = await postsubscriptiondata(data);
      if (responseData.status === 200) {
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: 'success',
          message: responseData.data.statusDescription,
        });
        handleClose();
        setToBackend(false);
        setOpen(false);
        settoggle();
        return 1;
      }
    } catch (e) {
      console.error('Error:', e.response.data.statusDescription);
      setToBackend(false);
      setSnack({
        ...snack,
        open: true,
        severity: 'error',
        message: e.response.data.statusDescription,
      });
      console.error('Error:', e.response.data.statusDescription);
      console.log('error:', e.data);

      return 0;
    }
  };

  /*-------------------------Table get call --------------------------------------------*/

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchsubscriptionTable();
      if (Array.isArray(data)) {
        setSiteSitesubcription(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setSiteSitesubcription([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCrate = () => {
    setCreateFormala(true);
    setOpen(true);
    setEdit(false);
    seteditcall();
    setChecked(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setFormalaFields(initalState);
  };

  const statusList = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const equipmentHeadCells = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
    },
    {
      label: 'Po Number',
      id: 'poNumber',
      view: true,
    },
    {
      label: 'From Date',
      id: 'poFromDate',
      view: true,
    },
    {
      label: 'To Date',
      id: 'poToDate',
      view: true,
    },
    {
      label: "To Date",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
    },
  ];

  /*------------ modal state-----------------*/
  const handleEditOpen = async (sub_detailsId) => {
    let filteredFormalaMapId = Object.keys(initalState).reduce((acc, key) => {
      if (sub_detailsId.hasOwnProperty(key)) {
        acc[key] = sub_detailsId[key];
      }
      return acc;
    }, {});
    seteditcall(sub_detailsId);
    setFormalaFields(filteredFormalaMapId);
    setOpen(true);
    setEdit(true);
  };

  const handleDropdownChange = (event, value, name) => {
    console.log(value, 'dropValue');
    if (name === 'timePeriod' || name === 'status') {
      setFormalaFields({ ...FormalaFields, [name]: value.label });
    } else {
      setFormalaFields({ ...FormalaFields, [name]: value });
    }
  };
  // ========customrange=========

  const handleTxtChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;
    console.log(value, 'fgh');
    setFormalaFields({
      ...FormalaFields,
      [name]: newValue,
    });
  };

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Subscription', path: 'Subscription' },
  ];

  const handleDatepicChange = (date, name) => {
    console.log(date.toString());
    setFormalaFields({ ...FormalaFields, [name]: date });
  };

  return (
    <div>
      {' '}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          {/* ---------search---bar--- Card----------- */}

          <div>
            {/* ---------   -----save andupdate---- popup----------- */}
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <Formik
              key={edit ? 'edit' : 'add'}
              enableReinitialize={true}
              initialValues={FormalaFields}
              validationSchema={subcriptionSchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = {
                  // siteName: values.siteName,
                  // PoNumber: values.poNumber,
                  //  PoFromDate: values.PoFromDate,
                  //  PoToDate: values.PoToDate,
                  //  status:values.status
                };
                if (edit) {
                  const result = await handleUpdate(submitValue);
                  console.log(result, 'result');
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSave(submitValue);
                  console.log(result, 'result');
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalSmall'}
                        style={{ height: '40vh' }}
              
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                         
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                           
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleUpdate}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={'Add Subscription'}
                          />
                          <Divider sx={{ borderColor: '#888' }} />
                          <div
                            style={{
                              height: '30vh',
                              padding: '20px',
                            }}
                          >
                            <Grid container spacing={2} columns={16}>
                              {text?.length > 0
                                ? text?.map((data, index) => (
                                  <Grid item xs={4}>
                                    <div key={index}>
                                      

                                      {data.type === 'textField' ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Field
                                            as={TextField}
                                            disabled={toBackend}
                                            id={`outlined-basic-${index}`}
                                            size="small"
                                            variant="outlined"
                                            name={data.name}
                                            inputProps={{
                                              maxLength: data.length,
                                            }}
                                            placeholder={data.placeholder}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleTxtChange(e);
                                            }}
                                            sx={{ width: '12vw' }}
                                          />
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : data.type === 'dropdown' ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Tooltip>
                                            <Field
                                              render={({ field, form }) => (
                                                <Tooltip>
                                                  <Autocomplete
                                                    disabled={
                                                      toBackend ||
                                                      (!editcall &&
                                                        data.name ===
                                                        "status") ||
                                                      (editcall &&
                                                        data.name ===
                                                        "siteName")
                                                    }
                                                    options={getOptions(
                                                      data.name
                                                    )}
                                                    size="small"
                                                    id={`combo-box-demo-${index}`}
                                                    onChange={(
                                                      event,
                                                      value
                                                    ) =>
                                                      handleDropdownChange(
                                                        event,
                                                        value,
                                                        data.name
                                                      )
                                                    }
                                                    value={
                                                      !edit &&
                                                        data.name === 'status'
                                                        ? 'Active'
                                                        : edit &&
                                                          data.name ===
                                                          'status'
                                                          ? typeof values[
                                                            data.name
                                                          ] === 'string'
                                                            ? values[data.name]
                                                            : values.status ===
                                                              1
                                                              ? 'Active'
                                                              : 'Inactive'
                                                          : values[data.name]
                                                    }
                                                    sx={{ width: '12vw' }}
                                                    ListboxProps={{
                                                      style: {
                                                        maxHeight: '200px', // Set your desired height here
                                                      },
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                      />
                                                    )}
                                                  />
                                                </Tooltip>
                                              )}
                                            />
                                          </Tooltip>
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : data.type === 'date' ? (
                                        <>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <DatePicker
                                              size="small"
                                              sx={{
                                                width: '12vw',
                                              }}
                                              name={data.name}
                                              onChange={(e, name) => {
                                                handleDatepicChange(
                                                  e,
                                                  data.name
                                                );
                                              }}
                                              defaultValue={
                                                edit &&
                                                  data.name === 'PoToDate'
                                                  ? editcall
                                                    ? dayjs(editcall.poToDate)
                                                    : ''
                                                  : edit &&
                                                    data.name === 'PoFromDate'
                                                    ? editcall
                                                      ? dayjs(
                                                        editcall.poFromDate
                                                      )
                                                      : ''
                                                    : editcall
                                              }
                                              shouldDisableDate={(date) =>
                                                data.name === 'PoToDate'
                                                ? dayjs(date).isBefore(dayjs(FormalaFields.PoFromDate), 'day'):null
                                              }
                                              slotProps={{
                                                textField: {
                                                  size: 'small',
                                                },
                                              }}
                                            />
                                          </LocalizationProvider>
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : null}
                                      {data.type === 'switch' && (
                                        <>
                                          <div
                                            style={{
                                              display: 'flex',
                                              marginTop: '3vh',
                                              marginLeft: '2vw',
                                            }}
                                          >
                                            <div>
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                                style={{ width: '20%' }}
                                              >
                                                {data.label}
                                              </Typography>
                                            </div>
                                            <div
                                              style={{ marginLeft: '3vw' }}
                                            >
                                              <Switch
                                                checked={checked}
                                                onChange={handlecheckChange}
                                                style={{ marginTop: '-1%' }}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </Grid>
                                ))
                                : null}
                            </Grid>
                          </div>
                        </Card>

                        <div>
                          <Grid container spacing={2} columns={32}></Grid>
                        </div>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          {/* ---------------pass props value----------- */}

          <div style={{ marginTop: '-0.5%' }}>
            <TableTemplate
              PageName={'Subscription'}
              addButton={'Subscription'}
              SearchLabel={'Search Subscription Here... '}
              rawData={siteSitesubcription}
              header={equipmentHeadCells}
              rowsValue={siteSitesubcription}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              userRole={Report[0]}
              paths={paths}
              fallbackText={`Subscriptions has been configured yet`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
