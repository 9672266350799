import axios from 'axios';

export const dataloggerfilePath = async (id) => {
  try {
    let responseData = await axios.get(
      `${process.env.REACT_APP_FTP_API_PATH}${process.env.REACT_APP_DATALOG_FILELIST}${id}`
    );

    return responseData.data;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};

export const dataloggerName = async (id) => {
  // let id=Number(sessionStorage.getItem('id'))
  try {
    let responseData = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_ALL_PARAMETER}/${id}`
    );
    console.log(responseData.data, 'API Response Data');
    return responseData.data;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};

export const getMonthList = async (id) => {
  // let id=Number(sessionStorage.getItem('id'))
  try {
    let responseData = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_MONTH_LIST}`
    );

    return responseData.data;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};
