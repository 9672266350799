import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const CheckboxGroup = ({
  key,
  groupKey,
  title,
  checkbox,
  configuration,
  onUpdate,
  disabled,
  AllowedKey,
  ViewKey,
}) => {
  const handleCheckboxChange = (key) => {
    console.log(key, 'keyy');
    onUpdate(key);
  };

  return (
    <Stack spacing={2} direction="row" style={{ marginTop: '3%' }}>
      <Stack spacing={2} direction="row">
        {Object?.entries(configuration)?.map(([key, value]) => {
          if (key === 'view') {
            return (
              <FormControlLabel
                key={key}
                disabled={disabled}
                control={
                  <Checkbox
                    checked={value}
                    onClick={() => handleCheckboxChange(key)}
                  />
                }
              />
            );
          }
          return null;
        })}
      </Stack>
      <div style={{ width: '300px', display: 'flex', alignItems: 'center' }}>
        <Typography>{title}</Typography>
      </div>
      <Stack spacing={2} direction="row">
        {Object?.entries(configuration)?.map(([key, value]) => {
          if (key !== 'view') {
            return (
              <FormControlLabel
                key={key}
                disabled={disabled}
                style={{ marginLeft: '20px' }}
                control={
                  <Checkbox
                    checked={value}
                    onClick={() => handleCheckboxChange(key)}
                  />
                }
              />
            );
          }
          return null;
        })}
      </Stack>
    </Stack>
  );
};

export default CheckboxGroup;
