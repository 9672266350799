import React, { useEffect, useState } from 'react';
import './AnalyticStyle.css';
/*-------------------------- MUi icons -------------------------*/
import DeleteIcon from '@mui/icons-material/Delete';
/*-----------------------------Mui components ---------------------------*/
import {
  Typography,
  Stack,
  Button,
  Grid,
  Paper,
  Snackbar,
  Autocomplete,
  TextField,
  Tooltip,
  Box,
  Card,
  Modal,
  CardContent,
  PaginationItem,
  Divider,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '@mui/material/Pagination';
import FadeLoader from 'react-spinners/FadeLoader';
/*----------------------------------- External Components ---------------------*/
import {
  getDataAnalyst,
  WizardDeleteCall,
  postDashboardName,
} from '../../../Api/DataAnalystApi';
import ApexChart from './ApexexChart';
import BarChart from '../../Charts/BarChart';
import AreaChart from '../../Charts/AreaChart';
import CombinedChart from '../../Charts/CombainedChart';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  fetchEquipDropdownBySite,
  fetchParamsDropdownByLogger,
  fetchSiteDropdownByUser,
} from '../../../Api/CommonApi';
import { set } from 'date-fns';
import {
  decryptPaddedQuery,
  paddedEncryptQuery,
} from '../../util/security/Cipher';
import { removeQuestionMark } from '../../util/common/CommanFunction';
/*-------------------- Dummy data---------------------------------*/

const buttonStyle = {
  textTransform: 'capitalize',
  boxShadow: '4px 4px 17px rgba(0, 0, 0.50, 0.50)',
  fontWeight: 'bold',
  // backgroundColor:'#09001A'
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  height: '20vh',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const Analytics = () => {
  const alertStyle = {
    color: 'white', // Text color
    '& .MuiSvgIcon-root': {
      color: 'white', // Icon color
    },
  };
  const navigate = useNavigate();
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const searchParams = new URLSearchParams(location.search);
  const successParam = searchParams.get('success');
  const [openDashboard, setOpenDashboard] = useState(false);
  const [analystData, setAnalystData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [variantText, setVarient] = useState('');
  const [dashboardName, setDashboardName] = useState('');
  const [textValue, setTextValue] = useState('');
  const [analystWizardData, setAnalystWizard] = React.useState([]);
  const [Total, setTotal] = React.useState(0);
  console.log(Total);

  const [siteListAPI, setSiteList] = React.useState([]);
  const [equipmentListAPI, setEquipmentList] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [reLoading, setReLoading] = useState(true);
  const [siteIdName, setSiteId] = useState([]);
  const [site, setSite] = React.useState('');
  const [equpParamMap, setEqupParamMapValue] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [pageValue, setPageValue] = useState('');
  const [page, setPage] = useState(0);
  console.log(page);

  const [rowvalue, setrowvalue] = useState();
  const [Analyst, setAnalyst] = useState([]);
  const rowsPerPageOptions = [4, 8];

  const [openToast, setOpenToast] = React.useState(successParam === 'false');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSite = async (data, val) => {
    if (val === null) {
      setSite('');
    } else {
      const params = new URLSearchParams();
      params.set('siteName', val);
      const encryptedParams = paddedEncryptQuery(params.toString());
      navigate(`?${encryptedParams}`);
      setSite(val);
      setPage(0);
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  useEffect(() => {
    getSiteCall();
  }, []);

  useEffect(() => {
    setAnalystData([]);
    setAnalystWizard([]);
    getAnalystCall();
  }, [site]);

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const getSiteCall = async () => {
    setPageValue(1);
    try {
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDropdownByUser(id);
      if (data.length > 0) {
        setSiteId([...new Set(data)]);
        const siteNameFromSession = queryParam
          ?.split('=')[1]
          ?.replaceAll('+', ' ');
        const name = siteNameFromSession
          ? siteNameFromSession
          : data[0].siteName;
        setSite(name);
      }
    } catch (e) {
      console.error(e);
    }
  };

  async function createEquipmentMap(siteId) {
    // Fetch the equipment dropdown data for the site
    try {
      const equip = await fetchEquipDropdownBySite(siteId);

      // Create a map to store equipment based on dataLoggerId
      const dataLoggerMap = equip.reduce((map, equipment) => {
        const { dataLoggerId } = equipment;
        if (!map[dataLoggerId]) {
          map[dataLoggerId] = [];
        }
        map[dataLoggerId].push(equipment);
        return map;
      }, {});

      const resultMap = {};
      for (const [dataLoggerId, equipments] of Object.entries(dataLoggerMap)) {
        const params = await fetchParamsDropdownByLogger({
          dataloggerId: [Number(dataLoggerId)],
        });
        equipments.forEach((equipment) => {
          resultMap[equipment.displayName] = params.map(
            (param) => param.stdParameterName
          );
        });
      }

      console.log('resultMap', resultMap);

      // Update the state with the resulting map
      setEqupParamMapValue(resultMap);
      return resultMap;
    } catch (e) {
      return [];
    }
  }

  const getAnalystCall = async () => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    setLoading(true);
    setPage(1);
    setPageValue(1);
    if (siteIdFilter && siteIdFilter.length > 0) {
      let siteId = siteIdFilter[0]?.siteId;
      let id = sessionStorage.getItem('id');
      let obj = {
        userId: Number(id),
        siteId: Number(siteId),
        fromCount: fromValue,
        toCount: toValue,
      };

      let getanalyst = await getDataAnalyst(obj);
      await createEquipmentMap(siteIdFilter[0]?.siteId);
      setPage(0);
      setPageValue(1);
      setAnalystData(getanalyst);
      setAnalystWizard(getanalyst.widgetList);
      setSiteList(getanalyst.siteList);
      function convertKey(data) {
        return data.map((obj) => {
          const { equipmentid, ...rest } = obj;
          return {
            equipmentId: equipmentid,
            ...rest,
          };
        });
      }
      setEquipmentList(convertKey(getanalyst.equipmentList));
      setTotal(getanalyst.totalCount);
      setLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate('/menu/add_analyst');
  };
  const handleDelete = async (id) => {
    navigate(location.pathname);
    await WizardDeleteCall(id);
    setDeleteId('');
    setOpen(false);
    setOpenToast(true);
    setVarient('success');
    setTextValue('Your Wizard Deleted Successfully');
    getAnalystCall();
  };
  /*---------------------------------------- deleet wizard-------------*/
  const handlePopup = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function refreshPageAfterTime(timeInSeconds) {
    var timeInMilliseconds = timeInSeconds * 10000;
    setLoading(true);
    setOpen(true);
    // //console.log("opened");
    setTimeout(function () {
      setOpen(false);
      setReLoading(false);
      setLoading(false);
      // //console.log("closed");
    }, timeInMilliseconds);
  }
  useEffect(() => {
    if (reLoading) {
      refreshPageAfterTime(0);
    }
  }, [setLoading, reLoading]);

  const handleCloseDashboard = () => {
    setOpenDashboard(false);
  };

  const handleSubmit = async () => {
    let obj = {
      name: dashboardName,
      UserId: sessionStorage.getItem('id'),
    };
    let getanalyst = await postDashboardName(obj);
    if (getanalyst.data.status === 200) {
      setOpenToast(true);
      setVarient('success');
      setTextValue('Your Wizard Dashboard Created Successfully');
      getAnalystCall();
    } else {
      setOpenToast(true);
      setVarient('Warning');
      setTextValue('Facing Error in Create Dashboard');
    }
  };

  const handleChangePage = async (event, newPage) => {
    console.log(rowsPerPage);
    console.log(newPage);

    const updatedPage = newPage - 1;
    setPage(updatedPage);

    const fromValue = Math.max(updatedPage * rowsPerPage + 1, 1);
    const toValue = Math.max((updatedPage + 1) * rowsPerPage, rowsPerPage);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    let obj = {
      userId: sessionStorage.getItem('id'),
      siteId: siteIdFilter[0]?.siteId,
      fromCount: fromValue,
      toCount: toValue,
    };

    console.log(fromValue, toValue); // To check the range values

    try {
      setLoading(true);
      const getAnalyst = await getDataAnalyst(obj);
      setAnalystWizard(getAnalyst.widgetList);
      setTotal(getAnalyst.totalCount);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChangeRowsPerPage = async (event, newValue) => {
    setRowsPerPage(parseInt(newValue));
    setPage(0);
    setPageValue(0);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    const pagee = 0;
    const fromValue = Math.max(pagee * newValue + 1, 1);
    const toValue = Math.max((pagee + 1) * newValue, newValue);

    let obj = {
      userId: sessionStorage.getItem('id'),
      siteId: siteIdFilter[0]?.siteId,
      fromCount: fromValue,
      toCount: toValue,
    };

    try {
      setLoading(true);
      const getAnalyst = await getDataAnalyst(obj);
      setAnalystWizard(getAnalyst.widgetList);
      setTotal(getAnalyst.totalCount);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const PageChange = (value) => {
    setPageValue(value);
  };

  const handleClickGo = async () => {
    const pageNumber = parseInt(pageValue, 10);
    console.log(pageValue);
    if (pageNumber > 0 && pageNumber <= Math.ceil(Total / rowsPerPage)) {
      console.log(pageNumber, '56789');

      setPage(pageNumber - 1);
      setPageValue('');
    }
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    const fromValue = Math.max((pageNumber - 1) * rowsPerPage + 1, 1);
    const toValue = Math.max((pageNumber - 1 + 1) * rowsPerPage, rowsPerPage);

    let obj = {
      userId: sessionStorage.getItem('id'),
      siteId: siteIdFilter[0]?.siteId,
      fromCount: fromValue,
      toCount: toValue,
    };

    try {
      setLoading(true);
      const getAnalyst = await getDataAnalyst(obj);
      setAnalystWizard(getAnalyst.widgetList);
      setTotal(getAnalyst.totalCount);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={<ArrowBackIcon style={{ fontSize: '16px' }} />}
      {...props}
    />
  );
  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: name === 'NEXT' ? 'row-reverse' : 'row',
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: '14px',

          fontSize: '12px',
        }}
      >
        {name}
      </Typography>
    </div>
  );
  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={<ArrowForwardIcon style={{ fontSize: '16px' }} />}
      {...props}
    />
  );

  const fromValue = Math.max((page - 1) * rowsPerPage + 1, 1);
  const toValue = Math.max(page * rowsPerPage, rowsPerPage);

  const rangeObject = {
    fromValue,
    toValue,
  };

  // console.log(rangeObject.fromValue);
  // console.log(rangeObject.toValue);

  const fallBack = (
    <Card
      sx={{
        width: '100%',
        height: '43vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '43vh',
        }}
      >
        <Typography
          style={{
            fontSize: '1.5rem',
            textTransform: 'capitalize',
            lineHeight: '14.52px !important',
            color: ' rgb(113, 118, 111)',
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <div className="">
      <Card elevation={0}>
        <CardContent>
          <Grid
            container
            spacing={2}
            columns={16}
            direction="row"
            alignItems="center"
          >
            <Grid item xs={4}>
              <div>
                <Typography
                  style={{
                    fontSize: '1.7rem',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    lineHeight: '1.2',
                    color: 'rgb(113, 118, 111)',
                  }}
                >
                  Analytics Dashboard
                </Typography>
                <Tooltip title={site}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    value={site}
                    options={siteIdName.map((option) => option.siteName)}
                    onChange={(event, newValue) => handleSite(event, newValue)}
                    sx={{ width: '20vw' }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Site ..." />
                    )}
                    classes={{ option: 'autocomplete' }}
                  />
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={7}>
              <Typography
                style={{
                  fontSize: '1.7rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  lineHeight: '1.2',
                  color: 'rgb(113, 118, 111)',
                  visibility: 'hidden',
                }}
              >
                Analytics Dashboard
              </Typography>
              <Stack
                spacing={2}
                sx={{
                  visibility:
                    analystWizardData.length > 0 ? 'visible' : 'hidden',
                }}
              >
                <Grid container direction="row" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '80%',
                      visibility:
                        analystWizardData.length === 0 ? 'hidden' : 'visible',
                    }}
                  >
                    <Stack direction="row">
                      <Pagination
                        count={Total === 0 ? 5 : Math.ceil(Total / rowsPerPage)}
                        shape="rounded"
                        color="primary"
                        page={page + 1}
                        onChange={handleChangePage}
                        disabled={analystWizardData.length === 0}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: renderCustomPrevious,
                              next: renderCustomNext,
                            }}
                            {...item}
                          />
                        )}
                      />
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{
                          color: '#E6E7E9',
                          height: '30px',
                          marginRight: '16px',
                        }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginLeft: '1%',
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '12px',
                          lineHeight: '14.52px',
                          fontWeight: '400',
                          color:
                            analystWizardData.length == 0
                              ? 'rgb(207 207 207)'
                              : 'black',
                        }}
                        disabled={analystWizardData.length == 0}
                      >
                        Go to page
                      </Typography>
                      {/* <Button
                    style={{
                      padding: '0',
                      width: '15px',
                      fontSize: '12px',
                      fontWeight: '400',
                    }}
                    onClick={handleClickGo}
                    disabled={analystWizardData.length == 0}
                 
                  >
                   Go to page
                  </Button> */}
                      <TextField
                        variant="standard"
                        style={{
                          width: '24px',
                          marginLeft: '5px',
                        }}
                        disabled={analystWizardData.length == 0}
                        value={pageValue !== 0 ? pageValue : ''}
                        onChange={(e) => PageChange(e.target.value)}
                      />
                      <Button
                        style={{
                          padding: '0',
                          width: '12px',
                          fontSize: '14px',
                        }}
                        onClick={handleClickGo}
                        disabled={analystWizardData.length == 0}
                        endIcon={
                          <ArrowForwardIcon style={{ fontSize: '16px' }} />
                        }
                      >
                        GO
                      </Button>
                      <Autocomplete
                        options={rowsPerPageOptions}
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            sx={{
                              fontFamily: 'Inter, sans-serif',
                              width: 40,
                            }}
                          />
                        )}
                        disabled={analystWizardData.length == 0}
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                        disableClearable
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            {/* Add New Widget Button in a new Grid Item */}
            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2%',
              }}
            >
              <Tooltip title="Add New Widget">
                <Button
                  variant="contained"
                  style={{
                    borderRadius: '25px',
                    textTransform: 'capitalize',
                    marginLeft: '12%',
                  }}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={handleNavigate}
                >
                  New Widget
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <div className="card-analyst">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15%',
                marginLeft: '50%',
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </div>
          ) : analystWizardData.length > 0 ? (
            analystWizardData.map((elem) => (
              <Grid item xs={21} md={6} key={elem.widgetId}>
                <Paper elevation={0} style={{ marginTop: '2%' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={20} md={10.8}>
                      {elem.chartType === 'Daily Generation' &&
                      elem.range === 'custom' ? (
                        <BarChart
                          tableData={elem.dailygenerationChartData}
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          fallBack={fallBack}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'Energy Performance' &&
                        elem.range === 'custom' ? (
                        <BarChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.energyPerformanceChartData}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          fallBack={fallBack}
                          siteListAPI={siteListAPI}
                          equipmentListAPI={equipmentListAPI}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'Daily Generation' &&
                        elem.range === 'daily' ? (
                        <ApexChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.dailygenerationChartData}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          fallBack={fallBack}
                          siteListAPI={siteListAPI}
                          equipmentListAPI={equipmentListAPI}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'Energy Performance' &&
                        elem.range === 'daily' ? (
                        <ApexChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.energyPerformanceChartData}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          siteListAPI={siteListAPI}
                          fallBack={fallBack}
                          equipmentListAPI={equipmentListAPI}
                          loggerMap={equpParamMap}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'Parameter Comparision' &&
                        elem.range === 'daily' ? (
                        <ApexChart
                          chartName={elem.chartType}
                          widgetName={elem.widgetName}
                          tableData={elem.parameterList}
                          chartValue={[elem]}
                          range={elem.range}
                          siteId={elem.siteId}
                          siteListAPI={siteListAPI}
                          fallBack={fallBack}
                          equipmentListAPI={equipmentListAPI}
                          loggerMap={equpParamMap}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'Specific Yield' &&
                        elem.equSpeciYield !== null ? (
                        <CombinedChart
                          tableData={elem.equSpeciYield}
                          WidgetName={elem.widgetName}
                          equipmentListAPI={equipmentListAPI}
                          chartName={elem.chartType}
                          customView={elem}
                          fallBack={fallBack}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'Specific Yield' &&
                        elem.specificYieldInv !== null ? (
                        <CombinedChart
                          tableData={elem.specificYieldInv}
                          WidgetName={elem.widgetName}
                          equipmentListAPI={equipmentListAPI}
                          chartName={elem.chartType}
                          customView={elem}
                          fallBack={fallBack}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'Actual Vs Expected' ? (
                        <CombinedChart
                          tableData={elem.actualVsExcepted}
                          WidgetName={elem.widgetName}
                          chartName={elem.chartType}
                          fallBack={fallBack}
                          customView={elem}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'DG PV Grid Management' &&
                        elem.range === 'custom' ? (
                        <BarChart
                          tableData={elem.pvDgGridSyncvalue}
                          widgetName={elem.widgetName}
                          chartName={elem.chartType}
                          fallBack={fallBack}
                          customView={elem}
                          from={'analytics'}
                        />
                      ) : elem.chartType === 'DG PV Grid Management' &&
                        elem.range === 'daily' ? (
                        <AreaChart
                          tableData={elem.pvDgGridSyncvalue}
                          WidgetName={elem.widgetName}
                          chartName={elem.chartType}
                          fallBack={fallBack}
                          customView={elem}
                          from={'analytics'}
                        />
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs={-2}>
                      <DeleteIcon
                        style={{ marginTop: '45%', cursor: 'pointer' }}
                        onClick={() => {
                          handlePopup(elem?.widgetId);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          ) : (
            <div style={{ marginLeft: '42%', marginTop: '8%' }}>
              <Typography
                style={{
                  fontSize: '1.2rem',
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  lineHeight: '14.52px !important',
                  color: ' rgb(113, 118, 111)',
                }}
              >
                No Widgets Configure for this Site..
              </Typography>
            </div>
          )}
        </Grid>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Delete Confirmation ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to remove this widget form the Analytics Dashboard
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={4}>
              <Button
                color="error"
                variant="contained"
                onClick={() => handleDelete(deleteId)}
                style={buttonStyle}
              >
                Yes, Remove it !
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={handleClose}
                style={buttonStyle}
              >
                No Thanks
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        <div>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleCloseToast}
              severity={variantText}
              sx={{ width: '100%', ...alertStyle }}
            >
              {textValue}
            </Alert>
          </Snackbar>
        </div>
      </div>

      {/* ---------------------------------Dashboard Open Popup------------------------- */}
      <div>
        <Modal
          open={openDashboard}
          onClose={handleCloseDashboard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontSize: '1rem',
                      fontWeight: '400',
                      textTransform: 'capitalize',
                      lineHeight: '14.52px !important',
                      color: ' rgb(113, 118, 111)',
                    }}
                  >
                    Dashboard Name :
                  </Typography>
                  <TextField
                    id="standard-basic"
                    placeholder=" Enter your Dashboard Name"
                    variant="standard"
                    onChange={(e) => {
                      setDashboardName(e.target.value);
                    }}
                    required
                    style={{ textTransform: 'capitalize' }}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: '3%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                style={{ textTransform: 'capitalize' }}
              >
                Save
              </Button>
            </div>
          </Box>
        </Modal>
      </div>

      {/*  <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Delete Confirmation ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Would you like to remove this Dashboard form the Analytics
              Dashboard ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={4}>
              <Button
                color="error"
                variant="contained"
                onClick={() => DeleteDashboard()}
                style={buttonStyle}
              >
                Yes, Remove it !
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={handleClose}
                style={buttonStyle}
              >
                No Thanks
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        <div>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleCloseToast}
              severity={variantText}
              sx={{ width: '100%', ...alertStyle }}
            >
              {textValue}
            </Alert>
          </Snackbar>
        </div>
      </div> */}
      {/* -----------------------------------Dashboard Delete ------------------------------ */}
    </div>
  );
};
export default Analytics;
