import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  Backdrop,
  Divider,
} from '@mui/material';
import FadeLoader from 'react-spinners/FadeLoader';
// ========Modal================

import { styled } from '@mui/material/styles';

/*------------api call-----------------*/
import {
  fetchEquipmentListTable,
  putEquipmenttype,
  postEquipmentSave,
  Equipmentcategorydropdown,
  Equipmenttypedropdown,
} from '../../Api/EquipmentListApi';
import TableTemplate from '../../Template/TableTemplate';
import Download from '../../Template/Excel/Download';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { EquipmentType } from '../util/TextField';
import { fetchequipmentTableById } from '../../Api/EquipmentApi';
import errorMsg from '../util/errorMessage.json';
import CustomSnackbar from '../util/components/CustomSnackbar';

export default function EquipmentList({ equipmentList }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(false);
  const [category, setCategory] = useState();
  const [equipment, setEquipment] = useState();
  const [editcall, seteditcall] = useState();
  const [activeFlag, setactiveFlag] = useState();
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [siteTab, setSiteTab] = useState('');

  const [text, setTextField] = useState([]);
  let initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const [stdField, setstdField] = useState({
    equipmentType: '',
    equipmentCategory: '',
    description: '',
    status: '',
    createdDate: '',
    lastUpdatedDate: '',
    displayName: '',
    // displayName: "",
  });

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    // fetchEquipmenttype();
    // fetchEquipmentcategory();
  }, []);

  useEffect(() => {
    let data = EquipmentType();
    // console.log(data, "data");
    setTextField(data);
  }, []);

  const handleChange = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };

  const handleDropdownChange = (event, value, name) => {
    console.log(event, name, value);
    setstdField({ ...stdField, [event.target.name]: event.target.value });
  };

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Equipment List', path: 'equipmentlist' },
  ];

  /*------------------------- handle Update --------------------------------------------*/
  // const handleUpdate = async () => {
  //   let id = editcall?.equipmentTypeId;
  //   console.log(id, "id");
  //   let categorytype = category !== "" ? category : editcall?.equipmentCategory;
  //   let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
  //     return data.equipmentCategory === categorytype;
  //   });
  //   console.log(categorytype);
  //   let equipmentType =
  //     stdField?.equipmentType !== ""
  //       ? stdField.equipmentType
  //       : editcall?.equipmentType;
  //   let capacity =
  //     stdField?.capacity !== "" ? stdField.capacity : editcall?.capacity;
  //   let description =
  //     stdField?.description !== ""
  //       ? stdField.description
  //       : editcall?.description;
  //   let manufacturer =
  //     stdField?.manufacturer !== ""
  //       ? stdField.manufacturer
  //       : editcall?.manufacturer;
  //   let remarks =
  //     stdField?.remarks !== "" ? stdField.remarks : editcall?.remarks;
  //   let displayName =
  //     stdField?.displayName !== ""
  //       ? stdField.displayName
  //       : editcall?.displayName;
  //   // let equipmentTypeCode =
  //   //   stdField?.equipmentTypeCode !== ""
  //   //     ? stdField.equipmentTypeCode
  //   //     : editcall?.equipmentTypeCode;
  //   let EquipmentIdcategory =
  //     CategoryTypeIdFilter.length > 0
  //       ? CategoryTypeIdFilter[0].categoryId
  //       : editcall?.categoryId;
  //   let obj = {
  //     equipmentType: equipmentType,
  //     capacity: capacity,
  //     description: description,
  //     manufacturer: manufacturer,
  //     remarks: remarks,
  //     status:
  //       stdField.status !== ""
  //         ? stdField.status === "Active"
  //           ? 1
  //           : 0
  //         : editcall?.status,
  //     categoryId: EquipmentIdcategory,
  //     lastUpdatedBy: sessionStorage.getItem("id"),
  //     // equipmentTypeCode: equipmentTypeCode,
  //     displayName: displayName,
  //   };
  //   let responsedata = await putEquipmenttype(obj, id);
  //   try {
  //     if (responsedata === 200) {
  //       fetchData();
  //       setOpen(false);
  //     }
  //   } catch (e) {
  //     console.log("equipment:", e.message);
  //   }
  //   console.log(obj);
  // };

  // const handleSave = async () => {
  //   console.log(stdField, "stdField");
  //   let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
  //     return data.equipmentCategory === category;
  //   });
  //   let data = {
  //     equipmentType: stdField.equipmentType,
  //     capacity: stdField?.capacity,
  //     createdBy: sessionStorage.getItem("id"),
  //     description: stdField?.description,
  //     manufacturer: stdField?.manufacturer,
  //     remarks: stdField?.remarks,
  //     status: stdField.status === "Active" ? 1 : 0,
  //     categoryId: CategoryTypeIdFilter[0]?.categoryId,
  //     // equipmentTypeCode: stdField?.equipmentTypeCode,
  //     displayName: stdField?.displayName,
  //   };
  //   const responseData = await postEquipmentSave(data);
  //   console.log(responseData);
  //   try {
  //     if (responseData === 200) {
  //       fetchData();

  //       setOpen(false);
  //     }
  //   } catch (e) {
  //     console.log("equipment:", e.message);
  //   }
  // };

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setloading(true);
    try {
      const data = await fetchequipmentTableById();
      // const reverse = [...data].reverse();
      setSiteEquipment(data);
      setloading(false);
      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchEquipmenttype = async () => {
    const data = await Equipmenttypedropdown();
    if (Array.isArray(data)) {
      // If data is an array, set tableValue to data directly
      setEquipmevalue(data);
    } else if (typeof data === 'object' && data !== null) {
      // If data is an object, wrap it in an array and set tableValue
      setEquipmevalue([data]);
    }
    console.log(data);
  };

  const fetchEquipmentcategory = async () => {
    const data = await Equipmentcategorydropdown();
    if (Array.isArray(data)) {
      setCategoryvalue(data);
    } else if (typeof data === 'object' && data !== null) {
      setCategoryvalue([data]);
    }
  };

  const Priority = [
    { label: 'High', value: 3 },
    { label: ' Medium', value: 2 },
    { label: 'Low', value: 1 },
  ];

  /*-------------------------report Name list dropdown --------------------------------------------*/

  //
  const handleactiveFlag = (data, event) => {
    setactiveFlag(event?.value);
  };

  const handleEquipmentvalue = (data, value) => {
    if (value === null) {
      setEquipment('');
    } else {
      setEquipment(value);
    }
  };

  const handleCategoryvalue = (data, value) => {
    if (value === null) {
      setCategory('');
    } else {
      setCategory(value);
    }
  };

  /*---------------------------------- New Equipment Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall('');
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  const statusvalue = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (equipmenttypeId) => {
    seteditcall(equipmenttypeId);
    setEdit(true);
    setOpen(true);
  };

  const headCells = [
    {
      label: 'Equipment Name',
      id: 'displayName',
      view: true,
      default: true,
    },
    {
      label: 'Equipment Category',
      id: 'equipmentCategory',
      view: true,
    },
    {
      label: 'Equipment Type',
      id: 'equipmentType',
      view: true,
    },
    {
      label: 'Created On',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'Updated On',
      id: 'lastUpdatedDate',
      view: false,
    },
    {
      label: 'Status',
      id: 'status',
      view: true,
      default: true,
    },
    // {
    //   label: 'Action',
    //   id: 'action',
    // },
  ];

  const handleDownloadTable = async (val) => {
    console.log(val, 'val');
    const status = await Download(val, 'EquipmentList');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const tableValues = SiteEquipment.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = SiteEquipment.length;

  const Active = SiteEquipment.filter((site) => site.status == 1).length;

  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Type', value: 'all', badgeCount: Total },
    { name: 'Active Type', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Type',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <div>
            {/* ---------   -----save andupdate---- popup----------- */}

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <Typography
                          id="transition-modal-title"
                          variant="h6"
                          component="h4"
                          className="modal-head"
                        >
                          {edit
                            ? 'Update Equipment Type'
                            : 'Add New  Equipment Type'}
                        </Typography>
                      </div>
                      <div>
                        {/* <Stack spacing={2} direction="row">
                          {edit ? (
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: "25px",
                                textTransform: "capitalize",
                              }}
                              startIcon={<SaveIcon />}
                              onClick={() => handleUpdate()}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: "25px",
                                textTransform: "capitalize",
                              }}
                              startIcon={<SaveIcon />}
                              onClick={() => handleSave()}
                            >
                              Save
                            </Button>
                          )}

                          <Button
                            variant="contained"
                            style={{
                              borderRadius: "25px",
                              textTransform: "capitalize",
                            }}
                            onClick={handleClose}
                            startIcon={<CancelIcon />}
                          >
                            Cancel
                          </Button>
                        </Stack> */}
                      </div>
                    </div>
                    <Divider style={{ marginTop: '2%' }} />

                    <div style={{ marginTop: '3%' }}>
                      <Grid container spacing={3} columns={32}>
                        {text?.length > 0
                          ? text.map((data, index) => (
                              <Grid item xs={8}>
                                <div key={index}>
                                  <Typography className="modal-typo">
                                    {data.label}
                                  </Typography>
                                  {data.type === 'textField' ? (
                                    <TextField
                                      id={`outlined-basic-${index}`}
                                      size="small"
                                      variant="outlined"
                                      name={data.name}
                                      inputProps={{ maxLength: data.length }}
                                      onChange={(e) => handleChange(e)}
                                      placeholder={data.placeholder}
                                      defaultValue={
                                        editcall ? editcall[data.name] : null
                                      }
                                      sx={{ width: '12vw' }}
                                    />
                                  ) : data.type === 'dropdown' ? (
                                    <Autocomplete
                                      options={
                                        data.options
                                          ? data.options.map(
                                              (option) => option.label
                                            )
                                          : data.name === 'equipmentCategory'
                                          ? categoryvalue.map(
                                              (option) =>
                                                option.equipmentCategory
                                            )
                                          : null
                                      }
                                      disablePortal
                                      size="small"
                                      id={`combo-box-demo-${index}`}
                                      onChange={(event, value) =>
                                        handleDropdownChange(
                                          event,
                                          value,
                                          data.name
                                        )
                                      }
                                      defaultValue={
                                        edit && data.name === 'status'
                                          ? editcall[data.name] == 1
                                            ? 'Active'
                                            : 'Inactive'
                                          : data.name === 'equipmentCategory'
                                          ? editcall?.equipmentCategory
                                          : null
                                      }
                                      sx={{ width: '12vw' }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  ) : null}
                                </div>
                              </Grid>
                            ))
                          : null}
                      </Grid>
                    </div>
                  </div>

                  <div>
                    <Grid container spacing={2} columns={32}></Grid>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: '-0.5%' }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={'Equipment List'}
              SearchLabel={'Search Equipment Here... '}
              header={headCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              userRole={equipmentList[0]}
              rawData={SiteEquipment}
              handleChange={handleChangeTab}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={'No equipment available to display'}
            />
          </div>
        </div>
      )}
    </div>
  );
}
