import React from 'react';
import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const AdditionalFields = ({
  field,
  setField,
  allowedKeys,
  deleteAdditionalField,
  fieldToAdd,
}) => {
  let fieldToMap = [];
  let fieldToUpdate = '';

  switch (fieldToAdd) {
    case 'equipmentadditionalfield':
      fieldToMap = field.equipmentadditionalfield || [];
      fieldToUpdate = 'equipmentadditionalfield';
      break;
    case 'sitedetailadditionalfield':
      fieldToMap = field.sitedetailadditionalfield || [];
      fieldToUpdate = 'sitedetailadditionalfield';
      break;
    case 'customeradditionalfield':
      fieldToMap = field.customeradditionalfield || [];
      fieldToUpdate = 'customeradditionalfield';
      break;
    case 'useradditionalfield':
      fieldToMap = field.useradditionalfield || [];
      fieldToUpdate = 'useradditionalfield';
      break;
    case 'equipmentcategory':
      fieldToMap = field.equipmentcategory || [];
      fieldToUpdate = 'equipmentcategory';
      break;
    case 'companyadditionalfield':
      fieldToMap = field.companyadditionalfield || [];
      fieldToUpdate = 'companyadditionalfield';
      break;
    default:
      break;
  }

  console.log(field, 'fieldsTomap');
  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...fieldToMap];
    updatedFields[index][key] = value;

    setField((prevState) => ({
      ...prevState,
      [fieldToUpdate]: updatedFields,
    }));
  };

  return (
    <>
      <div>
        {fieldToMap.map((field, index) => (
          <Grid item key={index} xs={12} sm={6} md={3} style={{ marginBottom: '10%' }}>
            <div
              style={{
                marginTop: '3%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {allowedKeys.map((key, innerIndex) => (
                <div key={innerIndex} style={{ marginLeft: '10px' }}>
                  <Typography className="modal-typo">{`${key}: `}</Typography>
                  {key === 'status' ? (
                    <Autocomplete
                      disableClearable
                      options={['Active', 'Inactive']}
                      getOptionLabel={(option) => option}
                      defaultValue={'Active'}
                      value={field[key] === 1 ? 'Active' : 'Inactive'}
                      onChange={(event, newValue) =>
                        handleFieldChange(
                          index,
                          key,
                          newValue === 'Active' ? 1 : 0
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ width: '13.5vw', marginTop: '2%' }}
                          variant="outlined"
                          name={key}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      id="outlined-basic"
                      size="small"
                      sx={{ width: '13.5vw', marginTop: '2%' }}
                      variant="outlined"
                      name={key}
                      placeholder={
                        key === 'fieldName'
                          ? 'E.g: GST'
                          : 'E.g: 8.9'
                      }
                      value={field[key]}
                      onChange={(e) =>
                        handleFieldChange(index, key, e.target.value)
                      }
                    />
                  )}
                </div>
              ))}
              <div
                style={{
                  marginTop: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {!field.createdBy && (
                  <Button
                    variant=""
                    color="error"
                    size="small"
                    onClick={() => deleteAdditionalField(index)}
                    style={{
                      marginLeft: '20px',
                      height: '25px',
                      width: '25px',
                      cursor: 'pointer',
                      borderRadius: '20px 20px 20px 20px',
                      border: 'none',
                    }}
                  >
                    <HighlightOffIcon />
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        ))}

        {fieldToMap.length < 5 && (
          <Grid item xs={12} sm={6} md={3}>
            {/* Render your new field component here */}
          </Grid>
        )}
      </div>
    </>
  );
};

export default AdditionalFields;
