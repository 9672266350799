import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserContextProvider } from '../src/UiComponents/Auth/UserContext';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

<div >
<>
 <UserContextProvider>
  <App />
  </UserContextProvider>
  
 </>
</div>
 
);

