import React, { useMemo, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { fetchEquipmentIdDetails } from '../../Api/ExcelUploadAPi';
import { CheckForLight } from '../util/common/CommanFunction';
import { IconButton } from '@mui/material';
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const BarChart = (props) => {
  const {
    tableData,
    chartName,
    barchartdata,
    widgetName,
    chartValue,
    range,
    equipmentListAPI,
    customView,
    from,
    fallBack,
  } = props;

  const [barChart, setBarchartData] = React.useState();
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState('');
  console.log(props, 'props');
  // console.log('tableData:', tableData);
  // console.log("chartName:", chartName);
  // console.log('barchartdata:', barchartdata);
  // console.log("siteId:", siteId);
  // console.log("widgetName:", widgetName);
  // console.log("chartValue:", chartValue);
  // console.log("range:", range);
  // console.log('barChart:', barChart);
  const isLight = CheckForLight();
  useEffect(() => {
    if (chartName === 'Energy Performance' && range === 'custom') {
      chartValue?.map(async (data) => {
        let equipmentsdata = equipmentListAPI;
        const convertedData = tableData.reduce((result, item) => {
          const found = result.find(
            (x) => x.name === item.equipmentId.toString()
          );
          if (found) {
            found.data.push(item.todayEnergy);
          } else {
            result.push({
              name: item.equipmentId.toString(),
              data: [item.todayEnergy],
            });
          }
          return result;
        }, []);
        let converteddata = convertedData;

        let dataMap = new Map(
          equipmentsdata.map((item) => [
            String(item.equipmentId),
            item.customernaming,
          ])
        );

        converteddata.forEach((item) => {
          if (dataMap.has(item.name)) {
            item.name = dataMap.get(item.name);
          }
        });
        console.log(converteddata, 'converteddata');

        setBarchartData(convertedData);
      });
    } else if (chartName === 'DG PV Grid Management') {
      // Transformation for DG PV Grid Management chart
      const meters = ['gridMeter', 'solarMeter', 'dgMeter'];
      const allTimestamps = Array.from(
        new Set(
          meters
            .flatMap((meter) => tableData[meter] || [])
            .map((data) => data.timeStamp)
        )
      );

      const transformedData = meters.map((meter) => {
        const meterData = tableData[meter] || [];
        const meterValues = allTimestamps.map((timeStamp) => {
          const entry = meterData.find((data) => data.timeStamp === timeStamp);
          return entry ? entry.todayEnergy : 0;
        });
        console.log(meterValues, 'meterValues');
        // Map meter names to desired format
        const meterNameMap = {
          gridMeter: 'Grid',
          solarMeter: 'Solar',
          dgMeter: 'DG',
        };

        return {
          name: meterNameMap[meter],
          data: meterValues,
        };
      });

      setBarchartData(transformedData);
    } else if (chartName === 'Deemed Generation') {
      function transformData(inputArray) {
        // Helper function to convert a timestamp to minutes
        function convertTimestampToMinutes(timestamp) {
          // Split the timestamp to rearrange it into YYYY-MM-DD format
          const [day, month, year] = timestamp.split('-');
          const formattedDate = `${year}-${month}-${day}`;

          // Create a Date object
          const date = new Date(formattedDate);
          if (isNaN(date.getTime())) {
            console.error('Invalid Date');
            return null;
          }

          return date.getTime();
        }

        return inputArray
          .map((item) => {
            const timestampInMinutes = convertTimestampToMinutes(
              item.timeStamp
            );
            console.log(timestampInMinutes, 'timestampInMinutes');

            return [
              {
                name: 'Deened Generation',
                data: [[timestampInMinutes, item.deenedGeneration]],
              },
              {
                name: 'Potential Generation',
                data: [[timestampInMinutes, item.potentialGeneration]],
              },
              {
                name: 'Set Point',
                data: [[timestampInMinutes, item.setPoint]],
              },
              {
                name: 'Today Energy',
                data: [[timestampInMinutes, item.todayEnergy]],
              },
              {
                name: 'Irradiation',
                data: [[timestampInMinutes, item.irradiation]],
              },
            ];
          })
          .flat();
      }

      const transformedData = transformData(tableData);
      setBarchartData(transformedData);
    } else {
      setBarchartData(barchartdata);
    }
  }, [props, chartValue]);

  const chartData = useMemo(() => {
    if (!tableData || chartName === 'DG PV Grid Management') return [];
    return tableData.map((data) => [
      new Date(data.timeStamp).getTime(),
      Number(data.todayEnergy),
    ]);
  }, [tableData, chartName]);

  const Irradiation = useMemo(() => {
    if (!tableData || chartName === 'DG PV Grid Management') return [];
    return tableData.map((data) => [
      new Date(data.timeStamp).getTime(),
      Number(data.irradiation),
    ]);
  }, [tableData, chartName]);

  // const formattedDates = timeStampData.map(dateStr => {
  //   const parts = dateStr.split('-');
  //   return `${parts[2]}-${parts[1]}-${parts[0]}`;
  // });

  const columnChartOptions = {
    chart: {
      type: 'column',
      backgroundColor: isLight ? 'white' : '#121212',
    },
    title: {
      text: widgetName,
      style: {
        color: isLight ? 'black' : 'white',
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d/%m/%Y',
      },
      title: {
        text: 'Time (HH:MM)',
      },
      labels: {
        style: {
          color: isLight ? 'black' : 'white',
        },
        formatter: function () {
          return Highcharts.dateFormat('%d/%m/%Y', this.value);
        },
      },
    },

    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadJPEG',
            'downloadPNG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadCSV',
            'downloadXLS',
            // from === 'analytics' ? '' : 'viewData',
            from === 'Vanalytics'
              ? {
                  text: 'View Table',
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data
                    // console.log(dataHtml, 'before');

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );
                    dataHtml = dataHtml.replace(
                      /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2}) \d{2}:\d{2}:\d{2}<\/th>/g,
                      '<th class="highcharts-text" scope="row">$1</th>'
                    );
                    // console.log(dataHtml, 'after');
                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : '',
          ],
        },
      },
      enabled: true,
      // showTable: true,
      columnHeaderFormatter: function (item, key) {
        if (item.isXAxis) {
          return item.options.title.text || item.name;
        }
        if (key === 'y') {
          return item.yAxis.options.title.text || item.name;
        }
      },
    },
    yAxis: [
      {
        title: {
          text: 'Energy Generation (kWh)',
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
        style: {
          color: isLight ? 'black' : 'white',
          fontSize: '0.8em',
          fontFamily: 'Inter',
        },
        labels: {
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
      },
      {
        title: {
          text: 'Irradiation',
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
        opposite: true,
        visible: chartName === 'Daily Generation' ? true : false,
        labels: {
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
      },
    ],
    plotOptions: {
      series: {
        stacking: chartName === 'DG PV Grid Management' ? 'normal' : undefined,
        events: {
          legendItemClick: function () {
            const series = this.chart.series;
            const currentSeries = this;

            if (series.length === 2) {
              const [series1, series2] = series;

              if (currentSeries === series1) {
                // If series1 is clicked
                if (series1.visible && !series2.visible) {
                  // Both series should be visible if series1 is visible and series2 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series1.visible && series2.visible) {
                  // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series2 invisible, and series1 remains visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (!series1.visible && !series2.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              } else if (currentSeries === series2) {
                // If series2 is clicked
                if (series2.visible && !series1.visible) {
                  // Both series should be visible if series2 is visible and series1 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && series1.visible) {
                  // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series1 invisible, and series2 remains visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && !series1.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              }

              // Finally, redraw the chart
              this.chart.redraw();
            } else {
              // Handle charts with more than two series or other cases
              this.chart.update(
                {
                  series: series.map((s) => {
                    if (s === currentSeries) {
                      return {
                        visible: currentSeries.visible ? false : true,
                      };
                    } else {
                      const visbleSeries = series.filter(
                        (series) => series.visible
                      );
                      console.log(
                        visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1,
                        visbleSeries
                      );

                      if (
                        visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1
                      ) {
                        visbleSeries.forEach((series) => {
                          series.update({ visible: false }, false);
                        });
                      }
                      return {
                        visible: s.visible
                          ? visbleSeries.length > 1 &&
                            visbleSeries.length < series.length - 1
                            ? true
                            : false
                          : true,
                      };
                    }
                  }),
                },
                true
              );
            }

            return false; // Prevent the default action of hiding the clicked series
          },
        },
      },
    },
    legend: {
      maxHeight: 100,
      itemStyle: {
        color: isLight ? 'black' : 'white',
      },
      itemHoverStyle: {
        color: isLight ? 'gray' : 'lightgray',
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: isLight ? 'white' : '#121212',
      formatter: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (chartName === 'DG PV Grid Management') {
          var currentPoint = this,
            currentSeries = currentPoint.series,
            chart = currentSeries.chart,
            stackValues = '';

          chart.series.forEach(function (series) {
            series.points.forEach(function (point) {
              if (
                currentSeries.userOptions.stack === series.userOptions.stack &&
                currentPoint.key === point.category
              ) {
                stackValues +=
                  series.name + ': ' + point.y.toFixed(2) + '  kWh' + '<br/>';
              }
            });
          });

          return (
            `<div class="custom-tooltip"  style="color:${
              isLight ? 'black' : 'white'
            };">` +
            '<b>Energy Generation</b>' +
            '<br/><b>' +
            this.x +
            '</b><br/>' +
            stackValues +
            'Total: ' +
            this.point.stackTotal.toFixed(2) +
            '  kWh' +
            '</div>'
          );
        } else if (chartName === 'Deemed Generation') {
          const xLabel = Highcharts.dateFormat(
            '%d/%m/%Y',
            this.point.options.x
          );
          if (this.series.name === 'Deened Generation') {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };" >
          <p>${xLabel}</p>
          <p>Deened Generation: ${this.point.y.toFixed(2)}</p>
        </div>`;
          } else if (this.series.name === 'Potential Generation') {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };">
          <p>${xLabel}</p>
          <p>Potential Generation: ${this.point.y.toFixed(2)}</p>
        </div>`;
          } else if (this.series.name === 'Set Point') {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };">
          <p>${xLabel}</p>
          <p>Set Point: ${this.point.y.toFixed(2)}</p>
        </div>`;
          } else if (this.series.name === 'Today Energy') {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };">
          <p>${xLabel}</p>
          <p>Today Energy: ${this.point.y.toFixed(2)}</p>
        </div>`;
          } else {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };">
          <p>${xLabel}</p>
          <p>Irradiation: ${this.point.y.toFixed(2)}</p>
        </div>`;
          }
        } else {
          const xLabel = this.point.options.name
            ? this.point.options.name
            : chartName === 'Energy Performance'
            ? this.point.series.userOptions.name
            : Highcharts.dateFormat('%Y-%m-%d', this.point.options.x);
          // Default tooltip for other charts
          if (this.series.name === 'Energy Generation (kWh)') {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };" >
          <p>${xLabel}</p>
          <p>Energy Generation: ${this.point.y} (kWh)</p>
        </div>`;
          } else if (this.series.name === 'Irradiation') {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };">
          <p>${xLabel}</p>
          <p>Irradiation: ${this.point.y}</p>
        </div>`;
          } else {
            return `<div class="custom-tooltip" style="color:${
              isLight ? 'black' : 'white'
            };">
          <p>${xLabel}</p>
          <p>Energy Generation: ${this.point.y} (kWh)</p>
        </div>`;
          }
        }
      },
    },

    series:
      chartName === 'Daily Generation'
        ? [
            {
              name: 'Energy Generation (kWh)',
              data: chartData,
              type: 'column',
              color: 'LightSkyBlue',
            },
            {
              name: 'Irradiation',
              data: Irradiation,
              type: 'line',
              yAxis: 1,
              color: 'Navy',
            },
          ]
        : tableData && chartName === 'DG PV Grid Management'
        ? (barChart ?? [])
            .map((item) => ({
              ...item,
              data: item.data.map((value) => Number(value.toFixed(2))),
              color:
                item.name === 'Grid'
                  ? 'Grey'
                  : item.name === 'Solar'
                  ? 'Orange'
                  : item.name === 'DG'
                  ? 'LightSkyBlue'
                  : undefined,
            }))
            .sort((a, b) => {
              const sumA = a.data.reduce((sum, value) => sum + value, 0);
              const sumB = b.data.reduce((sum, value) => sum + value, 0);
              return sumA - sumB; // Sort in ascending order of total values
            })
        : barChart,
  };
  console.log(columnChartOptions);

  return (
    <div
      style={{ width: '100%', height: from === 'Vanalytics' ? '500px' : null }}
    >
      {tableData.length > 0 ? (
        showTable ? (
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  height: '40px',
                  marginRight: '10px',
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{widgetName.split('-')[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: '100%',
                height: from === 'Vanalytics' ? '500px' : null,
              },
            }}
            options={columnChartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
export default BarChart;
