import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';

import '../Style.css';
import PieChart from './SiteStatusChart';

const Content2 = (props) => {
  const siteStats = props.SiteStatistics;
  const [pieChart, setPieChart] = useState([0, 0, 0, 0]);
  useEffect(() => {
    setPieChart([
      siteStats.activeCount,
      siteStats.downCount,
      siteStats.offlineCount,
      siteStats.warningCount,
    ]);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card
            sx={{
              width: '100%',
              // height: '38vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PieChart data={pieChart} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Content2;
