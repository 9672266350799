import React, { useState, useEffect } from 'react';
import TableTemplate from '../../../Template/TableTemplate';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { companyListSchmea } from '../../util/ValidateSchema';
import { CompanyListTextField } from '../../util/TextField';
import { FadeLoader } from 'react-spinners';
import {
  Box,
  Backdrop,
  Modal,
  Button,
  Typography,
  Paper,
  TextField,
  Autocomplete,
  Stack,
  Grid,
  Fade,
  Divider,
  Card,
  CardContent,
  CircularProgress,
  Tooltip,
  CardHeader,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import featherUpload from '../../../images/feather_upload-cloud.svg';
import errorMsg from '../../util/errorMessage.json';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import ImageCropper from '../../util/components/Cropper';

import {
  CountryDropdown,
  StateDropdown,
  Statusdropdown,
} from '../../../Api/CommonApi';
import { getCompanyList, getCompanyUpdateList } from '../../../Api/CompanyAPI';
import AdditionalFields from '../../util/components/AdditionalFields';
import replaceObjectById from '../../util/StateUpdater';
import lightThemeIcon from '../../../images/headerLogo.svg';
import CustomSnackbar from '../../util/components/CustomSnackbar';
import { CheckForLight } from '../../util/common/CommanFunction';

const allowedKeys = ['fieldName', 'value', 'status'];

const headCells = [
  {
    label: 'Company  Name',
    id: 'companyName',
    view: true,
    default: true,
  },
  {
    label: 'Email Id',
    id: 'emailId',
    view: true,
    default: true,
  },
  {
    label: 'Address',
    id: 'address',
    view: true,
  },
  {
    label: 'State',
    id: 'state',
    view: false,
  },
  {
    label: 'Contact Person',
    id: 'contactPerson',
    view: true,
    default: true,
  },
  {
    label: 'Phone Number',
    id: 'mobileNumber',
    view: true,
  },
  {
    label: 'Country',
    id: 'country',
    view: false,
  },
  {
    label: 'Created On',
    id: 'createdDate',
    view: false,
  },
  {
    label: 'Status',
    id: 'status',
    view: true,
  },
  {
    label: 'Action',
    id: 'action',
    view: true,
    default: true,
  },
];

const CompanyList = ({ companyConfiguration }) => {
  const initialState = {
    state: '',
    address: '',
    companyLogoPath: '',
    companyWebsite: '',
    contactPerson: '',
    emailId: '',
    mobileNumber: '',
    companyName: '',
    country: '',
    createdBy: '',
    telePhoneNumber: '',
    status: '',
    companyadditionalfield: [],
  };

  const initialSnack = { open: false, severity: '', message: '' };

  const [tableValue, setTableValue] = useState([]);
  const [editValue, setEditValue] = useState();
  const [editState, setEditState] = useState(false);
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [selectedImgName, setSelectedImgName] = useState('');
  const [base64Image, setBase64Image] = useState('');
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [fieldValue, setFieldValue] = useState(initialState);
  const [text, setTextField] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusDrop, setStatusDrop] = useState([]);
  const [editied, setEditied] = useState(false);
  const [countryDrop, setCountryDrop] = useState([]);
  const [stateDrop, setStateDrop] = useState([]);
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const AddFieldsLength = fieldValue?.companyadditionalfield?.length;

  useEffect(() => {
    getTableData();

    fetchStatus();
    getCountryList();
  }, []);

  const handleFileChange = (event) => {
    console.log(event, 'fileFileFile');
    setSelectedImgName(event.target.files[0]?.name);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setBase64Image(reader.result);
    };

    let fileSize = event.target.files[0]?.size;
    let fileType = event.target.files[0]?.type;
    console.log(fileSize, 'fileSize');
    if (file) {
      const fileTypeCheck =
        fileType === 'image/png' || fileType === 'image/jpeg';
      const fileSizeCheck = fileSize < 2000000;
      if (fileTypeCheck && fileSizeCheck) {
        reader.readAsDataURL(file);
        console.log(reader, 'imageFile');
      } else {
        setSnack({
          ...initialSnack,
          open: true,
          severity: 'warning',
          message: !fileSizeCheck
            ? 'File Size is too Big'
            : 'File Type is not supported',
        });
      }
    } else {
      setBase64Image('');
    }
    setCrop(true);
  };

  const closeEditor = () => {
    setCrop(false);
    setBase64Image('');
  };

  const onComplete = (val) => {
    console.log(val);
    setCroppedImageUrl(val);
    setEditied(true);
    setCrop(false);
    // You can now use 'croppedImageUrl' as the final cropped image
    console.log('Cropped Image:', val);
  };

  const deleteAdditionalField = (index) => {
    const updatedFields = [...fieldValue.companyadditionalfield];
    updatedFields.splice(index, 1);
    setFieldValue((prevState) => ({
      ...prevState,
      companyadditionalfield: updatedFields,
    }));
  };

  useEffect(() => {
    if (fieldValue.country !== '') {
      let countryID = countryDrop?.filter((data) => {
        return data.countryName === fieldValue?.country;
      });
      getStateList(countryID[0]?.countryId);
    }
  }, [fieldValue.country]);

  const getStateList = async (Id) => {
    try {
      let data = await StateDropdown(Id);
      setStateDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getCountryList = async () => {
    try {
      let data = await CountryDropdown();
      setCountryDrop(data);
    } catch (e) {
      console.error(e);
    }
  };
  const addAdditionalField = () => {
    const newField = {
      fieldName: '',
      status: 1,
      value: '',
    };

    setFieldValue((prevState) => ({
      ...prevState,
      companyadditionalfield: [...prevState.companyadditionalfield, newField],
    }));
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  useEffect(() => {
    let data = CompanyListTextField();
    // console.log(data, "data");
    setTextField(data);
  }, []);
  const handleClose = () => {
    setOpen(false);
    setCroppedImageUrl('');
  };

  const getTableData = async () => {
    let responseData = await getCompanyList(
      sessionStorage.getItem('companyId')
    );
    if (sessionStorage.getItem('firstTime') == 1) {
      console.log(responseData);
      handleEditOpen(responseData[0]);
      sessionStorage.removeItem('firstTime');
    }
    setTableValue(responseData);
    setLoading(false);
  };

  const handleEditOpen = async (companyId) => {
    console.log(companyId);
    console.log(companyId.companyadditionalfield === undefined);
    setFieldValue(companyId);
    setFieldValue((prevState) => ({
      ...prevState,
      companyadditionalfield:
        companyId.companyadditionalfield === undefined
          ? []
          : companyId.companyadditionalfield,
    }));
    setEditValue(companyId);
    setEditState(true);
    setBase64Image('');
    setOpen(true);
  };

  

  const handleUpdate = async (values) => {
    console.log(values, 'values');

    let id = editValue?.companyId;
    console.log(fieldValue);
    let imagedata = croppedImageUrl.split('base64,')[1];
    console.log(imagedata, 'imagedata');
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    // let country = countryDrop.filter(
    //   (option) => option.countryName === values.country
    // );
    // let state = stateDrop.filter((option) => option.stateName === values.state);
    // console.log(fieldValue, 'fieldvalues');

    let userAddField = [];
    fieldValue.companyadditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        if (obj.fieldId) {
          userAddField.push({
            lastUpdatedBy: sessionStorage.getItem('id'),
            fieldId: obj.fieldId,
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        } else {
          userAddField.push({
            createdBy: Number(sessionStorage.getItem('id')),
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        }
      }
    });
    let obj;
    if (imagedata) {
      obj = {
        state: values.state,
        address: values?.address,
        country: values.country,
        contactPerson: values?.contactPerson,
        companyName: values?.companyName,
        lastUpdatedBy: sessionStorage.getItem('id'),
        logoName: selectedImgName,
        companyLogoPath:
          editValue.companyLogoPath === undefined ||
          editValue.companyLogoPath === null ||
          editValue.companyLogoPath === 'null'
            ? 'null'
            : editValue.companyLogoPath,
        image: `${imagedata}`,
        companyWebsite: values?.companyWebsite,
        mobileNumber: values?.mobileNumber,
        // telePhoneNumber: values?.telePhoneNumber,
        emailId: values?.emailId,
        status: editState
          ? typeof values.status === 'string'
            ? status[0].value
            : values.status
          : status[0].value,
        companyadditionalfield: userAddField,
      };
    } else {
      obj = {
        state: values.state,
        address: values?.address,
        country: values.country,
        contactPerson: values?.contactPerson,
        companyName: values?.companyName,
        lastUpdatedBy: sessionStorage.getItem('id'),
        logoName: selectedImgName,
        companyLogoPath:
          editValue.companyLogoPath === undefined ||
          editValue.companyLogoPath === null ||
          editValue.companyLogoPath === 'null'
            ? 'null'
            : editValue.companyLogoPath,
        companyWebsite: values?.companyWebsite,
        mobileNumber: values?.mobileNumber,
        // telePhoneNumber: values?.telePhoneNumber,
        emailId: values?.emailId,
        status: editState
          ? typeof values.status === 'string'
            ? status[0].value
            : values.status
          : status[0].value,
        companyadditionalfield: userAddField,
      };
    }
    console.log(obj, 'updateVal');
    try {
      setToBackend(true);
      let responseData = await getCompanyUpdateList(obj, id);
      console.log(responseData, 'response data');
      if (responseData.customerId == sessionStorage.getItem('customerId')) {
        sessionStorage.removeItem('customerLogoPath');
        sessionStorage.setItem(
          'customerLogoPath',
          responseData.customerLogoPath
        );
      }
      getTableData();
      setEditState(false);
      setSnack(errorMsg.success);
      const updatedTable = replaceObjectById(
        tableValue,
        'customerId',
        id,
        responseData
      );
      handleClose();
      setToBackend(false);
    
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      
      return 1;
    } catch (error) {
      console.error(error)
      
      if (error.response.status === 406) {
        setSnack(errorMsg.unique.userMail);
        console.error('Error:', error.response.status);
        setToBackend(false);
      } else {
        setSnack(errorMsg.failure);
        console.error('Error:', error.response.status);
        setToBackend(false);
      }
      return 0;
    }
  };
  const handleTxtChange = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };

  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'status':
        return statusDrop.map((option) => option.label);
      case 'state':
        return stateDrop.map((option) => option.stateName);
      case 'country':
        return countryDrop.map((option) => option.countryName);
      default:
    }
    return option;
  }

  const handleDropdownChange = (event, value, name) => {
    // console.log(event, name, value.value);
    if (name === 'country') {
      setFieldValue({ ...fieldValue, [name]: value, state: '' });
    } else {
      setFieldValue({ ...fieldValue, [name]: value });
    }
  };
  const cloudUploadLogo = featherUpload;

  let isLogo = sessionStorage.getItem('companyLogoPath');

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Company Configuration', path: 'companylist' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <TableTemplate
            PageName={'Company List'}
            SearchLabel={'Search Company Here... '}
            header={headCells}
            rowsValue={tableValue}
            handleEditPopupOpen={(val) => handleEditOpen(val)}
            userRole={companyConfiguration[0]}
            paths={paths}
            rawData={tableValue}
            fallbackText={'No company has been created'}
          />

          {/* --------------------------------------------Model Ui Add / edit ----------------------------------- */}
          <Formik
            key={editState ? 'edit' : 'add'}
            enableReinitialize={true}
            initialValues={fieldValue}
            validationSchema={companyListSchmea}
            onSubmit={async (values, { resetForm }) => {
              console.log(values);
              let submitValue = values;
              if (editState) {
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form className="login-access">
                <div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModal'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {editState ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    s
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={
                              editState
                                ? 'Company Information'
                                : 'Add New Company'
                            }
                          />
                          <Divider sx={{ borderColor: '#888' }} />

                          <div className="card " style={{}}>
                            <Card
                              sx={{
                                overflowY:
                                  AddFieldsLength !== 0 ? 'scroll' : 'visible',
                                height: '25rem',
                                paddingLeft: '1rem',
                                scrollBehavior: 'smooth',
                                scrollbarGutter: 'stable',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                  width: '0.4em',
                                },
                                '&::-webkit-scrollbar-track': {
                                  background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: '#888',
                                  borderRadius: '20px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                  background: '#555',
                                },
                              }}
                              elevation={0}
                            >
                              <CardContent sx={{ height: '2%' }}>
                                <div className="card-content">
                                  <div className="paper-content">
                                    <Grid container spacing={2} columns={16}>
                                      {text?.length > 0
                                        ? text.map((data, index) => (
                                            <Grid item xs={4}>
                                              <div key={index}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.type !== 'logo' &&
                                                    data.label}
                                                </Typography>
                                                {data.type === 'textField' ? (
                                                  <div>
                                                    <Field
                                                      as={TextField}
                                                      id={`outlined-basic-${index}`}
                                                      type={data.type}
                                                      size="small"
                                                      variant="outlined"
                                                      name={data.name}
                                                      inputProps={{
                                                        maxLength: data.length,
                                                      }}
                                                      onChange={(e) => {
                                                        handleChange(e);
                                                        handleTxtChange(e);
                                                      }}
                                                      placeholder={
                                                        data.placeholder
                                                      }
                                                      value={
                                                        values[data.name] ===
                                                        null
                                                          ? ''
                                                          : values[data.name]
                                                      }
                                                      sx={{ width: '12vw' }}
                                                    />
                                                    <ErrorMessage
                                                      name={data.name}
                                                      component="div"
                                                      className="errorStyle"
                                                      style={{
                                                        color: 'red',
                                                        marginTop: '1%',
                                                        textAlign: 'left',
                                                        marginLeft: '0%',
                                                      }}
                                                    />
                                                  </div>
                                                ) : data.type === 'dropdown' ? (
                                                  <>
                                                    <Tooltip
                                                      title={
                                                        !editState &&
                                                        data.name === 'status'
                                                          ? 'Active'
                                                          : editState &&
                                                            data.name ===
                                                              'status'
                                                          ? typeof values[
                                                              data.name
                                                            ] === 'string'
                                                            ? values[data.name]
                                                            : values.status ===
                                                              1
                                                            ? 'Active'
                                                            : 'Inactive'
                                                          : values[data.name]
                                                      }
                                                    >
                                                      <Field
                                                        name={data.name}
                                                        render={({
                                                          field,
                                                          form,
                                                        }) => (
                                                          <Tooltip
                                                            title={
                                                              !editState &&
                                                              data.name ===
                                                                'status'
                                                                ? 'Active'
                                                                : editState &&
                                                                  data.name ===
                                                                    'status'
                                                                ? typeof values[
                                                                    data.name
                                                                  ] === 'string'
                                                                  ? values[
                                                                      data.name
                                                                    ]
                                                                  : values.status ===
                                                                    1
                                                                  ? 'Active'
                                                                  : 'Inactive'
                                                                : values[
                                                                    data.name
                                                                  ]
                                                            }
                                                          >
                                                            <Autocomplete
                                                              disableClearable
                                                              options={getOptions(
                                                                data.name
                                                              )}
                                                              disablePortal
                                                              disabled={
                                                                data.name ===
                                                                  'status' ||
                                                                (data.name ===
                                                                  'state' &&
                                                                  !values.country)
                                                              }
                                                              size="small"
                                                              id={`combo-box-demo-${index}`}
                                                              onChange={(
                                                                event,
                                                                value
                                                              ) =>
                                                                handleDropdownChange(
                                                                  event,
                                                                  value,
                                                                  data.name
                                                                )
                                                              }
                                                              value={
                                                                !editState &&
                                                                data.name ===
                                                                  'status'
                                                                  ? 'Active'
                                                                  : editState &&
                                                                    data.name ===
                                                                      'status'
                                                                  ? typeof values[
                                                                      data.name
                                                                    ] ===
                                                                    'string'
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                    : values.status ===
                                                                      1
                                                                    ? 'Active'
                                                                    : 'Inactive'
                                                                  : values[
                                                                      data.name
                                                                    ]
                                                              }
                                                              // Ensure value is controlled by Formik
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                  style={{
                                                                    userSelect:
                                                                      'none',
                                                                  }}
                                                                  placeholder={
                                                                    data.placeholder
                                                                  }
                                                                />
                                                              )}
                                                            />
                                                          </Tooltip>
                                                        )}
                                                      />
                                                    </Tooltip>
                                                    <ErrorMessage
                                                      name={data.name}
                                                      component="div"
                                                      className="errorStyle"
                                                      style={{
                                                        color: 'red',
                                                        marginTop: '1%',
                                                        textAlign: 'left',
                                                        marginLeft: '0%',
                                                      }}
                                                    />
                                                  </>
                                                ) : data.type === 'number' ? (
                                                  <>
                                                    <Field
                                                      as={TextField}
                                                      id={`outlined-basic-${index}`}
                                                      size="small"
                                                      variant="outlined"
                                                      name={data.name}
                                                      type={data.type}
                                                      onChange={(e) => {
                                                        handleChange(e);
                                                        handleTxtChange(e);
                                                      }}
                                                      inputProps={{
                                                        maxLength: 10,
                                                      }}
                                                      placeholder={
                                                        data.placeholder
                                                      }
                                                      value={
                                                        values[data.name] ===
                                                        null
                                                          ? ''
                                                          : values[data.name]
                                                      }
                                                      sx={{ width: '12vw' }}
                                                    />
                                                    <ErrorMessage
                                                      name={data.name}
                                                      component="div"
                                                      className="errorStyle"
                                                      style={{
                                                        color: 'red',
                                                        marginTop: '1%',
                                                        textAlign: 'left',
                                                        marginLeft: '0%',
                                                      }}
                                                    />
                                                  </>
                                                ) : null}
                                              </div>
                                            </Grid>
                                          ))
                                        : null}
                                    </Grid>
                                  </div>
                                  <Card
                                    sx={{
                                      width: '500px',
                                      height: '70px',
                                      border: '2px solid #d0d0d0',
                                      borderStyle: 'dashed',
                                      borderRadius: '10px',
                                      marginTop: '20px',
                                    }}
                                    elevation={0}
                                  >
                                    <CardContent
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      {(values.companyLogoPath !== '' &&
                                        values.companyLogoPath !== null) ||
                                      croppedImageUrl !== '' ? (
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <img
                                            src={
                                              editState &&
                                              croppedImageUrl === ''
                                                ? isLogo === 'null'
                                                  ? lightThemeIcon
                                                  : isLogo
                                                : // `${values.companyLogoPath
                                                  // }?${new Date().getTime()}`
                                                  croppedImageUrl
                                            }
                                            alt="Preview"
                                            style={{
                                              maxWidth: '100%',
                                              maxHeight: '6vh',
                                              marginRight: '10px',
                                              marginTop: '-5px',
                                            }}
                                          />
                                          <div>
                                            <Typography
                                              variant="body1"
                                              gutterBottom
                                              className="modal-typo"
                                              style={{ marginTop: '-10px' }}
                                            >
                                              {editState &&
                                              croppedImageUrl === ''
                                                ? 'Existing Logo'
                                                : 'Upload Logo Preview'}
                                            </Typography>
                                            <Typography className="modal-typo">
                                              {selectedImgName?.length > 0 ||
                                              !values.companyLogoPath
                                                ? null
                                                : values.companyLogoPath
                                                    .substring(
                                                      values.companyLogoPath.lastIndexOf(
                                                        '/'
                                                      ) + 1
                                                    )
                                                    .replace(/%20/g, ' ')
                                                    .replace(/\/\//g, '/')}
                                            </Typography>

                                            <Typography className="modal-typo">
                                              {selectedImgName?.length > 0
                                                ? selectedImgName
                                                : ''}
                                            </Typography>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '-10px',
                                          }}
                                        >
                                          <img
                                            src={cloudUploadLogo}
                                            alt=""
                                            style={{ marginRight: '10px' }}
                                          />
                                          <div>
                                            <Typography
                                              className="modal-typo"
                                              fontWeight="410"
                                            >
                                              Select Logo
                                            </Typography>
                                            <Typography
                                              sx={{
                                                marginTop: '1%',
                                                fontSize: '12px',
                                                color: '#788ea2',
                                                opacity: '0.8',
                                              }}
                                            >
                                              JPG or PNG , file size no more
                                              than 2MB
                                            </Typography>
                                          </div>
                                        </div>
                                      )}
                                      <label
                                        htmlFor="upload-logo"
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <TextField
                                          type="file"
                                          id="upload-logo"
                                          style={{ display: 'none' }}
                                          onChange={(event) => {
                                            handleFileChange(event);
                                          }}
                                        />
                                        <Button
                                          variant="outlined"
                                          size="small"
                                          component="span"
                                          style={
                                            selectedImgName.length > 0
                                              ? {
                                                  textTransform: 'capitalize',
                                                  marginTop: '-10px',
                                                }
                                              : { textTransform: 'capitalize' }
                                          }
                                        >
                                          Select File
                                        </Button>
                                      </label>
                                    </CardContent>
                                  </Card>

                                  {AddFieldsLength !== 0 && (
                                    <>
                                      <Typography
                                        className="modal-head"
                                        style={{ width: '300px' }}
                                        sx={{
                                          marginLeft: '3%',
                                          marginTop: '25px',
                                        }}
                                        gutterBottom
                                      >
                                        Additional Fields
                                      </Typography>
                                      <Grid container spacing={3} columns={30}>
                                        <Grid
                                          item
                                          xs={8}
                                          sx={{
                                            marginLeft: '3.5%',
                                            marginTop: '5px',
                                          }}
                                        >
                                          <div>
                                            <AdditionalFields
                                              field={fieldValue}
                                              setField={setFieldValue}
                                              allowedKeys={allowedKeys}
                                              deleteAdditionalField={
                                                deleteAdditionalField
                                              }
                                              fieldToAdd="companyadditionalfield"
                                            />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}
                                  {/* </Box> */}
                                </div>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      marginTop: '1.5vh',
                                      marginBottom: '2vh',
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: 'center',
                                        marginTop: '5px',
                                      }}
                                    >
                                      {fieldValue?.companyadditionalfield
                                        ?.length < 5 && (
                                        <Card
                                          sx={{
                                            width: '200%',
                                            // marginLeft: '4%',
                                            height: '45px',
                                            border: '2px solid #d0d0d0',
                                            borderStyle: 'dashed',
                                            borderRadius: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                          elevation={0}
                                        >
                                          <CardContent
                                            onClick={addAdditionalField}
                                            sx={{
                                              cursor: 'pointer',
                                              display: 'flex',
                                              marginTop: '0.65%',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <AddCircleOutlineSharpIcon
                                              sx={{
                                                color: '#71766f',
                                                marginRight: '10px',
                                                fontSize: '30px',
                                              }}
                                            />
                                            <Typography
                                              className="modal-typo"
                                              fontWeight="410"
                                              fontSize="20px !important"
                                            >
                                              Add Customized Data
                                            </Typography>
                                          </CardContent>
                                        </Card>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                                {base64Image && (
                                  <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={crop}
                                    onClose={closeEditor}
                                    closeAfterTransition
                                    slots={{ backdrop: Backdrop }}
                                    slotProps={{
                                      backdrop: {
                                        timeout: 500,
                                      },
                                    }}
                                  >
                                    <Box
                                      className={'styleModalCropper'}
                                      sx={{ bgcolor: 'background.paper' }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          height: '100%',
                                          width: '100%',
                                        }}
                                      >
                                        <ImageCropper
                                          imageSrc={base64Image}
                                          onComplete={(val) => onComplete(val)}
                                        />
                                      </div>
                                    </Box>
                                  </Modal>
                                )}
                              </CardContent>
                            </Card>
                          </div>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default CompanyList;
