import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
  TextField,
  Tooltip,
  Autocomplete,
  CircularProgress,
  Modal,
  Backdrop,
  Fade,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { profileFields } from '../UiComponents/util/TextField';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { profileSchema } from '../UiComponents/util/ValidateSchema';
import CustomSnackbar from '../UiComponents/util/components/CustomSnackbar';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import {
  CountryDropdown,
  StateDropdown,
  Statusdropdown,
} from '../Api/CommonApi';
import { FadeLoader } from 'react-spinners';
import {
  fetchUserTableById,
  GetCustomerdropdowndata,
  GetRoledropdowndata,
  updateEditCall,
} from '../Api/UserConfigurationAPI';
import { FetchUserType } from '../Api/SignupApi';
import replaceObjectById from '../UiComponents/util/StateUpdater';
import errorMsg from '../UiComponents/util/errorMessage.json';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ChangePassAPI } from '../Api/PasswordControlApi';
import {
  decryptPaddedQuery,
  PassEncrypt,
} from '../UiComponents/util/security/Cipher';
import { removeQuestionMark } from '../UiComponents/util/common/CommanFunction';

export const ProfilePage = () => {
  const initalState = {
    city: '',
    userId: '',
    country: '',
    createdBy: '',
    emailId: '',
    firstName: '',
    lastName: '',
    mobileNo: '',
    postalCode: '',
    state: '',
    status: '',
    lastUpdatedBy: '',
    roleName: '',
    customerId: '',
    companyName: '',
    customerName: '',
    designation: '',
    userTypeName: '',
    encryptedPassword: '',
    useradditionalfield: [],
  };
  const [profileDetails, setProfileDetails] = useState(initalState);
  const [passUpdate, setPassUpdate] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const initialSnack = { open: false, severity: '', message: '' };
  const [tableValue, setTableValue] = useState([]);
  const [editValue, setEditValue] = useState();
  const [snack, setSnack] = useState(initialSnack);
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = useState(false);
  const [countryDrop, setCountryDrop] = useState([]);
  const [statusDrop, setStatusDrop] = useState([]);
  const [userTypeList, setUserType] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [stateDrop, setStateDrop] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [toBackendPass, setToBackendPass] = useState(false);
  const [edit, setEdit] = useState(false);
  const [text, setTextField] = useState([]);
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const isOEM = sessionStorage.getItem('userTypeId') == 1;

  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  useEffect(() => {
    fetchData();
    getRoleList();
    getCustomerList();
    getCountryList();
    userType();
    fetchStatus();
  }, []);

  useEffect(() => {
    let data = profileFields();
    // console.log(data, "data");
    setTextField(data);
  }, []);

  useEffect(() => {
    if (profileDetails.country !== '' && profileDetails.country !== null) {
      let countryID = countryDrop?.filter((data) => {
        return data.countryName === profileDetails.country;
      });
      getStateList(countryID[0]?.countryId);
    }
  }, [profileDetails.country]);

  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const fetchData = async () => {
    setloading(true);
    try {
      const data = await fetchUserTableById();
      data?.forEach((obj) => {
        obj?.useradditionalfield.forEach((element) => {
          const fieldExists = data.some((item) =>
            item.hasOwnProperty(element.fieldName)
          );
          if (!fieldExists) {
            data.forEach((item) => (item[element.fieldName] = '-'));
          }
          obj[element.fieldName] = element.value || '-';
        });
      });

      let tableData = data;
      if (Array.isArray(data)) {
        tableData = data;
        setTableValue(tableData);
      } else if (typeof data === 'object' && data !== null) {
        tableData = [data];
        setTableValue(tableData);
      }

      if (Object.keys(params).length > 0) {
        const userId = params.key;
        const filteredData = tableData.filter((item) => item.userId == userId);
        if (filteredData.length > 0) {
          setProfileDetails(filteredData[0]);
          setEditValue(filteredData[0]);
          setEdit(false);
        }
      }
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };

  const getStateList = async (Id) => {
    try {
      let data = await StateDropdown(Id);
      setStateDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getCountryList = async () => {
    try {
      let data = await CountryDropdown();
      setCountryDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  //----------------User Company List Get Api----------------//
  const getRoleList = async () => {
    function removeObjectById(array, id) {
      return array.filter((obj) => obj.roleId !== id);
    }

    function setRoleIdData(data) {
      if (isOEM) {
        setRoleId(
          removeObjectById(
            Array.isArray(data) ? data : [data],
            Number(sessionStorage.getItem('roleId'))
          )
        );
      } else {
        setRoleId(data);
      }
    }

    try {
      let data = await GetRoledropdowndata();
      console.log(data, 'role');
      setRoleIdData(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getCustomerList = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      console.log(data, 'GetCustomerdropdowndata');
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === 'object' && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const userType = async () => {
    let responseData = await FetchUserType();
    console.log(responseData.data, 'responseData');
    setUserType(responseData.data);
  };

  const handleTxtChange = (event) => {
    console.log(event.target.name, event.target.value);
    setProfileDetails({
      ...profileDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handlePassChange = (event) => {
    setPassUpdate({
      ...passUpdate,
      [event.target.name]: event.target.value,
    });
  };

  const handleDropdownChange = (event, value, name) => {
    setProfileDetails({ ...profileDetails, [name]: value });
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleClose = () => {
    setEdit(false);
  };

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const handlePassword = () => {
    setOpen(true);
  };

  const handlePassClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (values) => {
    console.log(values, 'values');
    let cty = values.city !== '' ? values.city : editValue.city;
    let cntry = values.country !== '' ? values.country : editValue.country;
    let emailId = values.emailId !== '' ? values.emailId : editValue.emailId;
    let frstname =
      values.firstName !== '' ? values.firstName : editValue.firstName;
    let lstname = values.lastName !== '' ? values.lastName : editValue.lastName;
    let mobileNo =
      values.mobileNo !== '' ? values.mobileNo : editValue.mobileNo;
    let pstlcode =
      values.postalCode !== '' ? values.postalCode : editValue.postalCode;
    let ste = values.state !== '' ? values.state : editValue.state;
    let desig =
      values.designation !== '' ? values.designation : editValue.designation;
    values.firstName = frstname;
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    let roleIdFilter = roleId?.filter((data) => {
      return data.roleName === values.roleName;
    });
    console.log(roleIdFilter, 'roleIdFiltr', roleId);
    let userAddField = [];
    profileDetails.useradditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        if (obj.fieldId) {
          userAddField.push({
            lastUpdatedBy: sessionStorage.getItem('id'),
            fieldId: obj.fieldId,
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        } else {
          userAddField.push({
            createdBy: Number(sessionStorage.getItem('id')),
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        }
      }
    });

    let userTypeId = userTypeList?.filter(
      (data) => data.userTypeName === profileDetails.userTypeName
    );
    let obj = isOEM
      ? {
          city: cty,
          country: cntry,
          emailId: emailId,
          firstName: frstname,
          lastName: lstname,
          mobileNo: mobileNo,
          postalCode: Number(pstlcode),
          state: ste,
          userTypeId: isOEM
            ? userTypeId[0].userTypeId
            : Number(sessionStorage.getItem('userTypeId')),
          status: edit
            ? typeof values.status === 'string'
              ? status[0].value
              : values.status
            : status[0].value,
          lastUpdatedBy: Number(sessionStorage.getItem('id')),
          designation: desig,
          roleId:
            roleIdFilter.length === 0
              ? editValue.roleId
              : roleIdFilter[0].roleId,
          companyId: sessionStorage.getItem('companyId'),
          useradditionalfield: userAddField,
        }
      : {
          city: cty,
          country: cntry,
          emailId: emailId,
          firstName: frstname,
          lastName: lstname,
          mobileNo: mobileNo,
          postalCode: Number(pstlcode),
          state: ste,
          userTypeId: isOEM
            ? userTypeId[0].userTypeId
            : Number(sessionStorage.getItem('userTypeId')),
          status: edit
            ? typeof values.status === 'string'
              ? status[0].value
              : values.status
            : status[0].value,
          lastUpdatedBy: Number(sessionStorage.getItem('id')),
          designation: desig,
          roleId:
            roleIdFilter.length === 0
              ? editValue.roleId
              : roleIdFilter[0].roleId,
          companyId: sessionStorage.getItem('companyId'),
          customerId: Number(sessionStorage.getItem('customerId')),
          useradditionalfield: userAddField,
        };
    console.log(obj, 'data to update');
    try {
      setToBackend(true);
      let response = await updateEditCall(obj, editValue?.userId);
      const updatedTable = replaceObjectById(
        tableValue,
        'userId',
        editValue?.userId,
        response.data
      );
      setSnack(errorMsg.profileSuccess);
      setToBackend(false);
      setEdit(false);
      return 1;
    } catch (error) {
      if (error.response.status === 406) {
        setSnack(errorMsg.unique.userMail);
      } else {
        setSnack(errorMsg.failure);
      }

      setToBackend(false);
      setEdit(false);
      return 0;
    }
  };

  const handlePassUpdate = async () => {
    if (passUpdate.newPassword !== passUpdate.confirmNewPassword) {
      setSnack(errorMsg.unique.passMatch);
    }

    let payLoad = {
      oldPassword: PassEncrypt(passUpdate.oldPassword),
      newPassword: PassEncrypt(passUpdate.confirmNewPassword),
    };

    try {
      setToBackendPass(true);
      let result = await ChangePassAPI(profileDetails.userId, payLoad);
      console.log(result);
      setSnack(errorMsg.passSuccess);
      setToBackendPass(false);
      setPassUpdate({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      handlePassClose();
    } catch (error) {
      if (error.response?.data === 'Old password is incorrect') {
        setSnack(errorMsg.unique.currentPass);
      } else {
        setSnack(errorMsg.failure);
      }

      setToBackendPass(false);
    }
  };

  return (
    <>
      <CustomSnackbar
        open={snack.open}
        onClose={handleSnackClose}
        autoHideDuration={5000}
        severity={snack.severity}
        message={snack.message}
      />
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '95%',
              marginLeft: '2%',
              height: '40px',
              marginBottom: '30px',
              padding: '5px',
              borderRadius: '10px',
            }}
            sx={{ bgcolor: 'background.paper' }}
          >
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid
                item
                xs={4}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    marginLeft: '1vw',
                    userSelect: 'none',
                  }}
                >
                  User Profile
                </Typography>
              </Grid>
              <Grid
                item
                xs="auto"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <Tooltip title="Edit  profile">
                  <IconButton disabled={edit} onClick={handleEdit}>
                    <EditOutlinedIcon
                      style={{
                        color: edit ? 'rgba(0, 0, 0, 0.26)' : '#1976d2',
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Change Password">
                  <IconButton onClick={handlePassword}>
                    <KeyOutlinedIcon
                      style={{
                        color: edit ? '#1976d2' : '#1976d2',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '60vh',
            }}
          >
            <Box
              sx={{
                bgcolor: 'background.paper',
                width: '95%',
                height: '50vh',
                display: 'flex',
                justifyContent: 'center',
                padding: '20px',
                marginLeft: '2%',
                borderRadius: '10px',
              }}
            >
              <Formik
                key={'add'}
                enableReinitialize={true}
                initialValues={profileDetails}
                validationSchema={profileSchema}
                onSubmit={async (values, { resetForm }) => {
                  let submitValue = values;
                  const result = handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }}
              >
                {({ values, handleChange, handleSubmit, handleReset }) => (
                  <Form>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div className="modelGrid" style={{ width: '40%' }}>
                        <div
                          style={{
                            marginBottom: '20px',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: '20px',
                              fontWeight: '400',
                              lineHeight: '16px',
                              userSelect: 'none',
                            }}
                          >
                            Personal Details
                          </Typography>
                        </div>
                        <Grid container spacing={2} columns={8}>
                          {text?.length > 0
                            ? text.map((data, index) => {
                                return data.view &&
                                  data.section === 'profile' ? (
                                  <Grid item xs={4}>
                                    <div key={index}>
                                      <Typography
                                        variant="body1"
                                        className="modal-typo"
                                        gutterBottom
                                      >
                                        {data.label}
                                      </Typography>
                                      {data.type === 'textField' ? (
                                        <>
                                          <Field
                                            as={TextField}
                                            id={`outlined-basic-${index}`}
                                            size="small"
                                            variant="outlined"
                                            name={data.name}
                                            inputProps={{
                                              maxLength: data.length,
                                            }}
                                            disabled={toBackend || !edit}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleTxtChange(e);
                                            }}
                                            value={
                                              values[data.name] === null
                                                ? ''
                                                : values[data.name]
                                            }
                                            sx={{ width: '15vw' }}
                                          />
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="errorStyle"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : data.type === 'number' ? (
                                        <>
                                          <Field
                                            as={TextField}
                                            id={`outlined-basic-${index}`}
                                            size="small"
                                            disabled={toBackend || !edit}
                                            variant="outlined"
                                            name={data.name}
                                            type={data.type}
                                            onChange={(e) => {
                                              let numLength;
                                              let inputValue = e.target.value;
                                              const onlyDigits = /^\d+$/;

                                              if (
                                                e.target.name === 'mobileNo'
                                              ) {
                                                numLength = 10;
                                              } else if (
                                                e.target.name === 'postalCode'
                                              ) {
                                                numLength = 6;
                                              }
                                              if (
                                                onlyDigits.test(inputValue) &&
                                                inputValue.length <= numLength
                                              ) {
                                                handleChange(e);
                                                handleTxtChange(e);
                                              } else if (inputValue === '') {
                                                // Reset the state for the field if input value is empty
                                                setProfileDetails({
                                                  ...profileDetails,
                                                  [e.target.name]: '',
                                                });
                                              }
                                            }}
                                            value={
                                              values[data.name] === null
                                                ? ''
                                                : values[data.name]
                                            }
                                            sx={{ width: '15vw' }}
                                          />
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="errorStyle"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : data.type === 'dropdown' &&
                                        data.view ? (
                                        <>
                                          <Tooltip title={values[data.name]}>
                                            <Field
                                              name={data.name} // Ensure this matches the field name in your validation schema
                                              render={({ field, form }) => (
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit &&
                                                        data.name === 'status'
                                                      ? typeof values[
                                                          data.name
                                                        ] === 'string'
                                                        ? values[data.name]
                                                        : values[data.name] == 1
                                                        ? 'Active'
                                                        : 'Inactive'
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Autocomplete
                                                    disableClearable
                                                    disabled={
                                                      toBackend || !edit
                                                    }
                                                    autoHighlight
                                                    options={
                                                      data.name === 'country'
                                                        ? countryDrop.map(
                                                            (option) =>
                                                              option.countryName
                                                          )
                                                        : data.name === 'state'
                                                        ? stateDrop.map(
                                                            (option) =>
                                                              option.stateName
                                                          )
                                                        : data.name ===
                                                          'roleName'
                                                        ? roleId.map(
                                                            (option) =>
                                                              option.roleName
                                                          )
                                                        : null
                                                    }
                                                    disablePortal
                                                    size="small"
                                                    id={`combo-box-demo-${index}`}
                                                    onChange={(event, value) =>
                                                      handleDropdownChange(
                                                        event,
                                                        value,
                                                        data.name
                                                      )
                                                    }
                                                    value={values[data.name]}
                                                    sx={{
                                                      width: '15vw',
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField {...params} />
                                                    )}
                                                  />
                                                </Tooltip>
                                              )}
                                            />
                                          </Tooltip>
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="errorStyle"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                  </Grid>
                                ) : null;
                              })
                            : null}
                        </Grid>
                      </div>
                      <div
                        className="modelGrid"
                        style={{
                          marginTop: '20px',
                          width: '40%',
                          marginLeft: '10%',
                        }}
                      >
                        <div style={{ marginBottom: '20px' }}>
                          <Typography
                            style={{
                              fontSize: '20px',
                              fontWeight: '400',
                              lineHeight: '16px',
                              userSelect: 'none',
                            }}
                          >
                            Address
                          </Typography>
                        </div>
                        <Grid
                          container
                          spacing={2}
                          columns={16}
                          style={{ justifyContent: 'space-between' }}
                        >
                          {text?.length > 0
                            ? text.map((data, index) => {
                                return data.view &&
                                  data.section === 'address' ? (
                                  <>
                                    <Grid item xs={8}>
                                      <div key={index}>
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          {data.label}
                                        </Typography>
                                        {data.type === 'textField' ? (
                                          <>
                                            <Field
                                              as={TextField}
                                              id={`outlined-basic-${index}`}
                                              size="small"
                                              variant="outlined"
                                              name={data.name}
                                              inputProps={{
                                                maxLength: data.length,
                                              }}
                                              disabled={toBackend || !edit}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleTxtChange(e);
                                              }}
                                              value={
                                                values[data.name] === null
                                                  ? ''
                                                  : values[data.name]
                                              }
                                              sx={{ width: '15vw' }}
                                            />
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }}
                                            />
                                          </>
                                        ) : data.type === 'number' ? (
                                          <>
                                            <Field
                                              as={TextField}
                                              id={`outlined-basic-${index}`}
                                              size="small"
                                              disabled={toBackend || !edit}
                                              variant="outlined"
                                              name={data.name}
                                              type={data.type}
                                              onChange={(e) => {
                                                let numLength;
                                                let inputValue = e.target.value;
                                                const onlyDigits = /^\d+$/;

                                                if (
                                                  e.target.name === 'mobileNo'
                                                ) {
                                                  numLength = 10;
                                                } else if (
                                                  e.target.name === 'postalCode'
                                                ) {
                                                  numLength = 6;
                                                }
                                                if (
                                                  onlyDigits.test(inputValue) &&
                                                  inputValue.length <= numLength
                                                ) {
                                                  handleChange(e);
                                                  handleTxtChange(e);
                                                } else if (inputValue === '') {
                                                  // Reset the state for the field if input value is empty
                                                  setProfileDetails({
                                                    ...profileDetails,
                                                    [e.target.name]: '',
                                                  });
                                                }
                                              }}
                                              value={
                                                values[data.name] === null
                                                  ? ''
                                                  : values[data.name]
                                              }
                                              sx={{ width: '15vw' }}
                                            />
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }}
                                            />
                                          </>
                                        ) : data.type === 'dropdown' &&
                                          data.view ? (
                                          <>
                                            <Tooltip title={values[data.name]}>
                                              <Field
                                                name={data.name} // Ensure this matches the field name in your validation schema
                                                render={({ field, form }) => (
                                                  <Tooltip
                                                    title={
                                                      !edit &&
                                                      data.name === 'status'
                                                        ? 'Active'
                                                        : edit &&
                                                          data.name === 'status'
                                                        ? typeof values[
                                                            data.name
                                                          ] === 'string'
                                                          ? values[data.name]
                                                          : values[data.name] ==
                                                            1
                                                          ? 'Active'
                                                          : 'Inactive'
                                                        : values[data.name]
                                                    }
                                                  >
                                                    <Autocomplete
                                                      disableClearable
                                                      disabled={
                                                        toBackend || !edit
                                                      }
                                                      autoHighlight
                                                      options={
                                                        data.name === 'country'
                                                          ? countryDrop.map(
                                                              (option) =>
                                                                option.countryName
                                                            )
                                                          : data.name ===
                                                            'state'
                                                          ? stateDrop.map(
                                                              (option) =>
                                                                option.stateName
                                                            )
                                                          : null
                                                      }
                                                      disablePortal
                                                      size="small"
                                                      id={`combo-box-demo-${index}`}
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) =>
                                                        handleDropdownChange(
                                                          event,
                                                          value,
                                                          data.name
                                                        )
                                                      }
                                                      value={values[data.name]}
                                                      sx={{
                                                        width: '15vw',
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                        />
                                                      )}
                                                    />
                                                  </Tooltip>
                                                )}
                                              />
                                            </Tooltip>
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }}
                                            />
                                          </>
                                        ) : null}
                                      </div>
                                    </Grid>
                                  </>
                                ) : null;
                              })
                            : null}
                        </Grid>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        disableElevation
                        disabled={!edit || toBackend}
                        variant="outlined"
                        style={{
                          textTransform: 'none',
                          marginRight: '10px',
                        }}
                        startIcon={<CancelIcon />}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        disabled={!edit || toBackend}
                        style={{
                          textTransform: 'none',
                        }}
                        startIcon={
                          toBackend ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <SaveIcon />
                          )
                        }
                        onClick={toBackend ? null : handleSubmit}
                      >
                        {toBackend ? 'Saving changes...' : 'Save changes'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
              if (!toBackendPass) {
                handlePassClose();
              }
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box
                className={'styleModal'}
                sx={{
                  bgcolor: 'background.paper',
                  width: '30%',
                  height: '50vh',
                  padding: '20px',
                  marginRight: '2%',
                  borderRadius: '10px',
                }}
              >
                <div
                  className="modelGrid"
                  style={{
                    marginLeft: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      style={{
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '16px',
                        userSelect: 'none',
                      }}
                    >
                      Update Password
                    </Typography>
                  </div>

                  <Grid container spacing={2} columns={8}>
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        className="modal-typo"
                        gutterBottom
                      >
                        Current Password *
                      </Typography>
                      <OutlinedInput
                        id="outlined-basic"
                        variant="outlined"
                        name="oldPassword"
                        type={showPassword.old ? 'text' : 'password'}
                        size="small"
                        value={passUpdate.oldPassword}
                        onChange={(e) => {
                          handlePassChange(e);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-placeholder="toggle password visibility"
                              onClick={() =>
                                setShowPassword((prev) => ({
                                  ...prev,
                                  old: !prev.old,
                                }))
                              }
                              edge="end"
                            >
                              {showPassword.old ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{ width: '24vw' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        className="modal-typo"
                        gutterBottom
                      >
                        New Password *
                      </Typography>
                      <OutlinedInput
                        id="outlined-basic"
                        variant="outlined"
                        name="newPassword"
                        type={showPassword.new ? 'text' : 'password'}
                        value={passUpdate.newPassword}
                        onChange={(e) => {
                          handlePassChange(e);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-placeholder="toggle password visibility"
                              onClick={() =>
                                setShowPassword((prev) => ({
                                  ...prev,
                                  new: !prev.new,
                                }))
                              }
                              edge="end"
                            >
                              {showPassword.new ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        size="small"
                        sx={{ width: '24vw' }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        className="modal-typo"
                        gutterBottom
                      >
                        Retype Password *
                      </Typography>
                      <OutlinedInput
                        id="outlined-basic"
                        name="confirmNewPassword"
                        type={showPassword.confirm ? 'text' : 'password'}
                        value={passUpdate.confirmNewPassword}
                        onChange={(e) => {
                          handlePassChange(e);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-placeholder="toggle password visibility"
                              onClick={() =>
                                setShowPassword((prev) => ({
                                  ...prev,
                                  confirm: !prev.confirm,
                                }))
                              }
                              edge="end"
                            >
                              {showPassword.confirm ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        variant="outlined"
                        size="small"
                        sx={{ width: '24vw' }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    disableElevation
                    variant="contained"
                    disabled={toBackendPass}
                    style={{
                      textTransform: 'none',
                      marginTop: '30px',
                      width: '24vw',
                    }}
                    startIcon={
                      toBackendPass ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <UpdateOutlinedIcon />
                      )
                    }
                    onClick={toBackendPass ? null : handlePassUpdate}
                  >
                    {toBackendPass ? 'Updating Password...' : 'Update Password'}
                  </Button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </>
  );
};
