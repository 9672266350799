import axios from 'axios';
export const fetchindustrytable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_TABLE_INDUSTRY}`
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchindustrytableById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_TABLE_INDUSTRY_BYID
      }/${sessionStorage.getItem('customerId')}`
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchSectordropdownvalue = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_Sector_dropdown_SectortypeList}`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const putSectorupdateEdit = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_UPDATE_INDUSTRY}/${id}`,
      data
    );

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const postSectordata = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SAVE_INDUSTRY}`,
      obj
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
