import React, { useEffect, useState } from 'react';
import TotalCard from './HeaderCard/TotalCard';

import Contact3 from './ContentCard/Contact3';
import Content2 from './ContentCard/Content2';
import Content1 from './ContentCard/Content1';

import './Style.css';
import { FadeLoader } from 'react-spinners';
import { getDashboardValues } from '../../Api/DashboardAllAPI';
import axios from 'axios';

export const SaasDashboardHome = () => {
  const [EventStatistcs, setEventStatistcs] = useState();
  const [SiteStatistics, setSiteStatistics] = useState();
  const [dashboardDetails, setDashboardDetails] = useState();
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAPiDashboardCall();
  }, []);

  const GroupedData = (data) => ({
    dashboardDetails: {
      siteCount: data.siteCount,
      utilityCount: data.utilityCount,
      roofTopCount: data.roofTopCount,
      alarmTotalCount: data.alarmTotalCount,
      alarmTodayCount: data.alarmTodayCount,
      totalTodayEnergy: data.totalTodayEnergy,
      sumOfTotalEnergy: data.sumOfTotalEnergy,
      co2: data.co2,
      totalOpenTickets: data.totalOpenTickets,
      totalHoldTickets: data.totalHoldTickets,
      totalCloseTickets: data.totalCloseTickets,
    },
    siteDetails: [...data.siteDetails],
    SiteStatistics: {
      activeCount: data.activeCount,
      warningCount: data.warningCount,
      offlineCount: data.offlineCount,
      downCount: data.downCount,
    },
  });

  const getAPiDashboardCall = async () => {
    const token = sessionStorage.getItem('jwtToken');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['allowCredentials'] = true;
    try {
      setLoading(true);
      // let responseData = await SiteDetails();
      let id = sessionStorage.getItem('id');
      let response = await getDashboardValues(id);
      const data = GroupedData(response);
      setDashboardDetails(data.dashboardDetails);
      setEventStatistcs(data.siteDetails);
      setSiteStatistics(data.SiteStatistics);
      setResponseData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          {/* 
          
          <div className="card-main-align">
            <div style={{ width: '38vw' }}>
              <TotalCard dashboardDetails={dashboardDetails} />
            </div>
            <div style={{ width: '25vw' }}>
              <Content2 SiteStatistics={SiteStatistics} />
            </div>
            <div style={{ width: '26vw' }}>
              <Content1 EventStatistcs={EventStatistcs} />
            </div>
        
          </div>

          <div className="card-main-align" style={{ paddingTop: '16px' }}>
            <div style={{ width: '58vw' }}>
              <Contact3 name={'daily generation'} /> 
            </div>
          </div>*/}

          <div className="card-main">
            <div style={{ width: '65%' }}>
              <TotalCard dashboardDetails={dashboardDetails} />
            </div>
            <div style={{ width: '30%' }}>
              <Content2 SiteStatistics={SiteStatistics} />
            </div>
          </div>
          <div className="card-main-card">
            <div style={{ width: '65%' }}>
              <Contact3 name={'daily generation'} />
            </div>
            <div style={{ width: '30%' }}>
              <Content1 EventStatistcs={EventStatistcs} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
