import React, { useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DraggableItem = ({ item, index, onChange, from, length }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [fixedPosition, setFixedPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const handleMouseMove = (event) => {
      setCursorPosition({
        x: event.clientX,
        y: event.clientY,
      });
    };

    document.addEventListener('mousemove', handleMouseMove);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [cursorPosition]);

  const handleChange = (e) => {
    if (onChange) {
      onChange(index, e.target.value);
    }
  };

  const onDragStart = () => {
    setFixedPosition({ x: cursorPosition.x, y: cursorPosition.y });
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        const DividerY =
          from === 'operators'
            ? 4
            : from === 'items'
            ? 3
            : from === 'params'
            ? 3
            : 3;
        const DividerX =
          from === 'operators'
            ? 4
            : from === 'items'
            ? 2
            : from === 'params'
            ? 4
            : 3;
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="draggable-item"
            onMouseEnter={onDragStart}
            style={{
              ...provided.draggableProps.style,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'white',
              width: 'fit-content',
              border: '1px solid lightgrey',
              borderRadius: from === 'formula' ? null : '4px',
              borderRight:
                from === 'formula' && index !== length - 1
                  ? null
                  : '1px solid lightgrey',
              borderLeft:
                from === 'formula' && index !== 0
                  ? null
                  : '1px solid lightgrey',
              padding:
                from === 'formula'
                  ? index === length - 1
                    ? '0 8px 0 8px'
                    : '0 0 0 8px'
                  : '8px',
              margin: from === 'formula' ? null : '0 8px 8px 0',
              boxShadow: snapshot.isDragging
                ? '0 2px 8px rgba(0,0,0,0.2)'
                : 'none',
              zIndex: snapshot.isDragging ? 1000 : 'auto',
              top: `${fixedPosition.y - fixedPosition.y / DividerY}px`,
              left: `${fixedPosition.x - fixedPosition.x / DividerX}px`,
              position: snapshot.isDragging ? 'fixed' : 'static',
            }}
          >
            {item.type === 'number' || item.type === 'text' ? (
              <input
                type={item.type}
                value={item.content}
                onChange={handleChange}
                style={{
                  width: '50px',
                  marginRight: from === 'formula' ? null : '8px',
                  border: 'none',
                }}
              />
            ) : (
              item.content
            )}
          </div>
        );
      }}
    </Draggable>
  );
};

export default DraggableItem;
