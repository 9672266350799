import React, { useState, useEffect, useContext } from 'react';
import { useUserContext } from '../Auth/UserContext';

import TableTemplate from '../../Template/TableTemplate';
import {
  fetchsectorTable,
  Getdropdownsectorlist,
  Getdropdowndata,
  putupdatesectorvalue,
  postsectordata,
  fetchsectorTableById,
} from '../../Api/SectorApi';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FadeLoader from 'react-spinners/FadeLoader';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { SectorSchema } from '../util/ValidateSchema';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Tooltip,
} from '@mui/material';
import Download from '../../Template/Excel/Download';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { sectorField } from '../util/TextField';
import { Statusdropdown } from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import errorMsg from '../util/errorMessage.json';
import { CheckForLight } from '../util/common/CommanFunction';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const headCells = [
  {
    label: 'Sector Type',
    id: 'sectorName',
    view: true,
    default: true,
  },
  {
    label: 'Created On',
    id: 'createdDate',
    view: false,
  },
  {
    label: 'Status',
    id: 'status',
    view: true,
  },
  {
    label: 'Action',
    id: 'action',
    view: true,
    default: true,
  },
];

const Sector = ({ SectorConfigurations }) => {
  const { userContextValue } = useUserContext();
  const { userMapDetails } = userContextValue || {};
  const [loading, setLoading] = useState(true);
  const [tableValue, setTableValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [statusDrop, setStatusDrop] = useState([]);
  const [editValue, setEditValue] = useState();
  const [customerIds, setcustomerId] = useState([]);
  const [sectorIds, setSectorId] = useState([]);
  // const [companyName, setCompanyName] = useState('');
  const [toBackend, setToBackend] = useState(false);
  const [siteTab, setSiteTab] = React.useState('');
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  useEffect(() => {
    fetchData();
    getcompanylist();
    getsectorlist();
    fetchStatus();
  }, [userMapDetails]);
  console.log(SectorConfigurations, 'SectorConfigurations');

  /*---------------------------------------Table Fetch data ------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchsectorTable();

      if (Array.isArray(data)) {
        setTableValue(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setTableValue([data]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initalState = {
    sectorName: '',
    status: '',
  };
  const [userFields, setUserFields] = useState(initalState);
  /*-----------------------------Drop Down ----------------------------------*/

  const getcompanylist = async () => {
    try {
      let data = await Getdropdowndata();
      console.log(data, 'companylist');
      setcustomerId(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getsectorlist = async () => {
    try {
      let data = await Getdropdownsectorlist();
      console.log(data, 'sector list');
      if (Array.isArray(data)) {
        setSectorId(data);
      } else if (typeof data === 'object' && data !== null) {
        setSectorId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };

  const [text, setTextField] = useState([]);
  useEffect(() => {
    let data = sectorField();
    setTextField(data);
  }, []);
  /*------------------------------------- Onchange Values --------------------*/

  const handleOpenPopup = (val) => {
    setOpen(val);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setUserFields(initalState);
  };

  const handleDropDownChange = (event, value, name) => {
    console.log(value, name);
    setUserFields({ ...userFields, [name]: value });
  };
  const handleCrate = () => {
    setOpen(true);
    // seteditcall("");
  };
  /*-------------------------------------Add Sector Call ---------------------*/
  const handleSubmit = async () => {
    let sectorTypeIdFilter = sectorIds?.filter((data) => {
      return data.sectorName === userFields.sectorName;
    });
    let data = {
      customerId: sessionStorage.getItem('id'),
      createdBy: sessionStorage.getItem('id'),
      status: 1,
      sectorTypeId: sectorTypeIdFilter[0]?.sectorTypeId,
    };
    console.log(data, 'dataSubmit');
    try {
      setToBackend(true);
      const responseData = await postsectordata(data);
      console.log(responseData, 'responseData');
      if (responseData.data.statusCode == 200) {
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: 'success',
          message: responseData.data.statusDescription,
        });
        handleClose();
        setToBackend(false);
        return 1;
      }
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.sector);
      } else {
        setSnack(errorMsg.failure);
        console.error('Error:', e.response.status);
        console.log('equipment:', e.message);
      }
      setToBackend(false);
      return 0;
    }
  };
  /*--------------------------------------- Edit popup Open----------------------------*/
  const handleEditOpen = (sectorId) => {
    console.log(sectorId);
    let filteredsectorId = Object.keys(initalState).reduce((acc, key) => {
      if (sectorId.hasOwnProperty(key)) {
        acc[key] = sectorId[key];
      }
      return acc;
    }, {});
    setEditValue(sectorId);
    setUserFields(filteredsectorId);
    setEdit(true);
    setOpen(true);
  };

  /*------------------------------------------Update Sector ---------------------------*/
  const handleUpdate = async (values) => {
    let SectorId = editValue?.sectorId;
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    // let companyNameEdit = companyName !== '' ? companyName : editValue.companyName;
    let sectorNameEdit = values.sectorName;
    let getSectorId = sectorIds?.filter((data) => {
      return data.sectorName === sectorNameEdit;
    });
    let obj = {
      customerId: sessionStorage.getItem('id'),
      sectorTypeId: getSectorId[0]?.sectorTypeId,
      status: edit
        ? typeof values.status === 'string'
          ? status[0].value
          : values.status
        : status[0].value,
      lastUpdatedBy: sessionStorage.getItem('id'),
    };
    console.log(obj, 'objUpdate');
    try {
      setToBackend(true);
      let responsedata = await putupdatesectorvalue(obj, SectorId);
      const updatedTable = replaceObjectById(
        tableValue,
        'sectorId',
        SectorId,
        responsedata
      );
      // fetchData();
      setSnack(errorMsg.success);
      handleClose();
      setToBackend(false);
      return 1;
    } catch (error) {
      if (error.response.status === 406) {
        setSnack(errorMsg.unique.sector);
      } else {
        setSnack(errorMsg.failure);
      }

      console.error('Error:', error.response.status);
      setToBackend(false);
      return 0;
    }
  };
  /*-----------------------------------------Table Download ----------------------------*/
  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Sector');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const tableValues = tableValue.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = tableValue?.length;

  const Active = tableValue?.filter((site) => site?.status == 1).length;

  const Inactive = tableValue?.filter((site) => site?.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Sector', value: 'all', badgeCount: Total },
    { name: 'Active Sector', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Sector',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  function getOptions(propName) {
    let option;
    switch (propName) {
      case 'sectorName':
        return (option = sectorIds?.map((option) => option.sectorName));
      case 'status':
        return (option = statusDrop?.map((option) => option.label));
      default:
    }
    return option;
  }
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Sector', path: 'sector' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <TableTemplate
            PageName={'Sector'}
            addButton={'Sector'}
            SearchLabel={'Search Sector Here... '}
            header={headCells}
            rowsValue={tableValues}
            tabsData={tabsData}
            handleChange={handleChangeTab}
            rawData={tableValue}
            userRole={SectorConfigurations[0]}
            // handleAddPopupOpen={(val) => handleOpenPopup(val)}
            handleAddPopupOpen={(val) => handleCrate(val)}
            handleEditPopupOpen={(val) => handleEditOpen(val)}
            handleDownloadExcel={(val) => {
              handleDownloadTable(val);
            }}
            paths={paths}
            fallbackText={`No${
              siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''
            } Sector has been configured yet`}
          />
          <div className="Modal">
            <Formik
              key={!edit ? 'add' : 'edit'}
              enableReinitialize={true}
              initialValues={userFields}
              validationSchema={SectorSchema}
              onSubmit={async (values, { resetForm }) => {
                console.log(values);
                let submitValue = {
                  sectorName: values.sectorName,
                  status: values.status,
                };
                console.log(values, 'values');
                if (!edit) {
                  const result = await handleSubmit(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    sx={{ marginTop: '12vh' }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalSmall'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    s
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={edit ? 'Update Sector' : 'Add New Sector'}
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          <Card
                            sx={{
                              overflowY: 'visible',
                              marginLeft: '-1vw',
                              // paddingLeft: '-1vw',
                              // marginTop: '-2%',
                              marginBottom: '-1%',
                              // paddingTop: '-2%',
                              scrollBehavior: 'smooth',
                              scrollbarGutter: 'stable',
                              scrollbarWidth: 'thin',
                              '&::-webkit-scrollbar': {
                                width: '0.4em',
                              },
                              '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '20px',
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                background: '#555',
                              },
                            }}
                            elevation={0}
                          >
                            <CardContent sx={{ marginTop: '-2%' }}>
                              {text?.length > 0 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '50px',
                                    flexWrap: 'wrap',
                                    marginTop: '1.5rem',
                                    marginLeft: '1.5rem',
                                    marginBottom: '1.5rem',
                                  }}
                                >
                                  {text?.map((data, index) => {
                                    return (
                                      <div key={index}>
                                        <Typography
                                          variant="body1"
                                          className="modal-typo"
                                          gutterBottom
                                        >
                                          {data.label}
                                        </Typography>
                                        {data.type === 'dropdown' ? (
                                          <>
                                            <Tooltip
                                              title={
                                                !edit && data.name === 'status'
                                                  ? 'Active'
                                                  : edit &&
                                                    data.name === 'status'
                                                  ? typeof values[data.name] ===
                                                    'string'
                                                    ? values[data.name]
                                                    : values.status === 1
                                                    ? 'Active'
                                                    : 'Inactive'
                                                  : values[data.name]
                                              }
                                            >
                                              <Field
                                                name={data.name}
                                                render={({ field, form }) => (
                                                  <Tooltip
                                                    title={
                                                      !edit &&
                                                      data.name === 'status'
                                                        ? 'Active'
                                                        : edit &&
                                                          data.name === 'status'
                                                        ? typeof values[
                                                            data.name
                                                          ] === 'string'
                                                          ? values[data.name]
                                                          : values.status === 1
                                                          ? 'Active'
                                                          : 'Inactive'
                                                        : values[data.name]
                                                    }
                                                  >
                                                    <Autocomplete
                                                      disabled={
                                                        toBackend ||
                                                        (!edit &&
                                                          data.name ===
                                                            'status')
                                                      }
                                                      disableClearable
                                                      options={getOptions(
                                                        data.name
                                                      )}
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) => {
                                                        handleDropDownChange(
                                                          event,
                                                          value,
                                                          data.name
                                                        );
                                                      }}
                                                      disablePortal
                                                      size="small"
                                                      value={
                                                        !edit &&
                                                        data.name === 'status'
                                                          ? 'Active'
                                                          : edit &&
                                                            data.name ===
                                                              'status'
                                                          ? typeof values[
                                                              data.name
                                                            ] === 'string'
                                                            ? values[data.name]
                                                            : values.status ===
                                                              1
                                                            ? 'Active'
                                                            : 'Inactive'
                                                          : values[data.name]
                                                      }
                                                      id={`combo-box-demo-${index}`}
                                                      sx={{ width: '12vw' }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          placeholder={
                                                            data.placeholder
                                                          }
                                                        />
                                                      )}
                                                    />
                                                  </Tooltip>
                                                )}
                                              />
                                            </Tooltip>
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }}
                                            />
                                          </>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </CardContent>
                          </Card>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sector;
