import axios from 'axios';

export const SiteListApi = async () => {
  try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_PATH}${
    //     process.env.REACT_APP_SITELIST
    //   }=${sessionStorage.getItem('id')}`
    // );
    //uncomment below code and code above to access eira db
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITE_LIST_TABLE
      }/${sessionStorage.getItem('id')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const fetchSiteEquipment = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE__EQUIPMENT_ALERMS}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching site equipment:', error);
    throw error;
  }
};

export const fetchSiteAlermsfilter = async (data) => {
  const { fromDate, toDate, siteId, Priority } = data;
  console.log(fromDate, toDate, siteId);
  console.log(siteId);
  console.log(Priority);

  try {
    const baseURL = `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_ALARMS_FILTER}=${siteId}&fromDate=${fromDate}&toDate=${toDate}`;
    const url =
      Priority.length > 0 ? `${baseURL}&priority=${Priority}` : baseURL;
    //      const baseURL = `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_SITE_ALARMS_FILTER}=${101 }&fromDate=${"2024-07-01"}&toDate=${"2024-07-31"}`
    //  const url = Priority.length > 0 ? `${baseURL}&priority=${Priority}` : baseURL;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching site equipment:', error);
    throw error;
  }
};

export const fetchSiteAlerms = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE__USERDIFINE_ALERMS}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const SiteOverview = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITEOVERVIEW}=${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};



export const fetchEquipmentlistTable = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SITE_EQUIPLIST}/${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const EnergymeterTables = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_ENERGYMETERTABLE}/${id}`
    );
    console.log(response, 'responsetotheconsole');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const SensorTables = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SENSORTABLE}/${id}`
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const ScbParams = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_SCB_PARAMETERS}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    return error;
  }
};

export const ScbTables = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SCBTABLE}/${id}`
    );
    console.log(response, 'responsetesting for SCB Tables');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
