import React, { useEffect, useState } from 'react';

/*----------------------Mui Icons----------------------*/
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
/*-----------------------MUI---------------------------*/
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Stack,
  Modal,
  Fade,
  Autocomplete,
  Backdrop,
  Divider,
  CardContent,
  Card,
  CircularProgress,
  useTheme,
  CardHeader,
} from '@mui/material';

/*---------------------Api-----------------------*/
import {
  fetchUserRoleTableById,
  postUserRoleSave,
  updateUserRoleEdit,
} from '../../../Api/UserConfigurationAPI';
import CheckboxGroup from '../../util/components/CheckBoxGroup';
import TableTemplate from '../../../Template/TableTemplate';
import Download from '../../../Template/Excel/Download';
import { fetchActivityName } from '../../../Api/UserRoleApi';
import CustomSnackbar from '../../util/components/CustomSnackbar';
import { Statusdropdown } from '../../../Api/CommonApi';
import replaceObjectById from '../../util/StateUpdater';
import errorMsg from '../../util/errorMessage.json';
import { getBroadcastChannel } from '../../util/security/BroadcastChannel';
import { queryDecrypt } from '../../util/security/Cipher';
import { FadeLoader } from 'react-spinners';
import { CheckForLight } from '../../util/common/CommanFunction';

export default function AddRoleTable({ userRoleConfiguration }) {
  const broadcastChannel = getBroadcastChannel();
  const [open, setOpen] = React.useState(false);
  const [statusDrop, setStatusDrop] = useState([]);
  const handleOpen = () => {
    setOpen(true);
    setEditValue();
  };
  //--------------------MODAL------------------//
  const stylemodal = {
    position: 'absolute',
    top: '50%',
    left: '51%',
    transform: 'translate(-50%, -50%)',
    width: '72%',
    height: '85vh',
    bgcolor: 'background.paper',
    p: 4,
  };

  const userStatusOptions = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 0 },
  ];
  /*------------state-----------------*/
  const [edit, setEdit] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [siteTab, setSiteTab] = React.useState('');
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const [activityName, setActivityName] = useState();
  console.log(activityName);
  const theme = useTheme();
  const isLightMode = theme.palette.mode === 'light';
  /*---------------User Role CREATE EDIT VIEW------------*/
  function transformActivities(activities) {
    const transformedObject = {};

    activities?.forEach((activity) => {
      const { activityName, status } = activity;
      const key = activityName;
      console.log(activity, 'acctivity');
      if (status == 1 && !transformedObject[key]) {
        transformedObject[key] = { create: false, edit: false, view: false };
      }
    });

    return transformedObject;
  }

  const [configurations, setConfigurations] = useState({});
  const handleClose = () => {
    setOpen(false);
    setUserFields({
      description: '',
      status: '',
      lastUpdatedBy: '',
      roleId: '',
      createdBy: '',
      roleName: '',
      lastUpdatedDate: '',
      creationDate: '',
    });
    const transformedObject = transformActivities(activityName);
    setConfigurations(transformedObject);
    setEditValue('');
    setEdit(false);
  };

  const processDataForConfigurations = (data) => {
    const mappedConfigurations = {};

    data.usermap.forEach((activity) => {
      const { activityName, create, edit, view } = activity;
      const key =
        activityName === 'SOP'
          ? activityName
          : activityName?.charAt(0).toLowerCase() + activityName.slice(1);

      mappedConfigurations[key] = {
        create: create == 1,
        edit: edit == 1,
        view: view == 1,
      };
    });

    return mappedConfigurations;
  };

  function activityIdExtractor(ActivityName) {
    const item = activityName?.find(
      (item) => item.activityName === ActivityName
    );
    return item ? item.activityId : null;
  }

  const rolesString = queryDecrypt(sessionStorage.getItem('roles'));
  const rolesArray = rolesString ? JSON.parse(rolesString) : [];

  function hasPermission(activityName) {
    const isSuperAdmin =
      sessionStorage.getItem('roleName') === 'Super Admin-Hummer';
    console.log(rolesArray);
    if (!isSuperAdmin) {
      const activityEntry = rolesArray.find((entry) => {
        const roleName = sessionStorage.getItem('roleName');
        if (roleName !== 'Super Admin' && roleName !== 'Super Admin-Hummer') {
          return (
            entry.activityName === activityName &&
            entry.status === 1 &&
            entry.view === 1
          );
        } else {
          return entry.activityName === activityName && entry.status === 1;
        }
      });

      return !!activityEntry;
    }
    return true;
  }

  /*---------------------API Functionality--------------------*/
  const [editValue, setEditValue] = useState();
  /*-------------------------Edit Icon----------------------*/
  const handleEditClick = async (roleId) => {
    console.log(roleId);
    const processedConfigurations = processDataForConfigurations(roleId);
    console.log(processedConfigurations);
    setConfigurations((prevConfigurations) => {
      const updatedConfigurations = { ...prevConfigurations };

      Object.keys(processedConfigurations).forEach((activity) => {
        if (updatedConfigurations.hasOwnProperty(activity)) {
          updatedConfigurations[activity] = processedConfigurations[activity];
        }
      });
      console.log(updatedConfigurations);
      return updatedConfigurations;
    });
    setEditValue(roleId);
    setEdit(true);
    setOpen(true);
  };
  const handleChange = (event) => {
    setUserFields({ ...userFields, [event.target.name]: event.target.value });
  };
  const [userFields, setUserFields] = useState({
    description: '',
    status: '',
    lastUpdatedBy: '',
    roleId: '',
    createdBy: '',
    roleName: '',
    lastUpdatedDate: '',
    creationDate: '',
  });

  const handleStatus = (data, event) => {
    // console.log(event.value, 'status');
    setUserFields((prevState) => ({
      ...prevState,
      status: event.value,
    }));
  };
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };

  const isOEM = sessionStorage.getItem('userTypeId') == 1;
  /*-----------------------handleSubmit Call-------------------------*/
  const handleSubmit = async () => {
    if (userFields.roleName !== '' && userFields.description !== '') {
      console.log(userFields.status);
      let data = isOEM
        ? {
            roleName: userFields?.roleName,
            companyId: Number(sessionStorage.getItem('companyId')),
            description: userFields?.description,
            createdBy: Number(sessionStorage.getItem('id')),
            status: 1,
            userMap: Object.entries(configurations).map(
              ([activityName, config]) => ({
                activityId: activityIdExtractor(activityName),
                status: 1,
                create: config.create ? 1 : 0,
                view: config.view ? 1 : 0,
                edit: config.edit ? 1 : 0,
              })
            ),
          }
        : {
            roleName: userFields?.roleName,
            customerId: Number(sessionStorage.getItem('customerId')),
            companyId: Number(sessionStorage.getItem('companyId')),
            description: userFields?.description,
            createdBy: Number(sessionStorage.getItem('id')),
            status: 1,
            userMap: Object.entries(configurations).map(
              ([activityName, config]) => ({
                activityId: activityIdExtractor(activityName),
                status: 1,
                create: config.create ? 1 : 0,
                view: config.view ? 1 : 0,
                edit: config.edit ? 1 : 0,
              })
            ),
          };
      // console.log(data, "data testsave user");
      let responseData;
      try {
        setToBackend(true);
        responseData = await postUserRoleSave(data);
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: 'success',
          message: responseData.statusDescription,
        });
        handleClose();
        setToBackend(false);
        console.log('Response:', responseData);
      } catch (error) {
        setSnack(errorMsg.failure);
        setToBackend(false);
        console.error('Error:', error);
      }
    } else {
      setSnack(errorMsg.required);
    }
  };

  /*------------------------handleUpdate--------------------------*/
  const handleUpdate = async () => {
    // console.log(userFields);
    let desc =
      userFields.description !== ''
        ? userFields.description
        : editValue.description;
    let role =
      userFields.roleName !== '' ? userFields.roleName : editValue.roleName;
    let userstatus =
      userFields.status !== '' ? userFields.status : editValue.status;

    const activityMap = editValue.usermap.reduce((map, item) => {
      map[item.activityName] = item.mapId;
      return map;
    }, {});

    let obj = isOEM
      ? {
          roleName: role,
          companyId: Number(sessionStorage.getItem('companyId')),
          description: desc,
          lastUpdatedBy: Number(sessionStorage.getItem('id')),
          status: userstatus,
          userMap: Object.entries(configurations).map(
            ([activityName, config]) => ({
              activityId: activityIdExtractor(activityName),
              mapId: activityMap[activityName],
              status: 1,
              create: config.create ? 1 : 0,
              view: config.view ? 1 : 0,
              edit: config.edit ? 1 : 0,
            })
          ),
        }
      : {
          roleName: role,
          customerId: Number(sessionStorage.getItem('customerId')),
          companyId: Number(sessionStorage.getItem('companyId')),
          description: desc,
          lastUpdatedBy: Number(sessionStorage.getItem('id')),
          status: userstatus,
          userMap: Object.entries(configurations).map(
            ([activityName, config]) => ({
              activityId: activityIdExtractor(activityName),
              mapId: activityMap[activityName],
              status: 1,
              create: config.create ? 1 : 0,
              view: config.view ? 1 : 0,
              edit: config.edit ? 1 : 0,
            })
          ),
        };
    try {
      setToBackend(true);
      let response = await updateUserRoleEdit(obj, editValue.roleId);
      // console.log(data);
      if (editValue.roleId == sessionStorage.getItem('roleId')) {
        const token = sessionStorage.getItem('jwtToken');
        setSnack({
          ...snack,
          open: true,
          severity: 'success',
          message:
            'Your Role has been updated, Session will expire in 5 seconds',
        });
        setTimeout(() => {
          broadcastChannel.postMessage({
            type: 'logout',
            token: token,
          });
        }, 5000);
      } else {
        setSnack({
          ...snack,
          open: true,
          severity: 'success',
          message: 'Role updated successfully',
        });
      }
      handleClose();
      setToBackend(false);
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error('Error:', error.response.status);
      setToBackend(false);
    }
  };

  const handleConfigurationUpdate = (category, key) => {
    setConfigurations((prevConfig) => ({
      ...prevConfig,
      [category]: {
        ...prevConfig[category],
        [key]: !prevConfig[category][key],
      },
    }));
  };

  const findStatusName = (value) => {
    let status = statusDrop?.filter((data) => {
      return data.value === value;
    });
    return status[0].label;
  };

  /*------------------User Role-------------------*/

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
    fetchStatus();
  }, []);

  function createConfigGroups(activities, configurations) {
    return activities
      ?.filter((item) => item.status === 1)
      ?.map((item) => {
        const title = item.activityName
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .split(/(?=[A-Z])/)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        return {
          title: title,
          key: item.activityName,
          config: configurations[item.activityName],
        };
      });
  }
  const configGroups = createConfigGroups(activityName, configurations);
  console.log(configGroups, 'configuration groups');
  const fetchData = async () => {
    try {
      const result = await fetchUserRoleTableById();
      const activityName = await fetchActivityName();
      let data = [];
      if (
        sessionStorage.getItem('roleName') !== 'Super Admin' ||
        sessionStorage.getItem('roleName') === 'Super Admin-Hummer'
      ) {
        data = result.filter(
          (data) =>
            data.roleName !== 'Super Admin' &&
            data.roleName !== 'Super Admin-Hummer'
        );
      } else {
        data = result;
      }
      console.log(data, 'data');
      if (Array.isArray(data)) {
        setTableValue(data);
      } else if (typeof data === 'object' && data !== null) {
        setTableValue([data]);
      }
      setActivityName(activityName);
      const transformedObject = transformActivities(activityName);
      console.log(transformedObject);
      setConfigurations(transformedObject);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const headCells = [
    {
      label: 'Role Name',
      id: 'roleName',
      view: true,
      default: true,
    },
    {
      label: 'Description',
      id: 'description',
      view: true,
      default: true,
    },
    {
      label: 'Created Date',
      id: 'createdDate',
      view: true,
    },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];
  const tableValues = tableValue.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = tableValue.length;

  const Active = tableValue.filter((site) => site.status == 1).length;

  const Inactive = tableValue.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All User Role', value: 'all', badgeCount: Total },
    { name: 'Active User Role', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive User Role',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];

  const paths = [
    { label: 'Admin', path: '/config/admin/userconf' },
    { label: 'User Role', path: 'admin/userrole' },
  ];

  return (
    <div>
      <CustomSnackbar
        open={snack.open}
        onClose={handleSnackClose}
        autoHideDuration={5000}
        severity={snack.severity}
        message={snack.message}
        page={'Role'}
      />
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box
                className={'styleModal'}
                // sx={{ bgcolor: 'background.paper' }}
              >
                <Card
                  sx={{
                    borderRadius: '20px',
                    // height: '68vh',
                    background: CheckForLight() ? null : '#121212',
                    height: 'fit-content',
                  }}
                >
                  <CardHeader
                    sx={{
                      padding: '20px 20px 20px 20px',
                      background: CheckForLight()
                        ? 'rgb(246, 248, 252)'
                        : '#4f4f4f',
                    }}
                    action={
                      <Stack spacing={2} direction="row">
                        {edit ? (
                          <Button
                            variant="contained"
                            style={{
                              borderRadius: '25px',
                              textTransform: 'capitalize',
                            }}
                            startIcon={
                              toBackend ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            onClick={toBackend ? null : handleUpdate}
                          >
                            {toBackend ? 'Updating...' : 'Update'}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            style={{
                              borderRadius: '25px',
                              textTransform: 'capitalize',
                            }}
                            s
                            startIcon={
                              toBackend ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            onClick={toBackend ? null : handleSubmit}
                          >
                            {toBackend ? 'Saving...' : 'Save'}
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          disabled={toBackend}
                          style={{
                            borderRadius: '25px',
                            textTransform: 'capitalize',
                          }}
                          onClick={() => {
                            handleClose();
                          }}
                          startIcon={<CancelIcon />}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    }
                    title={edit ? 'Update Role' : 'Add New Role'}
                  />
                  <Divider sx={{ borderColor: '#888' }} />

                  <div className="user-div ">
                    <Card
                      sx={{
                        overflowY: 'scroll',
                        height: '26rem',
                        scrollBehavior: 'smooth',
                        scrollbarGutter: 'stable',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                          width: '0.4em',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#888',
                          borderRadius: '20px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                          background: '#555',
                        },
                      }}
                      elevation={0}
                    >
                      <CardContent sx={{ height: '2%' }}>
                        <Stack spacing={1} direction="row">
                          <div className="above-stack">
                            <Paper
                              elevation={0}
                              style={{
                                padding: '20px',
                                marginBottom: '20px',
                                backgroundColor: isLightMode
                                  ? '#f6f8fc'
                                  : '#4f4f4f',
                              }}
                            >
                              <Stack spacing={2} direction="row">
                                <div>
                                  <Typography variant="body1" gutterBottom>
                                    Role Name*
                                  </Typography>
                                  <TextField
                                    id="outlined-basic"
                                    size="small"
                                    disabled={toBackend}
                                    variant="outlined"
                                    sx={{ width: '12vw' }}
                                    name="roleName"
                                    defaultValue={
                                      editValue !== undefined
                                        ? editValue?.roleName
                                        : ''
                                    }
                                    placeholder="Role Name"
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                                <div>
                                  <Typography variant="body1" gutterBottom>
                                    Description*
                                  </Typography>
                                  <TextField
                                    id="outlined-basic"
                                    disabled={toBackend}
                                    size="small"
                                    variant="outlined"
                                    sx={{ width: '12vw' }}
                                    name="description"
                                    defaultValue={
                                      editValue !== undefined
                                        ? editValue?.description
                                        : ''
                                    }
                                    placeholder="Description"
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                                <div>
                                  <Typography variant="body1" gutterBottom>
                                    User Status*
                                  </Typography>
                                  <Autocomplete
                                    sx={{ width: '12vw' }}
                                    size="small"
                                    disabled={toBackend || !edit}
                                    disablePortal
                                    id="user-status-combo-box"
                                    defaultValue={
                                      edit
                                        ? findStatusName(editValue.status)
                                        : 'Active '
                                    }
                                    options={statusDrop}
                                    onChange={(data, event) =>
                                      handleStatus(data, event)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="userstatus"
                                      />
                                    )}
                                  />
                                </div>
                              </Stack>
                            </Paper>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <PeopleOutlinedIcon
                                  style={{
                                    marginRight: '5px',
                                    marginTop: '15px',
                                  }}
                                />
                                <Typography
                                  style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                    marginTop: '2%',
                                  }}
                                >
                                  Screens and Permission
                                  <Divider style={{ marginTop: '2%' }} />
                                </Typography>
                              </div>

                              <Paper
                                elevation={0}
                                style={{
                                  height: '50vh',
                                  width: '50vw',
                                  marginTop: '1%',
                                }}
                              >
                                <div style={{ padding: '20px' }}>
                                  <Paper
                                    elevation={0}
                                    style={{
                                      padding: '20px',
                                      marginBottom: '20px',
                                      backgroundColor: isLightMode
                                        ? '#f6f8fc'
                                        : '#4f4f4f',
                                    }}
                                  >
                                    <Stack
                                      spacing={5}
                                      direction="row"
                                      style={{
                                        marginTop: '3%',
                                        marginLeft: '3.6rem',
                                      }}
                                    >
                                      <Typography
                                        style={{ whiteSpace: 'nowrap' }}
                                      >
                                        Screen Name
                                      </Typography>

                                      <Typography
                                        style={{ marginLeft: '14.1rem' }}
                                      >
                                        Create
                                      </Typography>

                                      <Typography
                                        style={{ marginLeft: '1.7rem' }}
                                      >
                                        Edit
                                      </Typography>
                                    </Stack>
                                    {configGroups?.map(
                                      (configGroup) =>
                                        hasPermission(configGroup.key) && (
                                          <CheckboxGroup
                                            key={configGroup.key}
                                            disabled={toBackend}
                                            title={configGroup.title}
                                            configuration={configGroup.config}
                                            onUpdate={(key) =>
                                              handleConfigurationUpdate(
                                                configGroup.key,
                                                key
                                              )
                                            }
                                          />
                                        )
                                    )}
                                    {/* Only For development */}
                                    {/* uncomment this to add new page access */}
                                    {/* {configGroups.map((configGroup) => (
                                      <CheckboxGroup
                                        key={configGroup.key}
                                        title={configGroup.title}
                                        configuration={configGroup.config}
                                        onUpdate={(key) =>
                                          handleConfigurationUpdate(
                                            configGroup.key,
                                            key
                                          )
                                        }
                                      />
                                    ))} */}
                                  </Paper>
                                </div>
                              </Paper>
                            </div>
                          </div>
                        </Stack>
                      </CardContent>
                    </Card>
                  </div>
                </Card>
              </Box>
            </Fade>
          </Modal>

          <div>
            <div>
              <TableTemplate
                PageName={'User Role'}
                addButton={'Role'}
                SearchLabel={'Search Role Here... '}
                header={headCells}
                rowsValue={tableValues}
                tabsData={tabsData}
                userRole={userRoleConfiguration[0]}
                rawData={tableValue}
                handleChange={handleChangeTab}
                handleAddPopupOpen={(val) => handleOpen(val)}
                handleEditPopupOpen={(val) => handleEditClick(val)}
                paths={paths}
                fallbackText={`No${
                  siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''
                } Role has been created yet`}
                // handleDownloadExcel={(val) => {
                //   handleDownloadTable(val);
                // }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
