import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Card,
  Autocomplete,
  CardContent,
  Divider,
  Backdrop,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  textClasses,
  CircularProgress,
  Tooltip,
  CardHeader,
  Alert,
} from '@mui/material';
import FadeLoader from 'react-spinners/FadeLoader';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
/*------------api call-----------------*/
import {
  Equipmentdropdown,
  Equipmenttypedropdown,
  Equipmentsitedropdown,
  postConfigurationSave,
  putConfiguration,
  fetchequipmentTableById,
  primaryDrop,
  fetchSiteEquipmentFilterTable,
} from '../../Api/EquipmentApi';
import { fetchSiteDetails } from '../../Api/ReportApi';
import AdditionalFields from '../util/components/AdditionalFields';
import TableTemplate from '../../Template/TableTemplate';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Download from '../../Template/Excel/Download';
import { downloadExcelTemplate } from '../../Template/ExcelTemplates/ExcelTemplate';
import * as XLSX from 'xlsx';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ConfigurationSchema } from '../util/ValidateSchema';

import { EquipmentConfiguration } from '../util/TextField';
import { ConfigurationList } from '../util/TextField';
import { Statusdropdown, fetchInstalltionCapacity } from '../../Api/CommonApi';
import { statusMasterDrop, dataLoggerDrop } from '../../Api/EquipmentApi';
import { PostAddNewMaster } from '../../Api/ExcelUploadAPi';
import replaceObjectById from '../util/StateUpdater';
import errorMsg from '../util/errorMessage.json';
import { CheckForLight } from '../util/common/CommanFunction';

export default function Sectorhome({ equipmentConfiguration }) {
  /*------------state-------------*/
  const dataLoggerIdOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
  ];
  const [SiteSector, setSiteSector] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [loading, setloading] = useState(true);
  const [statusvalue, setStatusvalue] = useState();
  const [sitestatus, setSitestatus] = useState('');
  const [dailyGeneration, setdailyGeneration] = useState('');
  const [equipment, setEquipment] = useState('');
  const [primaryEquipment, setPrimaryEquipment] = useState('');
  const [primarymapequip, setprimaryMapEquip] = useState('');
  const [productionFlag, setProductionFlag] = useState('');
  const [mastervalue, setMastervalue] = useState(null);
  const [dataloggervalue, setDataLoggervalue] = useState('');
  const [energyCounter, setenergyCounter] = useState('');
  const [genvalue, setGenvalue] = useState();
  const [activeFlag, setactiveFlag] = useState('');

  //eslint-disable-next-line
  const [activeButton, setActiveButton] = useState('Equipment');
  const [activeTab, setActiveTab] = useState('Equipment');
  const [DataStatusvalue, setDataStatusvalue] = useState();
  const [Flog, setFlog] = useState();
  console.log(dailyGeneration, 'dailyGen');
  const [equipmentvalue, setEquipmentValue] = useState([]);
  const [sitenamelist, setSiteNameLists] = useState();
  const [siteName, setSiteNameList] = useState([]);
  // const [statusMastervalue, setStatusMastervalue] = useState([]);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [descriptionvalue, SetDescriptionvalue] = useState([]);
  const [text, settext] = useState([]);
  const [textConfiguration, settextConfiguration] = useState([]);
  const [statusDrop, setStatusDrop] = useState([]);
  const [statusMasterDroplist, setStatusMasterDroplist] = useState([]);
  // const [DataLoggerDroplist, setDataLoggerDroplist] = useState(dataLoggerIdOptions);
  const [DataLoggerDroplist, setDataLoggerDroplist] = useState([]);
  const [dailyGenerationBased, setdailyGenerationBased] = useState(null);
  const [primaryValue, setPrimaryValue] = useState([]);
  const [primaryEquipValue, setPrimaryEquipValue] = useState([]);
  const [sitedropvalue, setSitedropvalue] = useState([]);
  const [Value, setValue] = useState([]);
  const initialState = {
    siteName: '',
    equipmentName: '',
    displayName: '',
    dailyGenBasedOn: '',
    serialNo: '',
    modBusAddress: '',
    prodFlag: '',
    statusMasterId: '',
    dataLoggerName: '',
    status: '',
    energyCounter: '',
    device_Sn: '',
  };
  const [stdField, setstdField] = useState(initialState);
  console.log(stdField);
  // const [SiteTypeIdFilter, setSiteTypeIdFilter] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [siteTab, setSiteTab] = useState('');
  const [unitsIC, setUnitsIC] = useState([]);
  const [Valuedata, setValuedata] = useState();
  console.log(Valuedata);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const styleTable = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  const handleChangeTxt = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };

  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, 'dataOutside');
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };
  const energy = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },
  ];

  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case 'siteName':
        return (option = siteName.map((option) => option.siteName));
      case 'displayName':
        return (option = equipmentvalue?.map((option) => option?.displayName));
      case 'dailyGenBasedOn':
        return (option = dailyGenerationOptions.map((option) => option.label));
      case 'status':
        return (option = statusDrop);
      case 'statusMasterId':
        return (option = statusMasterDroplist.map(
          (option) => option.statusMasterName
        ));
      case 'energyCounter':
        return (option = energy.map((option) => option.label));
      case 'dataLoggerName':
        return (option = DataLoggerDroplist.map(
          (option) => option.dataLoggerName
        ));
      case 'prodFlag':
        return (option = productionflagOptions.map((option) => option.label));
      default:
    }
    return option;
  }

  const dailyGenerationOptions = [
    { value: 1, label: 'Today Energy' },
    { value: 2, label: 'Total Energy' },
  ];
  const isPrimaryEquipmentOptions = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
  ];
  console.log(isPrimaryEquipmentOptions, 'isPrimaryEquipmentOptions');

  const productionflagOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
  ];

  useEffect(() => {
    let dataConfiguration = ConfigurationList();
    settextConfiguration(dataConfiguration);
    console.log(dataConfiguration, 'dataConfiguration');
    if (!edit) {
      setstdField((prevState) => ({
        ...prevState,
        status: 'Active',
      }));
    }
  }, [edit]);

  const mapDataLogger = (dataLoggerName) => {
    if (typeof dataLoggerName === 'string') {
      dataLoggerName = dataLoggerName.toLowerCase();
    }

    switch (dataLoggerName) {
      case 'low':
        return 1;
      case 'medium':
        return 2;
      case 'high':
        return 3;
      case 1:
        return 'Low';
      case 2:
        return 'Medium';
      case 3:
        return 'High';
      default:
        return 0;
    }
  };
  /*------------------------- Api --------------------------------------------*/

  // const handleSiteFilterValue = (siteName) => {
  //   if (siteName) {
  //     const siteIdFilter = siteName.filter((data) => data.siteName === siteName);
  //     if (siteIdFilter) {
  //       fetchData(siteIdFilter.siteId);
  //     }
  //   } else {
  //     fetchData(); // Fetch all data when no site is selected
  //   }
  // };
  // const handleSiteFilterValue = (val) => {
  //   console.log(val);
  //   const siteIdFilter = siteName?.filter((data) => data.siteName === val);
  //   console.log(siteIdFilter[0]?.siteId);

  //   setValuedata(siteIdFilter[0]?.siteName);
  //   console.log(val?.siteId);

  //   // setTicketview(false);
  //   // setdropsite(true)
  //   fetchData(siteIdFilter[0]?.siteId);
  // };
  const handleSiteFilterValue = (val) => {
    console.log('Selected value:', val);
    if (val) {
      const siteIdFilter = siteName?.find((data) => data.siteName === val);
      console.log('Filtered site:', siteIdFilter);
      if (siteIdFilter) {
        setValuedata(siteIdFilter.siteName);
        fetchData(siteIdFilter.siteId);
      }
    } else {
      // Clear the site selection
      setValuedata(null);
      // Clear the table data
      setSiteSector([]);
      // Optionally fetch all equipment data
      // fetchData();
    }
  };

  useEffect(() => {
    fetchData();
    fetchEquipmenttype();
    fetchsitedrop();
    fetchStatusMatser();
    fetchSitenameList();
    fetchStatus();
    fetchUnits();
  }, []);
  useEffect(() => {
    let SiteTypeIdFilter = siteName?.filter((data) => {
      console.log(sitestatus, 'filterSite');
      return data.siteName === sitestatus;
    });
    if (edit) {
      fetchPrimaryEquip(editcall.siteId);
      fetchDataLogger(editcall.siteId);
    }
    if (sitestatus !== '') {
      fetchPrimaryEquip(SiteTypeIdFilter[0]?.siteId);
      fetchDataLogger(SiteTypeIdFilter[0]?.siteId);
    }
  }, [sitestatus, edit]);

  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async (e) => {
    let id = editcall?.equipmentId;
    console.log(e, 'data');
    let SiteTypeIdFilter = sitedropvalue?.filter((data) => {
      return data.siteName === sitestatus;
    });
    console.log(equipmentvalue, 'equipemnt');
    let EquipmentFilter = equipmentvalue?.filter((data) => {
      console.log(data.equipmentName, equipment, 'equipmentName');
      return data.displayName === equipment;
    });

    let dailyGenerationFilter = dailyGenerationOptions?.filter((data) => {
      return data.label === dailyGeneration;
    });
    let ProductionFlagFilter = productionflagOptions?.filter((data) => {
      return data.label === productionFlag;
    });
    let StatusMasterFilter = statusMasterDroplist?.filter((data) => {
      return data.statusMasterName === mastervalue;
    });
    let DataLoggerFilter = DataLoggerDroplist?.filter((data) => {
      return data.dataLoggerName === dataloggervalue;
    });

    let data = {
      siteId: SiteTypeIdFilter[0]?.siteId,
      equipmentName: EquipmentFilter[0]?.equipmentId,
      dailyGenBasedOn: Number(dailyGenerationFilter[0]?.value),
      dataLoggerId: DataLoggerFilter[0]?.dataLoggerId,
      ftpDefDevicesn: stdField?.serialNo,
      energyCounter: stdField?.energyCounter === 'Yes' ? '1' : '0',
      modBusAddress: stdField?.modBusAddress,
      prodFlag: Number(ProductionFlagFilter[0]?.value),
      deviceSn: stdField?.device_Sn,
      statusMasterId: StatusMasterFilter[0]?.statusMasterId,
      status: 1,
      lastUpdatedBy: Number(sessionStorage.getItem('id')),
    };

    console.log(data, 'data');

    try {
      setToBackend(true);
      const responseData = await postConfigurationSave(
        data,
        EquipmentFilter[0]?.equipmentId
      );
      console.log(responseData, 'responseData');
      fetchData();
      setSnack(errorMsg.success);
      setToBackend(false);
      handleClose();
    } catch (error) {
      setSnack(errorMsg.failure);
      setToBackend(false);
      console.error('Error:', error.response.status);
    }
  };

  const handleUpdate = async (values) => {
    let id = editcall?.equipmentId;

    let SiteTypeIdFilter = sitedropvalue?.filter((data) => {
      return data.siteName === sitestatus;
    });

    let EquipmentFilter = equipmentvalue?.filter((data) => {
      return data.displayName === equipment;
    });

    let dailyGenerationFilter = dailyGenerationOptions?.filter((data) => {
      return data.label === dailyGeneration;
    });
    let ProductionFlagFilter = productionflagOptions?.filter((data) => {
      return data.label === productionFlag;
    });
    let StatusMasterFilter = statusMasterDroplist?.filter((data) => {
      return data.statusMasterName === mastervalue;
    });
    let DataLoggerFilter = DataLoggerDroplist?.filter((data) => {
      return data.dataLoggerName === dataloggervalue;
    });

    let StatusFilter = statusDrop?.filter((data) => {
      return data.label === activeFlag;
    });

    const userAddField = stdField.equipmentadditionalfield.map((obj) => {
      if (obj.fieldId) {
        return {
          lastUpdatedBy: sessionStorage.getItem('id'),
          fieldId: obj.fieldId,
          fieldName: obj.fieldName,
          status: Number(obj.status),
          value: obj.value,
        };
      } else {
        return {
          createdBy: Number(sessionStorage.getItem('id')),
          fieldName: obj.fieldName,
          status: Number(obj.status),
          value: obj.value,
        };
      }
    });

    let obj = {
      siteId:
        edit && values.siteId !== ''
          ? editcall.siteId
          : SiteTypeIdFilter[0]?.siteId,
      equipmentId:
        edit && equipment !== ''
          ? editcall.equipmentId
          : Number(EquipmentFilter[0]?.equipmentId),
      dailyGenBasedOn: Number(dailyGenerationFilter[0]?.value),
      dataLoggerId:
        dataloggervalue == ''
          ? editcall.dataLoggerId
          : DataLoggerFilter[0]?.dataLoggerId,
      energyCounter: stdField?.energyCounter === 'Yes' ? '1' : '0',
      ftpDefDevicesn: stdField?.serialNo,
      modBusAddress: stdField?.modBusAddress,
      deviceSn: stdField?.device_Sn,
      prodFlag:
        edit && productionFlag === ''
          ? values.prodFlag
          : Number(ProductionFlagFilter[0]?.value),
      status:
        stdField?.status === 'Inactive'
          ? '0'
          : stdField?.status === 'Active'
          ? '1'
          : stdField?.status === 0
          ? '0'
          : '1',
      statusMasterId: StatusMasterFilter[0]?.statusMasterId,
      lastUpdatedBy: Number(sessionStorage.getItem('id')),
    };

    console.log(obj, 'obj');

    try {
      console.log(obj, 'obj');
      const responsedata = await putConfiguration(obj, id);
      console.log(responsedata, 'responsedata');
      setSnack(errorMsg.success);
      // const updatedTable = replaceObjectById(
      //   SiteSector,
      //   'equipmentId',
      //   id,
      //   responsedata
      // );
      fetchData(
        edit && values.siteId !== ''
          ? editcall.siteId
          : SiteTypeIdFilter[0]?.siteId
      );
      setValuedata(
        edit && values.siteId !== ''
          ? editcall.siteName
          : SiteTypeIdFilter[0]?.siteName
      );
      handleClose();
      // window.location.reload()
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error('Error:', e.response.status);
    }
  };

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async (siteId = null) => {
    setloading(true);
    try {
      let data;
      data = await fetchSiteEquipmentFilterTable(siteId);

      if (Array.isArray(data)) {
        setSiteSector(data);
      } else if (typeof data === 'object' && data !== null) {
        setSiteSector([data]);
      } else {
        console.warn('Unexpected data type:', typeof data);
        setSiteSector([]);
      }
    } catch (e) {
      console.error('Error fetching data:', e);
    } finally {
      setloading(false);
    }
  };
  // const fetchData = async (siteId = null) => {
  //   setloading(true);
  //   try {

  //     const data = await fetchequipmentTableById();
  //     // const reverse = [...data].reverse();
  //     if (Array.isArray(data)) {
  //       setSiteSector(data);
  //     } else if (typeof data === 'object' && data !== null) {
  //       setSiteSector([data]);
  //     }
  //     setloading(false);
  //     console.log(data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };
  const fetchEquipmenttype = async () => {
    const data = await Equipmenttypedropdown();
    if (Array.isArray(data)) {
      // If data is an array, set tableValue to data directly
      setEquipmevalue(data);
    } else if (typeof data === 'object' && data !== null) {
      // If data is an object, wrap it in an array and set tableValue
      setEquipmevalue([data]);
    }
    console.log(data);
  };

  const fetchEquipment = async (dataValue) => {
    let SiteTypeIdFilter = sitedropvalue?.filter((data) => {
      return data.siteName === dataValue;
    });

    console.log(SiteTypeIdFilter[0]?.siteId, 'SiteTypeIdFilter[0]?.siteId');
    const data = await Equipmentdropdown(SiteTypeIdFilter[0]?.siteId);
    if (Array.isArray(data)) {
      setEquipmentValue(data);
    } else if (typeof data === 'object' && data !== null) {
      setEquipmentValue([data]);
    }
  };
  const fetchEquipmentt = async (dataValue) => {
    let id = editcall?.siteId;
    const data = await Equipmentdropdown(id);
    if (Array.isArray(data)) {
      setEquipmentValue(data);
    } else if (typeof data === 'object' && data !== null) {
      setEquipmentValue([data]);
    }
  };

  const fetchsitedrop = async () => {
    const data = await Equipmentsitedropdown();
    if (Array.isArray(data)) {
      setSitedropvalue(data);
    } else if (typeof data === 'object' && data !== null) {
      setSitedropvalue([data]);
    }
  };

  function getDataLoggerNameById(dataLoggerId) {
    const dataLogger = DataLoggerDroplist.find(
      (logger) => logger.dataLoggerId === dataLoggerId
    );
    return dataLogger ? dataLogger.dataLoggerName : dataLoggerId;
  }
  /*-------------------------report Name list dropdown --------------------------------------------*/

  const allowedKeys = ['fieldName', 'value', 'status'];
  const addAdditionalField = () => {
    const newField = {
      fieldName: '',
      status: '',
      value: '',
    };

    setstdField((prevState) => ({
      ...prevState,
      equipmentadditionalfield: [
        ...prevState.equipmentadditionalfield,
        newField,
      ],
    }));
  };

  const deleteAdditionalField = (index) => {
    const updatedFields = [...stdField.equipmentadditionalfield];
    updatedFields.splice(index, 1);
    setstdField((prevState) => ({
      ...prevState,
      equipmentadditionalfield: updatedFields,
    }));
  };

  /*---------------------------------- New Sector Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    // setEdit(false);
    seteditcall('');
  };

  const handleClose = () => {
    setSelectedFileData(null);
    setstdField(initialState);
    setActiveTab('Equipment');
    seteditcall('');
    setSitestatus('');
    setdailyGeneration('');
    setMastervalue();
    setEquipmentValue([]);
    setDataLoggervalue('');
    setDataLoggerDroplist([]);
    setEquipment('');
    setOpen(false);
    setModalvalue(true);
    setEdit(false);
    setactiveFlag('');
  };
  const style = {
    position: 'absolute',
    top: '60%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };

  const getName = (type, find, typeId, data) => {
    const filteredData = data.filter((item) => item[`${type}Id`] === typeId);

    if (filteredData.length > 0) {
      return filteredData[0][find];
    } else {
      return null;
    }
  };

  const handleEditOpen = async (sectorTypeId) => {
    try {
      console.log(sectorTypeId, 'sectorTypeId');
      console.log(sectorTypeId.displayName, 'displayName');

      const data = await Equipmentdropdown(sectorTypeId.siteId);
      const logger = await dataLoggerDrop(sectorTypeId.siteId);
      console.log(data);
      setEquipmentValue(data);
      setEquipment(sectorTypeId.displayName);

      const val = dailyGenerationOptions.filter(
        (data) => data.value === sectorTypeId?.dailyGenBasedOn
      );
      console.log(val, 'val');
      setdailyGeneration(val[0]?.label);

      const value = isPrimaryEquipmentOptions.filter(
        (data) => Number(data.value) === sectorTypeId?.isPrimaryEquipment
      );
      setPrimaryEquipment(value[0]?.label);

      const getPrimaryVal = await primaryDrop(sectorTypeId?.siteId);
      const value1 = getPrimaryVal.filter(
        (data) => data.equipmentId === sectorTypeId?.primaryMapEquip
      );
      console.log(value1, '>>>>>>>>>>>>>>>>>>>>>>>>>>');
      setprimaryMapEquip(value1.length > 0 ? value1[0]?.displayName : '');

      const value2 = statusMasterDroplist.filter(
        (data) => Number(data.statusMasterId) === sectorTypeId?.statusMasterId
      );
      console.log(value2, 'statusMasterId');
      setMastervalue(value2[0]?.statusMasterName);
      console.log(statusMasterDroplist, 'statusMasterDroplist');

      const dataLoggerName = logger.filter(
        (logger) => logger.dataLoggerId === sectorTypeId.dataLoggerId
      );

      console.log(dataLoggerName);
      seteditcall({
        ...sectorTypeId,
      });

      setstdField({
        ...sectorTypeId,
      });
      setEdit(true);
      setOpen(true);
    } catch (error) {
      console.error('Error opening edit dialog:', error);
    }
  };
  console.log(editcall, stdField);
  console.log(equipment, 'equipment');
  const handleStatus = (data, event) => {
    setStatusvalue(event?.value);
  };

  const handlePrimary = (data, event) => {
    setstdField((prev) => ({
      ...prev,
      isPrimaryEquipment: event?.value,
    }));
  };

  const handlePrimaryMap = (data, event) => {
    setstdField((prev) => ({
      ...prev,
      primaryMapEquip: event?.value,
    }));
  };

  const handleproflog = (data, value) => {
    if (value === null) {
      setFlog('');
    } else {
      setFlog(value);
    }
  };
  const handleData = (data, value) => {
    if (value === null) {
      setDataStatusvalue('');
    } else {
      setDataStatusvalue(value);
    }
  };
  const handleGenbase = (data, event) => {
    setGenvalue(event?.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 'configuration') {
      setActiveButton('save');
    } else {
      setActiveButton('next');
    }
  };

  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'EquipmentConfiguration');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    console.log(data, 'data');
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };

  const fetchSitenameList = async () => {
    const data = await fetchSiteDetails();
    await fetchData(data[0]?.siteId);
    setValuedata(data[0]?.siteName);
    if (Array.isArray(data)) {
      setSiteNameList(data);
    } else if (typeof data === 'object' && data !== null) {
      setSiteNameList([data]);
    }
  };
  /*---------------- Satatus Master Dropdown -----------------*/
  const fetchStatusMatser = async (customerId) => {
    const data = await statusMasterDrop();
    console.log(data, 'data');
    if (Array.isArray(data)) {
      setStatusMasterDroplist(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusMasterDroplist([data]);
    }
  };
  /*----------------- Data Logger Dropdown -------------------*/
  const fetchDataLogger = async (Id) => {
    const data = await dataLoggerDrop(Id);
    console.log(data, 'data');
    if (Array.isArray(data)) {
      setDataLoggerDroplist(data);
    } else if (typeof data === 'object' && data !== null) {
      setDataLoggerDroplist([data]);
    }
  };

  const fetchPrimaryEquip = async (id) => {
    const data = await primaryDrop(id);
    console.log(data, 'data');
    if (Array.isArray(data)) {
      setPrimaryEquipValue(data);
    } else if (typeof data === 'object' && data !== null) {
      setPrimaryEquipValue([data]);
    }
  };
  let nullEquipmentCondition =
    equipmentvalue.length === 0 && sitestatus.length > 0;

  const headCells = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
      default: true,
    },
    {
      label: 'Equipment Name',
      id: 'displayName',
      view: true,
      default: true,
    },
    {
      label: 'Daily Generation',
      id: 'dailyGeneration',
      view: false,
    },
    {
      label: 'Serial No',
      id: 'serialNo',
      view: true,
    },
    {
      label: 'Modbusaddress',
      id: 'modBusAddress',
      view: false,
    },
    {
      label: 'Product Flag',
      id: 'prodFlag',
      view: false,
    },
    {
      label: 'Data Logger',
      id: 'dataLoggerName',
      view: false,
    },
    {
      label: 'Status Master',
      id: 'statusMasterName',
      view: false,
    },
    {
      label: 'Created Date',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];

  let configurationCardTitle = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h4"
          className="modal-head"
          style={{ fontWeight: 'bold' }}
        >
          {edit ? 'Update Configuration' : 'Add Configuration'}
        </Typography>
      </div>
      {nullEquipmentCondition && (
        <Alert
          severity="error"
          style={{
            userSelect: 'none',
            height: '40px',
            marginLeft: 'auto',
            marginRight: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`All equipments are configured for this site`}
        </Alert>
      )}
    </div>
  );

  const tableValues = SiteSector.filter((row) => {
    let filteredStatus = statusMasterDroplist.filter(
      (option) => option.statusMasterId === row.statusMasterId
    );
    let statusVal =
      filteredStatus.length > 0 ? filteredStatus[0].statusMasterName : '';
    let filteredGen = dailyGenerationOptions.filter(
      (option) => option.value === row.dailyGenBasedOn
    );
    let val = filteredGen.length > 0 ? filteredGen[0].label : '';
    row['dailyGeneration'] = val;
    row['statusMasterName'] = statusVal;
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = SiteSector.length;

  const Active = SiteSector.filter((site) => site.status == 1).length;

  const Inactive = SiteSector.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Equipment', value: 'all', badgeCount: Total },
    { name: 'Active Equipment', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Equipment',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];

  let customerId = sessionStorage.getItem('customerId');
  const handleFileUpload = (e) => {
    setOpen(true);
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setSelectedFileData(jsonData);
      };
      reader.readAsBinaryString(file);
    }
  };
  /*-------------------------report Name list dropdown --------------------------------------------*/

  const handleactiveFlag = (data, event) => {
    setactiveFlag(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      status: data.target.innerHTML,
    }));
  };

  const handleSite = (data, event) => {
    setSitestatus(data.target.innerHTML);
    setEquipment(null);
    setstdField((prevState) => ({
      ...prevState,
      siteName: data.target.innerHTML,
      displayName: null,
      serialNo: null,
    }));
    fetchEquipment(data.target.innerHTML);
  };
  const handleDescription = (data, event) => {
    SetDescriptionvalue(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      description: data.target.innerHTML,
    }));
  };
  const handleEquipmentvalue = (data, event) => {
    let returnVal = tableValues.filter(
      (val) => val.displayName === data.target.innerHTML
    );
    setEquipment(data.target.innerHTML);

    setstdField((prevState) => ({
      ...prevState,
      displayName: data.target.innerHTML,
      serialNo: returnVal[0].serialNo,
    }));
  };

  const handleDailyGeneration = (data, event) => {
    setdailyGeneration(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      dailyGenBasedOn: data.target.innerHTML,
    }));
  };
  // const handlePrimaryEquipment = (data, event) => {
  //   setPrimaryEquipment(data.target.innerHTML);
  //   setstdField((prevState) => ({
  //     ...prevState,
  //     isPrimaryEquipment: data.target.innerHTML,
  //   }));
  // };
  // const handleprimaryMapEquip = (data, event) => {
  //   setprimaryMapEquip(data.target.innerHTML);
  //   setstdField((prevState) => ({
  //     ...prevState,
  //     primaryMapEquip: data.target.innerHTML,
  //   }));
  // };
  const handlePrimaryEquipment = (data, event) => {
    const value = data.target.innerHTML;
    setPrimaryEquipment(value);
    setstdField((prevState) => ({
      ...prevState,
      isPrimaryEquipment: value,
    }));

    // Disable primaryMapEquip if isPrimaryEquipment is 'Yes'

    if (value === 'Yes') {
      setprimaryMapEquip(''); // Reset primaryMapEquip value
      setstdField((prevState) => ({
        ...prevState,
        primaryMapEquip: '',
      }));
    }
  };

  const handleprimaryMapEquip = (data, event) => {
    const value = data.target.innerHTML;
    console.log(value, 'value');
    setprimaryMapEquip(value);
    setstdField((prevState) => ({
      ...prevState,
      primaryMapEquip: value,
    }));

    // Disable condition
    // if (
    //     (stdField.isPrimaryEquipment === 1 || editcall.isPrimaryEquipment === 1 || stdField.isPrimaryEquipment === '') &&
    //     data.name === 'primaryMapEquip'
    // ) {
    //     return true; // Disable the field
    // } else {
    //     return false; // Enable the field
    // }
  };

  const handleProductionFlag = (data, event) => {
    setProductionFlag(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      prodFlag: data.target.innerHTML,
    }));
  };
  const handleDataLogger = (data, event) => {
    setDataLoggervalue(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      dataLoggerName: data.target.innerHTML,
    }));
  };
  const handleenergyCounter = (data, event) => {
    setenergyCounter(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      energyCounter: data.target.innerHTML,
    }));
  };
  const handleStatusMaster = (data, event) => {
    console.log(data.target.innerHTML, 'data.target.innerHTML');
    setMastervalue(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      statusMasterId: data.target.innerHTML,
    }));
  };
  // const handleDataLogger = (data, event) => {
  //   console.log(data.target, "innerHTML");
  //   setDataLoggervalue(data.target.innerHTML);
  //   setstdField((prevState) => ({
  //     ...prevState,
  //     dataLoggerName: data.target.innerHTML,
  //   }));
  // };

  const handleDropdownChange = (event, value, name) => {
    console.log(name, value, 'valavalaa');
    setstdField({ ...stdField, [name]: value });
  };

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Equipment Configuration', path: 'Configuration' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Formik
            key={edit ? 'edit' : 'create'}
            enableReinitialize={true}
            initialValues={stdField}
            validationSchema={ConfigurationSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log(values, 'testttt');
              let submitValue = values;
              if (edit) {
                console.log(values);
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              } else {
                const result = await handleSave(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                {/* ---------   -----save andupdate---- popup----------- */}
                {edit && editcall.siteName === null ? (
                  <div>
                    <Backdrop
                      sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={open}
                    >
                      <FadeLoader color="#000440" loading={true} />
                    </Backdrop>
                  </div>
                ) : (
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModal'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                          marginTop: '5%',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={configurationCardTitle}
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          <Card elevation={0}>
                            <CardContent sx={{ height: '2%' }}>
                              {/* {
                              edit && values.siteName !== null ?
                                <div style={{ display: 'flex', justifyContent: 'center', height: '15vh', alignItems: 'center' }}>
                                  <FadeLoader color="#000440" loading={true} />
                                </div>
                                : */}
                              <Grid
                                container
                                spacing={2}
                                columns={16}
                                style={{ marginLeft: '-0.35vw' }}
                              >
                                {textConfiguration?.length > 0
                                  ? textConfiguration.map((data, index) => {
                                      return (
                                        <Grid item xs={4}>
                                          <div key={index}>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            {data.type === 'textField' ? (
                                              <>
                                                <Field
                                                  as={TextField}
                                                  disabled={
                                                    toBackend ||
                                                    (!edit &&
                                                      data.name === 'status') ||
                                                    data.name === 'serialNo' ||
                                                    nullEquipmentCondition
                                                  }
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  inputProps={{
                                                    maxLength: data.length,
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleChangeTxt(e);
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={
                                                    values[data.name] || ''
                                                  }
                                                  sx={{
                                                    width: '12vw',
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </>
                                            ) : data.type === 'dropdown' ? (
                                              <>
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit
                                                      ? data.name ===
                                                          'status' &&
                                                        activeFlag === ''
                                                        ? values[data.name] == 1
                                                          ? 'Active'
                                                          : 'Inactive'
                                                        : data.name ===
                                                          'dataLoggerName'
                                                        ? values[data.name] ===
                                                          1
                                                          ? 'Low'
                                                          : values[
                                                              data.name
                                                            ] === 2
                                                          ? 'Medium'
                                                          : values[
                                                              data.name
                                                            ] === 3
                                                          ? 'High'
                                                          : data.name ===
                                                            'dailyGenBasedOn'
                                                          ? dailyGeneration
                                                          : data.name ===
                                                            'equipmentName'
                                                          ? equipment
                                                          : data.name ===
                                                            'statusMasterId'
                                                          ? mastervalue
                                                          : values[data.name]
                                                        : data.name ===
                                                          'dailyGenBasedOn'
                                                        ? dailyGeneration
                                                        : data.name ===
                                                          'statusMasterId'
                                                        ? mastervalue
                                                        : values[data.name]
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Field
                                                    name={data.name}
                                                    render={({
                                                      field,
                                                      form,
                                                    }) => (
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit
                                                            ? data.name ===
                                                                'status' &&
                                                              activeFlag === ''
                                                              ? values[
                                                                  data.name
                                                                ] == 1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : data.name ===
                                                                'dataLoggerName'
                                                              ? values[
                                                                  data.name
                                                                ] === 1
                                                                ? 'Low'
                                                                : values[
                                                                    data.name
                                                                  ] === 2
                                                                ? 'Medium'
                                                                : values[
                                                                    data.name
                                                                  ] === 3
                                                                ? 'High'
                                                                : // : data.name ===
                                                                //   'dataLoggerName'
                                                                //   ? dataloggervalue

                                                                data.name ===
                                                                  'dailyGenBasedOn'
                                                                ? dailyGeneration
                                                                : data.name ===
                                                                  'statusMasterId'
                                                                ? mastervalue
                                                                : values[
                                                                    data.name
                                                                  ]
                                                              : data.name ===
                                                                'dailyGenBasedOn'
                                                              ? dailyGeneration
                                                              : data.name ===
                                                                'statusMasterId'
                                                              ? mastervalue
                                                              : data.name ===
                                                                'energyCounter'
                                                              ? values[
                                                                  data.name
                                                                ] === 1
                                                                ? 'Yes'
                                                                : 'No'
                                                              : values[
                                                                  data.name
                                                                ]
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Autocomplete
                                                          disableClearable
                                                          autoHighlight
                                                          disabled={
                                                            toBackend ||
                                                            (!edit &&
                                                              data.name ===
                                                                'status') ||
                                                            (!edit &&
                                                              (data.name ===
                                                                'displayName' ||
                                                                data.name ===
                                                                  'dataLoggerName') &&
                                                              !values.siteName) ||
                                                            (edit &&
                                                              (data.name ===
                                                                'siteName' ||
                                                                data.name ===
                                                                  'displayName')) ||
                                                            (nullEquipmentCondition &&
                                                              data.name !==
                                                                'siteName')
                                                          }
                                                          options={
                                                            getOptions(
                                                              data.name
                                                            ) || []
                                                          }
                                                          disablePortal
                                                          size="small"
                                                          id={`combo-box-demo-${index}`}
                                                          onChange={(event) =>
                                                            data.name ===
                                                            'siteName'
                                                              ? handleSite(
                                                                  event
                                                                )
                                                              : data.name ===
                                                                'displayName'
                                                              ? handleEquipmentvalue(
                                                                  event,
                                                                  values
                                                                )
                                                              : data.name ===
                                                                'statusMasterId'
                                                              ? handleStatusMaster(
                                                                  event
                                                                )
                                                              : data.name ===
                                                                'dailyGenBasedOn'
                                                              ? handleDailyGeneration(
                                                                  event
                                                                )
                                                              : data.name ===
                                                                'prodFlag'
                                                              ? handleProductionFlag(
                                                                  event
                                                                )
                                                              : data.name ===
                                                                'dataLoggerName'
                                                              ? handleDataLogger(
                                                                  event
                                                                )
                                                              : data.name ===
                                                                'energyCounter'
                                                              ? handleenergyCounter(
                                                                  event
                                                                )
                                                              : handleactiveFlag(
                                                                  event
                                                                )
                                                          }
                                                          value={
                                                            !edit &&
                                                            data.name ===
                                                              'status'
                                                              ? 'Active'
                                                              : edit
                                                              ? data.name ===
                                                                  'energyCounter' &&
                                                                energyCounter ===
                                                                  ''
                                                                ? values[
                                                                    'energyCounter'
                                                                  ] == 1
                                                                  ? 'Yes'
                                                                  : 'No'
                                                                : data.name ===
                                                                    'status' &&
                                                                  activeFlag ===
                                                                    ''
                                                                ? values[
                                                                    data.name
                                                                  ] == 1
                                                                  ? 'Active'
                                                                  : 'Inactive'
                                                                : data.name ===
                                                                  'dataLoggerName'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : data.name ===
                                                                  'dailyGenBasedOn'
                                                                ? dailyGeneration
                                                                : data.name ===
                                                                  'displayName'
                                                                ? equipment
                                                                : data.name ===
                                                                  'statusMasterId'
                                                                ? mastervalue
                                                                : values[
                                                                    data.name
                                                                  ]
                                                              : data.name ===
                                                                'dailyGenBasedOn'
                                                              ? dailyGeneration
                                                              : data.name ===
                                                                'displayName'
                                                              ? equipment
                                                              : values[
                                                                  data.name
                                                                ]
                                                          }
                                                          sx={{
                                                            width: '12vw',
                                                          }}
                                                          ListboxProps={{
                                                            style: {
                                                              maxHeight:
                                                                '150px',
                                                            },
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                            />
                                                          )}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  />
                                                </Tooltip>
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </>
                                            ) : null}
                                          </div>
                                        </Grid>
                                      );
                                    })
                                  : null}
                                {/* <Grid item xs={4}>
                                <div
                                  style={{ textAlign: 'center' }}
                                >
                                  <Button
                                    // style={{ marginRight: "-80%" }}
                                    style={{ marginTop: '12%' }}
                                    // onClick={handleAddFieldsClick}
                                    onClick={addAdditionalField}
                                    color="primary"
                                    variant="contained"
                                  >
                                    Add Fields
                                  </Button>
                                </div>
                              </Grid> */}
                              </Grid>
                              {/* } */}
                            </CardContent>
                          </Card>
                          {/* </div> */}
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                )}
              </Form>
            )}
          </Formik>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: '-0.5%' }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={'Equipment Configuration'}
              addButton={'Equipment'}
              SearchLabel={'Search Equipment Here... '}
              header={headCells}
              rowsValue={tableValues}
              // userRole={configurationEquipment[0]}
              userRole={equipmentConfiguration[0]}
              tabsData={tabsData}
              rawData={SiteSector}
              sitevalue={Valuedata}
              siteNameList={siteName}
              handleSiteFilterChange={handleSiteFilterValue}
              handleChange={handleChangeTab}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No${
                siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''
              } Equipment has been configured yet`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
