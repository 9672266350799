import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Autocomplete,
  TextField,
  Button,
  Menu,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,Stack
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const SopNewDocument = () => {
  const navigate=useNavigate()
  const [typeValue, setTypeValue] = useState("");
  const [OperationValue, setOperationValue] = useState("");
  const [maintenanceValue, setmaintenanceValue] = useState("");
  const [categoeryValue, setCategoryValue] = useState("");
  const [categoeryOptions, setCategoryOptions] = useState([]);
  const [omCode, setOMCode] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [options, setOptions] = useState([
    { label: "Operation" },
    { label: "Maintenance" },
  ]);
  const [OperationCategory, setOperationCategory] = useState([
    { label: "Inverter Down" },
    { label: "Plant Down" },
    { label: "Module Damage" },
    { label: "Equipment Failurre" },
  ]);
  const [MaintenanceCategory, setMaintenanceCategory] = useState([
    { label: "Module Cleaning " },
    { label: "Invertor Cleaning " },
    { label: "Dataloger Cleaning" },
    { label: "Vegitation" },
  ]);

  const [rows, setRows] = useState([
    {
      id: uuidv4(),
      sequence: "",
      description: "",
      verification: "",
      verificationEnabled: false,
      remarks: "",
    },
    {
      id: uuidv4(),
      sequence: "",
      description: "",
      verification: "",
      verificationEnabled: false,
      remarks: "",
    },
    {
      id: uuidv4(),
      sequence: "",
      description: "",
      verification: "",
      verificationEnabled: false,
      remarks: "",
    },
  ]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };

  const handleInputChangeTable = (id, field, value) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event, value) => {
    if (value && !options.some((option) => option.label === value)) {
      setOptions((prev) => [...prev, { label: value }]);
      setTypeValue(value);
    }
    setTypeValue(value);
  };

  const handleOperationChange = (event, value) => {
    if (value && !options.some((option) => option.label === value)) {
      setOperationCategory((prev) => [...prev, { label: value }]);
    }
    setOperationValue(value);
  };
  const handleMaintenanceChange = (event, value) => {
    if (value && !options.some((option) => option.label === value)) {
      setMaintenanceCategory((prev) => [...prev, { label: value }]);
    }
    setmaintenanceValue(value);
  };
  const handleCategory = (event, value) => {
    if (value && !options.some((option) => option.label === value)) {
      setCategoryOptions((prev) => [...prev, { label: value }]);
    }
    setCategoryValue(value);
  };

  const handleOMcode = (event) => {
    setOMCode(event.target.value);
  };

  const handleAddRow = () => {
    const newRow = {
      id: uuidv4(),
      sequence: rows.length + 1,
      description: "",
      verification: "",
      verificationEnabled: false,
      remarks: "",
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };

  const handleCheckboxChange = (event, id) => {
    const { name, checked } = event.target;

    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [name]: checked };
        if (name === "imageUpload") {
          updatedRow.verificationEnabled = checked;
        }
        return updatedRow;
      }
      return row;
    });

    setRows(updatedRows);
  };

  const handleExit=()=>{
    navigate('/menu/sop_file')
  }

  return (
    <div className="sop-template">
      <div>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "75vh",
            }}
          >
            <div style={{ margin: "1%" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography>Start a new document</Typography>
                </div>
                <div>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      onClick={handleExit}
                    >
                      {" "}
                      Exit{" "}
                    </Button>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Stack>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      Make Template & Save
                    </MenuItem>
                    <MenuItem onClick={handleClose}>Only Save </MenuItem>
                  </Menu>
                </div>
              </div>

              <div style={{ marginTop: "3%" }}>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={2} alignItems="center">
                    <Typography> Type</Typography>
                    <Autocomplete
                      freeSolo
                      size="small"
                      options={options.map((option) => option.label)}
                      onInputChange={handleInputChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={2} alignItems="center">
                    <Typography> Category</Typography>
                    <Autocomplete
                      freeSolo
                      size="small"
                      options={
                        typeValue === "Operation"
                          ? OperationCategory.map((option) => option.label)
                          : typeValue === "Maintenance"
                          ? MaintenanceCategory.map((option) => option.label)
                          : categoeryOptions
                      }
                      onInputChange={
                        typeValue === "Operation"
                          ? handleOperationChange
                          : typeValue === "Maintenance"
                          ? handleMaintenanceChange
                          : handleCategory
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={2} alignItems="center">
                    <Typography> O & M Code</Typography>
                    <TextField
                      variant="outlined"
                      onChange={(event) => handleOMcode(event)}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </div>

              <div style={{ marginTop: "2%" }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRow}
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      textTransform: "capitalize",
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add Row
                  </Button>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Sequence Order</TableCell>
                          <TableCell align="center">Work Description</TableCell>
                          <TableCell align="center">Field</TableCell>
                          <TableCell align="center">
                            <div> Previous & After Image / Count</div>
                          </TableCell>
                          <TableCell align="center">Remarks</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <TableBody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {rows.map((row, index) => (
                              <Draggable
                                key={row.id}
                                draggableId={row.id}
                                index={index}
                              >
                                {(provided) => (
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                    >
                                      <TextField
                                        style={{ width: "35%" }}
                                        size="small"
                                        value={row.sequence}
                                        variant="standard"
                                        placeholder="Sequence No."
                                        onChange={(e) =>
                                          handleInputChangeTable(
                                            row.id,
                                            "sequence",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </TableCell>

                                    <TableCell align="center">
                                      <TextField
                                        variant="standard"
                                        placeholder="Description .."
                                        size="small"
                                        InputProps={{
                                          style: { border: "none" },
                                        }}
                                        value={row.description}
                                        onChange={(e) =>
                                          handleInputChangeTable(
                                            row.id,
                                            "description",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ width: "30%" }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={row.textField}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, row.id)
                                            }
                                            name="textField"
                                          />
                                        }
                                        label="TextField"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={row.dropDown}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, row.id)
                                            }
                                            name="dropDown"
                                          />
                                        }
                                        label="DropDown"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={row.checkbox}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, row.id)
                                            }
                                            name="checkbox"
                                          />
                                        }
                                        label="Checkbox"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={row.imageUpload}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, row.id)
                                            }
                                            name="imageUpload"
                                          />
                                        }
                                        label="Image"
                                      />
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ width: "25%" }}
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <RadioGroup
                                          row
                                          value={row.verification}
                                          onChange={(e) =>
                                            handleInputChangeTable(
                                              row.id,
                                              "verification",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                            disabled={!row.verificationEnabled}
                                          />
                                          <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                            disabled={!row.verificationEnabled}
                                          />
                                        </RadioGroup>
                                        <TextField
                                          size="small"
                                          variant="standard"
                                          type="number"
                                          inputProps={{ min: 0, max: 5 }}
                                          style={{ width: "35%" }}
                                          onChange={(e) =>
                                            handleInputChangeTable(
                                              row.id,
                                              "verificationText",
                                              e.target.value
                                            )
                                          }
                                          disabled={!row.verificationEnabled}
                                        />
                                      </Box>
                                    </TableCell>

                                    <TableCell align="center">
                                      <TextField
                                        variant="standard"
                                        placeholder="Remarks"
                                        size="small"
                                        value={row.remarks}
                                        onChange={(e) =>
                                          handleInputChangeTable(
                                            row.id,
                                            "remarks",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </TableCell>

                                    <TableCell align="center">
                                      <DeleteIcon
                                        onClick={() => handleDeleteRow(row.id)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    </Table>
                  </TableContainer>
                </DragDropContext>
              </div>
            </div>
          </Paper>
        </Box>
      </div>
    </div>
  );
};

export default SopNewDocument;
