import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

export const fetchCustomerTablevalue = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_Customer_TABLE}`
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchCustomerTablevalueById = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_CUSTOMER_BYCOMPANY
          : process.env.REACT_APP_CUSTOMER_BYID
      }/${
        isOEM
          ? sessionStorage.getItem('companyId')
          : sessionStorage.getItem('customerId')
      }`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const fetchCustomerEdit = async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_CUSTOMER_EDIT}/${data}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

/*-------------------------------------------------- New User Add ----------------------------------*/

export const postCustomerSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_CUSTOMER_SAVE}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

/*---------------------------------------- Update User Call --------------------------------------------*/

// export const putCustomerEdit = async (data,id) => {

//     try {

//       const response = await axios.put(`${process.env.REACT_APP_CUSTOMER_EDIT_UPDATE}/${id}`,data);

//       return response.data

//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
//   };
export const putCustomerupdate = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_CUSTOMER_EDIT_UPDATE}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

//---------------------------------------- SECTOR PAGE API -------------------------------//

//   //FIND-ALL
//   export const fetchsectorTable = async () => {

//     try {
//         const response = await axios.get(`${process.env.REACT_APP_SECTOR_TABLE}`);

//         return response.data;
//     } catch (error) {
//         console.error('Error posting data:', error);
//         throw error;
//     }
// };
// // SECTOR - EDIT
// // export const fetchsectoredit = async () => {

// //   try {
// //       const response = await axios.get(`${process.env.REACT_APP_SECTOR_Edit}`);

// //       return response.data;
// //   } catch (error) {
// //       console.error('Error posting data:', error);
// //       throw error;
// //   }
// // };
// ///post data
// export const postsectordata = async (obj) => {

//     try {
//         const response = await axios.post(`${process.env.REACT_APP_SECTOR_Submit_Data}`,obj);

//         return response.data;
//     } catch (error) {
//         console.error('Error posting data:', error.message);
//         throw error;
//     }
// };

// //edit icon
// export const Getediticon = async (data) => {

//   try {
//     const response = await axios.get(`${process.env.REACT_APP_SECTOR_Edit}=${data}`);
//     // const response = await axios.get(`${process.env.REACT_APP_SECTOR_Edit}`);
//     console.log(response,"response")
//     return response.data

//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
// /// for getting data from autocomplete
// export const Getdropdowndata = async () => {
//   // console.log(datas,'data');
//   try {
//   //   const response = await axios.get(`${process.env.REACT_APP_CUSTOMER_EDIT}/${data}`);
//     const response = await axios.get(`${process.env.REACT_APP_SECTOR_dropdown_companylist}`);
//     console.log(response,"response")
//     return response.data

//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// export const Getdropdownsectorlist = async () => {
//   // console.log(datas,'data');
//   try {
//   //   const response = await axios.get(`${process.env.REACT_APP_CUSTOMER_EDIT}/${data}`);
//     const response = await axios.get(`${process.env.REACT_APP_SECTOR_dropdown_sectorlist}`);
//     console.log(response,"response")
//     return response.data

//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };
