import React from 'react';
import Chart from 'react-apexcharts';
import {
  Paper,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import CellTowerIcon from '@mui/icons-material/CellTower';
import TimelineIcon from '@mui/icons-material/Timeline';
import { GiHeartBottle } from 'react-icons/gi';

const SiteStatusChart = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const series = props.data;
  const options = {
    chart: {
      width: isSmallScreen ? '100%' : '35%',
      type: 'donut',
    },
    colors: ['#71DD37', '#FF1913', '#98A3B2', '#FFB10E'],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <Paper
      elevation={0}
      className="total-status"
      style={{
        paddingRight: '15px',
        padding: ' 2px 10px 2px 10px',
        borderRadius: '20px',
        height: '90%',

        width: '100%',
      }}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography
            style={{
              textTransform: 'capitalize',
              lineHeight: '14.52px !important',
              color: ' rgb(113, 118, 111)',
              fontSize: '18px',
            }}
          >
            Site Status
          </Typography>
        </Grid>
        <Grid item>
          <GiHeartBottle
            style={{
              fontSize: '35px',
            }}
          />
        </Grid>
      </Grid>
      {/* {series[0] !== 0 ? ( */}
      <div>
        <Chart
          options={options}
          series={series}
          type="donut"
          width="100%"
          height="68%"
        />
        <Grid container spacing={2} sx={{ marginLeft: '10px' }}>
          <Grid item xs={6} md={5}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                // margin: isSmallScreen ? '10px 0' : '0 0 0 0px',
              }}
            >
              <div
                style={{
                  textTransform: 'capitalize',
                  lineHeight: '14.52px !important',
                  color: ' rgb(113, 118, 111)',
                }}
              >
                <VerifiedUserIcon
                  style={{
                    color: '#228B22',
                    verticalAlign: 'bottom',
                  }}
                />{' '}
                Active{' '}
              </div>
              <div
              // style={{ margin: isSmallScreen ? '5px 0 0 0' : '0 0 0 60px' }}
              >
                <Typography>{series[0]}</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={5}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: isSmallScreen ? '10px 0' : '0 0 0 10px',
              }}
            >
              <div
                style={{
                  textTransform: 'capitalize',
                  lineHeight: '14.52px !important',
                  color: ' rgb(113, 118, 111)',
                }}
              >
                <CellTowerIcon
                  style={{
                    color: '#B22222',
                    verticalAlign: 'bottom',
                  }}
                />{' '}
                Down{' '}
              </div>
              <div>
                <Typography>{series[1]}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <div style={{ margin: '10px 0 0 0px ' }}>
          <Grid container spacing={2} style={{ marginLeft: '10px' }}>
            <Grid item xs={6} md={5} paddingLeft={10}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    textTransform: 'capitalize',
                    lineHeight: '14.52px !important',
                    color: ' rgb(113, 118, 111)',
                  }}
                >
                  <TimelineIcon
                    style={{
                      color: '#FFB10E',
                      verticalAlign: 'bottom',
                    }}
                  />{' '}
                  Warning
                </div>
                <div>
                  <Typography>{series[3]}</Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={5}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: isSmallScreen ? '10px 0' : '0 0 0 10px',
                }}
              >
                <div
                  style={{
                    textTransform: 'capitalize',
                    lineHeight: '14.52px !important',
                    color: ' rgb(113, 118, 111)',
                  }}
                >
                  <SportsSoccerIcon
                    style={{
                      color: '#98A3B2',
                      verticalAlign: 'bottom',
                    }}
                  />{' '}
                  Offline{' '}
                </div>
                <div>
                  <Typography>{series[2]}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* ) : (
        <div>
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '25%',
            }}
          >
            There are no Sites to Show
          </Typography>
        </div>
      )} */}
    </Paper>
  );
};

export default SiteStatusChart;
