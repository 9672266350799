import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Stack,
  Autocomplete,
  Card,
} from "@mui/material";
import FadeLoader from "react-spinners/FadeLoader";
import { CheckForLight } from '../util/common/CommanFunction';
import dayjs from "dayjs";
// ========Modal================
import { styled } from "@mui/material/styles";
/*------------api call-----------------*/
import {
  fetchEquipmentTable,
  //   Getdropdownvalue,
} from "../../Api/EquipmenttypeApi";
import {
  Equipmentsitedropdowns,
  getDownTimeMinutes,
  postDgrSave,
  postDgrUpdate,
  fetchSiteandDateGet,
  fetchTodaySiteandDateGet,
} from "../../Api/DgrApi";
import { Getdropdownvalue } from "../../Api/MapsiteApi";
import CustomBreadcrumbs from "../util/components/CustomBread";
import { EquipmentType } from "../util/TextField";
import { Statusdropdown, fetchInstalltionCapacity } from "../../Api/CommonApi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomSnackbar from "../util/components/CustomSnackbar";
export default function Equipmenthome({ dailyGeneration }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  console.log(SiteEquipment, "SiteEquipment");
  const [equipmentvalue, setEquipmentValue] = useState([]);
  const [DownTime, setDownTime] = useState("");
  console.log(DownTime);
  console.log(DownTime[0]?.downTime);
  console.log(equipmentvalue);
  const [down, setdown] = useState("");
  const [downn, setdownn] = useState("");
  console.log(downn, "downn");
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [isAnyRemarkFilled, setIsAnyRemarkFilled] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [equipment, setEquipment] = useState("");
  const [selectedSite, setSelectedSite] = useState(null);
  const [toBackend, setToBackend] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const [sitestatus, setSitestatus] = useState("");
  const [remarks, setRemarks] = useState("");
  console.log(remarks, "remarks");
  const [remarkvalue, setRemarkValue] = useState("");
  console.log(remarkvalue, ">>>>>>>");
  const remarkRefs = useRef([]);
  const [editcall, seteditcall] = useState();
  const [unitsIC, setUnitsIC] = useState([]);
  const [text, setTextField] = useState([]);
  const [timeData, setTimeData] = useState({ startTime: "", endTime: "" });
  const initalState = {
    dgrDate: "",
    downtime: "",
    startTime: "",
    endTime: "",
    downTimeMinutes: "",
  };
  const [stdField, setStdField] = useState(initalState);
  console.log(stdField);
  console.log(stdField[0]?.downtime);
  const [statusDrop, setStatusDrop] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);

  /*----------------------------Fields-------------------------------*/
  const initialFieldSet = {
    equipmentName: "",
    equipmentId: "",
    downTimeId: "",
    dgrDate: "",
    downtime: "",
    startTime: "",
    endTime: "",
    downTimeMinutes: "",
    remarks: [""],
  };
  const [fieldSets, setFieldSets] = useState([initialFieldSet]);
  console.log(fieldSets);
  console.log("Field Sets after update:", fieldSets);

  // const handleSite = async (event, value) => {
  //   setSitestatus(value);
  //   const site = siteIdName.find((item) => item.siteName === value);
  //   if (site) {
  //     try {
  //       const [equipmentData, downtimeData] = await Promise.all([
  //         Equipmentsitedropdowns(site.siteId),
  //       ]);
  //       setEquipmentValue(equipmentData);
  //       const newFieldSets = equipmentData?.map((equipment) => {
  //         return {
  //           equipmentName: equipment.displayName || "",
  //           equipmentId: equipment.equipmentId || "",
  //         };
  //       });
  //       setFieldSets(newFieldSets);
  //       // Fetch today's data
  //       const today = new Date();
  //       const formattedDate = today.toISOString().split("T")[0];
  //       await fetchData(value, formattedDate);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  // };

  const handleSite = async (event, value) => {
    setSitestatus(value);
    setSelectedSite(value);  // Add this line
    const site = siteIdName.find((item) => item.siteName === value);
    if (site && selectedDate) {
      try {
        const formattedDate = selectedDate.format("YYYY-MM-DD");
        await fetchData(value, formattedDate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  // const handleSite = async (event, value) => {
  //   setSitestatus(value);
  //   const site = siteIdName.find((item) => item.siteName === value);
  //   if (site) {
  //     try {
  //       const [equipmentData, downtimeData] = await Promise.all([
  //         Equipmentsitedropdowns(site.siteId),
  //         getDownTimeMinutes(site.siteId),
  //       ]);
  //       setEquipmentValue(equipmentData);
  //       setDownTime(downtimeData);
  //       const newFieldSets = equipmentData.map((equipment) => {
  //         const downtimeInfo = downtimeData.find(
  //           (item) => item.equipmentId === equipment.equipmentId
  //         ) || { downtime: '', downtimeMinutes: '' };
  //         return {
  //           equipmentName: equipment.displayName || '',
  //           equipmentId: equipment.equipmentId || '',
  //           ...downtimeInfo,
  //           remarks: [''],
  //         };
  //       });
  //       setFieldSets(newFieldSets);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   }
  // };

  // const handleDateChange = (date) => {
  //   const selectedDate = new Date(date);
  //   selectedDate.setDate(selectedDate.getDate());
  //   setSelectedDate(date);
  //   console.log(selectedDate);
  // };
  // const handleDateChange = async (date) => {
  //   const selectedDate = new Date(date);
  //   selectedDate.setDate(selectedDate.getDate());
  //   setSelectedDate(date);
  //   setIsDateSelected(!!date);

  //   if (sitestatus) {
  //     const formattedDate = selectedDate.toISOString().split("T")[0];
  //     await fetchData(sitestatus, formattedDate);
  //   }
  // };
  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setIsDateSelected(!!date);
    setSelectedDate(date)
    if (sitestatus && date) {
      const formattedDate = date.format("YYYY-MM-DD");
      await fetchData(sitestatus, formattedDate);
    }
  };
  const getEquipment = async (id) => {
    // const data = await Equipmentsitedropdowns(id);
    // setEquipmentValue(data);
    // return(data)
  };
  const getRemarks = async (id) => {
    // const data = await RemarksField(id);
    // console.log(data, "data");
    // setRemarks(data);
    // return(data)
  };
  const handleRemarks = (event) => {
    console.log(event, ">>>>>>>>>");
    setRemarkValue(event);
  };
  const handleInputChange = (index, field, event) => {
    const newFieldSets = [...fieldSets];
    newFieldSets[index][field] = event.target.value;
    setFieldSets(newFieldSets);

    const anyRemarkFilled = newFieldSets.some(
      (fieldSet) => fieldSet.remarks && fieldSet.remarks.trim() !== ""
    );
    setIsAnyRemarkFilled(anyRemarkFilled);
  };
  // const handleInputChange = (index, field, event, values) => {
  //   const newFieldSets = [...fieldSets];
  //   console.log(field, index, event);
  //   setdown(event.target.value);
  //   newFieldSets[index][field] = event.target.value;
  //   setFieldSets(newFieldSets);
  //   console.log(fieldSets, "fieldSets");

  //   const anyRemarkFilled = newFieldSets.some(
  //     (fieldSet) => fieldSet.remarks && fieldSet.remarks.trim() !== ""
  //   );
  //   setIsAnyRemarkFilled(anyRemarkFilled);

  //   if (field === "downtime") {
  //     const site = siteIdName.find((item) => item.siteName === sitestatus);
  //     if (site) {
  //       getDownTimeMinutes(site.siteId)
  //         .then((downtimeData) => {
  //           const equipmentData = downtimeData.find(
  //             (item) => item.equipmentId === newFieldSets[index].equipmentId
  //           );
  //           if (equipmentData) {
  //             newFieldSets[index].downTimeMinutes =
  //               equipmentData.downtime_Minutes;
  //             setFieldSets(newFieldSets);
  //           }
  //         })
  //         .catch((error) =>
  //           console.error("Error fetching downtime minutes:", error)
  //         );
  //     }
  //   }
  // };
  const handleRemarkChange = (fieldSetIndex, remarkIndex, value) => {
    const newFieldSets = [...fieldSets];
    if (!newFieldSets[fieldSetIndex].remarks) {
      newFieldSets[fieldSetIndex].remarks = [];
    }
    newFieldSets[fieldSetIndex].remarks[remarkIndex] = value;
    setFieldSets(newFieldSets);
  };
  const handleInputChangee = (index, field, event, values) => {
    const newFieldSets = [...fieldSets];
    console.log(field, index, event);
    // setdownn(event.target.value);
    newFieldSets[index][field] = event.target.value;
    setFieldSets(newFieldSets);
    if (index === 0) {
      setdownn(event.target.value);
    }
    console.log(fieldSets, "fieldSets");
    if (field === "downtime") {
      const site = siteIdName.find((item) => item.siteName === sitestatus);
      if (site) {
        getDownTimeMinutes(site.siteId)
          .then((downtimeData) => {
            const equipmentData = downtimeData.find(
              (item) => item.equipmentId === newFieldSets[index].equipmentId
            );
            if (equipmentData) {
              newFieldSets[index].downTimeMinutes =
                equipmentData.downtime_Minutes;
              setFieldSets(newFieldSets);
            }
          })
          .catch((error) =>
            console.error("Error fetching downtime minutes:", error)
          );
      }
    }
  };
  console.log(down, ">>>>>");
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addFieldSet();
    }
  };
  const addFieldSet = () => {
    setFieldSets([...fieldSets, { equipmentName: "", remarks: [""] }]);
    setStdField([...stdField, { downtime: "", downtime_Minutes: "" }]);
  };
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    fetchUnits();
    getsitelist();
  }, []);
  useEffect(() => {
    let data = EquipmentType();
    setTextField(data);
  }, []);
  useEffect(() => {
    console.log("Field Sets updated:", fieldSets);
  }, [fieldSets]);
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, "dataOutside");
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };
  const getsitelist = async () => {
    try {
      let data = await Getdropdownvalue();
      if (Array.isArray(data)) {
        setSiteId(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteId([data]);
      }
      setLoading(false);
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };
  console.log(siteIdName, "siteIdName");

  const convertUTCtoIST = (utcTime) => {
    const date = new Date(utcTime);
    const istTime = new Date(date.getTime() + 5.5 * 60 * 60 * 1000); // Add 5 hours and 30 minutes
    return istTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  const handleApply = async () => {
    if (!sitestatus || !selectedDate) {
      setSnack({
        open: true,
        severity: "error",
        message: "Please select both site and date",
      });
      return;
    }

    setLoading(true);
    try {
      const siteId = siteIdName.find((s) => s.siteName === sitestatus)?.siteId;
      if (!siteId) {
        throw new Error("Site ID not found");
      }

      const formattedDate = selectedDate.format("YYYY-MM-DD");
      const data = await fetchSiteandDateGet(siteId, formattedDate);

      const newFieldSets = data.map((item) => ({
        equipmentId: item.equipmentId,
        equipmentName: `Equipment ${item.equipmentId}`,
        startTime: convertUTCtoIST(item.startTime),
        endTime: convertUTCtoIST(item.endTime),
        downTimeMinutes: item.downTimeMinutes,
        // remarks: item.remarks || [''],
      }));

      setFieldSets(newFieldSets);
      setSnack({
        open: true,
        severity: "success",
        message: "Data fetched successfully",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error fetching data. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };
  /*------------------------- handleSave ---------------------------*/

  const formatDateTime = (date, time) => {
    const [hours, minutes] = time.match(/(\d+):(\d+)/).slice(1);
    const isPM = time.toLowerCase().includes("pm");
    let hour = parseInt(hours);
    if (isPM && hour !== 12) hour += 12;
    if (!isPM && hour === 12) hour = 0;

    return dayjs(date)
      .hour(hour)
      .minute(parseInt(minutes))
      .second(0)
      .millisecond(0)
      .toISOString();
  };
//   const handleSave = async () => {
//   if (!selectedDate) {
//     setSnack({
//       open: true,
//       severity: "error",
//       message: "Please select a date before saving.",
//     });
//     return;
//   }

//   let data = fieldSets.map((fieldSet) => ({
//     siteId: siteIdName.find((site) => site.siteName === sitestatus)?.siteId,
//     downTimeDate: selectedDate.format("YYYY-MM-DD"),
//     downTimeType: "InverterFailure", // You might want to make this dynamic
//     equipmentId: fieldSet.equipmentId,
//     downTimeId: fieldSet.downTimeId || null, // Use existing downTimeId if present, otherwise null for new entry
//     equipmentDownTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
//     startTime: formatDateTime(selectedDate, fieldSet.startTime),
//     endTime: formatDateTime(selectedDate, fieldSet.endTime),
//     downTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
//     remarks: fieldSet.remarks || "",
//     createdBy: Number(sessionStorage.getItem("id")),
//   }));

//   try {
//     const response = await postDgrSave(data);
//     console.log("Data saved successfully:", response);

//     // Update fieldSets with the received downTimeId if they were newly created
//     const updatedFieldSets = fieldSets.map((fieldSet, index) => ({
//       ...fieldSet,
//       downTimeId: response[index].downTimeId || fieldSet.downTimeId,
//     }));
//     setFieldSets(updatedFieldSets);

//     // Reset fields and show success message
//     setSelectedDate(null);
//     setSitestatus("");
//     setSiteId([]);
//     setRemarks("");
//     setSnack({
//       ...snack,
//       open: true,
//       severity: "success",
//       message: "Data saved successfully",
//     });
//   } catch (error) {
//     console.error("Error saving data:", error.message);
//     setSnack({
//       ...snack,
//       open: true,
//       severity: "error",
//       message: "Error saving data. Please try again.",
//     });
//   }
// };
// const handleUpdate = async () => {
//   if (!selectedDate) {
//     setSnack({
//       open: true,
//       severity: "error",
//       message: "Please select a date before updating.",
//     });
//     return;
//   }

//   const siteId = siteIdName.find((site) => site.siteName === sitestatus)?.siteId;
//   if (!siteId) {
//     setSnack({
//       open: true,
//       severity: "error",
//       message: "Invalid site selected.",
//     });
//     return;
//   }

//   const formattedDate = selectedDate.format("YYYY-MM-DD");

//   try {
//     const updatedData = fieldSets.map((fieldSet) => ({
//       downTimeId: fieldSet.downTimeId,
//       siteId: siteId,
//       downTimeDate: formattedDate,
//       downTimeType: "InverterFailure", // You might want to make this dynamic
//       equipmentId: fieldSet.equipmentId,
//       equipmentDownTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
//       startTime: formatDateTime(selectedDate, fieldSet.startTime),
//       endTime: formatDateTime(selectedDate, fieldSet.endTime),
//       downTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
//       remarks: fieldSet.remarks || "",
//       updatedBy: Number(sessionStorage.getItem("id")),
//     }));

//     // Filter out any entries without a downTimeId
//     const validUpdates = updatedData.filter(item => item.downTimeId);

//     if (validUpdates.length === 0) {
//       setSnack({
//         open: true,
//         severity: "warning",
//         message: "No valid records to update.",
//       });
//       return;
//     }

//     const response = await postDgrUpdate(validUpdates);
//     console.log("Data updated successfully:", response);

//     // Update fieldSets if necessary (e.g., if the API returns updated data)
//     // setFieldSets(response);

//     setSnack({
//       open: true,
//       severity: "success",
//       message: "Data updated successfully",
//     });
//   } catch (error) {
//     console.error("Error updating data:", error.message);
//     setSnack({
//       open: true,
//       severity: "error",
//       message: "Error updating data. Please try again.",
//     });
//   }
// };
// const handleUpdate = async () => {
//   if (!selectedDate) {
//     setSnack({
//       open: true,
//       severity: "error",
//       message: "Please select a date before saving.",
//     });
//     return;
//   }

//   // Prepare the data for saving
//   let data = fieldSets.map((fieldSet) => ({
//     siteId: siteIdName.find((site) => site.siteName === sitestatus)?.siteId,
//     downTimeDate: selectedDate.format("YYYY-MM-DD"),
//     downTimeType: "InverterFailure", // You might want to make this dynamic
//     equipmentId: fieldSet.equipmentId,
//     downTimeId: fieldSet.downTimeId || null, // Include null explicitly for new entries
//     equipmentDownTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
//     startTime: formatDateTime(selectedDate, fieldSet.startTime),
//     endTime: formatDateTime(selectedDate, fieldSet.endTime),
//     downTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
//     remarks: fieldSet.remarks || "",
//     createdBy: Number(sessionStorage.getItem("id")),
//   }));

//   try {
//     // Perform the save operation, assuming postDgrSave can handle both create and update
//     const response = await postDgrUpdate(data);

//     // Check if the response includes the downTimeId for each item
//     if (response && Array.isArray(response)) {
//       const updatedFieldSets = fieldSets.map((fieldSet, index) => ({
//         ...fieldSet,
//         downTimeId: response[index]?.downTimeId || fieldSet.downTimeId, // Update downTimeId if returned
//       }));
//       setFieldSets(updatedFieldSets);
//     }

//     // Reset fields and show success message
//     setSelectedDate(null);
//     setSitestatus("");
//     setSiteId([]);
//     setRemarks("");
//     setSnack({
//       open: true,
//       severity: "success",
//       message: "Data saved successfully",
//     });
//   } catch (error) {
//     console.error("Error saving data:", error);
//     setSnack({
//       open: true,
//       severity: "error",
//       message: "Error saving data. Please try again.",
//     });
//   }
// };

const handleUpdate = async () => {
  if (!selectedDate) {
    setSnack({
      open: true,
      severity: "error",
      message: "Please select a date before updating.",
    });
    return;
  }

  let data = fieldSets.map((fieldSet) => ({
    siteId: siteIdName.find((site) => site.siteName === sitestatus)?.siteId,
    downTimeDate: selectedDate.format("YYYY-MM-DD"),
    downTimeType: "InverterFailure", // You might want to make this dynamic
    equipmentId: fieldSet.equipmentId,
    downTimeId: fieldSet.downTimeId, // This is crucial for updating
    equipmentDownTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
    startTime: formatDateTime(selectedDate, fieldSet.startTime),
    endTime: formatDateTime(selectedDate, fieldSet.endTime),
    downTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
    remarks: fieldSet.remarks || "",
    updatedBy: Number(sessionStorage.getItem("id")), // Changed from createdBy to updatedBy
  }));

  try {
    // Filter out any entries that don't have a downTimeId (these would be new entries)
    const updateData = data.filter(item => item.downTimeId);
    
    if (updateData.length === 0) {
      setSnack({
        open: true,
        severity: "warning",
        message: "No existing data to update. Use Save for new entries.",
      });
      return;
    }

    const response = await postDgrUpdate(updateData);
    console.log("Data updated successfully:", response);

    // Update fieldSets if necessary (e.g., if the API returns updated data)
    const updatedFieldSets = fieldSets.map((fieldSet) => {
      const updatedData = response.find(item => item.downTimeId === fieldSet.downTimeId);
      return updatedData ? { ...fieldSet, ...updatedData } : fieldSet;
    });
    setFieldSets(updatedFieldSets);

    setSnack({
      open: true,
      severity: "success",
      message: "Data updated successfully",
    });
  } catch (error) {
    console.error("Error updating data:", error.message);
    setSnack({
      open: true,
      severity: "error",
      message: "Error updating data. Please try again.",
    });
  }
};




  // const handleSave = async () => {
  //   if (!selectedDate) {
  //     setSnack({
  //       open: true,
  //       severity: "error",
  //       message: "Please select a date before saving.",
  //     });
  //     return;
  //   }
  
  //   let data = fieldSets.map((fieldSet) => {
  //     let existingDownTimeId = fieldSet.downTimeId;
  
  //     // Prepare the payload based on whether downTimeId exists
  //     return {
  //       siteId: siteIdName.find((site) => site.siteName === sitestatus)?.siteId,
  //       downTimeDate: selectedDate.format("YYYY-MM-DD"),
  //       downTimeType: "InverterFailure", // You might want to make this dynamic
  //       equipmentId: fieldSet.equipmentId,
  //       downTimeId: existingDownTimeId ? existingDownTimeId : undefined, // Use the existing downTimeId if it exists
  //       equipmentDownTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
  //       startTime: formatDateTime(selectedDate, fieldSet.startTime),
  //       endTime: formatDateTime(selectedDate, fieldSet.endTime),
  //       downTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
  //       remarks: fieldSet.remarks || "",
  //       createdBy: Number(sessionStorage.getItem("id")),
  //     };
  //   });
  
  //   try {
  //     const response = await postDgrSave(data);
  //     console.log("Data saved successfully:", response);
  
  //     // Update fieldSets with the received downTimeId from the response
  //     const updatedFieldSets = fieldSets.map((fieldSet, index) => ({
  //       ...fieldSet,
  //       downTimeId: response[index].downTimeId,
  //     }));
  //     setFieldSets(updatedFieldSets);
  
  //     // Reset fields and show success message
  //     setSelectedDate(null);
  //     setSitestatus("");
  //     setSiteId([]);
  //     setRemarks("");
  //     setSnack({
  //       ...snack,
  //       open: true,
  //       severity: "success",
  //       message: "Data saved successfully",
  //     });
  //   } catch (error) {
  //     console.error("Error saving data:", error.message);
  //     setSnack({
  //       ...snack,
  //       open: true,
  //       severity: "error",
  //       message: "Error saving data. Please try again.",
  //     });
  //   }
  // };
  
  // const handleSave = async () => {
  //   if (!selectedDate) {
  //     setSnack({
  //       open: true,
  //       severity: "error",
  //       message: "Please select a date before saving.",
  //     });
  //     return;
  //   }

  //   let data = fieldSets.map((fieldSet) => ({
  //     siteId: siteIdName.find((site) => site.siteName === sitestatus)?.siteId,
  //     downTimeDate: selectedDate.format("YYYY-MM-DD"),
  //     downTimeType: "InverterFailure", // You might want to make this dynamic
  //     equipmentId: fieldSet.equipmentId,
  //     downTimeId: fieldSet.downTimeId,
  //     equipmentDownTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
  //     startTime: formatDateTime(selectedDate, fieldSet.startTime),
  //     endTime: formatDateTime(selectedDate, fieldSet.endTime),
  //     downTimeMinutes: parseFloat(fieldSet.downTimeMinutes),
  //     remarks: fieldSet.remarks || "",
  //     createdBy: Number(sessionStorage.getItem("id")),
  //   }));

  //   try {
  //     const response = await postDgrSave(data);
  //     console.log("Data saved successfully:", response);

  //     // Update fieldSets with the received downTimeId
  //     const updatedFieldSets = fieldSets.map((fieldSet, index) => ({
  //       ...fieldSet,
  //       downTimeId: response[index].downTimeId,
  //     }));
  //     setFieldSets(updatedFieldSets);

  //     // Reset fields and show success message
  //     setSelectedDate(null);
  //     setSitestatus("");
  //     setSiteId([]);
  //     setRemarks("");
  //     setSnack({
  //       ...snack,
  //       open: true,
  //       severity: "success",
  //       message: "Data saved successfully",
  //     });
  //   } catch (error) {
  //     console.error("Error saving data:", error.message);
  //     setSnack({
  //       ...snack,
  //       open: true,
  //       severity: "error",
  //       message: "Error saving data. Please try again.",
  //     });
  //   }
  // };
  useEffect(() => {
    fetchData();
    fetchTodayData();
    getsitelist();
    fetchUnits();
    getEquipment();
    fetchStatus();
    getRemarks();
  }, []);
  /*-------------------------Table get call --------------------------------------------*/
  // const fetchData = async (site, date) => {
  //   setLoading(true);
  //   try {
  //     const siteId = siteIdName.find((s) => s.siteName === site)?.siteId;
  //     if (!siteId) {
  //       throw new Error('Site ID not found');
  //     }
  //     const formattedDate = date.format('YYYY-MM-DD');
  //     const data = await fetchSiteandDateGet(siteId, formattedDate);

  //     const newFieldSets = data.map(item => ({
  //       equipmentId: item.equipmentId,
  //       equipmentName: `Equipment ${item.equipmentId}`, // You might want to fetch actual names
  //       startTime: new Date(item.startTime).toLocaleTimeString(),
  //       endTime: new Date(item.endTime).toLocaleTimeString(),
  //       downTimeMinutes: item.downTimeMinutes,
  //       remarks: item.remarks || '',
  //     }));

  //     setFieldSets(newFieldSets);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setSnack({
  //       open: true,
  //       severity: 'error',
  //       message: 'Error fetching data. Please try again.',
  //     });
  //     setLoading(false);
  //   }
  // };
  // const fetchData = async (site, date) => {
  //   setLoading(true);
  //   try {
  //     const siteId = siteIdName.find((s) => s.siteName === site)?.siteId;
  //     if (!siteId) {
  //       throw new Error("Site ID not found");
  //     }

  //     let data;
  //     if (date === new Date().toISOString().split("T")[0]) {
  //       data = await fetchTodaySiteandDateGet(siteId, date);
  //     } else {
  //       data = await fetchSiteandDateGet(siteId, date);
  //     }

  //     // const newFieldSets = data.map((item) => ({
  //     //   equipmentId: item.equipmentId,
  //     //   equipmentName: `Equipment ${item.equipmentId}`,
  //     //   startTime: convertUTCtoIST(item.startTime),
  //     //   endTime: convertUTCtoIST(item.endTime),
  //     //   downTimeMinutes: item.downTimeMinutes,
  //     //   remarks: item.remarks || "",
  //     // }));
  //     const newFieldSets = data.map((item) => ({
  //       equipmentId: item.equipmentId,
  //       equipmentName: `Equipment ${item.equipmentId}`,
  //       startTime: item.startTime, // Use raw API response
  //       endTime: item.endTime, // Use raw API response
  //       downTimeMinutes: item.downTimeMinutes,
  //       remarks: item.remarks || "",
  //     }));

  //     setFieldSets(newFieldSets);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setSnack({
  //       open: true,
  //       severity: "error",
  //       message: "Error fetching data. Please try again.",
  //     });
  //     setLoading(false);
  //   }
  // };
  const fetchData = async (site, date) => {
    setLoading(true);
    try {
      const siteId = siteIdName.find((s) => s.siteName === site)?.siteId;
      if (!siteId) {
        throw new Error("Site ID not found");
      }

      let data;
      if (date === new Date().toISOString().split("T")[0]) {
        data = await fetchTodaySiteandDateGet(siteId, date);
      } else {
        data = await fetchSiteandDateGet(siteId, date);
      }

      const newFieldSets = data.map((item) => ({
        downTimeId: item.downTimeId,
        equipmentId: item.equipmentId,
        equipmentName: `Equipment ${item.equipmentId}`,
        startTime: convertUTCtoIST(item.startTime),
        endTime: convertUTCtoIST(item.endTime),
        downTimeMinutes: item.downTimeMinutes,
        remarks: item.remarks || "",
      }));

      setFieldSets(newFieldSets);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error fetching data. Please try again.",
      });
      setLoading(false);
    }
  };
  // const fetchData = async (site, date) => {
  //   setLoading(true);
  //   try {
  //     const siteId = siteIdName.find((s) => s.siteName === site)?.siteId;
  //     if (!siteId) {
  //       throw new Error('Site ID not found');
  //     }
  //     const data = await fetchSiteandDateGet(siteId, date);

  //     const newFieldSets = data.map(item => ({
  //       equipmentId: item.equipmentId,
  //       equipmentName: `Equipment ${item.equipmentId}`, // You might want to fetch actual names
  //       downtime: `${item.startTime} - ${item.endTime}`,
  //       downtime_Minutes: item.downTimeMinutes,
  //       remarks: item.remarks ? [item.remarks] : [''],
  //     }));

  //     setFieldSets(newFieldSets);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setSnack({
  //       open: true,
  //       severity: 'error',
  //       message: 'Error fetching data. Please try again.',
  //     });
  //     setLoading(false);
  //   }
  // };
  // const fetchData = async () => {
  //   setLoading(true);
  //   try {
  //     const data = await fetchEquipmentTable();
  //     if (Array.isArray(data)) {
  //       setSiteEquipment(data);
  //       setLoading(false);
  //     } else if (typeof data === 'object' && data !== null) {
  //       setSiteEquipment([data]);
  //     }
  //     setLoading(false);
  //     console.log(data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  /*-------------------- Today Date Table get call -----------------------------*/
  const fetchTodayData = async () => {
    if (sitestatus) {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      await fetchData(sitestatus, formattedDate);
    }
  };

  /*---------------------------------- New Equipment Call -----------------------*/
  const handleClose = () => {
    setOpen(false);
    seteditcall([]);

    setEdit(false);
    setEquipment("");
  };
  const Item = styled(Paper)(({ theme }) => ({}));
  const Total = SiteEquipment.length;
  const Active = SiteEquipment.filter((site) => site.status == 1).length;
  const Inactive = SiteEquipment.filter((site) => site.status == 0).length;
  console.log(Active);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Down Time", path: "dgr" },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={() => setSnack(initialSnack)}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  // mt: 2,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "101%",
                  height: "6.5vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </div>
              </Card>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                margin: "0 0.5% 0 0.5%",
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  mr: 0,
                  mb: 1,
                  borderRadius: 0,
                  width: "100%",
                  height: "7.5vh",
                },
              }}
            >
              <Card
                elevation={0}
                style={{
                  display: "flex",
                  width: "100%",
                  height: "13vh",
                  borderRadius: "2px 2px 0px 0px",
                  userSelect: "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "30px",
                  }}
                >
                  <div style={{ marginRight: "5%" }}>
                    <Typography className="modal-typo">Site Name</Typography>
                    <div style={{ marginTop: "5%" }}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={siteIdName?.map((option) => option.siteName)}
                        defaultValue={edit ? edit.siteName : null}
                        value={selectedSite}  // Add this line
                        onChange={(data, event) => handleSite(data, event)}
                        sx={{ width: "14vw" }}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} sx={{}} placeholder="IFP" />
                        )}
                      />
                    </div>
                  </div>

                  <div style={{ marginRight: "5%" }}>
                    <Typography className="modal-typo" sx={{ width: "14vw" }}>
                      Date
                    </Typography>
                    <div style={{ marginTop: "5%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          size="small"
                          sx={{ width: "14vw" }}
                          onChange={handleDateChange}
                          value={selectedDate}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      marginRight: "30px",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                      }}
                      onClick={handleApply}
                      disabled={!isDateSelected}
                    >
                      Apply
                    </Button>
                  </div>
                  <div
                    style={{
                      marginRight: "30px",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "25px",
                        textTransform: "capitalize",
                      }}
                      onClick={handleUpdate}
                      disabled={!isAnyRemarkFilled}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Card>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": { m: 1, width: "101%", height: "57vh" },
              }}
            >
              <Paper elevation={0}>
                {/* {sitestatus ? (
                  <div
                    style={{
                      height: "55vh",
                      overflowY: "auto",
                      padding: "25px",
                    }}
                  >
                    {fieldSets?.map((fieldSet, index) => (
                        <Grid container spacing={4}>
                          <Grid item xs={1.5}>
                            <Typography className="modal-typo">
                              Equipment Name
                            </Typography>
                            <Typography
                              sx={{
                                width: "14vw",
                                padding: "8.5px 14px",
                                fontSize: "1rem",
                                backgroundColor: "white",
                                borderRadius: "4px",
                                minHeight: "1.4375em",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ marginTop: "5%" }}>
                                {fieldSet.equipmentName}
                              </div>
                            </Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography className="modal-typo">
                              Start Time
                            </Typography>
                            <div style={{ marginTop: "5%" }}>
                              <TextField
                                value={fieldSet.startTime}
                                onChange={(event) =>
                                  handleInputChange(index, "startTime", event)
                                }
                                inputProps={{ readOnly: true }}
                                sx={{ width: "14vw" }}
                                size="small"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography className="modal-typo">
                              End Time
                            </Typography>
                            <div style={{ marginTop: "5%" }}>
                              <TextField
                                value={fieldSet.endTime}
                                onChange={(event) =>
                                  handleInputChange(index, "endTime", event)
                                }
                                inputProps={{ readOnly: true }}
                                sx={{ width: "14vw" }}
                                size="small"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography className="modal-typo">
                              DownTime Minutes
                            </Typography>
                            <div style={{ marginTop: "5%" }}>
                              <TextField
                                value={fieldSet.downTimeMinutes}
                                onChange={(event) =>
                                  handleInputChangee(
                                    index,
                                    "downTimeMinutes",
                                    event
                                  )
                                }
                                inputProps={{ readOnly: true }}
                                sx={{ width: "14vw" }}
                                size="small"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography className="modal-typo">
                              Remarks
                            </Typography>
                            
                              <Box
                                // key={remarkIndex}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <div style={{ marginTop: "5%" }}>
                                  <TextField
                                    sx={{ width: "19vw" }}
                                    variant="standard"
                                    // value={remark}
                                    // onChange={(event) =>
                                    //   handleRemarkChange(
                                    //     index,
                                    //     remarkIndex,
                                    //     event.target.value
                                    //   )
                                    // }
                                    inputProps={{ maxLength: 100 }}
                                  />
                                </div>
                              </Box>
                         
                          </Grid>
                        </Grid>
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      // margin: "340px 0 0 530px",
                      position: "fixed",
                      textAlign: "center",
                      // width: "100%",
                      left: "40%",
                      top: "61%",
                    }}
                  >
                    <Typography>No site has been selected</Typography>
                  </div>
                )} */}
                {sitestatus ? (
                  <Box>
                    {/* Headers */}
                    <Grid
                      container
                      spacing={2}
                      sx={{ p: 3, borderBottom: "1px solid #e0e0e0",  }}
                    >
                      <Grid item xs={2}>
                        <Typography className="modal-typo">
                          Equipment Name
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography className="modal-typo">
                          Start Time
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography className="modal-typo">End Time</Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography className="modal-typo">
                          DownTime Minutes
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography className="modal-typo">Remarks</Typography>
                      </Grid>
                    </Grid>

                    {/* Data rows */}
                    <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
                      {fieldSets?.map((fieldSet, index) => (
                        <Grid
                          container
                          spacing={2}
                          key={index}
                          sx={{ p: 3 }}
                        >
                          <Grid item xs={2}>
                            <Typography>{fieldSet.equipmentName}</Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <TextField
                              value={fieldSet.startTime}
                              onChange={(event) =>
                                handleInputChange(index, "startTime", event)
                              }
                              inputProps={{ readOnly: true }}
                              size="small"
                              width="25px"
                            />
                          </Grid>
                          <Grid item xs={2.5}>
                            <TextField
                              value={fieldSet.endTime}
                              onChange={(event) =>
                                handleInputChange(index, "endTime", event)
                              }
                              inputProps={{ readOnly: true }}
                              size="small"
                              width="25px"
                            />
                          </Grid>
                          <Grid item xs={2.5}>
                            <TextField
                              value={fieldSet.downTimeMinutes}
                              onChange={(event) =>
                                handleInputChangee(
                                  index,
                                  "downTimeMinutes",
                                  event
                                )
                              }
                              inputProps={{ readOnly: true }}
                              size="small"
                              width="25px"
                            />
                          </Grid>
                          <Grid item xs={2.5}>
                          <TextField
          value={fieldSet.remarks}
          onChange={(event) => handleInputChange(index, "remarks", event)}
          size="small"
          width="25px"
          inputProps={{ maxLength: 100 }}
        />
                            {/* <TextField
                              value={fieldSet.remarks}
                              onChange={(event) =>
                                handleInputChange(index, "remarks", event)
                              }
                              // fullWidth
                              // variant="standard"
                              size="small"
                              width="25px"
                              inputProps={{ maxLength: 100 }}
                            /> */}
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ textAlign: "center", py: 4 }}>
                    <Typography>No site has been selected</Typography>
                  </Box>
                )}
              </Paper>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}
