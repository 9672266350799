export default function replaceObjectById(
  tableValueState,
  idToUse,
  idToMatch,
  replacementObject
) {
  const indexToReplace = tableValueState.findIndex(
    (obj) => obj[idToUse] === idToMatch
  );

  if (indexToReplace !== -1) {
    tableValueState[indexToReplace] = replacementObject;
  } else {
    console.error(`Object with id ${idToMatch} not found.`);
  }

  return tableValueState;
}
