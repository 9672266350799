import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  TableContainer,
  Backdrop,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { downloadExcelTemplate } from '../../Template/ExcelTemplates/ExcelTemplate';
import * as XLSX from 'xlsx';
import { FadeLoader } from 'react-spinners';
// ========Modal================
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
/*------------api call--------------*/
import {
  Equipmentsitedropdown,
  postExpecteddateSave,
  putExpecteddateUpdate,
  fetchExpectedData,
  postExpectedexceldata,
} from '../../Api/ExpectedDateApi';
import {
  fetchSiteDetails}from '../../Api/ReportApi'
import { getMonthList } from '../../Api/DataLoggerAPi';
import TableTemplate from '../../Template/TableTemplate';
import Download from '../../Template/Excel/Download';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { Form, Formik } from 'formik';
import { ErrorConfigSchema } from '../util/ValidateSchema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Statusdropdown } from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import Accordion from '@mui/material/Accordion';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import errorMsg from '../util/errorMessage.json';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CheckForLight } from '../util/common/CommanFunction';
export default function Expecteddata({ errorConfiguration }) {
  /*------------state-----------------*/
  const [expectedata, setExpectData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [existingMonths, setExistingMonths] = useState([]);
  const [activeFlag, setactiveFlag] = useState('');
  const [statusDrop, setStatusDrop] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const [errorIndex, setErrorIndex] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [sitestatus, setSitestatus] = useState('');
  const [siteExpect, setsiteExpect] = useState('');
  const [replace, setReplace] = useState(null);
  const [siteName, setSiteNameList] = useState([]);
  const [category, setCategory] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [toBackend, setToBackend] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [indexVal, setIndexVal] = useState(null);
  const [errorVal, setErrorVal] = useState(null);
  const [siteTab, setSiteTab] = React.useState('');
  const initalState = {
    siteName: '',
    expectedGeneration: '',
    month: '',
    expectedGenerationMonth: '',
    month: '',
    status: '',
    lastUpdatedBy: '',
    equipmentTypeId: '',
    customerName: [],
    categoryId: '',
    equipmentType: '',
  };
  const [expectfields, setExpectFields] = useState([initalState]);
  const [stdField, setstdField] = useState(initalState);
  const [disabledSiteName, setDisabledSiteName] = useState(false);
  const [SiteTypeIdFilter, setSiteTypeIdFilter] = useState([]);
  const [expectedGeneration, setExpectedGeneration] = useState([]);
  const [expectedMonth, setExpectedMonth] = useState([]);
  const [autocompleteDefaultValues, setAutocompleteDefaultValues] =
    useState(expectedMonth);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  useEffect(() => {
    getMonth();
  }, []);
  const getMonth = async () => {
    const getmonthdata = await getMonthList();
    setExpectedMonth(getmonthdata);
  };
  const handleMonth = (newMonth, event, index, defaultValue) => {
    setExpectedGeneration((prevData) => ({
      ...prevData,
      month: newMonth.monthName,
    }));
  };
  const handleChangeData = (event, index) => {
    const newValue = Number(event.target.value);
    setExpectedGeneration((prevData) => ({
      ...prevData,
      expectedGeneration: newValue,
    }));
  };
  console.log(expectedGeneration, 'expectedGeneration>>>>>>>>>>>>>>>>>>>>>>');
  const typoStyle = {
    textTransform: 'capitalize',
    opacity: '0.8',
    fontSize: '15px',
  };
  const requiredHeader = ['Site Name', 'Month', 'Expected Data'];
  const isOEM = sessionStorage.getItem('userTypeId') == 1;
  /*--------------------- Accept Numeric Only ---------------------*/
  const handleInput = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, '');
    if (input.length > 50) {
      input = input.slice(0, 10);
    }
    event.target.value = input;
  };
  /*----------------------------Fields-------------------------------*/
  const [clickCount, setClickCount] = useState(1);
  const maxFields = 12;
  const handleAddButtonClick = () => {
    if (clickCount < maxFields) {
      const newClickCount = clickCount + 1;
      setClickCount(newClickCount);
      setExpectFields(expectfields.concat(initalState));
      const defaultMonth =
        expectedMonth[autocompleteDefaultValues.length]?.label;
      const newDefaultValues = [...autocompleteDefaultValues, defaultMonth];
      setAutocompleteDefaultValues(newDefaultValues);
    }
  };
  const handleRemoveButtonClick = (indices) => {
    if (clickCount > 1) {
      setClickCount(clickCount - 1);
    }
    expectfields.splice(indices, 1);
  };
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchStatus();
    fetchSitenameList();
    setSitestatus();
    setSiteNameList();
    setExpectedMonth();
    setExpectedGeneration();
  }, []);
  useEffect(() => {
    if (siteExpect !== '') {
      let SiteTypeIdsFilter = siteName?.filter((data) => {
        return data.siteName === siteExpect;
      });
      let siteExpectId = SiteTypeIdsFilter[0]?.siteId;
      getExpectData(siteExpectId);
    }
  }, [siteExpect, siteName]);
  /*-------------------------Table get call --------------------------------------------*/
  const getExpectData = async (Id) => {
    setloading(true);
    try {
      let id = Id;
      if (Array.isArray(Id)) {
        id = Id[0]?.siteId;
      }
      const data = await fetchExpectedData(id);
      if (Array.isArray(data)) {
        setExpectData(data);

        const months = data.map((item) => item.month);

        setExistingMonths(months); // Store the existing months
      } else if (typeof data === 'object' && data !== null) {
        setExpectData([data]);
        setExistingMonths([data.month]); // Store the existing month
      }
      setloading(false);
    } catch (e) {
      console.error(e);
    }
  };
  /*----------------------- handle save --------------------*/
  const handleSave = async (values) => {
    let siteIdFilter = siteName?.filter((data) => {
      return data.siteName === stdField.siteName;
    });
    const selectedMonth = values.month;
    if (existingMonths.includes(selectedMonth)) {
      setSnack('This month has already been selected for the site.');
      return;
    }
    let arr = [];
    arr.push(expectedGeneration);
    let data = {
      siteId: siteIdFilter[0]?.siteId,
      status: 1,
      // expectedData: expectedGeneration,
      expectedData: arr,
    };
    console.log(data, 'data');
    try {
      setToBackend(true);
      const responseData = await postExpecteddateSave(data);
      // fetchData();
      getExpectData(siteName);
      setSnack({
        ...snack,
        open: true,
        severity: 'success',
        message: responseData.statusDescription,
      });
      handleClose();
      setToBackend(false);
      setOpen(false);
      setExistingMonths((prevExistingMonths) => [
        ...prevExistingMonths,
        selectedMonth,
      ]);
      return 1;
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error('Error:', e.response.status);
      setToBackend(false);
      return 0;
    }
  };
  /*----------------------- handle Update --------------------*/
  const handleUpdate = async (values) => {
    let id = editcall?.errorId;
    let siteIdFilter = siteName?.filter((data) => {
      return data.siteName === stdField.siteName;
    });
    let data = {
      siteId: siteIdFilter[0]?.siteId,
      status: 1,
      expectedData: expectedGeneration,
    };
    try {
      setToBackend(true);
      let responsedata = await putExpecteddateUpdate(data, id);
      // fetchData();
      const updatedTable = replaceObjectById(
        expectedata,
        'errorId',
        id,
        responsedata
      );
      setSnack(errorMsg.success);
      handleClose();
      setToBackend(false);
      return 1;
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error('Error:', e.response.status);
      setToBackend(false);
      return 0;
    }
  };
  const fetchSitenameList = async () => {
    const data = await   fetchSiteDetails();
    if (Array.isArray(data)) {
      setSiteNameList(data);
    } else if (typeof data === 'object' && data !== null) {
      setSiteNameList([data]);
    }
    getExpectData(data);
  };
  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  const handleFileUpload = (e) => {
    setOpen(true);
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const headers = [];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeader
          .map((header) => header.toLowerCase().replace(/ /g, ''))
          .every((header) =>
            headers
              .map((head) => head.toLowerCase().replace(/ /g, ''))
              .includes(header)
          );
        const updatedJsonData = jsonData.map((row) => {
          requiredHeader.forEach((header) => {
            if (!row.hasOwnProperty(header)) {
              row[header] = ''; // Add missing header with empty string value
            }
          });
          return row;
        });
        if (!headersPresented || jsonData.length === 0) {
          console.log('headersPresented', !headersPresented);
          setSnack({
            ...snack,
            open: true,
            severity: 'error',
            message: !headersPresented
              ? 'Enter the correct format'
              : jsonData.length === 0
                ? "Excel couldn't be empty"
                : null,
          });
          setExcelOpen(false);
          handleClose();
        } else {
          setExcelOpen(true);
          setSelectedFileData(updatedJsonData);
        }

        const errors = [];
        jsonData.forEach((row, rowIndex) => {
          let header = Object.keys(row);
          let cellValue = Object.values(row);
          cellValue.forEach((value, cellIndex) => {
            let columnName = header[cellIndex];
            if (value === undefined || value === null || value === '') {
              errors.push({ rowIndex, columnName });
            }
          });
        });
        setErrorIndex(errors);
      };
      reader.readAsBinaryString(file);
    }
  };
  const handleExcelUpload = async () => {
    const file = selectedFile;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const errors = [];
        selectedFileData.forEach((row) => {
          const siteName = row[requiredHeader[0]].length;
          const month = row[requiredHeader[1]].length;
          const expectedGeneration = row[requiredHeader[2]]?.toString().length;
          const validationForm = [
            {
              condition: siteName && siteName > 50,
              errorMessage: 'Site Name cannot exceed 50 characters.',
            },
            {
              condition: month && month > 100,
              errorMessage: 'Month cannot exceed 100 characters.',
            },
            {
              condition: expectedGeneration && expectedGeneration > 50,
              errorMessage:
                'Expected Data Generation message cannot exceed 50 characters.',
            },
          ];
          validationForm.forEach((validation) => {
            console.log('validation.condition:', validation.condition);
            if (validation.condition) {
              errors.push(validation.errorMessage);
            }
          });
        });
        console.log(errors, 'errors');
        const processedData = jsonData.map((item) => {
          const lowercaseItem = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              lowercaseItem[key.toLowerCase().replace(/\s/g, '')] =
                typeof item[key] === 'string' ? item[key].trim() : item[key];
            }
          }
          const customerName = !isOEM
            ? Number(sessionStorage.getItem('customerName'))
            : lowercaseItem.customername;
          console.log(lowercaseItem, 'lower');
          let data = {
            siteName: lowercaseItem.sitename,
            month: lowercaseItem?.month,
            createdBy: Number(sessionStorage.getItem('id')),
            expectedGeneration: Number(lowercaseItem?.expecteddata),
            // expectedGenerationMonth: lowercaseItem?.expecteddatageneration || 0,
            status: 1,
            customerName: customerName,
          };
          console.log(lowercaseItem, 'lowercaseItem');
          return data;
        });
        console.log(processedData, 'processedData');
        try {
          if (errorIndex.length > 0) {
            setSnack({
              ...snack,
              open: true,
              severity: 'error',
              message: `${errorIndex[0].columnName} is missing`,
            });
          } else if (errors.length > 0) {
            setSnack({
              ...snack,
              open: true,
              severity: 'error',
              message: `${errors[0]}`,
            });
          } else {
            const responseData = await postExpectedexceldata(processedData);
            if (responseData.status === 200) {
              // fetchData();
              getExpectData(siteName);
              handleClose();
              setSnack(errorMsg.success);
            }
            console.log(responseData, 'responseData');
          }
        } catch (e) {
          if (e.response.status === 400) {
            console.log('error is 400');
            const errorResponse = e.response.data;
            const errorValue = Object.keys(errorResponse).map(
              (key) => errorResponse[key]?.error
            );
            setErrorVal(errorValue);
            console.log(errorValue, 'errorResponse');
            const indexValue = Object.keys(errorResponse).map(
              (key) => errorResponse[key]?.index
            );
            setIndexVal(indexValue);
            console.log(indexValue);
            if (errorValue?.length == 1) {
              setSnack({
                ...snack,
                open: true,
                severity: 'error',
                message: errorValue,
              });
            } else {
              setSnack(errorMsg.failure);
            }
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  };
  const handleSite = (data, event) => {
    setSitestatus(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      siteName: data.target.innerHTML,
    }));
  };
  /*---------------------------------- New Error Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall('');
  };
  const handleExpect = (value, event) => {
    if (value === null) {
      setsiteExpect('');
    } else {
      setsiteExpect(value);
    }
  };
  console.log(siteExpect);
  
  const handleClose = () => {
    setSelectedFileData(null);
    setstdField(initalState);
    seteditcall('');
    setCategory('');
    setactiveFlag('');
    setModalvalue(true);
    setEdit(false);
    setOpen(false);
    setReplace(null);
    setClickCount(1);
  };
  const styleTable = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
  };
  const handleEditOpen = async (errorId) => {
    let filterederrorId = Object.keys(initalState).reduce((acc, key) => {
      if (errorId.hasOwnProperty(key)) {
        acc[key] = errorId[key];
      }
      return acc;
    }, {});
    seteditcall(errorId);
    setstdField(filterederrorId);
    setCategory(errorId.equipmentCategory);
    setEdit(true);
    setOpen(true);
  };
  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'ExpectedData');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const headCells = [
    {
      label: 'Site Name',
      id: 'siteName',
      view: true,
      default: true,
    },
    {
      label: 'Month',
      id: 'month',
      view: true,
      default: true,
    },
    {
      label: 'Expected Data',
      id: 'expectedGenerationMonth',
      view: true,
      default: true,
    },
    {
      label: 'GlobHor',
      id: 'globHor',
      view: true,
      default: true,
    },
    {
      label: 'DiffHor',
      id: 'diffHor',
      view: true,
      default: true,
    },
    {
      label: 'Amb Temperature',
      id: 'ambTemperature',
      view: true,
      default: true,
    },
    {
      label: 'Globinc',
      id: 'globinc',
      view: true,
      default: true,
    },
    {
      label: 'GlobEff',
      id: 'globEff',
      view: true,
      default: true,
    },
    {
      label: 'EArray',
      id: 'eArray',
      view: true,
      default: true,
    },
    {
      label: 'E_Grid',
      id: 'eGris',
      view: true,
      default: true,
    },
    {
      label: 'Average PR',
      id: 'averagePR',
      view: true,
      default: true,
    },
    {
      label: 'Irradiation',
      id: 'irradiation',
      view: true,
      default: true,
    },
    {
      label: 'Specific Yield',
      id: 'specificYield',
      view: true,
      default: true,
    },
    {
      label: 'Soiling Losses',
      id: 'soilingLosses',
      view: true,
      default: true,
    },
  ];
  const tableValues = expectedata.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  useEffect(() => {
    const SiteTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory == category;
    });
    setSiteTypeIdFilter(SiteTypeIdFilter);
  }, [category, categoryvalue, stdField.status]);
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Expected Data', path: 'expecteddata' },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Formik
            key={edit ? 'edit' : 'create'}
            enableReinitialize={true}
            initialValues={expectfields}
            validationSchema={ErrorConfigSchema}
            onSubmit={async (values, { resetForm }) => {
              let submitValue = values;
              if (edit) {
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              } else {
                const result = await handleSave(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                <div>
                  {/* ---------   -----save andupdate---- popup----------- */}

                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalExpectedData'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={
                              edit ? 'Update Expected Data' : 'Add Generation'
                            }
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          <div className="modelGrid">
                            <Card
                              sx={{
                                overflowY: 'visible',
                                marginLeft: '-1vw',
                                // paddingLeft: '-1vw',
                                marginTop: '-2%',
                                // marginBottom: '-2%',
                                scrollBehavior: 'smooth',
                                scrollbarGutter: 'stable',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                  width: '0.4em',
                                },
                                '&::-webkit-scrollbar-track': {
                                  background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: '#888',
                                  borderRadius: '20px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                  background: '#555',
                                },
                              }}
                              elevation={0}
                            >
                              <CardContent sx={{ marginLeft: '1%' }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <div
                                      style={{ minWidth: 200, padding: '10px' }}
                                    >
                                      <Typography style={typoStyle}>
                                        {' '}
                                        Site Name{' '}
                                      </Typography>
                                      <Autocomplete
                                        size="small"
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo"
                                        disabled={edit ? true : false}
                                        options={siteName.map(
                                          (option) => option.siteName
                                        )}
                                        onChange={(data, event) =>
                                          handleSite(data, event)
                                        }
                                        sx={{ width: '12vw' }}
                                        ListboxProps={{
                                          style: {
                                            maxHeight: '200px', // Set your desired height here
                                          },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="IFP"
                                            disabled={disabledSiteName}
                                          />
                                        )}
                                        classes={{ option: 'autocomplete' }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={14}></Grid>

                                  <Card
                                    sx={{
                                      width: '100%',
                                      height: '26vh',
                                      marginTop:"-2%",
                                      overflowY:
                                        clickCount < 3 ? 'visible' : 'scroll',
                                      border: 'none',
                                      boxShadow: 'none',
                                    }}
                                    elevation={0}
                                  >
                                    <CardContent
                                      sx={{
                                        width: '100%',
                                        height: '2%',
                                        boxShadow: 'none',
                                        border: 'none',
                                      }}
                                    >
                                      {[...Array(clickCount)].map(
                                        (_, index) => (
                                          <Grid
                                            item
                                            xs={10}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              marginTop: '1.5%',
                                            }}
                                          >
                                            <Grid>
                                              <Grid>
                                                <div>
                                                  <Accordion
                                                  // expanded={expanded}
                                                  // onChange={handleChange}
                                                  // sx={{ boxShadow: 'none', border: 'none' }}
                                                  >
                                                    <AccordionSummary
                                                      expandIcon={
                                                        <ExpandMoreIcon />
                                                      }
                                                      elevation={22}
                                                    >
                                                      <Grid xs={5}>
                                                        <div
                                                          style={{
                                                            padding: '10px',
                                                          }}
                                                        >
                                                          <Typography
                                                            style={typoStyle}
                                                          >
                                                            Expected Data Month
                                                          </Typography>
                                                          <Autocomplete
                                                            size="small"
                                                            disablePortal
                                                            disableClearable
                                                            id="combo-box-demo"
                                                            onChange={(
                                                              event,
                                                              value
                                                            ) =>
                                                              handleMonth(
                                                                value,
                                                                event,
                                                                index,
                                                                expectedMonth[
                                                                  index
                                                                ]?.monthName
                                                              )
                                                            }
                                                            disabled={
                                                              edit
                                                                ? true
                                                                : false
                                                            }
                                                            options={expectedMonth?.map(
                                                              (data) => ({
                                                                monthName:
                                                                  data.monthName,
                                                                disabled:
                                                                  existingMonths.includes(
                                                                    data.monthName
                                                                  ),
                                                              })
                                                            )}
                                                            getOptionDisabled={(
                                                              option
                                                            ) =>
                                                              option.disabled
                                                            }
                                                            getOptionLabel={(
                                                              option
                                                            ) =>
                                                              option.monthName
                                                            }
                                                            renderOption={(
                                                              props,
                                                              option
                                                            ) => (
                                                              <li
                                                                {...props}
                                                                style={{
                                                                  pointerEvents:
                                                                    option.disabled
                                                                      ? 'none'
                                                                      : 'auto',
                                                                  opacity:
                                                                    option.disabled
                                                                      ? 0.5
                                                                      : 1,
                                                                }}
                                                              >
                                                                {
                                                                  option.monthName
                                                                }
                                                              </li>
                                                            )}
                                                            sx={{
                                                              width: '12vw',
                                                            }}
                                                            ListboxProps={{
                                                              style: {
                                                                maxHeight:
                                                                  '200px',
                                                              },
                                                            }}
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                                placeholder="E.g: IFP"
                                                                disabled={
                                                                  disabledSiteName
                                                                }
                                                              />
                                                            )}
                                                            classes={{
                                                              option:
                                                                'autocomplete',
                                                            }}
                                                          />
                                                        </div>
                                                      </Grid>
                                                      <Grid item xs={5}>
                                                        <div
                                                          style={{
                                                            padding: '10px',
                                                          }}
                                                        >
                                                          <Typography
                                                            style={typoStyle}
                                                          >
                                                            Expected Data
                                                          </Typography>
                                                          <TextField
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size="small"
                                                            name="expectedData"
                                                            placeholder="E.g: 1"
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleChangeData(
                                                                event,
                                                                index
                                                              );
                                                              handleInput(
                                                                event
                                                              );
                                                            }}
                                                            sx={{
                                                              width: '12vw',
                                                            }}
                                                            ListboxProps={{
                                                              style: {
                                                                maxHeight:
                                                                  '200px',
                                                              },
                                                            }}
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                                placeholder="Select  Site ..."
                                                              />
                                                            )}
                                                            classes={{
                                                              option:
                                                                'autocomplete',
                                                            }}
                                                          />
                                                        </div>
                                                      </Grid>
                                                      <Grid item xs={5}>
                                                        <div
                                                          style={{
                                                            padding: '10px',
                                                          }}
                                                        >
                                                          <Typography
                                                            style={typoStyle}
                                                          >
                                                            Average PR
                                                          </Typography>
                                                          <TextField
                                                            id="combo-box-demo"
                                                            size="small"
                                                            name="averagePR"
                                                            placeholder="E.g: 1"
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleChangeData(
                                                                event,
                                                                index
                                                              );
                                                              handleInput(
                                                                event
                                                              );
                                                            }}
                                                            sx={{
                                                              width: '12vw',
                                                            }}
                                                          />
                                                        </div>
                                                      </Grid>
                                                      <Grid item xs={5}>
                                                        <div
                                                          style={{
                                                            padding: '10px',
                                                          }}
                                                        >
                                                          <Typography
                                                            style={typoStyle}
                                                          >
                                                            Irradiation
                                                          </Typography>
                                                          <TextField
                                                            id="combo-box-demo"
                                                            size="small"
                                                            name="irradiation"
                                                            placeholder="E.g: 1"
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleChangeData(
                                                                event,
                                                                index
                                                              );
                                                              handleInput(
                                                                event
                                                              );
                                                            }}
                                                            sx={{
                                                              width: '12vw',
                                                            }}
                                                          />
                                                        </div>
                                                      </Grid>
                                                      <Grid item xs={5}>
                                                        <div
                                                          style={{
                                                            padding: '10px',
                                                          }}
                                                        >
                                                          <Typography
                                                            style={typoStyle}
                                                          >
                                                            Amb Temperature
                                                          </Typography>
                                                          <TextField
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            size="small"
                                                            name="ambTemperature"
                                                            placeholder="E.g: 1"
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              handleChangeData(
                                                                event,
                                                                index
                                                              );
                                                              handleInput(
                                                                event
                                                              );
                                                            }}
                                                            sx={{
                                                              width: '12vw',
                                                            }}
                                                            ListboxProps={{
                                                              style: {
                                                                maxHeight:
                                                                  '200px',
                                                              },
                                                            }}
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                              />
                                                            )}
                                                            classes={{
                                                              option:
                                                                'autocomplete',
                                                            }}
                                                          />
                                                        </div>
                                                      </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{marginTop:"-2%"}}>
                                                      <Grid
                                                        item
                                                        style={{
                                                          display: 'flex',
                                                          justifyContent:
                                                            'center',
                                                          marginTop: '1.5%',
                                                        }}
                                                      >
                                                        <Grid item xs={5}>
                                                          <div
                                                            style={{
                                                              padding: '0px',
                                                            }}
                                                          >
                                                            <Typography
                                                              style={typoStyle}
                                                            >
                                                              GlobHor
                                                            </Typography>
                                                            <TextField
                                                              id="combo-box-demo"
                                                              size="small"
                                                              name="expectedData"
                                                              placeholder="E.g: 1"
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                handleChangeData(
                                                                  event,
                                                                  index
                                                                );
                                                                handleInput(
                                                                  event
                                                                );
                                                              }}
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                            />
                                                          </div>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                          <div
                                                            style={{
                                                              padding: '10px',
                                                            }}
                                                          >
                                                            <Typography
                                                              style={typoStyle}
                                                            >
                                                              DiffHor
                                                            </Typography>
                                                            <TextField
                                                              id="combo-box-demo"
                                                              size="small"
                                                              name="expectedData"
                                                              placeholder="E.g: 1"
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                handleChangeData(
                                                                  event,
                                                                  index
                                                                );
                                                                handleInput(
                                                                  event
                                                                );
                                                              }}
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                            />
                                                          </div>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                          <div
                                                            style={{
                                                              padding: '10px',
                                                            }}
                                                          >
                                                            <Typography
                                                              style={typoStyle}
                                                            >
                                                              Globinc
                                                            </Typography>
                                                            <TextField
                                                              id="combo-box-demo"
                                                              size="small"
                                                              name="averagePR"
                                                              placeholder="E.g: 1"
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                handleChangeData(
                                                                  event,
                                                                  index
                                                                );
                                                                handleInput(
                                                                  event
                                                                );
                                                              }}
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                            />
                                                          </div>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                          <div
                                                            style={{
                                                              padding: '10px',
                                                            }}
                                                          >
                                                            <Typography
                                                              style={typoStyle}
                                                            >
                                                              GlobEff
                                                            </Typography>
                                                            <TextField
                                                              id="combo-box-demo"
                                                              size="small"
                                                              name="irradiation"
                                                              placeholder="E.g: 1"
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                handleChangeData(
                                                                  event,
                                                                  index
                                                                );
                                                                handleInput(
                                                                  event
                                                                );
                                                              }}
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                            />
                                                          </div>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                          <div
                                                            style={{
                                                              padding: '10px',
                                                            }}
                                                          >
                                                            <Typography
                                                              style={typoStyle}
                                                            >
                                                              EArray
                                                            </Typography>
                                                            <TextField
                                                              disablePortal
                                                              id="combo-box-demo"
                                                              size="small"
                                                              name="ambTemperature"
                                                              placeholder="E.g: 1"
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                handleChangeData(
                                                                  event,
                                                                  index
                                                                );
                                                                handleInput(
                                                                  event
                                                                );
                                                              }}
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                              ListboxProps={{
                                                                style: {
                                                                  maxHeight:
                                                                    '200px',
                                                                },
                                                              }}
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                />
                                                              )}
                                                              classes={{
                                                                option:
                                                                  'autocomplete',
                                                              }}
                                                            />
                                                          </div>
                                                        </Grid>
                                                      </Grid>
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </div>
                                              </Grid>
                                            </Grid>

                                            {/* <Grid xs={5}>
                                                <div  style={{padding:"10px"}}>
                                                  <Typography style={typoStyle}>
                                                    Expected Data Month
                                                  </Typography>
                                                  <Autocomplete
                                                    size="small"
                                                    disablePortal
                                                    disableClearable
                                                    id="combo-box-demo"
                                                    onChange={(event, value) =>
                                                      handleMonth(
                                                        value,
                                                        event,
                                                        index,
                                                        expectedMonth[index]
                                                          ?.monthName
                                                      )
                                                    }
                                                    disabled={
                                                      edit ? true : false
                                                    }
                                                    options={expectedMonth?.map(
                                                      (data) => ({
                                                        monthName:
                                                          data.monthName,
                                                        disabled:
                                                          existingMonths.includes(
                                                            data.monthName
                                                          ),
                                                      })
                                                    )}
                                                    getOptionDisabled={(
                                                      option
                                                    ) => option.disabled}
                                                    getOptionLabel={(option) =>
                                                      option.monthName
                                                    }
                                                    renderOption={(
                                                      props,
                                                      option
                                                    ) => (
                                                      <li
                                                        {...props}
                                                        style={{
                                                          pointerEvents:
                                                            option.disabled
                                                              ? "none"
                                                              : "auto",
                                                          opacity:
                                                            option.disabled
                                                              ? 0.5
                                                              : 1,
                                                        }}
                                                      >
                                                        {option.monthName}
                                                      </li>
                                                    )}
                                                    sx={{ width: "12vw" }}
                                                    ListboxProps={{
                                                      style: {
                                                        maxHeight: "200px",
                                                      },
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        placeholder="E.g: IFP"
                                                        disabled={
                                                          disabledSiteName
                                                        }
                                                      />
                                                    )}
                                                    classes={{
                                                      option: "autocomplete",
                                                    }}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={5} >
                                                <div
                                                style={{padding:"10px"}}
                                                 >
                                                  <Typography style={typoStyle}>
                                                    Expected Data
                                                  </Typography>
                                                  <TextField
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size="small"
                                                    name="expectedData"
                                                    placeholder="E.g: 1"
                                                    onChange={(event) => {
                                                      handleChangeData(
                                                        event,
                                                        index
                                                      );
                                                      handleInput(event);
                                                    }}
                                                    sx={{ width: "12vw" }}
                                                    ListboxProps={{
                                                      style: {
                                                        maxHeight: "200px",
                                                      },
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        placeholder="Select  Site ..."
                                                      />
                                                    )}
                                                    classes={{
                                                      option: "autocomplete",
                                                    }}
                                                  />
                                                </div>
                                              </Grid>
                                               <Grid item xs={5}>
                                                <div
                                                 style={{padding:"10px"}}
                                                >
                                                  <Typography style={typoStyle}>
                                                    Average PR
                                                  </Typography>
                                                  <TextField
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size="small"
                                                    name="averagePR"
                                                    placeholder="E.g: 1"
                                                    onChange={(event) => {
                                                      handleChangeData(
                                                        event,
                                                        index
                                                      );
                                                      handleInput(event);
                                                    }}
                                                    sx={{ width: "12vw" }}
                                                    ListboxProps={{
                                                      style: {
                                                        maxHeight: "200px",
                                                      },
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField {...params} />
                                                    )}
                                                    classes={{
                                                      option: "autocomplete",
                                                    }}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={5}>
                                                <div
                                                 style={{padding:"10px"}}
                                                >
                                                  <Typography style={typoStyle}>
                                                    Irradiation
                                                  </Typography>
                                                  <TextField
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size="small"
                                                    name="irradiation"
                                                    placeholder="E.g: 1"
                                                    onChange={(event) => {
                                                      handleChangeData(
                                                        event,
                                                        index
                                                      );
                                                      handleInput(event);
                                                    }}
                                                    sx={{ width: "12vw" }}
                                                    ListboxProps={{
                                                      style: {
                                                        maxHeight: "200px",
                                                      },
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField {...params} />
                                                    )}
                                                    classes={{
                                                      option: "autocomplete",
                                                    }}
                                                  />
                                                </div>
                                              </Grid> 
                                              <Grid item xs={5} >
                                                <div
                                                      style={{padding:"10px"}}
                                                >
                                                  <Typography style={typoStyle}>
                                                    Amb Temperature
                                                  </Typography>
                                                  <TextField
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size="small"
                                                    name="ambTemperature"
                                                    placeholder="E.g: 1"
                                                    onChange={(event) => {
                                                      handleChangeData(
                                                        event,
                                                        index
                                                      );
                                                      handleInput(event);
                                                    }}
                                                    sx={{ width: "12vw" }}
                                                    ListboxProps={{
                                                      style: {
                                                        maxHeight: "200px",
                                                      },
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField {...params} />
                                                    )}
                                                    classes={{
                                                      option: "autocomplete",
                                                    }}
                                                  />
                                                </div>
                                              </Grid> */}

                                            <Grid item xs={4}>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                                  marginTop: '7px',
                                                }}
                                              >
                                                {edit === false ? (
                                                  <div>
                                                    <Typography
                                                      style={{
                                                        color: '#000440',
                                                        margin: '24px 0 0 33px',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      {index === 0 ? (
                                                        <>
                                                          <div
                                                            style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                'center',
                                                              margin: '0 0 0 0',
                                                            }}
                                                          >
                                                            <AddIcon
                                                              style={{
                                                                color:
                                                                  CheckForLight()
                                                                    ? 'black'
                                                                    : 'white',
                                                              }}
                                                              onClick={
                                                                handleAddButtonClick
                                                              }
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <CloseIcon
                                                          style={{
                                                            color:
                                                              CheckForLight()
                                                                ? 'black'
                                                                : 'white',
                                                          }}
                                                          onClick={() =>
                                                            handleRemoveButtonClick(
                                                              index
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </Typography>
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        )
                                      )}
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </CardContent>
                            </Card>
                          </div>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </Form>
            )}
          </Formik>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: '-0.5%' }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={'Expected Data'}
              uploadButton={true}
              addButton={'Error'}
              header={headCells}
              rowsValue={tableValues}
              siteExpect={siteExpect}
              userRole={errorConfiguration[0]}
              handleChange={handleChangeTab}
              handleDownloadTemplate={() =>
                downloadExcelTemplate('Expected Data', isOEM ? true : false)
              }
              handleUploadProp={(e) => handleFileUpload(e)}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              handleSiteChangeExpect={(val) => handleExpect(val)}
              siteNameList={siteName}
              fallbackText={'No expected data has been created yet'}
            />
          </div>
        </div>
      )}
    </div>
  );
}
