import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import "./Customer.css";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { customerListSchmea } from '../util/ValidateSchema';
/*----------------------------- Mui Compoennts -------------------------------------*/

import {
  Box,
  Backdrop,
  Modal,
  Button,
  Typography,
  Paper,
  TextField,
  Autocomplete,
  Stack,
  Grid,
  Fade,
  Divider,
  Card,
  CardContent,
  CircularProgress,
  Tooltip,
  CardHeader,
} from '@mui/material';

import FadeLoader from 'react-spinners/FadeLoader';
/*------------------------------- Mui icons ----------------------------*/
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import lightThemeIcon from '../../images/headerLogo.svg';
/*---------------------- External component -------------*/
import {
  fetchCustomerTablevalue,
  putCustomerupdate,
  postCustomerSave,
  fetchCustomerTablevalueById,
} from '../../Api/CustomerconfigApi';
import AdditionalFields from '../util/components/AdditionalFields';
import TableTemplate from '../../Template/TableTemplate';
import Download from '../../Template/Excel/Download';
import { CustomerList } from '../util/TextField';
import CustomSnackbar from '../util/components/CustomSnackbar';
import {
  CountryDropdown,
  StateDropdown,
  Statusdropdown,
} from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import featherUpload from '../../images/feather_upload-cloud.svg';
import ImageCropper from '../util/components/Cropper';
import errorMsg from '../util/errorMessage.json';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import { FetchSubscriptionType } from '../../Api/SignupApi';
import { CheckForLight } from '../util/common/CommanFunction';

export default function Customerlist({ customerConfiguration }) {
  const navigate = useNavigate();
  const [tableValue, setTableValue] = useState([]);
  const [base64Image, setBase64Image] = useState('');
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [open1, openchange] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [orderBy, setOrderBy] = React.useState('calories');
  const [siteTab, setSiteTab] = React.useState('');
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState('asc');
  const [Statusvalue, setStatus] = useState();
  const [Statevalue, setState] = useState();
  const [editValue, setEditValue] = useState();
  const [editState, setEditState] = useState(false);
  const [tableScreen, setTableParams] = useState('');
  const [subscription, setSupscription] = useState([]);
  const [crop, setCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [statusDrop, setStatusDrop] = useState([]);
  const [text, setTextField] = useState([]);
  const [countryDrop, setCountryDrop] = useState([]);
  const [stateDrop, setStateDrop] = useState([]);
  const [selectedImgName, setSelectedImgName] = useState('');
  const [addHeadCells, setAddHeadCells] = useState([]);
  const [secMsg, setSecMsg] = useState(false);
  const initialState = {
    state: '',
    address: '',
    customerLogoPath: '',
    customerWebsite: '',
    contactPerson: '',
    emailId: '',
    mobileNumber: '',
    customerName: '',
    country: '',
    createdBy: '',
    telePhoneNumber: '',
    status: '',
    subscriptionName: '',
    customeradditionalfield: [],
  };
  const [fieldValue, setFieldValue] = useState(initialState);
  const [toBackend, setToBackend] = useState(false);
  const [editied, setEditied] = useState(false);
  const initialSnack = { open: false, severity: '', message: '' };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  // const [fieldValue, setFieldValue] = useState([])

  const allowedKeys = ['fieldName', 'value', 'status'];

  useEffect(() => {
    fetchData();
    fetchStatus();
    getCountryList();
  }, []);

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };

  useEffect(() => {
    let data = CustomerList();
    fetchSubscription();
    setTextField(data);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchCustomerTablevalueById();
      data.customeradditionalfield?.forEach((element) => {
        data[element.fieldName] = element.value;
      });
      if (Array.isArray(data)) {
        setTableValue(data);
        setLoading(false);
      } else if (typeof data === 'object' && data !== null) {
        setTableValue([data]);
      }
      if (sessionStorage.getItem('firstTime') == 1) {
        console.log(data);
        handleEditOpen(data);
        sessionStorage.removeItem('firstTime');
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  console.log(tableValue, 'fetchDatafetchData');

  useEffect(() => {
    if (fieldValue.country !== '') {
      let countryID = countryDrop?.filter((data) => {
        return data.countryName === fieldValue?.country;
      });
      getStateList(countryID[0]?.countryId);
    }
  }, [fieldValue.country]);

  const getStateList = async (Id) => {
    try {
      let data = await StateDropdown(Id);
      setStateDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSubscription = async () => {
    let responseData = await FetchSubscriptionType();
    console.log(responseData, 'subscription');
    setSupscription(responseData?.data);
  };

  const getCountryList = async () => {
    try {
      let data = await CountryDropdown();
      setCountryDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const addAdditionalField = () => {
    const newField = {
      fieldName: '',
      status: 1,
      value: '',
    };

    setFieldValue((prevState) => ({
      ...prevState,
      customeradditionalfield: [...prevState.customeradditionalfield, newField],
    }));
  };

  const deleteAdditionalField = (index) => {
    const updatedFields = [...fieldValue.customeradditionalfield];
    updatedFields.splice(index, 1);
    setFieldValue((prevState) => ({
      ...prevState,
      customeradditionalfield: updatedFields,
    }));
  };
  const handleDropdownChange = (event, value, name) => {
    // console.log(event, name, value.value);
    if (name === 'country') {
      setFieldValue({ ...fieldValue, [name]: value, state: '' });
    } else {
      setFieldValue({ ...fieldValue, [name]: value });
    }
  };
  const handleSubmit = async (values) => {
    let imagedata = croppedImageUrl.split('base64,')[1];
    // let status = statusDrop?.filter((data) => {
    //   return data.label === values.status;
    // });
    console.log(values, 'values');
    let subscriptionId = subscription.filter(
      (i) => i.subscriptionName === values.subscriptionName
    );
    let userAddField = [];
    fieldValue.customeradditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        userAddField.push({
          createdBy: Number(sessionStorage.getItem('id')),
          fieldName: obj.fieldName,
          status: Number(obj.status),
          value: obj.value,
        });
      }
    });
    let data =
      imagedata !== undefined
        ? {
            state: values?.state,
            address: values?.address,
            country: values?.country,
            contactPerson: values?.contactPerson,
            customerName: values?.customerName,
            companyId: sessionStorage.getItem('companyId'),
            createdBy: sessionStorage.getItem('id'),
            image: `${imagedata}`,
            logoName: selectedImgName,
            customerWebsite: values?.customerWebsite,
            mobileNumber: values?.mobileNumber,
            subscriptionId: subscriptionId[0].subscriptionId,
            // telePhoneNumber: values?.telePhoneNumber,
            emailId: values?.emailId,
            status: 1,
            customeradditionalfield: userAddField,
          }
        : {
            state: values?.state,
            address: values?.address,
            country: values?.country,
            contactPerson: values?.contactPerson,
            customerName: values?.customerName,
            companyId: sessionStorage.getItem('companyId'),
            createdBy: sessionStorage.getItem('id'),
            customerLogoPath: null,
            customerWebsite: values?.customerWebsite,
            mobileNumber: values?.mobileNumber,
            subscriptionId: subscriptionId[0].subscriptionId,
            // telePhoneNumber: values?.telePhoneNumber,
            emailId: values?.emailId,
            status: 1,
            customeradditionalfield: userAddField,
          };
    console.log(data, 'data to be submit');
    try {
      setToBackend(true);
      const responseData = await postCustomerSave(data);
      fetchData();
      handleClose();
      setSecMsg(true);
      setSnack({
        ...snack,
        open: true,
        severity: 'success',
        message: responseData.statusDescription,
      });
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setSecMsg(false);
      setSnack(errorMsg.firstLogin);
      setToBackend(false);
      console.log(responseData, 'responseData');
      return 1;
    } catch (e) {
      console.log(e, 'hhhhh');
      if (e.response.status === 406) {
        setSnack({
          ...snack,
          open: true,
          severity: 'error',
          message: e.response.data.statusDescription,
        });
        console.error('Error:', e.response.status);
        setToBackend(false);
      } else {
        setSnack(errorMsg.failure);
        console.error('Error:', e.response.status);
        setToBackend(false);
        return 0;
      }
    }
  };

  const handleUpdate = async (values) => {
    let id = editValue?.customerId;
    console.log(fieldValue);
    let imagedata = croppedImageUrl.split('base64,')[1];
    console.log(imagedata, 'imagedata');
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    // console.log(values, 'values');

    let userAddField = [];
    fieldValue.customeradditionalfield.forEach((obj) => {
      console.log(obj, 'obj.fieldName && obj.fieldId');
      if (obj.fieldName && obj.value) {
        if (obj.fieldId) {
          userAddField.push({
            lastUpdatedBy: sessionStorage.getItem('id'),
            fieldId: obj.fieldId,
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        } else {
          userAddField.push({
            createdBy: Number(sessionStorage.getItem('id')),
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        }
      }
    });
    let obj;
    if (imagedata) {
      obj = {
        state: values?.state,
        address: values?.address,
        country: values?.country,
        contactPerson: values?.contactPerson,
        customerName: values?.customerName,
        lastUpdatedBy: sessionStorage.getItem('id'),
        logoName: selectedImgName,
        customerLogoPath:
          editValue.customerLogoPath === undefined ||
          editValue.customerLogoPath === null ||
          editValue.customerLogoPath === 'null'
            ? 'null'
            : editValue.customerLogoPath,
        image: `${imagedata}`,
        customerWebsite: values?.customerWebsite,
        mobileNumber: values?.mobileNumber,
        // telePhoneNumber: values?.telePhoneNumber,
        emailId: values?.emailId,
        status: editState
          ? typeof values.status === 'string'
            ? status[0].value
            : values.status
          : status[0].value,
        customeradditionalfield: userAddField,
      };
    } else {
      obj = {
        state: values?.state,
        address: values?.address,
        country: values?.country,
        contactPerson: values?.contactPerson,
        customerName: values?.customerName,
        lastUpdatedBy: sessionStorage.getItem('id'),
        logoName: selectedImgName,
        customerLogoPath:
          editValue.customerLogoPath === undefined ||
          editValue.customerLogoPath === null ||
          editValue.customerLogoPath === 'null'
            ? 'null'
            : editValue.customerLogoPath,
        customerWebsite: values?.customerWebsite,
        mobileNumber: values?.mobileNumber,
        // telePhoneNumber: values?.telePhoneNumber,
        emailId: values?.emailId,
        status: editState
          ? typeof values.status === 'string'
            ? status[0].value
            : values.status
          : status[0].value,
        customeradditionalfield: userAddField,
      };
    }
    console.log(obj, 'updates');
    try {
      setToBackend(true);
      let responseData = await putCustomerupdate(obj, id);
      console.log(responseData, 'response data');
      if (responseData.customerId == sessionStorage.getItem('customerId')) {
        sessionStorage.removeItem('customerLogoPath');
        sessionStorage.setItem(
          'customerLogoPath',
          responseData.customerLogoPath
        );
      }
      fetchData();
      setEditState(false);
      setSnack(errorMsg.success);
      const updatedTable = replaceObjectById(
        tableValue,
        'customerId',
        id,
        responseData
      );
      handleClose();
      setToBackend(false);
      if (!isOEM) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      return 1;
    } catch (error) {
      console.log(error.response.status);
      
      if (error.response.status === 406) {
        setSnack(errorMsg.unique.userMail);
        console.error('Error:', error.response.status);
        setToBackend(false);
      } else {
        setSnack(errorMsg.failure);
        console.error('Error:', error.response.status);
        setToBackend(false);
      }
      return 0;
    }
  };

  const Modelstyle = {
    position: 'absolute',
    top: '50%', // Adjust the vertical position as needed
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '72%',
    maxHeight: '80vh', // Set a maximum height
    bgcolor: 'background.paper',
    // border: "2px solid #000",
    p: 4,
    overflowY: 'auto', // Enable vertical scrolling if content overflows
  };

  const state = [
    { label: 'Tamil Nadu', value: 'Tamil Nadu' },
    { label: 'Kerala', value: 'Kerala' },
    { label: 'Karanataka', value: 'Karanataka' },
    { label: 'Mumbai', value: 'Mumbai' },
  ];
  const status = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const onComplete = (val) => {
    console.log(val);
    setCroppedImageUrl(val);
    setEditied(true);
    setCrop(false);
    // You can now use 'croppedImageUrl' as the final cropped image
    console.log('Cropped Image:', val);
  };
  const isOEM = sessionStorage.getItem('userTypeId') == 1;
  const handleFinish = () => {
    setCroppedImageUrl(base64Image);
    setCrop(false);
  };
  const endEdit = () => {
    setCrop(false);
    setEditied(false);
  };
  let isLogo = sessionStorage.getItem('customerLogoPath');
  /*----------------- Model open Add / edit -----------------*/

  const handleClose = () => {
    setFieldValue(initialState);
    setSelectedImgName('');
    setBase64Image('');
    setCroppedImageUrl('');
    setStateDrop([]);
    setEditValue([]);
    setStatus();
    setEditState(false);
    setOpen(false);
  };

  /*-------------- Onchange while Add / edit -----------------------*/

  const handleTxtChange = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };
  /*--------------------------- on Submit ------------------------------*/

  const cloudUploadLogo = featherUpload;
  const sortData = (data, sortOrder, field) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];
      if (sortOrder === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else if (sortOrder === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return 0;
    });
    // Update the state with the sorted data
    setTableValue(sortedData);
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    sortData(tableValue, newOrder, property);
  };

  /*----------------------------- Edit open -----------------------------------*/

  const handleEditOpen = async (customerId) => {
    console.log(customerId);
    setFieldValue((prevState) => ({
      ...prevState,
      customeradditionalfield: customerId.customeradditionalfield,
    }));
    setEditValue(customerId);
    setFieldValue(customerId);
    setEditState(true);
    setBase64Image('');
    setOpen(true);
  };
  const handleCreate = () => {
    setOpen(true);
    setEditValue('');
  };

  const handleactiveFlag = (data, value) => {
    console.log(value.value);
    if (value === null) {
      setStatus('');
    } else {
      setStatus(value.value);
    }
  };
  const handleState = (data, value) => {
    if (value === null) {
      setState('');
    } else {
      setState(value.value);
    }
  };

  const handleDownloadTable = async (val) => {
    const status = await Download(val, 'Customer List');
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const closeEditor = () => {
    setCrop(false);
    setBase64Image('');
  };
  console.log('zzz', selectedImgName, 'fileFileFile');
  const handleFileChange = (event) => {
    console.log(event.target.files[0]?.name, 'fileFileFile');
    setSelectedImgName(event.target.files[0]?.name);
    let file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setBase64Image(reader.result);
    };

    let fileSize = event.target.files[0]?.size;
    let fileType = event.target.files[0]?.type;
    console.log(fileSize, 'fileSize');
    if (file) {
      const fileTypeCheck =
        fileType === 'image/png' || fileType === 'image/jpeg';
      const fileSizeCheck = fileSize < 700000;
      if (fileTypeCheck && fileSizeCheck) {
        reader.readAsDataURL(file);
      } else {
        setSnack({
          ...initialSnack,
          open: true,
          severity: 'error',
          message: !fileSizeCheck
            ? 'File Size is too Big'
            : 'File Type is not supported',
        });
      }
    } else {
      setBase64Image('');
    }
    setCrop(true);
  };

  const headCells = [
    {
      label: 'Customer Name',
      id: 'customerName',
      view: true,
      default: true,
    },
    {
      label: 'Email Id',
      id: 'emailId',
      view: true,
      default: true,
    },
    {
      label: 'Address',
      id: 'address',
      view: true,
    },
    {
      label: 'State',
      id: 'state',
      view: true,
    },
    {
      label: 'Contact Person',
      id: 'contactPerson',
      view: true,
      default: true,
    },
    {
      label: 'Phone Number',
      id: 'mobileNumber',
      view: true,
    },
    {
      label: 'Country',
      id: 'country',
      view: false,
    },
    {
      label: 'Created On',
      id: 'createdDate',
      view: false,
    },
    {
      label: 'Status',
      id: 'status',
      view: true,
    },
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
  ];
  useEffect(() => {
    // setLoading(tableValue.length > 0 ? true : false);
    let viewArr = [...headCells];
    const addField = tableValue[0]?.customeradditionalfield;
    console.log(addField, 'addField');
    if (addField?.length > 0) {
      addField.forEach((field) => {
        let key = field.fieldName;
        if (!viewArr.some((item) => item.id === key)) {
          let lastIndex = viewArr.length - 1;
          viewArr.splice(lastIndex, 0, { id: key, label: key });
        }
      });
    } else {
    }
    setAddHeadCells(viewArr);
    // setLoading(false)
  }, [headCells.length, addHeadCells.length, tableValue]);
  console.log(addHeadCells, 'jkdshfsdf');
  const tableValues = tableValue.filter((row) => {
    if (siteTab !== '') {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  console.log(tableValue, tableValues);
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case 'all':
        setSiteTab('');
        break;
      case 'Active':
        setSiteTab(1);
        break;
      case 'Inactive':
        setSiteTab(0);
        break;
      default:
        setSiteTab('');
    }
  };
  const Total = tableValue.length;

  const Active = tableValue.filter((site) => site.status == 1).length;

  const Inactive = tableValue.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: 'All Customer', value: 'all', badgeCount: Total },
    { name: 'Active Customer', value: 'Active', badgeCount: Active },
    {
      name: 'Inactive Customer',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];
  const AddFieldsLength = fieldValue?.customeradditionalfield?.length;

  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case 'status':
        return statusDrop.map((option) => option.label);
      case 'state':
        return stateDrop.map((option) => option.stateName);
      case 'country':
        return countryDrop.map((option) => option.countryName);
      case 'subscriptionName':
        return subscription?.map((item) => item.subscriptionName);
      default:
    }
    return option;
  }
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Customer Configuration', path: 'customerlist' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div className="clist-page">
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={secMsg ? 2000 : 5000}
            severity={snack.severity}
            message={snack.message}
          />
          <TableTemplate
            PageName={'Customer List'}
            addButton={
              sessionStorage.getItem('userTypeId') != 2 ? 'Customer' : null
            }
            SearchLabel={'Search Customer Here... '}
            header={addHeadCells}
            rowsValue={tableValues}
            handleAddPopupOpen={(val) => handleCreate(val)}
            handleEditPopupOpen={(val) => handleEditOpen(val)}
            userRole={customerConfiguration[0]}
            handleDownloadExcel={(val) => {
              handleDownloadTable(val);
            }}
            tabsData={isOEM ? tabsData : null}
            handleChange={handleChangeTab}
            rawData={tableValue}
            paths={paths}
            fallbackText={`No${
              siteTab === 1 ? ' Active' : siteTab === 0 ? ' Inactive' : ''
            } customer has been created yet`}
          />

          {/* --------------------------------------------Model Ui Add / edit ----------------------------------- */}
          <Formik
            key={editState ? 'edit' : 'add'}
            enableReinitialize={true}
            initialValues={fieldValue}
            validationSchema={customerListSchmea}
            onSubmit={async (values, { resetForm }) => {
              let submitValue = values;
              if (editState) {
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              } else {
                const result = handleSubmit(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => {
              console.log(values.customerLogoPath, 'croppedUrl');

              return (
                <Form className="login-access">
                  {editState && editValue.siteName === null ? (
                    <div>
                      <Backdrop
                        sx={{
                          color: '#fff',
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={open}
                      >
                        <FadeLoader color="#000440" loading={true} />
                      </Backdrop>
                    </div>
                  ) : (
                    <div>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                          backdrop: {
                            timeout: 500,
                          },
                        }}
                      >
                        <Fade in={open}>
                          <Box
                            className={'styleModal'}
                            sx={{ outline: 'none' }}
                          >
                            <Card
                              sx={{
                                borderRadius: '20px',
                                // height: '68vh',
                                height: 'fit-content',
                              }}
                            >
                              <CardHeader
                                sx={{
                                  padding: '20px 20px 20px 20px',
                                  background: CheckForLight()
                                    ? 'rgb(246, 248, 252)'
                                    : '#4f4f4f',
                                }}
                                action={
                                  <Stack spacing={2} direction="row">
                                    {editState ? (
                                      <Button
                                        variant="contained"
                                        disabled={toBackend}
                                        style={{
                                          borderRadius: '25px',
                                          textTransform: 'capitalize',
                                        }}
                                        startIcon={
                                          toBackend ? (
                                            <CircularProgress
                                              size={20}
                                              color="inherit"
                                            />
                                          ) : (
                                            <SaveIcon />
                                          )
                                        }
                                        onClick={
                                          toBackend ? null : handleSubmit
                                        }
                                      >
                                        {toBackend ? 'Updating...' : 'Update'}
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        style={{
                                          borderRadius: '25px',
                                          textTransform: 'capitalize',
                                        }}
                                        disabled={toBackend}
                                        startIcon={
                                          toBackend ? (
                                            <CircularProgress
                                              size={20}
                                              color="inherit"
                                            />
                                          ) : (
                                            <SaveIcon />
                                          )
                                        }
                                        onClick={
                                          toBackend ? null : handleSubmit
                                        }
                                      >
                                        {toBackend ? 'Saving...' : 'Save'}
                                      </Button>
                                    )}
                                    <Button
                                      variant="contained"
                                      disabled={toBackend}
                                      style={{
                                        borderRadius: '25px',
                                        textTransform: 'capitalize',
                                      }}
                                      onClick={() => {
                                        handleClose();
                                        handleReset();
                                      }}
                                      startIcon={<CancelIcon />}
                                    >
                                      Cancel
                                    </Button>
                                  </Stack>
                                }
                                title={
                                  editState
                                    ? 'Update Customer'
                                    : 'Add New Customer'
                                }
                              />
                              <Divider sx={{ borderColor: '#888' }} />

                              <div className="card">
                                <Card
                                  sx={{
                                    overflowY:
                                      AddFieldsLength !== 0
                                        ? 'scroll'
                                        : 'visible',
                                    height: '26rem',
                                    scrollBehavior: 'smooth',
                                    scrollbarGutter: 'stable',
                                    scrollbarWidth: 'thin',
                                    '&::-webkit-scrollbar': {
                                      width: '0.4em',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                      background: '#f1f1f1',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                      backgroundColor: '#888',
                                      borderRadius: '20px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                      background: '#555',
                                    },
                                  }}
                                  elevation={0}
                                >
                                  <CardContent sx={{ marginLeft: '2%' }}>
                                    <div className="card-content">
                                      <div className="paper-content">
                                        <Grid
                                          container
                                          spacing={2}
                                          columns={16}
                                        >
                                          {text?.length > 0
                                            ? text.map(
                                                (data, index) =>
                                                  data.view && (
                                                    <Grid item xs={4}>
                                                      <div key={index}>
                                                        <Typography
                                                          variant="body1"
                                                          className="modal-typo"
                                                          gutterBottom
                                                        >
                                                          {data.type !==
                                                            'logo' &&
                                                            data.label}
                                                        </Typography>
                                                        {data.type ===
                                                        'textField' ? (
                                                          <div>
                                                            <Field
                                                              as={TextField}
                                                              id={`outlined-basic-${index}`}
                                                              type={data.type}
                                                              size="small"
                                                              variant="outlined"
                                                              name={data.name}
                                                              disabled={
                                                                toBackend
                                                              }
                                                              inputProps={{
                                                                maxLength:
                                                                  data.length,
                                                              }}
                                                              onChange={(e) => {
                                                                handleChange(e);
                                                                handleTxtChange(
                                                                  e
                                                                );
                                                              }}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                              value={
                                                                values[
                                                                  data.name
                                                                ] === null
                                                                  ? ''
                                                                  : values[
                                                                      data.name
                                                                    ]
                                                              }
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                            />
                                                            <ErrorMessage
                                                              name={data.name}
                                                              component="div"
                                                              className="errorStyle"
                                                              style={{
                                                                color: 'red',
                                                                marginTop: '1%',
                                                                textAlign:
                                                                  'left',
                                                                marginLeft:
                                                                  '0%',
                                                              }}
                                                            />
                                                          </div>
                                                        ) : data.type ===
                                                          'dropdown' ? (
                                                          <>
                                                            <Tooltip
                                                              title={
                                                                !editState &&
                                                                data.name ===
                                                                  'status'
                                                                  ? 'Active'
                                                                  : editState &&
                                                                    data.name ===
                                                                      'status'
                                                                  ? typeof values[
                                                                      data.name
                                                                    ] ===
                                                                    'string'
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                    : values.status ===
                                                                      1
                                                                    ? 'Active'
                                                                    : 'Inactive'
                                                                  : values[
                                                                      data.name
                                                                    ]
                                                              }
                                                            >
                                                              <Field
                                                                name={data.name}
                                                                render={({
                                                                  field,
                                                                  form,
                                                                }) => (
                                                                  <Tooltip
                                                                    title={
                                                                      !editState &&
                                                                      data.name ===
                                                                        'status'
                                                                        ? 'Active'
                                                                        : editState &&
                                                                          data.name ===
                                                                            'status'
                                                                        ? typeof values[
                                                                            data
                                                                              .name
                                                                          ] ===
                                                                          'string'
                                                                          ? values[
                                                                              data
                                                                                .name
                                                                            ]
                                                                          : values.status ===
                                                                            1
                                                                          ? 'Active'
                                                                          : 'Inactive'
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ]
                                                                    }
                                                                  >
                                                                    <Autocomplete
                                                                      disableClearable
                                                                      options={getOptions(
                                                                        data.name
                                                                      )}
                                                                      disablePortal
                                                                      disabled={
                                                                        (!editState &&
                                                                          data.name ===
                                                                            'status') ||
                                                                        (data.name ===
                                                                          'state' &&
                                                                          !fieldValue.country) ||
                                                                        (editState &&
                                                                          data.name ===
                                                                            'subscriptionName') ||
                                                                        toBackend
                                                                      }
                                                                      size="small"
                                                                      id={`combo-box-demo-${index}`}
                                                                      onChange={(
                                                                        event,
                                                                        value
                                                                      ) =>
                                                                        handleDropdownChange(
                                                                          event,
                                                                          value,
                                                                          data.name
                                                                        )
                                                                      }
                                                                      value={
                                                                        !editState &&
                                                                        data.name ===
                                                                          'status'
                                                                          ? 'Active'
                                                                          : editState &&
                                                                            data.name ===
                                                                              'status'
                                                                          ? typeof values[
                                                                              data
                                                                                .name
                                                                            ] ===
                                                                            'string'
                                                                            ? values[
                                                                                data
                                                                                  .name
                                                                              ]
                                                                            : values.status ===
                                                                              1
                                                                            ? 'Active'
                                                                            : 'Inactive'
                                                                          : values[
                                                                              data
                                                                                .name
                                                                            ]
                                                                      }
                                                                      sx={{
                                                                        width:
                                                                          '12vw',
                                                                      }}
                                                                      ListboxProps={{
                                                                        style: {
                                                                          maxHeight:
                                                                            '200px',
                                                                        },
                                                                      }}
                                                                      renderInput={(
                                                                        params
                                                                      ) => (
                                                                        <TextField
                                                                          {...params}
                                                                          style={{
                                                                            userSelect:
                                                                              'none',
                                                                          }}
                                                                          placeholder={
                                                                            data.placeholder
                                                                          }
                                                                        />
                                                                      )}
                                                                    />
                                                                  </Tooltip>
                                                                )}
                                                              />
                                                            </Tooltip>
                                                            <ErrorMessage
                                                              name={data.name}
                                                              component="div"
                                                              className="errorStyle"
                                                              style={{
                                                                color: 'red',
                                                                marginTop: '1%',
                                                                textAlign:
                                                                  'left',
                                                                marginLeft:
                                                                  '0%',
                                                              }}
                                                            />
                                                          </>
                                                        ) : data.type ===
                                                          'number' ? (
                                                          <>
                                                            <Field
                                                              as={TextField}
                                                              id={`outlined-basic-${index}`}
                                                              size="small"
                                                              variant="outlined"
                                                              name={data.name}
                                                              type={data.type}
                                                              disabled={
                                                                toBackend
                                                              }
                                                              onChange={(e) => {
                                                                let numLength;
                                                                if (
                                                                  e.target
                                                                    .name ===
                                                                  'mobileNumber'
                                                                ) {
                                                                  numLength = 10;
                                                                } else {
                                                                  numLength = 7;
                                                                }
                                                                if (
                                                                  e.target.value
                                                                    .length <=
                                                                  numLength
                                                                ) {
                                                                  handleChange(
                                                                    e
                                                                  );
                                                                  handleTxtChange(
                                                                    e
                                                                  );
                                                                }
                                                              }}
                                                              inputProps={{
                                                                maxLength: 10,
                                                              }}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                              value={
                                                                values[
                                                                  data.name
                                                                ] === null
                                                                  ? ''
                                                                  : values[
                                                                      data.name
                                                                    ]
                                                              }
                                                              sx={{
                                                                width: '12vw',
                                                              }}
                                                            />
                                                            <ErrorMessage
                                                              name={data.name}
                                                              component="div"
                                                              className="errorStyle"
                                                              style={{
                                                                color: 'red',
                                                                marginTop: '1%',
                                                                textAlign:
                                                                  'left',
                                                                marginLeft:
                                                                  '0%',
                                                              }}
                                                            />
                                                          </>
                                                        ) : null}
                                                      </div>
                                                    </Grid>
                                                  )
                                              )
                                            : null}
                                        </Grid>
                                      </div>
                                      <Card
                                        sx={{
                                          width: '500px',
                                          height: '70px',
                                          border: '2px solid #d0d0d0',
                                          borderStyle: 'dashed',
                                          borderRadius: '10px',
                                          marginTop: '20px',
                                        }}
                                        elevation={0}
                                      >
                                        <CardContent
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          {(values.customerLogoPath !== '' &&
                                            values.customerLogoPath !== null &&
                                            values.customerLogoPath !==
                                              'null') ||
                                          croppedImageUrl !== '' ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <img
                                                src={
                                                  editState &&
                                                  croppedImageUrl === '' &&
                                                  values.customerLogoPath !==
                                                    'null'
                                                    ? `${
                                                        values.customerLogoPath
                                                      }?${new Date().getTime()}`
                                                    : croppedImageUrl
                                                }
                                                alt="Preview"
                                                style={{
                                                  maxWidth: '100%',
                                                  maxHeight: '6vh',
                                                  marginRight: '10px',
                                                  marginTop: '-5px',
                                                }}
                                              />
                                              <div>
                                                <Typography
                                                  variant="body1"
                                                  gutterBottom
                                                  className="modal-typo"
                                                  style={{ marginTop: '-10px' }}
                                                >
                                                  {editState &&
                                                  croppedImageUrl === ''
                                                    ? 'Existing Logo'
                                                    : 'Upload Logo Preview'}
                                                </Typography>
                                                <Typography className="modal-typo">
                                                  {selectedImgName.length > 0
                                                    ? null
                                                    : values.customerLogoPath
                                                        .substring(
                                                          values.customerLogoPath.lastIndexOf(
                                                            '/'
                                                          ) + 1
                                                        )
                                                        .replace(/%20/g, ' ')
                                                        .replace(/\/\//g, '/')}
                                                </Typography>
                                                <Typography className="modal-typo">
                                                  {selectedImgName.length > 0
                                                    ? selectedImgName
                                                    : ''}
                                                </Typography>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '-10px',
                                              }}
                                            >
                                              <img
                                                src={cloudUploadLogo}
                                                alt=""
                                                style={{ marginRight: '10px' }}
                                              />
                                              <div>
                                                <Typography
                                                  className="modal-typo"
                                                  fontWeight="410"
                                                >
                                                  Select Logo
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    marginTop: '1%',
                                                    fontSize: '12px',
                                                    color: '#788ea2',
                                                    opacity: '0.8',
                                                  }}
                                                >
                                                  JPG or PNG , file size no more
                                                  than 1MB
                                                </Typography>
                                              </div>
                                            </div>
                                          )}
                                          <label
                                            htmlFor="upload-logo"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            <TextField
                                              type="file"
                                              id="upload-logo"
                                              style={{ display: 'none' }}
                                              onChange={(event) => {
                                                handleFileChange(event);
                                              }}
                                            />
                                            <Button
                                              variant="outlined"
                                              size="small"
                                              component="span"
                                              style={
                                                selectedImgName.length > 0
                                                  ? {
                                                      textTransform:
                                                        'capitalize',
                                                      marginTop: '-10px',
                                                    }
                                                  : {
                                                      textTransform:
                                                        'capitalize',
                                                    }
                                              }
                                            >
                                              Select File
                                            </Button>
                                          </label>
                                        </CardContent>
                                      </Card>

                                      {AddFieldsLength !== 0 && (
                                        <>
                                          <Typography
                                            className="modal-head"
                                            style={{ width: '300px' }}
                                            sx={{
                                              marginLeft: '3%',
                                              marginTop: '25px',
                                            }}
                                            gutterBottom
                                          >
                                            Additional Fields
                                          </Typography>
                                          <Grid
                                            container
                                            spacing={3}
                                            columns={30}
                                          >
                                            <Grid
                                              item
                                              xs={8}
                                              sx={{
                                                marginLeft: '3.5%',
                                                marginTop: '5px',
                                              }}
                                            >
                                              <div>
                                                <AdditionalFields
                                                  field={fieldValue}
                                                  setField={setFieldValue}
                                                  allowedKeys={allowedKeys}
                                                  deleteAdditionalField={
                                                    deleteAdditionalField
                                                  }
                                                  fieldToAdd="customeradditionalfield"
                                                />
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </>
                                      )}
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={6}
                                          style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '2.5vh',
                                          }}
                                        >
                                          <div
                                            style={{
                                              textAlign: 'center',
                                              marginTop: '5px',
                                            }}
                                          >
                                            {fieldValue?.customeradditionalfield
                                              ?.length < 5 && (
                                              <Card
                                                sx={{
                                                  width: '200%',
                                                  // marginLeft: '4%',
                                                  height: '45px',
                                                  border: '2px solid #d0d0d0',
                                                  borderStyle: 'dashed',
                                                  borderRadius: '10px',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                }}
                                                elevation={0}
                                              >
                                                <CardContent
                                                  onClick={addAdditionalField}
                                                  sx={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    marginTop: '0.65%',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <AddCircleOutlineSharpIcon
                                                    sx={{
                                                      color: '#71766f',
                                                      marginRight: '10px',
                                                      fontSize: '30px',
                                                    }}
                                                  />
                                                  <Typography
                                                    className="modal-typo"
                                                    fontWeight="410"
                                                    fontSize="20px !important"
                                                  >
                                                    Add Customized Data
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                      {/* </Box> */}
                                    </div>
                                    {base64Image && (
                                      <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={crop}
                                        onClose={closeEditor}
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotProps={{
                                          backdrop: {
                                            timeout: 500,
                                          },
                                        }}
                                      >
                                        <Box
                                          className={'styleModalCropper'}
                                          sx={{ bgcolor: 'background.paper' }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: '100%',
                                              width: '100%',
                                            }}
                                          >
                                            <ImageCropper
                                              imageSrc={base64Image}
                                              onComplete={(val) =>
                                                onComplete(val)
                                              }
                                            />
                                          </div>
                                        </Box>
                                      </Modal>
                                    )}
                                  </CardContent>
                                </Card>
                              </div>
                            </Card>
                          </Box>
                        </Fade>
                      </Modal>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}
