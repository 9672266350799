/**
 * Returns a map of site status values to their corresponding descriptions.
 *
 * @returns {Object} A map of site status values to their descriptions.
 */
export function StatusSiteFlagMaps() {
  /**
   * Map of site status values to their corresponding descriptions.
   * @type {Object}
   */
  const statusSiteFlagMaps = {
    // Inactive status
    0: 'Inactive',
    // Active status
    1: 'Active',
    // Warning status
    2: 'Warning',
    // Offline status
    3: 'Offline',
    // Down status
    4: 'Down',
  };

  return statusSiteFlagMaps;
}

/**
 * Returns a object of capacity category and unit category values for AC and DC.
 *
 * @returns {Object} A object of capacity category and unit category values for AC and DC..
 */
export function CapacityCategoryMaps() {
  /**
   * Returns a object of capacity category values for AC and DC.
   *
   * @returns {Object} A object of capacity category values for AC and DC.
   */
  function mapCategory() {
    // AC categories
    const acCategories = [
      'Inverter Duty Transformer',
      'DG Meter',
      'Grid Meter',
      'RMU',
      'UPS',
      'Water Pumping',
      'RO Plant',
      'LT Panel',
      'HT Panel',
      'Metering Panel',
      'Power Transformer',
      'Auxiliary Transformer',
    ];

    // DC categories
    const dcCategories = [
      'String Monitoring Box',
      'PV Modules',
      'Batteries',
      'Battery Charger',
      'String Combiner',
      'Central Inverter',
    ];

    // Both categories
    const bothCategories = ['String Inverter', 'Central Inverter'];

    return {
      acCategories,
      dcCategories,
      bothCategories,
    };
  }

  /**
   * Returns a object of unit category values for AC and DC.
   *
   * @returns {Object} A object of unit category values for AC and DC.
   */
  function mapUnitCategory() {
    // AC Kw categories
    const acKwCategories = [
      'rmu',
      'ups',
      'dgmeter',
      'gridmeter',
      'meteringpanel',
      'htpanel',
      'ltpanel',
      'roplant',
      'stringinverter',
      'centralinverter',
    ];

    // AC Kva categories
    const acKvaCategories = ['inverterdutytransformer', 'auxiliarytransformer'];

    // DC Kwp categories
    const dcKwpCategories = [
      'stringcombiner',
      'stringinverter',
      'stringmonitoringbox',
      'centralinverter',
    ];

    // DC Vah categories
    const dcVahCategories = ['batterycharger', 'batteries'];

    return {
      acKwCategories,
      acKvaCategories,
      dcKwpCategories,
      dcVahCategories,
    };
  }

  // Map of category values
  const categoryMaps = mapCategory();

  // Map of unit category values
  const unitCategoryMaps = mapUnitCategory();

  return { categoryMaps, unitCategoryMaps };
}
