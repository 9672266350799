import React, { useEffect, useState } from 'react';
import './Loginpage.css';
import Eira from '../../images/eiralogo.jpg';
import {
  Paper,
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

//----------- Icons -----------//
import { AiFillLock } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';

import webdyn from '../../images/headerLogo.svg';
import { PostPassReset } from '../../Api/PasswordControlApi';
import { PassEncrypt } from '../util/security/Cipher';

const ResetPass = () => {
  // const isIdpresent = Boolean(sessionStorage.getItem('id'));
  // useEffect(() => {
  //   if (!isIdpresent) {
  //     navigate('/');
  //   }
  // }, []);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [userName, setEmail] = React.useState('');
  const [Password, setPassword] = React.useState('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [open, setOpen] = React.useState(false);
  const [textValue, setTextValue] = React.useState('');
  const [variantText, setVariantText] = React.useState('info');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [toBackend, setToBackend] = useState(false);

  const handleNextfield = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      passwordRef.current.focus();
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleNavigate();
    }
  };
  const passwordRef = React.useRef(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleNavigate = async () => {
    if (
      newPassword !== '' &&
      confirmPassword !== '' &&
      newPassword === confirmPassword
    ) {
      const encryptedPassword = {
        encryptedPassword: PassEncrypt(confirmPassword),
      };
      try {
        setToBackend(true);
        // console.log(encryptedPassword);
        let responsedata = await PostPassReset(encryptedPassword);
        // setToBackend(false);
        // if (responsedata.status === 200) {
        console.log(responsedata);
        
          navigate('/');
          setToBackend(false);
        // }
      } catch (error) {
        console.error('Error occured while reseting password', error);
        setOpen(true);
        setVariantText('error');
        setTextValue('Error while reseting try again');
      }
    } else if (newPassword === '' || confirmPassword === '') {
      setOpen(true);
      setVariantText('error');
      setTextValue('Password cannot be empty');
    } else {
      setOpen(true);
      setVariantText('error');
      setTextValue('Passwords do not match');
    }
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <div className="background-container"></div>
      <div className="overlay"></div>
      <div className="main-change">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '95vh',
          }}
        >
          <Paper
            elevation={5}
            style={{ borderRadius: '10px', padding: '10px 10px 40px 0px' }}
          >
            <div className="main-change-sub">
              <div className="eira-logo">
                <img
                  src={webdyn}
                  alt="logoImage"
                  className="image"
                  style={{ width: '120px', height: '60px', marginLeft: '1px' }}
                />
                {/* <Typography style={{ marginTop: "-20%", marginLeft: "56%", fontSize: "30px", fontWeight: "550" }}>Eira</Typography> */}
              </div>

              <Typography
                style={{
                  marginLeft: '10%',
                  fontSize: '26px',
                  fontWeight: '550',
                  marginTop: '5%',
                  color: '#5b5e5c',
                }}
              >
                Reset Password <AiFillLock />
              </Typography>
            </div>
            <div style={{ width: '460px' }}>
              <Typography
                style={{ marginLeft: '10%', color: '#6c756f', marginTop: '1%' }}
              >
                Unlock a new chapter in your EIRA account's story with the
                creation of your fresh password.
              </Typography>
            </div>
            <div
              style={{ width: '330px', marginLeft: '15px', marginTop: '0%' }}
            >
              <FormControl
                sx={{
                  m: 3,
                  width: '37ch',
                  borderRadius: '90px',
                  marginLeft: '10%',
                }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(m) => setNewPassword(m.target.value)}
                  onKeyDown={handleNextfield}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-placeholder="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="New Password"
                />
              </FormControl>
            </div>

            <div
              style={{ width: '330px', marginLeft: '15px', marginTop: '-6%' }}
            >
              <FormControl
                sx={{
                  m: 3,
                  width: '37ch',
                  borderRadius: '90px',
                  marginLeft: '10%',
                }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onKeyDown={handleEnterKeyPress}
                  inputRef={passwordRef}
                  onChange={(m) => setConfirmPassword(m.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-placeholder="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      ></IconButton>
                    </InputAdornment>
                  }
                  placeholder="Confirm Password"
                />
              </FormControl>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '8%',
                marginTop: '-4%',
              }}
            >
              <Checkbox
                {...label}
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              <Typography>Show Password</Typography>
            </div>

            <div style={{ marginLeft: '6.5%' }}>
              <Button
                variant="contained"
                startIcon={
                  toBackend && <CircularProgress size={20} color="inherit" />
                }
                disabled={toBackend}
                sx={{
                  fontSize: '26px',
                  width: '25rem',
                  backgroundColor: '#000440',
                  margin: '20px 0  0',
                  mt: 3,
                  height: '45px',
                  letterSpacing: '0.5px',
                  textTransform: 'capitalize',
                  borderRadius: '20px 20px 20px 20px',
                  ':hover': {
                    backgroundColor: '#FFFFFF',
                    color: '#000440',
                  },
                }}
                onClick={handleNavigate}
              >
                Reset Password
              </Button>
            </div>
          </Paper>
          <div>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert
                onClose={handleClose}
                severity={variantText}
                sx={{ width: '100%' }}
              >
                {textValue}
              </Alert>
            </Snackbar>
          </div>
        </Box>
      </div>
    </>
  );
};

export default ResetPass;
