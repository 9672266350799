import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

export const Equipmentsitedropdowns = async (id) => {
  // let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_LI_DROPDOWN}/${id}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

// export const fetchSiteandDateGet = async (id) => {
//   // let id = Number(sessionStorage.getItem(''));
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_SITE_DATE}/${id}`
//     );
//     console.log(response, 'response');
//     return response.data;
//   } catch (error) {
//     console.error('Error posting data:', error);
//     throw error;
//   }
// };
export const fetchSiteandDateGet = async (id, date) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_SITE_DATE}?siteId=${id}&downTimeDate=${date}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const fetchTodaySiteandDateGet = async (id, date) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_GET_TODAY_SITE_DATE}?siteId=${id}&downTimeDate=${date}`
    );
    console.log(response, 'response');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*------------------------- getDownTime Api -----------------------*/
export const getDownTimeMinutes = async (id) => {
  // let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGR_DOWNTIME}/${id}`
    );
    console.log('Raw API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*---------------------- POST---------------------*/
export const postDgrUpdate = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGR_POSTAPI}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

/*---------------------- GET Remark ---------------------*/
export const RemarksField = async (id) => {
  // let id = Number(sessionStorage.getItem(''));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGR_REMARK}/${id}`
    );
    console.log(response, 'dgrresponse');
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*---------------------- POST---------------------*/
export const postDgrValueSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_DGR_VALUE_POSTAPI}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
