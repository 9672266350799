let broadcastChannel;
let authToken;

export function initializeBroadcastChannel(token) {
  authToken = token;
  const UserId = sessionStorage.getItem('id');
  broadcastChannel = new BroadcastChannel(`EiraChannel${UserId}`);
}

export function getBroadcastChannel() {
  if (!broadcastChannel) {
    throw new Error('Broadcast channel has not been initialized.');
  }
  return broadcastChannel;
}

export function verifyToken(token) {
  console.log(token === authToken);
  return token === authToken;
}
