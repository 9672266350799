import React, { useMemo, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { fetchEquipmentIdDetails } from '../../Api/ExcelUploadAPi';
import { CheckForLight } from '../util/common/CommanFunction';
import { IconButton } from '@mui/material';
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const CombinedChart = (props) => {
  const {
    tableData,
    WidgetName,
    equipmentListAPI,
    customView,
    chartName,
    fallBack,
    from,
  } = props;
  console.log(props, 'props');
  const [barChart, setBarchartData] = React.useState();
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState('');

  useEffect(() => {
    setBarchartData(tableData);
  }, [props]);

  const chartData = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => [
      customView.range === 'daily'
        ? from === 'analytics'
          ? equipmentListAPI.filter(
              (x) => x.equipmentId === data.equipmentId
            )[0].customernaming
          : data.equipmentName
        : new Date(data.timeStamp).getTime(),
      data.todayEnergy,
    ]);
  }, [tableData]);

  const specificYiled = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => [
      customView.range === 'daily'
        ? from === 'analytics'
          ? equipmentListAPI.filter(
              (x) => x.equipmentId === data.equipmentId
            )[0].customernaming
          : data.equipmentName
        : new Date(data.timeStamp).getTime(),
      data.specificYield,
    ]);
  }, [tableData]);

  const equipmentName = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.equipmentName);
  }, [tableData]);

  /*--------------------------- Actual vs Expected ------------------*/

  const Irradiation = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => [
      new Date(data.timeStamp).getTime(),
      data.irradiation,
    ]);
  }, [tableData]);

  const ExceptedEnergy = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => [
      new Date(data.timeStamp).getTime(),
      data.exceptedEnergy,
    ]);
  }, [tableData]);

  const isLight = CheckForLight();
  const columnChartOptions = {
    chart: {
      type: 'column',
      backgroundColor: isLight ? 'white' : '#121212',
    },
    title: {
      text: chartName === 'Specific Yield' ? ` ${WidgetName} ` : WidgetName,
      style: {
        color: isLight ? 'black' : 'white',
      },
    },
    credits: {
      enabled: false,
    },

    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadJPEG',
            'downloadPNG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadCSV',
            'downloadXLS',
            // from === 'analytics' ? '' : 'viewData',
            from === 'Vanalytics'
              ? {
                  text: 'View Table',
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );
                    dataHtml = dataHtml.replace(
                      /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2}) \d{2}:\d{2}:\d{2}<\/th>/g,
                      '<th class="highcharts-text" scope="row">$1</th>'
                    );

                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : '',
          ],
        },
      },
      enabled: true,
      // showTable: true,
      columnHeaderFormatter: function (item, key) {
        if (item.isXAxis) {
          return item.options.title.text || item.name;
        }
        if (key === 'y') {
          return item.yAxis.options.title.text || item.name;
        }
      },
    },
    plotOption: {
      series: {
        events: {
          legendItemClick: function () {
            const series = this.chart.series;
            const currentSeries = this;

            if (series.length === 2) {
              const [series1, series2] = series;

              if (currentSeries === series1) {
                // If series1 is clicked
                if (series1.visible && !series2.visible) {
                  // Both series should be visible if series1 is visible and series2 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series1.visible && series2.visible) {
                  // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series2 invisible, and series1 remains visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (!series1.visible && !series2.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              } else if (currentSeries === series2) {
                // If series2 is clicked
                if (series2.visible && !series1.visible) {
                  // Both series should be visible if series2 is visible and series1 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && series1.visible) {
                  // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series1 invisible, and series2 remains visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && !series1.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              }

              // Finally, redraw the chart
              this.chart.redraw();
            } else {
              // Handle charts with more than two series or other cases
              this.chart.update(
                {
                  series: series.map((s) => {
                    if (s === currentSeries) {
                      return {
                        visible: currentSeries.visible ? false : true,
                      };
                    } else {
                      const visbleSeries = series.filter(
                        (series) => series.visible
                      );
                      console.log(
                        visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1,
                        visbleSeries
                      );

                      if (
                        visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1
                      ) {
                        visbleSeries.forEach((series) => {
                          series.update({ visible: false }, false);
                        });
                      }
                      return {
                        visible: s.visible
                          ? visbleSeries.length > 1 &&
                            visbleSeries.length < series.length - 1
                            ? true
                            : false
                          : true,
                      };
                    }
                  }),
                },
                true
              );
            }

            return false; // Prevent the default action of hiding the clicked series
          },
        },
      },
    },
    xAxis:
      chartName === 'Specific Yield' && customView.range === 'daily'
        ? {
            type: 'category',
            labels: {
              style: {
                color: isLight ? 'black' : 'white',
              },
            },
          }
        : {
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%d/%m/%Y',
            },
            title: {
              text: 'Time (HH:MM)',
            },
            labels: {
              style: {
                color: isLight ? 'black' : 'white',
              },
              formatter: function () {
                return Highcharts.dateFormat('%d/%m/%Y', this.value);
              },
            },
          },
    yAxis: [
      {
        title: {
          text: 'Energy Generation (kWh)',
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
        style: {
          color: '#666666',
          fontSize: '0.8em',
          fontFamily: 'Inter',
        },
        labels: {
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
      },
      {
        title: {
          text: 'Specific Yield',
          color: isLight ? 'black' : 'white',
        },
        opposite: true,
        visible: chartName === 'Actual Vs Expected' ? false : true,
        min: 0,
        max: 10,
        labels: {
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
      },
      {
        title: {
          text: 'Irradiation (Wh/m2)',
          color: isLight ? 'black' : 'white',
        },
        opposite: true,
        visible: chartName === 'Actual Vs Expected' ? true : false,
        // min: 0,
        // max: 10,
        labels: {
          style: {
            color: isLight ? 'black' : 'white',
          },
        },
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: isLight ? 'white' : '#121212',
      formatter: function ({ series, seriesIndex, dataPointIndex, w }) {
        const xLabel = this.point.options.name
          ? this.point.options.name
          : Highcharts.dateFormat('%Y-%m-%d', this.point.options.x);
        if (this.series.name === 'Energy Generation (kWh)') {
          return `<div class="custom-tooltip" style="color:${
            isLight ? 'black' : 'white'
          };">
            <p>${xLabel}</p>
            <p>Energy Generation: ${this.point.y} (kWh)</p>
          </div>`;
        } else if (this.series.name === 'Specific Yield') {
          return `<div class="custom-tooltip" style="color:${
            isLight ? 'black' : 'white'
          };">
            <p>${xLabel}</p>
            <p>Specific Yield : ${this.point.y} </p>
          </div>`;
        } else if (this.series.name === 'Irradiation') {
          return `<div class="custom-tooltip" style="color:${
            isLight ? 'black' : 'white'
          };">
            <p>${xLabel}</p>
            <p>Irradiation (Wh/m2): ${this.point.y} </p>
          </div>`;
        } else if (this.series.name === 'Today Energy') {
          return `<div class="custom-tooltip" style="color:${
            isLight ? 'black' : 'white'
          };">
            <p>${xLabel}</p>
            <p>Today Energy (kWh): ${this.point.y} </p>
          </div>`;
        } else if (this.series.name === 'ExceptedEnergy') {
          return `<div class="custom-tooltip" style="color:${
            isLight ? 'black' : 'white'
          };">
            <p>${xLabel}</p>
            <p>Excepted Energy (kWh): ${this.point.y} </p>
          </div>`;
        }
      },
    },
    legend: {
      itemStyle: {
        color: isLight ? 'black' : 'white',
      },
      itemHoverStyle: {
        color: isLight ? 'gray' : 'lightgray',
      },
    },
    series:
      chartName === 'Specific Yield'
        ? [
            {
              name: 'Energy Generation (kWh)',
              data: chartData,
              type: 'column',
              color: 'LightSkyBlue',
            },
            {
              name: 'Specific Yield',
              data: specificYiled,
              type: 'line',
              yAxis: 1,
              color: 'Navy',
            },
          ]
        : chartName === 'Actual Vs Expected'
        ? [
            {
              name: 'Today Energy',
              data: chartData,
              yAxis: 0,
              type: 'column',
              color: 'LightSkyBlue',
            },
            {
              name: 'ExceptedEnergy',
              data: ExceptedEnergy,
              type: 'line',
              // yAxis: 0,
              color: 'Navy',
            },
            {
              name: 'Irradiation',
              data: Irradiation,
              type: 'line',
              yAxis: 2,
              color: '#AD1378',
            },
          ]
        : '',
  };

  console.log(columnChartOptions, 'columnChartOptions');

  return (
    <div
      style={{
        overflowX: 'auto',
        height: from === 'Vanalytics' ? '500px' : null,
      }}
    >
      {tableData.length > 0 ? (
        showTable ? (
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  height: '40px',
                  marginRight: '10px',
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{WidgetName.split('-')[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: '100%',
                height: from === 'Vanalytics' ? '500px' : null,
              },
            }}
            options={columnChartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
export default CombinedChart;
