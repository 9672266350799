import React, { useEffect, useState } from 'react';
import './Loginpage.css';
import Eira from '../../images/eiralogo.jpg';
import {
  Paper,
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

//----------- Icons -----------//
import { AiFillLock } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';

import eira from '../../images/headerLogo.svg';
import { PostChangePassAPI } from '../../Api/PasswordControlApi';
import { PassEncrypt } from '../util/security/Cipher';

const ResetPass = () => {
  const location = useLocation();
  const isChange = location.state?.changePassword === true ? true : false;
  useEffect(() => {
    if (!isChange) {
      navigate('/');
    }
  }, [isChange]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [textValue, setTextValue] = React.useState('');
  const [variantText, setVariantText] = React.useState('info');
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [password, setPassword] = useState({
    newPassword: null,
    confirmPassword: null,
  });

  const handleChange = (e) => {
    // console.log(e);
    setPassword({ ...password, [e.target.name]: e.target.value });
  };
  // console.log(password);
  const handleNavigate = async () => {
    if (password.newPassword === password.confirmPassword) {
      try {
        let encryptedPassword = {
          encryptedPassword: PassEncrypt(password.confirmPassword),
        };
        console.log(encryptedPassword);
         let response = await PostChangePassAPI(encryptedPassword);
        // if (response.status === 200) {
        setOpen(true);
        setTextValue('Password Changed SuccessFully!');
        setVariantText('success');
        navigate('/');
        // }
      } catch (error) {
        setOpen(true);
        setTextValue('Please Try Again Later!');
        setVariantText('error');
        console.log(error);
      }
    } else {
      setOpen(true);
      setVariantText('error');
      setTextValue('Passwords do not match');
    }
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <div className="background-container"></div>
      <div className="overlay"></div>
      <div className="main-change">
        <Box
          sx={{
            display: 'flex',
            m: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: '95vh',
          }}
        >
          <Paper
            elevation={5}
            style={{ borderRadius: '10px', padding: '25px 50px 40px 0px' }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: '5%',
                }}
              >
                <img src={eira} alt="logoImage" style={{ width: '120px' }} />
              </div>
              <Typography
                style={{
                  marginLeft: '10%',
                  fontSize: '26px',
                  fontWeight: '550',
                  marginTop: '6%',
                  color: '#5b5e5c',
                }}
              >
                Change Password <AiFillLock />
              </Typography>
              <Typography
                style={{ marginLeft: '10%', color: '#6c756f', marginTop: '1%' }}
              >
                Welcome to a fresh start. Your new password <br /> is your
                ticket to a world of security and possibility.
              </Typography>
            </div>
            <div
              style={{ width: '330px', marginLeft: '15px', marginTop: '-1%' }}
            >
              <FormControl
                sx={{
                  m: 3,
                  width: '37ch',
                  borderRadius: '90px',
                }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-placeholder="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="New Password"
                />
              </FormControl>
            </div>
            <div
              style={{ width: '330px', marginLeft: '15px', marginTop: '-6%' }}
            >
              <FormControl
                sx={{
                  m: 3,
                  width: '37ch',
                  borderRadius: '90px',
                }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-placeholder="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      ></IconButton>
                    </InputAdornment>
                  }
                  placeholder="Confirm Password"
                />
              </FormControl>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '8%',
                marginTop: '-4%',
              }}
            >
              <Checkbox
                {...label}
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              <Typography>Show Password</Typography>
            </div>
            <div style={{ marginLeft: '6.5%' }}>
              <Button
                variant="contained"
                sx={{
                  fontSize: '26px',
                  width: '25rem',
                  backgroundColor: '#000440',
                  margin: '20px 0  0',
                  mt: 3,
                  height: '45px',
                  letterSpacing: '0.5px',
                  textTransform: 'capitalize',
                  borderRadius: '20px 20px 20px 20px',
                  ':hover': {
                    backgroundColor: '#FFFFFF',
                    color: '#000440',
                  },
                }}
                onClick={handleNavigate}
              >
                Change Password
              </Button>
            </div>
            <div>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert
                  onClose={handleClose}
                  severity={variantText}
                  sx={{ width: '100%' }}
                >
                  {textValue}
                </Alert>
              </Snackbar>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default ResetPass;
